import React from 'react';
import Header from '../../Components/Header/Header';

import usePromotion from '../../Hooks/usePromotion';
import OfferCard from '../../Components/OfferCard/OfferCard';

const Promotion = () => {
    const promotionData = usePromotion(`promotion`);

    return (
        <div className='inner-scroll promotion'>
            <div className='sticky-top'>
                <Header pageTitle={"Promotion"} />
            </div>

            <div className="row gx-0 p-2">
                <div className="col-12">
                    {
                        promotionData &&
                        promotionData?.map((item, index) =>
                            <OfferCard key={item?.title || index}
                                item={item}
                                // from={"promotion"}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default Promotion;