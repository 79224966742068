import React from 'react';

const CustomSectionTitle = ({ title, page = "", icon = null, }) => {
    return (
        <div className={(page === "menu") ? "row gx-0 d-flex justify-content-center align-items-center" : "row gx-0"}>
            <div className={(page === "menu") ? "col-8 col-md-6 d-flex justify-content-center align-items-center" : (page === "bankingProfile") ? "col-12" : ((page === "Sports Exchange") && (icon !== null)) ? "col-6 d-flex justify-content-start align-items-center" : "col-6"} style={{ borderBottom: "2px solid var(--gray)" }}>
                {
                    (icon !== null) &&
                    <img className="mb-1 me-2" width={""} height={"18px"} src={icon} alt={title} />
                }
                <h6 className="title-font mb-1">{title}</h6>
            </div>
        </div>
    );
};

export default CustomSectionTitle;