import React from 'react';
import './GameTitle.css';

const GameTitle = ({ title }) => {
    return (
        <section>
            <div className="row gx-0">
                <div className="col-12">
                    <h3 className="game-title">{title}</h3>
                </div>
            </div>
        </section>
    );
};

export default GameTitle;