import React, { useState } from 'react';
import './CustomInputField .css';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const CustomInputField = ({ type = "text", name = "", label = "", langLabel = "", langPlaceHolder = "", value = "", func, icon1, icon2, errorSms = null, checkExist, pattern = null, disabled = false }) => {
    const [visibleCondition, setVisibleCondition] = useState(false);    // password Visible condition
    const [showLabel, setShowLabel] = useState(false);

    const changeCondition = (() => {    // password check
        setVisibleCondition(!visibleCondition)
    })

    const checkInputForLevel = (e) => {
        if (e.target.value === "undefine" || e.target.value === "" || e.target.value === null) {
            setShowLabel(false);
        }

        if (e.target.name === "username" || e.target.name === "receiverNameTransfer") {
            checkExist(e);
        }
    }


    return (
        <div className="custom-input">

            <div onFocus={() => setShowLabel(true)} className="module my-2">

                {
                    (name === "address" || showLabel || disabled) ? <label htmlFor={name}>{langLabel || label}</label> : null
                }

                {
                    (errorSms) ? <small className="">{errorSms}</small> : ""
                }


                {
                    (type === "password")
                        ? <div className="input-group">
                            {
                                icon1 && <span className="input-group-text">{icon1}</span>
                            }
                            <input
                                className="form-control"
                                type={visibleCondition ? 'text' : 'password'}
                                id={name}
                                name={name}
                                placeholder={langPlaceHolder || label}
                                value={value}
                                autoComplete='off'
                                onFocus={() => setShowLabel(true)}
                                onChange={func}
                                onBlur={checkInputForLevel}
                                required
                            />
                            <span onClick={changeCondition} className="input-group-text">
                                {
                                    visibleCondition ? <AiFillEye /> : <AiFillEyeInvisible />
                                }
                            </span>
                        </div>
                        :
                        <div className="input-group">
                            {
                                icon1 && <span className="input-group-text">{icon1}</span>
                            }
                            <input
                                type={type}
                                id={name}
                                name={name}
                                placeholder={langPlaceHolder || label}
                                value={value}
                                className="form-control"
                                // aria-label="Amount (to the nearest dollar)"
                                autoComplete='off'
                                title={label}
                                onFocus={() => setShowLabel(true)}
                                onChange={func}
                                onBlur={checkInputForLevel}
                                onWheel={event => event.target.blur()}
                                required
                                pattern={pattern}
                                disabled={disabled}
                            />
                            {
                                icon2 && <span className="input-group-text">{icon2}</span>
                            }
                        </div>
                }

            </div>
        </div>
    );
};

export default CustomInputField;

