import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import propic from "../../../Assets/icon/propic.svg";
import './ProfileImgModal.css'
import { AiFillCamera } from "react-icons/ai";
import { origin, rootUrl } from '../../App';
import { fetchLoginUserData, fetchLogoutUser, userDetail } from '../../Redux/userSlice';
import { showCustomAlert } from '../../Redux/alertSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const ProfileImgModal = ({ profileImageModal, setProfileImageModalClose }) => {
    const userInfo = useSelector(userDetail);
    const dispatch = useDispatch();
    const [userImg, setUserImg] = useState(null);

    const inputPic = (e) => {
        const newFile = e.target.files[0];
        if (((e.target.files[0].type).split('/')[0]) === 'image') {
            setUserImg(newFile);
        } else {
            alert("select an image file")
            // dispatch(setCustomAlertTrue({ massage: `select an image file`, type: 'danger' }));
        }
    }

    const changeImgFunc = () => {
        const formData = new FormData()
        formData.append("photo", userImg);
        formData.append('request_origin', 'betkingly');

        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/auth/users/photo/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${value.value}`,
                },
                body: formData
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // dispatch(setCustomAlertTrue({ massage: `${data.report}`, type: 'success' }));
                })
                .catch(err => {
                    err.json().then(text => {
                        // console.log(text);
                        if (text?.error) {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.error}`,
                            }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.detail}`,
                            }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`,
                    }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse());
                    dispatch(fetchLoginUserData());
                    setProfileImageModalClose();
                    setUserImg(null);
                })
        }
    }

    const cancelImgUpload = () => {
        setUserImg(null);
        setProfileImageModalClose();
    }

    return (
        <Modal className='profileImgModal' show={profileImageModal} onHide={cancelImgUpload}>
            <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                <div className='w-100'>
                    <div style={{ backgroundColor: 'var(--green)', color: "#fff", textAlign: "center", position: "relative" }} className='mb-2 p-1 py-1 '>
                        <div onClick={cancelImgUpload} style={{ position: "absolute", left: "5px", top: "6px" }} className="d-block d-md-none">
                            <i style={{ fontSize: '26px', fontWeight: 'bold' }} className='fa fa-angle-left ms-2'></i>
                        </div>
                        <h5 style={{ color: '#fff' }} className='text-center m-0'>{t('uploadimage')}</h5>
                        <div onClick={cancelImgUpload} style={{ position: "absolute", right: "10px", top: "7px" }} className="d-none d-md-block">
                            <i style={{ fontSize: '18px' }} className='fa fa-times ms-2'></i>
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className='d-flex align-items-center justify-content-center'>
                            <div className='imgModalContainer'>
                                {
                                    userImg ?
                                        <div className="modalImage">
                                            <img className="img-fluid" style={{ objectFit: "cover" }} src={`${URL.createObjectURL(userImg)}`} alt="user-dp" />
                                        </div>
                                        :
                                        userInfo?.user?.photo ?
                                            <div className="modalImage">
                                                <img src={`${rootUrl}${userInfo?.user?.photo}${origin}`} className="img-fluid" alt="UserImg" />
                                            </div>
                                            :
                                            <div className="modalImage">
                                                <AiFillCamera style={{ color: "#555555", fontSize: '60px' }} />
                                            </div>
                                }

                                <div style={{ position: 'absolute', bottom: "-15px", left: "calc(50% - 15px)", borderRadius: "50%", height: "30px", width: "30px", background: "white", boxShadow: "0 2px 5px gray", display: "flex", alignItems: 'center', justifyContent: "center" }}>
                                    <input className='d-none' type="file" id="profilePic" onChange={inputPic} />
                                    <label htmlFor='profilePic'>
                                        <AiFillCamera style={{ color: "#000", fontSize: '25px', cursor: "pointer" }} />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center row g-0 my-4">
                            <button className="col-5 btn btn-green" onClick={changeImgFunc} disabled={userImg === null ? true : false}> <Trans i18nKey="savebutton">Save</Trans></button>
                            <button className="col-5 btn btn-yellow" onClick={cancelImgUpload}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ProfileImgModal;