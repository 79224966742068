import React, { useEffect } from 'react';
import './Home.css';
import Footer from '../../Components/Footer/Footer';
// import { Outlet } from 'react-router-dom';
import LayoutWithoutHeaderBackButton from '../../Layout/LayoutWithoutHeaderBackButton';
import Sport from '../Sport/Sport';
import SlidingNavbar from '../../Components/SlidingNavbar/SlidingNavbar';
import CustomMarquee from '../../Components/CustomMarquee/CustomMarquee';
// import { GiTrophy } from 'react-icons/gi';
import Slider from '../../Components/Slider/Slider';
import { navbarData } from '../../Data/HomePageData';
import Lottery from '../Lottery/Lottery';
import LiveCasino from '../LiveCasino/LiveCasino';
// import Casino from '../Casino/Casino';
import Slots from '../Slots/Slots';
// import VirtualGames from '../VirtualGames/VirtualGames';
import Sports from '../Sports/Sports';
// import SportsExchange from '../SportsExchange/SportsExchange';
// import Sportsbook from '../Sportsbook/Sportsbook';
import { useSelector } from 'react-redux';
import { userDetail } from '../../Redux/userSlice';
import { mother_site } from '../../App';
import { useNavigate } from 'react-router-dom';
import GetStarted from '../GetStarted/GetStarted';
import Fishing from '../Fishing/Fishing';

import announce from "./../../Assets/Icons/announce.svg";
import BoardGame from '../BoardGame/BoardGame';

const Home = ({ getStartRef, setTrueStart, logo, selectedFragmentId, setSelectedFragmentID }) => {

    const user = useSelector(userDetail);
    const navigate = useNavigate();

    let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
    let url_val = parentUrl.split('/');
    let make_url = url_val[0] + '//' + url_val[2] + '/';

    const innerContents = [
        {
            id: 0,
            name: 'home',
            fragment: <Sport setSelectedFragmentID={setSelectedFragmentID} />
            // fragment: <Sports setSelectedFragmentID={setSelectedFragmentID} />
        },
        // {
        //     id: 1,
        //     name: 'exchange',
        //     // fragment: <SportsExchange />
        // },
        {
            id: 2,
            name: 'sportsbook',
            // fragment: <Sportsbook />
            fragment: <Sports />
        },
        {
            id: 3,
            name: 'lottery',
            fragment: <Lottery />
        },
        {
            id: 4,
            name: 'live_casino',
            fragment: <LiveCasino />
        },
        // {
        //     id: 5,
        //     name: 'casino',
        //     fragment: <Casino />
        // },
        {
            id: 6,
            name: 'slots',
            fragment: <Slots />
        },
        // {
        //     id: 7,
        //     name: 'virtual_sport',
        //     fragment: <VirtualGames />
        // },
        {
            id: 8,
            name: 'Fishing',
            fragment: <Fishing />
        },
        {
            id: 9,
            name: 'BoardGames',
            fragment: <BoardGame />
        },
    ]

    useEffect(() => {
        if ((selectedFragmentId === 1) && (user?.user?.user_base_origin === make_url) && (make_url !== mother_site)) {
            navigate("/");
            setSelectedFragmentID(0);
        }
    }, [user?.user?.user_base_origin, selectedFragmentId, setSelectedFragmentID, navigate, make_url]);

    return (
        <>
            {
                (innerContents[selectedFragmentId]?.name === "exchange") ?
                    <LayoutWithoutHeaderBackButton logo={logo} setSelectedFragmentID={setSelectedFragmentID}>

                        <main className=''>
                            {
                                // innerContents[selectedFragmentId]?.fragment
                                (innerContents?.find(item => item?.id === selectedFragmentId))?.fragment
                            }
                        </main>

                        {/* <Footer /> */}
                    </LayoutWithoutHeaderBackButton>
                    :
                    (innerContents[selectedFragmentId]?.name === "sportsbook") ?
                        <LayoutWithoutHeaderBackButton logo={logo} setSelectedFragmentID={setSelectedFragmentID}>

                            <main className=''>
                                {
                                    // innerContents[selectedFragmentId].fragment
                                    (innerContents?.find(item => item?.id === selectedFragmentId))?.fragment
                                }
                            </main>
                        </LayoutWithoutHeaderBackButton>
                        :
                        // ((innerContents[selectedFragmentId].name !== "exchange") || (innerContents[selectedFragmentId].name !== "sportsbook")) &&
                        // getStartRef === null ?
                        //     <GetStarted setTrueStart={setTrueStart} />
                        //     :
                        <LayoutWithoutHeaderBackButton getStartRef={getStartRef} setTrueStart={setTrueStart} logo={logo} setSelectedFragmentID={setSelectedFragmentID}>
                            {
                                getStartRef === null ?
                                    <main className=''>
                                        <GetStarted setTrueStart={setTrueStart} />
                                    </main>
                                    :
                                    <main className=''>
                                        <Slider />
                                        <div style={{
                                            position: 'relative',
                                            height: '30px',
                                        }}>
                                            <button className='d-flex align-items-center justify-content-center trophyBtn'>
                                                <img src={announce} alt="announce" />
                                            </button>

                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                zIndex: 9,
                                            }}>
                                                <CustomMarquee />
                                            </div>
                                        </div>

                                        <SlidingNavbar pageName={"Home"} navbarData={navbarData} selectedFragmentId={selectedFragmentId} setSelectedFragmentID={setSelectedFragmentID} />

                                        {
                                            // innerContents[selectedFragmentId]?.fragment
                                            (innerContents?.find(item => item?.id === selectedFragmentId))?.fragment
                                        }
                                    </main>
                            }
                            {getStartRef === null || <Footer />}
                        </LayoutWithoutHeaderBackButton>
            }
        </>
    );
};

export default Home;