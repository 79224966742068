import { useEffect, useState } from "react";
import { origin, rootUrl } from "../App";
import { useDispatch } from "react-redux";

export const useBankingProfile = () => {
    const [referralBonus, setReferralBonus] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/wallet/referral-bonus-limit/${origin}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Token ${value.value}`,
                },
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    let referralBonusAmount = (data?.find(item => item?.purpose === ("referral_bonus_on_deposit")));
                    setReferralBonus(referralBonusAmount?.bonus_percentage);
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            // dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch])
    return {
        referralBonus,
    };
};