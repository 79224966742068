import React, { useState } from 'react';
import GameTitle from '../../Components/GameTitle/GameTitle';
import GameCard from '../../Components/GameCard/GameCard';
// import { useSelector } from 'react-redux';
// import { homeLeftData } from '../../Data/HomePageData';
// import { LiveCasinoGame } from '../../Redux/gameSlice';
// import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
// import { liveCasinoLeftData } from '../../Data/LiveCasinoPageData';
import { leftData } from '../../Data/ProvidersPageData';
import { Trans } from 'react-i18next';
import ProvidersLeftSideBar from '../../Components/ProvidersLeftSideBar/ProvidersLeftSideBar';
import { useProvidersLeftSideBar } from '../../Hooks/useProvidersLeftSideBar';
import useGameData from '../../Hooks/useGameData';

const LiveCasino = () => {
    const { leftSideBarData } = useProvidersLeftSideBar();

    const gameCode = "LC";

    const leftLiveCasinoSideBar = leftSideBarData?.filter((item) => (item?.game_code)?.includes(gameCode));

    const [selectedLeftBarData, setSelectedLeftBarData] = useState(leftData[0]);
    // const liveCasinoGames = useSelector(LiveCasinoGame);
    const liveCasinoGames = useGameData(gameCode, selectedLeftBarData?.provider_code);

    // const showableExtraCards = (selectedLeftBarData?.extra_param === "iframe") ? [selectedLeftBarData] : liveCasinoGames;

    const iframes = leftLiveCasinoSideBar?.filter((item) => item?.extra_param === "iframe");
    const notIframes = leftLiveCasinoSideBar?.filter((item) => item?.extra_param !== "iframe");

    const showableExtraCards = (selectedLeftBarData?.name === "All") ? iframes.concat(liveCasinoGames) : (selectedLeftBarData?.type === "lobby") ? iframes : liveCasinoGames;

    // console.log(selectedLeftBarData, liveCasinoGames);

    return (

        <section className='row g-0 p-2'>
            {
                leftLiveCasinoSideBar &&
                <>
                    <ProvidersLeftSideBar selectedLeftBarData={selectedLeftBarData || leftData[0]} setSelectedLeftBarData={setSelectedLeftBarData} leftSideBarData={[...leftData, ...notIframes]} />
                    <div className="game-card-area gx-0 col-10">
                        <GameTitle title={<Trans i18nKey="casinoSubTitle">Casino</Trans>} />
                        <GameCard games={showableExtraCards} providerType={gameCode} />
                    </div>
                </>
            }
        </section>
    );
};

export default LiveCasino;