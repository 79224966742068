import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRegistrationData } from '../../Redux/userSlice';
import regSuccessImg from '../../Assets/Images/Registration/Group 414.svg'
import { useNavigate } from 'react-router-dom';

const RegSuccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setRegistrationData(null));
    }, [dispatch])
    return (
        <div className='inner-scroll registration'>
            <div className='position-relative'>
                <h3 className="text-center p-3">Your Registration Successful</h3>
                {/* <div className='position-absolute' style={{ top: "12px", left: '10px' }}>
                    <AiFillLeftCircle size='35' color='var(--yellow)' onClick={() => { navigate(-1, { replace: true }) }} />
                </div> */}
            </div>

            <div>
                <div className='d-flex align-items-center justify-content-center'>
                    <img className='confirmMailImg' src={regSuccessImg} alt="confirm mail img" />
                </div>
                <p className='p-0 py-2 m-0 text-center'></p>

                <div className='mx-4 mb-4'>
                    <div className='d-flex justify-content-center'>
                        <button onClick={() => navigate("/login", { replace: true })} type='button' className="btn btn-yellow my-2 w-100">Log in your account</button>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button onClick={() => navigate("/", { replace: true })} type='button' className="btn btn-white my-2 w-100">Back to Home</button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default RegSuccess;