import React, { useEffect, useState } from 'react';
import { rootUrl } from '../../../App';
import './PcsoPrize.css';
import { AiFillStar } from 'react-icons/ai';
import { Trans } from 'react-i18next';

const PcsoPrize = () => {

    // const staticTableData = [
    //     {
    //         id: 0,
    //         serial: 1,
    //         gameType: `6D STRAIGHT & RUMBLE`,
    //         d_win: `$1 X $4000`,
    //         r_win: `$1 X $400`,
    //         discount: `15%`,
    //     },
    //     {
    //         id: 1,
    //         serial: 2,
    //         gameType: `6D OPEN`,
    //         d_win: `$1 X $60 `,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 2,
    //         serial: 3,
    //         gameType: `6D CLOSE`,
    //         d_win: `$1 X $60`,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 3,
    //         serial: 4,
    //         gameType: `4D STRAIGHT & RUMBLE`,
    //         d_win: `$1 X $700`,
    //         r_win: `$1 X $200`,
    //         discount: `15%`,
    //     },
    //     {
    //         id: 4,
    //         serial: 5,
    //         gameType: `4D OPEN`,
    //         d_win: `$1 X $60`,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 5,
    //         serial: 6,
    //         gameType: `4D CLOSE`,
    //         d_win: `$1 X $60`,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 6,
    //         serial: 7,
    //         gameType: `3D STRAIGHT & RUMBLE`,
    //         d_win: `$1 X $400`,
    //         r_win: `$1 X $80`,
    //         discount: `15%`,
    //     },
    //     {
    //         id: 7,
    //         serial: 8,
    //         gameType: `3D OPEN`,
    //         d_win: `$1 X $60`,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 8,
    //         serial: 9,
    //         gameType: `3D CLOSE`,
    //         d_win: `$1 X $60`,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 9,
    //         serial: 10,
    //         gameType: `3D SENGLE`,
    //         d_win: `$1 X $3`,
    //         r_win: `X`,
    //         discount: `0%`,
    //     },
    //     {
    //         id: 10,
    //         serial: 11,
    //         gameType: `2D STRAIGHT & RUMBLE`,
    //         d_win: `$1 X $250`,
    //         r_win: `$1 X $120`,
    //         discount: `15%`,
    //     },
    //     {
    //         id: 11,
    //         serial: 12,
    //         gameType: `2D OPEN `,
    //         d_win: `$1 X $28`,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 12,
    //         serial: 13,
    //         gameType: `2D CLOSE`,
    //         d_win: `$1 X $28`,
    //         r_win: `X`,
    //         discount: `5%`,
    //     },
    //     {
    //         id: 13,
    //         serial: 14,
    //         gameType: `ANY GAME TOTAL`,
    //         d_win: `$1 X $8`,
    //         r_win: `X`,
    //         discount: `0%`,
    //     },
    // ];
    const staticData = [
        {
            id: 0,
            description: `(Bangkok time every day 1:00pm, 4:00pm, 8:00pm).`,
        },
        {
            id: 1,
            description: `6D Rumble any combination`,
        },
        {
            id: 2,
            description: `4D Rumble any combination`,
        },
        {
            id: 3,
            description: `2D Rumble 4 combination example 01-02 win number 01-02/01-02/02-01/20-01`,
        },
        {
            id: 4,
            description: `Last 3D 2Up Straight example 123 win number 231`,
        },
        {
            id: 5,
            description: `First 4D 2Up Straight example 123 win number 121`,
        },
        {
            id: 6,
            description: `Last 4D 2Up Down Straight example 1234 win number 341`,
        },
        {
            id: 7,
            description: `Last 4D 2Up Down Straight example 1234 win number 341`,
        },
        {
            id: 8,
            description: `Last 4D 2Up Down Straight example 1234 win number 341`,
        },
        {
            id: 9,
            description: `Last 4D 2Up Down Straight example 1234 win number 341`,
        },
    ];

    const [pcsoPrizeData, setPcsoPrizeData] = useState(null);
    useEffect(() => {
        fetch(`${rootUrl}/lottery/pcso-paytable-odds/`)
            .then(res => {
                if (!res.ok) return res.json().then(text => { throw new Error(text?.error) });
                else return res.json();
            })
            .then(data => {
                setPcsoPrizeData(data);
            })
    }, []);

    return (
        <div className="w-100 p-3 mb-1" style={{ backgroundColor: "var(--light)" }}>
            <section className="row gx-0 py-1 d-flex flex-column justify-content-center align-items-center">
                <div className="col-12 text-white text-uppercase text-center fw-bold rounded p-1 mb-3" style={{ backgroundColor: "var(--green)" }}>
                    <p className="p-0 m-0"><Trans i18nKey="paytableOddsTitle">PAYTABLE AND ODDS</Trans></p>
                </div>
            </section>

            <section className="row gx-0 d-flex justify-content-center align-items-center">
                <div className="col-12">
                    <div className="row gx-0 d-flex justify-content-between align-items-start">
                        <div className="col-12 text-center mb-3 " style={{ overflowX: "auto" }}>
                            <table className="table table-bordered border-golden prize-table-font rounded">
                                <thead className="text-uppercase" style={{ backgroundColor: "var(--light)", color: "var(--dark)" }}>
                                    <tr valign="top">
                                        <th className='text-center' scope="col">SL</th>
                                        <th className='text-center' scope="col">Game Type</th>
                                        <th className='text-center' scope="col">D WIN</th>
                                        <th className='text-center' scope="col">r win</th>
                                        <th className='text-center' scope="col">Discount</th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "var(--light)", color: "var(--dark)" }}>
                                    {
                                        pcsoPrizeData?.map((item, index) =>
                                            <tr key={index} className="">
                                                <th className='text-center' scope="row">{++index}.</th>
                                                <td className='text-start'>{item?.game_type}</td>
                                                <td style={{ color: "var(--green)", fontWeight: "bold", textAlign: "center" }}>{item?.d_amount}</td>
                                                <td style={{ color: "var(--green)", fontWeight: "bold", textAlign: "center" }}>{item?.r_amount}</td>
                                                <td className='text-center'>{item?.discount}%</td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>

                        <div className="col-12 text-start mb-3 prize-info-font" style={{ backgroundColor: "" }}>
                            {
                                staticData?.map((data, index) =>
                                    <div key={index} className="pe-4">
                                        <p className="ps-2 py-1 mb-1 pointer rounded-start">
                                            <AiFillStar />
                                            {data?.id === 0 && <> <AiFillStar /> <AiFillStar /> </>}
                                            {` `}
                                            {data?.description}
                                        </p>
                                        {/* <div className="triangle-right"></div> */}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PcsoPrize;