import React, { useEffect, useState } from 'react';
import { origin, rootUrl } from '../../App';
import Header from '../../Components/Header/Header';
import { Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { MdAdminPanelSettings, MdSupportAgent } from 'react-icons/md';
import { BiMessageError, BiSearch } from 'react-icons/bi';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const VerifiedAgents = () => {
    const dispatch = useDispatch();
    const [agentContactList, setAgentContactList] = useState(null);
    const [visibleAgentList, setVisibleAgentList] = useState(null);
    const [contactList, setContactList] = useState(null);
    const [verifiedAgentList, setVerifiedAgentList] = useState(
        {
            id: 0,
            navName: `Admin`,
            langNavName: 'adminAgentLists',
            icon: <MdAdminPanelSettings size={20} className='me-2' />,
            link: `/admin/`,
        },
    );

    const secondaryNavigationContent = [

        {
            id: 0,
            navName: `Admin`,
            langNavName: 'adminAgentLists',
            icon: <MdAdminPanelSettings size={20} className='me-2' />,
            link: `/admin/`,
        },
        {
            id: 1,
            navName: `Verified Agent List`,
            langNavName: 'verifiedAgentLists',
            icon: <MdSupportAgent size={20} className='me-2' />,
            link: `/verified_agent/`,
        },
        {
            id: 2,
            navName: `Complain`,
            langNavName: 'complainAgentLists',
            icon: <BiMessageError size={20} className='me-2' />,
            link: `/complain/`,

        },
    ];

    useEffect(() => {
        dispatch(setInnerLoaderTrue());
        fetch(`${rootUrl}/statics/agent-social-user-list/${origin}`)
            .then((res) => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then((data) => {
                // console.log(data);
                setAgentContactList(data);
                setVisibleAgentList(data);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`${rootUrl}/statics/social-connections/${origin}`)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                // console.log(data);
                setContactList(data);
                dispatch(setInnerLoaderFalse());
            })
    }, [dispatch, verifiedAgentList?.id]);


    const checkValue = (element, value) => {
        if (((element.referral_code).toLowerCase()).includes(value.toLowerCase())) return true;
        if ((element.country_name).toLowerCase().includes(value.toLowerCase())) return true;
        if ((element.old_resource).toLowerCase().includes(value.toLowerCase())) return true;
        if ((element.resource).toLowerCase().includes(value.toLowerCase())) return true;
        else return false;
    }

    const searchAgent = (e) => {
        e.preventDefault();
        let value = e.target.searchValue.value;
        if (value === "") {
            setVisibleAgentList(agentContactList);
            return;
        }

        let data = agentContactList.filter(element => checkValue(element, value));
        setVisibleAgentList(data);
    }

    return (
        <div className="inner-scroll verified-agents">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="agentListPage">Agent List</Trans>} />
            </div>
            <Navbar className="secondNavigation d-flex justify-content-center">
                {/* desktop view */}
                <div className="bar scroll-section">
                    <div style={{ overflowX: "auto", overflowY: 'hidden', whiteSpace: 'nowrap', scrollbarWidth: 0, scrollBarHeight: 0, textTransform: "uppercase", }}>
                        <div className="hstack">
                            {
                                secondaryNavigationContent?.map((oneNav, index) =>
                                    <NavLink key={index} className={() => (verifiedAgentList?.id === oneNav?.id ? "active nav-link" : "nav-link")} onClick={() => setVerifiedAgentList(oneNav)} >
                                        {oneNav.icon}
                                        <span>{t(oneNav.langNavName)}</span>
                                    </NavLink>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Navbar>

            <section className="row gx-0 my-3 my-md-3 d-flex flex-column justify-content-center align-items-center">
                <div className="col-11 col-md-11 text-white text-uppercase text-center fw-bold rounded py-1 mb-0" style={{ backgroundColor: "var(--green)" }}>
                    <p className="p-0 m-0">{t(verifiedAgentList?.langNavName)}</p>
                </div>
            </section>

            {
                verifiedAgentList?.id === 1 &&
                <section className="row gx-0 justify-content-center align-items-center">
                    <div className="col-12 col-md-11">
                        <p
                            style={{
                                color: "#FE1E1E",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "10px 0",
                                margin: "0",
                                fontWeight: 600,
                            }}
                        >
                            {t('textVerifiedAgentLists')}
                        </p>


                        <div className='row gx-0 justify-content-center'>
                            <form className="col-8 d-flex justify-content-center" onSubmit={searchAgent}>
                                <input className="me-2" type="search" placeholder="Search" aria-label="Search" name='searchValue' style={{ outline: "none", border: "1px solid grey", borderRadius: "5px", padding: "0 10px", margin: "0" }} />
                                <button className="btn btn-yellow px-3 py-0" type="submit"><BiSearch /></button>
                            </form>
                        </div>


                        <div className="my-3" style={{ overflowX: "auto", fontSize: "10px" }}>
                            <table className="table table-bordered border-golden">
                                <thead
                                    className="text-uppercase"
                                    style={{ backgroundColor: "#3D3D3D", color: "#fff" }}
                                >
                                    <tr valign="top">
                                        {/* <th className="text-center" scope="col">
                                                SL
                                            </th> */}
                                        <th className="text-center" scope="col">
                                            AGENT CODE
                                        </th>
                                        <th className="text-center" scope="col">
                                            AGENT COUNTRY
                                        </th>
                                        <th className="text-center" scope="col">
                                            App Link
                                        </th>
                                        <th className="text-center" scope="col">
                                            Old
                                        </th>
                                        {/* <th className="text-center" scope="col">
                                            New
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "#000" }}>
                                    {visibleAgentList &&
                                        visibleAgentList?.map((item, index) => (
                                            <tr key={index} className="" style={{ color: '#FFD600' }}>
                                                {/* <td className="text-center">{++index}.</td> */}
                                                <td className="">{item?.referral_code}</td>
                                                <td className="text-uppercase">
                                                    <img
                                                        style={{ backgoundColor: 'white', height: '20px', width: '20px', borderRadius: '50%', marginRight: '10px' }}
                                                        src={`${rootUrl}${item?.country_logo}`} alt="country flag"
                                                    />
                                                    <span>{item?.country_name}</span>
                                                </td>

                                                <td>
                                                    <div className="">
                                                        {/* { */}
                                                        {/* item?.social &&
                                                            item?.social.map(socialLink => */}
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            <p className="d-flex justify-content-center align-items-center mb-0" style={{ color: "#61FF39" }}>{item?.resource || ``}</p>
                                                            <a

                                                                className='d-flex justify-content-center align-items-end'
                                                                href={item?.prefix + item?.resource}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <img
                                                                    src={`${rootUrl}${item?.logo}`} alt="country flag"
                                                                    style={{ backgoundColor: 'white', height: '20px', width: '20px', borderRadius: '50%', marginLeft: '10px' }}
                                                                />
                                                            </a>
                                                        </div>

                                                        {/* ) */}
                                                        {/* // } */}
                                                    </div>
                                                </td>
                                                <td className="" style={{ color: "#FE1E1E", textDecoration: "line-through", }}>{item?.old_resource || `-`}</td>
                                                {/* <td className="text-center" style={{ color: "var(--green)" }}>{item?.social[0]?.resource || `N/A`}</td> */}
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            }
            {
                (verifiedAgentList?.id === 0) &&
                <>
                    <div className='mx-4 my-4'>
                        <div className="row gx-0 d-flex justify-content-center align-items-center">
                            <h3 className="text-capitalize text-center fw-bold">{t('atYourServiceAgentList')}</h3>
                        </div>
                        <div className="row gx-0 d-flex justify-content-center align-items-center">
                            <div className='col-12'>
                                {/* <p className='m-0 mx-3 mt-3 pb-4' style={{ color: "#000", fontSize: "20px" }}>At Your Service.....</p> */}
                                <ul className="row g-0 align-items-center" style={{ color: "#000", listStyle: "none", margin: 0, padding: 0 }}>
                                    {
                                        contactList?.map((item, index) =>
                                            <li className='col-12' key={index}>
                                                <div className='d-flex align-items-center'>
                                                    <img className='me-3 my-2' src={item?.logo} alt="" style={{ height: "30px", width: "30px", objectFit: "cover" }} />
                                                    <a href={item?.social?.prefix + item?.resource} target="_blank" rel="noreferrer" style={{ fontSize: "18px", color: "#000", textDecoration: "none", }} >
                                                        {item?.social?.name} <small style={{ fontSize: "14px" }}> - <span className="green-text">{item?.resource}</span></small>
                                                    </a>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                (verifiedAgentList?.id === 2) &&
                <section className="row gx-0 my-5">
                    <div className="col-11 col-md-11">
                        <h3 className="text-center">Upcoming...</h3>
                    </div>
                </section>
            }

        </div>
    );
};

export default VerifiedAgents;