import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userDetail } from '../../Redux/userSlice';
import { BsChevronRight } from 'react-icons/bs';

const MenuList = ({ list }) => {
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);

    return (
        <div className="row gx-0 d-flex justify-content-center align-items-center">
            {
                list?.map((item, index) =>
                    <button type="button" key={item?.id} onClick={() => navigate(item?.link + ((userInfo?.user?.username) ? ("?" + userInfo?.user?.username) : ''))} className={((!userInfo?.user?.agent) && (item?.link === "/agent-admin")) ? "d-none" : "col-12 d-flex justify-content-between align-items-center item mb-1 px-3 py-2"} style={{ outline: "none", border: "none", }}>
                        <div key={item?.id} className="nav-link">
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={item?.img} alt="menu-item" className="" height="23" width="23" />
                                <span className="item-text ms-3">{item?.navName}</span>
                            </div>
                        </div>
                        <BsChevronRight />
                    </button>
                )
            }
        </div>
    );
};

export default MenuList;