import React, { useState } from 'react';
import './GameCard.css';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import imageLoader from '../../Assets/Images/ImageLoader/image-loader0.png';

import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUserWallet, userDetail } from '../../Redux/userSlice';
import { rootUrl } from '../../App';
// import { useSportsExchange } from '../../Hooks/useSportsExchange';
// import { useSportLauncher } from '../../Hooks/useSportLauncer';
import useGetGSBalance from '../../Hooks/useGetGSBalance';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { showCustomAlert } from '../../Redux/alertSlice';

const GameCard = ({ games, providerType }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(userDetail);

    const [loadingImage, setLoadingImage] = useState(false);

    // const { exchangeLaunchFunction } = useSportsExchange();
    // const { sportLaunchFunction } = useSportLauncher();
    const { gsBalanceGetFetchAsync } = useGetGSBalance();

    // const launchFunc = (game) => {

    //     if (!user?.is_login) {
    //         // alert('Please Login');
    //         navigate("/login");
    //         return;
    //     }


    //     let fullUrl = `${gameLaunch_site}?tfkhgjfecb646c6c2b7fe0fae3fb183d0036d25ba7f50eslkgdhjloken=${JSON.parse(localStorage.getItem("bet7uUserKey"))?.value}&p_code=${game.p_code}&p_type=${game.p_type}&g_code=${game.g_code}`;


    //     const mainDiv = document.getElementById("main");
    //     const getHeight = mainDiv.scrollHeight;
    //     const getWidth = mainDiv.clientWidth;

    //     const gameLaunchWindow = window.open(`${fullUrl}`, `<div id="gameLauncher"></div>`, `width=${getWidth},height=${getHeight}`);

    //     window.onload = () => {
    //         gameLaunchWindow.opener.document.getElementById("gameLauncher").innerHTML = `<div id=""></div>`;
    //     }

    //     dispatch(fetchLoginUserWallet());
    // }

    // const gsSportsBookIBLaunch = (redirectToken) => {

    //     const mainDiv = document.getElementById("main");
    //     const getHeight = mainDiv.scrollHeight;
    //     const getWidth = mainDiv.clientWidth;

    //     const IBExtraparams = (window.innerWidth > 991) ? `?html5=0&webskintype=7&sportid=50` : `?html5=1&skin=7&sportid=50`;
    //     const IBExtraparamsForResponse = (window.innerWidth > 991) ? `&webskintype=7&sportid=50` : `&skin=7&sportid=50`;

    //     let fullUrl = `${rootUrl}/gs/game-launch/IB/SB/${IBExtraparams}`;

    //     fetch(fullUrl, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //             'Authorization': `Token ${redirectToken}`,
    //         }
    //     })
    //         .then(res => {
    //             if (!res.ok) throw res;
    //             else return res.json();
    //         })
    //         .then(data => {
    //             // console.log(data);
    //             if (data?.errMsg === "SUCCESS") {
    //                 const launchWindow = window.open(`${data?.gameUrl + IBExtraparamsForResponse}`, `<div id="launchWindow"></div>`, `width=${getWidth},height=${getHeight}`);
    //                 window.onload = () => {
    //                     launchWindow.opener.document.getElementById("launchWindow").innerHTML = `<div id=""></div>`;
    //                 }

    //                 dispatch(fetchLoginUserWallet());
    //             }
    //         })
    //         .catch(err => {
    //             err.json().then(text => {
    //                 if (text?.detail === "Invalid token.") {
    //                     // dispatch(fetchLogoutUser());
    //                     return;
    //                 }
    //             });
    //         })
    //         .finally(() => {
    //             // dispatch(setInnerLoaderFalse());
    //             // setPwaLoader(true);
    //         })
    // };

    const gsGameLaunchFunction = (redirectToken, game) => {

        // dispatch(setInnerLoaderTrue());

        const gameLaunchExtraparams = `&html5=1`;

        const mainDiv = document.getElementById("main");
        const getHeight = mainDiv.scrollHeight;
        const getWidth = mainDiv.clientWidth;

        // let fullUrl = `${rootUrl}/gs/game-launch/${game?.p_code}/${game?.p_type}/?g_code=${game?.g_code}/${gameLaunchExtraparams}`;
        let fullUrl = `${rootUrl}/gs/game-launch/${game?.p_code}/${providerType}/?g_code=${game?.g_code}${gameLaunchExtraparams}`;

        fetch(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${redirectToken}`,
            }
        })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                if (data?.errMsg === "SUCCESS") {
                    const launchWindow = window.open(`${data?.gameUrl + gameLaunchExtraparams}`, `<div id="launchWindow"></div>`, `width=${getWidth},height=${getHeight}`);
                    window.onload = () => {
                        launchWindow.opener.document.getElementById("launchWindow").innerHTML = `<div id=""></div>`;
                    }

                    if (data?.error) {
                        dispatch(showCustomAlert({ message: `${data?.error}`, type: 'error' }));
                    }

                    // dispatch(fetchLoginUserWallet());
                }
            })
            .catch(err => {
                err.json().then(text => {
                    if (text?.errMsg === `Account transaction is locked`) {
                        dispatch(showCustomAlert({ message: `${text?.errMsg}`, type: 'error' }));
                        return;
                    }
                    if (text?.detail === "Invalid token.") {
                        // dispatch(fetchLogoutUser());
                        return;
                    }
                });
            })
            .finally(() => {
                dispatch(setInnerLoaderFalse());
                // setPwaLoader(true);
            })
    };

    const gsProviderGameLaunch = (redirectToken, game) => {

        // dispatch(setInnerLoaderTrue());

        // const gameLaunchExtraparams = `&html5=1`;

        const mainDiv = document.getElementById("main");
        const getHeight = mainDiv.scrollHeight;
        const getWidth = mainDiv.clientWidth;

        const parameteres = ((game?.param)?.length > 0) ? game?.param : "";

        // let fullUrl = `${rootUrl}/gs/game-launch/${providerCode}/${providerType}/?g_code=${gameCode}/${gameLaunchExtraparams}`;
        let fullUrl = `${rootUrl}/gs/game-launch/${game?.provider_code || game?.p_code}/${providerType}/?${parameteres}`;

        fetch(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${redirectToken}`,
            }
        })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                if (data?.errMsg === "SUCCESS") {

                    const launchWindow = window.open(`${data?.gameUrl}${(game?.p_code !== "BW") ? ("&" + parameteres) : ""}`, `<div id="launchWindow"></div>`, `width=${getWidth},height=${getHeight}`);
                    window.onload = () => {
                        launchWindow.opener.document.getElementById("launchWindow").innerHTML = `<div id=""></div>`;
                    }

                    if (data?.error) {
                        dispatch(showCustomAlert({ message: `${data?.error}`, type: 'error' }));
                    }

                    // dispatch(fetchLoginUserWallet());
                }
            })
            .catch(err => {
                err.json().then(text => {
                    if (text?.errMsg === `Account transaction is locked`) {
                        dispatch(showCustomAlert({ message: `${text?.errMsg}`, type: 'error' }));
                        return;
                    }
                    if (text?.detail === "Invalid token.") {
                        // dispatch(fetchLogoutUser());
                        return;
                    }
                });
            })
            .finally(() => {
                dispatch(setInnerLoaderFalse());
                // setPwaLoader(true);
            })
    };

    const handleCardFunctionalities = async (item) => {
        try {
            dispatch(setInnerLoaderTrue());
            if (
                ((item?.Trans?.en === "PCSO Lottery" || item?.Trans?.en === "Thai Government Lottery") &&
                    item?.to &&
                    user?.is_login)
            ) {
                const successData = await gsBalanceGetFetchAsync();
                dispatch(fetchLoginUserWallet());
                navigateOnSuccess(successData, item);
            } else if (item?.to) {
                navigate(item?.to);
                dispatch(setInnerLoaderFalse());
            } else if (item?.extra_param === "iframe" && !user?.is_login) {
                navigate("/login");
            } else if (item?.extra_param === "iframe" && user?.is_login) {
                const successData = await gsBalanceGetFetchAsync();
                // dispatch(fetchLoginUserWallet());
                gsProviderGameLaunchOnSuccess(successData, item);
            } else {
                const successData = await gsBalanceGetFetchAsync();
                // dispatch(fetchLoginUserWallet());
                gsGameLaunchFunctionOnSuccess(successData, item);
            }
        } catch (error) {
            handleFetchError(error);
        }
    };

    const navigateOnSuccess = (successData, item) => {
        if (successData?.errMsg === "SUCCESS") {
            navigate(item?.to);
            dispatch(setInnerLoaderFalse());
        }
    };

    const gsProviderGameLaunchOnSuccess = (successData, item) => {
        if (successData?.errMsg === "SUCCESS") {
            let stringValue = localStorage.getItem("bet7uUserKey");
            let value = JSON.parse(stringValue);
            gsProviderGameLaunch(value?.value, item);
        }
    };

    const gsGameLaunchFunctionOnSuccess = (successData, item) => {
        if (successData?.errMsg === "SUCCESS") {
            let stringValue = localStorage.getItem("bet7uUserKey");
            let value = JSON.parse(stringValue);
            gsGameLaunchFunction(value?.value, item);
        }
    };

    const handleFetchError = (error) => {
        dispatch(showCustomAlert({
            type: "error",
            message: `Try Again!`,
        }));
        dispatch(setInnerLoaderFalse());
    };

    return (
        <section>
            <Row xs={2} md={2} className="d-flex justify-content-start align-items-center gx-2 gy-2">
                {
                    (games?.length > 0) &&
                    games?.map((item, idx) => (
                        <Col
                            onClick={() => handleCardFunctionalities(item)}
                            key={item?.ID || idx}
                            className="game-card d-flex justify-content-center align-items-center"
                        >
                            <Card className="p-0 w-100">
                                {/* <Card.Img variant="top" src={loadingImage ? item?.ImageFullPath : imageLoader} className="" height="120px" width="" onLoad={() => setLoadingImage(true)} /> */}
                                <Card.Img variant="top" src={loadingImage ? (((item?.imgFileName)?.search(`http`) ? (rootUrl + item?.imgFileName) : (item?.imgFileName)) || item?.ImageFullPath) : imageLoader} className="" height="120px" width="" onLoad={() => setLoadingImage(true)} />
                                <Card.Body className="px-2 py-2">
                                    {
                                        item?.gameName?.gameName_enus ?
                                            <Card.Title className="" style={{ fontSize: "14px" }}>{((item?.gameName?.gameName_enus)?.length >= 12) ? (item?.gameName?.gameName_enus)?.slice(0, 12) + `...` : (item?.gameName?.gameName_enus)}</Card.Title>
                                            :
                                            item?.title ?
                                                <Card.Title className="" style={{ fontSize: "14px" }}>{((item?.title)?.length >= 12) ? (item?.title)?.slice(0, 12) + `...` : (item?.title)}</Card.Title>
                                                :
                                                <Card.Title className="" style={{ fontSize: "14px" }}>{(item?.Trans?.en)?.length >= 12 ? (item?.Trans?.en)?.slice(0, 12) + `...` : (item?.Trans?.en)}</Card.Title>
                                    }
                                    {
                                        item?.MerchantName ?
                                            <Card.Text style={{ fontSize: "12px" }}>
                                                {item?.MerchantName}
                                            </Card.Text>
                                            :
                                            item?.game_provider ?
                                                <Card.Text style={{ fontSize: "12px" }}>
                                                    {item?.game_provider}
                                                </Card.Text>
                                                :
                                                (item?.extra_param !== "iframe") ?
                                                    <Card.Text style={{ fontSize: "12px" }}>
                                                        {/* {(leftProviderData?.find((data) => (data?.provider_code === item?.p_code)))?.game_provider} */}
                                                        {item?.g_type}
                                                    </Card.Text>
                                                    :
                                                    <></>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
            {/* {
                openPopUp &&
                <GSBalanceMergerPopUp status={openPopUp} closePopUp={closePopUp} />
            } */}
        </section >
    );
};

export default GameCard;