import React, { useEffect, useState } from 'react';
// import GatewayCards from '../../../../Components/GatewayCards/GatewayCards';
import { useAdminAgentGateways } from '../../../../Hooks/useAdminAgentGateways';
import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgentBankingState } from '../../../../Redux/agentAdminSlice';
import { agentActivities } from '../../../../Data/AgentAdminData';
import { fetchLogoutUser, userDetail } from '../../../../Redux/userSlice';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { Col, Modal, Row } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import { origin, rootUrl } from '../../../../App';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import { t } from 'i18next';
import { showCustomAlert } from '../../../../Redux/alertSlice';

const AddGateway = () => {
    const dispatch = useDispatch();
    const { addAgentAdminGateways } = useAdminAgentGateways();
    const userInfo = useSelector(userDetail);

    const selectAgentAdminBankingState = useSelector(selectAgentBankingState);
    const [toggleState, setToggleState] = useState(agentActivities);

    useEffect(() => {
        if (userInfo.user?.agent) {
            setToggleState(prev => [...prev,])
        }
    }, [userInfo?.user]);

    // modal functionalities
    const [addWalletModalData, setAddWalletModalData] = useState(null);
    const [bankReceiptImg, setBankReceiptImg] = useState(null);
    const [inputData, setInputData] = useState('');
    // console.log(agentGatewayList)

    const inputPic = (e) => {
        const newFile = e.target.files[0];
        // console.log(e.target.files);
        if (((e.target.files[0].type).split('/')[0]) === 'image') {
            setBankReceiptImg(newFile);
        } else {
            alert("select an image file")
        }
    }

    const closeModal = () => {
        setBankReceiptImg(null);
        setInputData("");
        setAddWalletModalData(null);
    }

    const requestForAddAgentWallet = () => {
        // let confirmData = {
        //     id: addWalletModalData?.item?.id,
        //     gateway_name: addWalletModalData?.item.gateway_title,
        //     gateway_id: inputData,
        //     gateway_image: bankReceiptImg,
        // };

        // console.log(confirmData);

        if (addWalletModalData?.item && bankReceiptImg && inputData.length > 0) {
            const formData = new FormData();
            formData.append("request_origin", "betkingly");
            formData.append("id", addWalletModalData?.item?.id);
            formData.append("gateway_name", addWalletModalData?.item.gateway_title);
            formData.append("gateway_id", inputData);
            if (bankReceiptImg) {
                formData.append("gateway_image", bankReceiptImg);
            }

            let stringValue = localStorage.getItem("bet7uUserKey")
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);

                dispatch(setInnerLoaderTrue());
                fetch(`${rootUrl}/payment/agent-gateway-list/${origin}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${value.value}`,
                    },
                    body: formData
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        if (data) {
                            dispatch(showCustomAlert({ message: data?.report, type: 'success' }));
                            closeModal();
                        }
                    })
                    .catch(err => {
                        // console.log(err);
                        err.json().then(text => {
                            // console.log(text);
                            if (text?.error) {
                                dispatch(showCustomAlert({ message: `${text?.error}`, type: 'error' }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                dispatch(showCustomAlert({ message: `${text?.detail}`, type: 'error' }));
                                return;
                            }
                            if (text?.detail) {
                                dispatch(showCustomAlert({ message: `${text?.detail}`, type: 'error' }));
                                return;
                            }
                        });
                        dispatch(showCustomAlert({ message: `${err.statusText}`, type: 'error' }));
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse());
                    })
            }
        } else {
            dispatch(showCustomAlert({ message: `All Field Required`, type: 'error' }));
        }
    }

    return (
        <>
            <div className="mb-4">
                <CustomSectionTitle title={toggleState[selectAgentAdminBankingState]?.cardTitle} page={"agentAdmin"} />
            </div>
            {
                (addAgentAdminGateways?.length === 0) ?
                    // data less view
                    <div className="d-flex justify-content-center align-items-center history-blurred-text my-5" >
                        <h3 className="mb-0">No Gateway Available</h3>
                    </div>
                    :
                    <div className=''>
                        {/* <GatewayCards transactionGetWays={addAgentAdminGateways} from={"addGateway"} /> */}
                        {/* <Row xs={3} sm={4} md={6} lg={3} className=""> */}
                        <Row xs={5} sm={5} md={5} lg={5} className="gx-2 gy-2">
                            {addAgentAdminGateways?.map((item, index) => (
                                <Col key={index}>
                                    <div
                                        onClick={() => { setAddWalletModalData({ show: true, item }) }}
                                        className='w-100 d-flex align-items-center justify-content-center'
                                        style={{ borderRadius: "0.3em", border: "1px solid #999999", height: "60px", width: "99%", position: "relative", cursor: "pointer", overflow: "hidden" }}>
                                        <img src={item?.gateway_logo} className="img-fluid" height="85%" alt="gateway" style={{ maxHeight: "90%", maxWidth: "90%" }} />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
            }

            <Modal className='transactionModal' fullscreen={false} show={addWalletModalData?.show}>
                <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                    <div className='w-100'>
                        <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                            <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                <button onClick={closeModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>

                                <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{toggleState[selectAgentAdminBankingState]?.cardTitle}</span>

                                <button onClick={closeModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>
                            </div>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <div className='row g-0 m-1'>
                                <div className="d-flex flex-column align-items-center justify-content-between">
                                    <img src={addWalletModalData?.item?.gateway_logo} className="" height="50px" alt="" />
                                    <p className='text-center m-0 p-0 mt-2' style={{ fontSize: "16px", color: "red", }}>
                                        <small>
                                            *** Deposit Amount limit : <span className='fw-bold'>{addWalletModalData?.item?.min_deposit_limit} to {addWalletModalData?.item?.max_deposit_limit}</span>
                                        </small>
                                        <br />
                                        <small>
                                            *** Exchange rate : <span className='fw-bold'>{addWalletModalData?.item?.currency_rate}</span>
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>

                            <div className="row g-0 my-2 mx-1 mx-md-5">
                                <div className="input-group">
                                    <textarea style={{ position: "relative", background: "#ffffff", }} value={inputData} type="text" name="gateway_id" id="gateway_id" className="form-control rounded" row="3" onChange={(e) => setInputData(e.target.value)} placeholder="Account/Wallet Address" />
                                    {
                                        <label style={{ position: "absolute", top: '-12px', left: "15px", background: "#ffffff", color: "#000000", zIndex: 10, fontWeight: "600", fontSize: "14px" }} htmlFor="gateway_id">Your Account/Wallet Address</label>
                                    }
                                </div>
                            </div>

                            <div className='row g-0 mt-3 mx-1 mx-md-5 text-center'>
                                <p>Upload Your Account QR Image</p>
                            </div>

                            {
                                bankReceiptImg ?
                                    <div className=''>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <div className='imgModalContainer'>
                                                <div className="modalImage">
                                                    <img src={`${URL.createObjectURL(bankReceiptImg)}`} alt="UserImg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='row g-0'>
                                        <div className='d-flex justify-content-center'>
                                            <input className='d-none' type="file" id="profilePic" onChange={inputPic} />
                                            <label htmlFor='profilePic'>
                                                <div className='btn btn-primary'>
                                                    <AiOutlineCloudUpload style={{ fontSize: "30px", marginRight: "10px" }} />
                                                    <span>{t('uploadScreenShootButton')}</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                            }

                            <div className='row g-0 my-4'>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button className="btn btn-yellow w-50" onClick={requestForAddAgentWallet}>Request</button>
                                    {/* <button onClick={requestForAddAgentWallet} className="btn w-50" style={{ backgroundColor: '#7DAA2F', color: '#fff' }}>Request</button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default AddGateway;