import React from 'react';
// import { useNavigate } from 'react-router-dom';
import './GetStarted.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay } from "swiper";
// import { origin, rootUrl } from '../../App';
import { useBanner } from '../../Hooks/useBanner';
// import Footer from '../../Components/Footer/Footer';

const GetStarted = ({ setTrueStart }) => {
    // const navigate = useNavigate();
    // {{local}}/statics/app_promo_banner/?request_origin=betkingly
    // const [getStartedData, setGetStartedData] = useState([]);
    const { getStartedData, } = useBanner();

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             let response = await fetch(`${rootUrl}/statics/app_promo_banner/${origin}`);

    //             if (!response.ok) throw response;
    //             else {
    //                 let data = await response.json();
    //                 setGetStartedData(data);
    //                 return data;
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     fetchData();
    // }, []);

    return (
        <div className={(getStartedData?.length > 0) ? "get-started" : "d-none"} style={{ minHeight: "100vh", position: "relative" }}>
            <div style={{ position: "absolute", bottom: 40, width: "100%", }}>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="starterSlider pb-3 pb-md-3"
                >
                    {
                        getStartedData?.map((item, index) =>
                            <SwiperSlide key={item?.title}>
                                <div className="row gx-0 d-flex flex-column justify-content-center align-items-center">
                                    <div className="col-11 d-flex justify-content-center align-items-center pb-3 pb-md-3">
                                        <img src={item?.banner_image} alt={item?.alt_text} style={{ width: "auto", height: "auto", minHeight: "30vh", maxHeight: "30vh", maxWidth: "75%", }} />
                                    </div>

                                    <div className="col-11 d-flex flex-column justify-content-center align-items-center py-4 py-md-4">
                                        <h2 className="fw-bold text-uppercase">{item?.title}</h2>
                                        <h4 className="text-uppercase pb-3 pb-md-4" style={{ color: "#1669E4" }}>{item?.sub_title}...</h4>
                                        <p className="text-center"><small>{item?.description}</small></p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>

                <div className="row-gx-0 d-flex justify-content-center align-items-center pt-3 pb-0 pt-md-5 pb-md-0">
                    <div className="col-11 d-flex justify-content-center align-items-center">
                        <button onClick={() => {
                            // navigate("/", { replace: true });
                            localStorage.setItem("getStartAccess", true);
                            setTrueStart();
                            // window.location.reload(false);
                        }} type='button' className="text-uppercase btn btn-yellow py-2 py-md-3 w-100">Get Started</button>
                    </div>
                </div>



            </div>
        </div>
    );
};

export default GetStarted;