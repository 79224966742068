import React, { useState } from 'react';
// import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
// import { gatewayList } from '../../../../Data/DummyGatewayData';
import CustomInputField from '../../../../Components/CustomInputField/CustomInputField';
import TransactionReport from '../../../../Components/TransactionReport/TransactionReport';
import { useNavigate } from 'react-router-dom';
import { IoMdRefresh } from 'react-icons/io';
import DataDetails from '../../../../Components/DataDetails/DataDetails';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUserWallet, userDetail } from '../../../../Redux/userSlice';
import { gatewayItemData } from '../../../../Redux/bankingTransactionSlice';

import { origin, originNameOnly, rootUrl } from '../../../../App';
import { showCustomAlert } from '../../../../Redux/alertSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import Header from '../../../../Components/Header/Header';
import useTime from '../../../../Hooks/useTime';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { useWithdrawal } from '../../../../Hooks/useWithdrawal';

const QRBankingWithdrawal = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [payInput, setPayInput] = useState();
    const [receiverInputData, setReceiverInputData] = useState({});
    const userInfo = useSelector(userDetail);
    const gatewayItem = useSelector(gatewayItemData);
    const [status, setStatus] = useState({});
    const { withdrawPercent, minWithDrawalData, } = useWithdrawal();
    const { convertDate, convertTime } = useTime();

    const receiverInputFunc = (e) => {
        let data = { ...receiverInputData };
        data[e.target.name] = e.target.value;
        setReceiverInputData(data);
    }

    const withdrawalFunction = () => {
        if (
            userInfo?.user?.first_name === "" ||
            userInfo?.user?.last_name === "" ||
            userInfo?.user?.gender === null ||
            userInfo?.user?.dob === null
        ) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Update Your Profile.`,
            }));
            return;
        }

        if (+payInput >= minWithDrawalData.minimum_limit) {
            let sendData = null;
            if (gatewayItem?.withdrawal_type === "bank"
                && +payInput > 0
                && receiverInputData.to_account
                && receiverInputData.receiverName
                && gatewayItem?.gateway_name
                && receiverInputData.bank_branch
                && receiverInputData.bank_branch_code
                && receiverInputData.bank_country
                && receiverInputData.bank_swift_code
                && gatewayItem?.withdrawal_type
                && gatewayItem?.gateway_title

            ) {
                sendData = {
                    amount: +payInput,
                    to_account: receiverInputData.to_account,
                    bank_account_name: receiverInputData.receiverName,
                    bank_name: gatewayItem?.gateway_name,
                    bank_branch: receiverInputData.bank_branch,
                    bank_branch_code: receiverInputData.bank_branch_code,
                    bank_country: receiverInputData.bank_country,
                    bank_swift_code: receiverInputData.bank_swift_code,
                    payment_gateway: gatewayItem?.withdrawal_type,
                    gateway_title: gatewayItem?.gateway_title,
                    gateway_id: gatewayItem?.id,
                    request_origin: originNameOnly
                }
                // console.log(sendData);
            } else if (gatewayItem?.withdrawal_type === "gateway"
                && +payInput > 0
                && receiverInputData.to_account
                && gatewayItem?.gateway_name
                && receiverInputData.receiverName
                && gatewayItem?.gateway_title
            ) {
                sendData = {
                    amount: +payInput,
                    to_account: receiverInputData.to_account,
                    bank_account_name: receiverInputData.receiverName,
                    payment_gateway: gatewayItem?.gateway_name,
                    gateway_title: gatewayItem?.gateway_title,
                    gateway_id: gatewayItem?.id,
                    request_origin: originNameOnly,
                };
            }
            // console.log(sendData);

            let stringValue = localStorage.getItem("bet7uUserKey")
            if (stringValue !== null) {
                dispatch(setInnerLoaderTrue());
                let value = JSON.parse(stringValue);
                if (sendData !== null) {

                    // Make a GET GS balance request
                    fetch(`${rootUrl}/gs/gs-credit/`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Token ${value?.value}`,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            // Check if data is received
                            if (data && data?.errMsg === "SUCCESS") {
                                // Make a POST request with the received data
                                fetch(`${rootUrl}/wallet/withdrawal/${origin}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                        'Authorization': `Token ${value?.value}`,
                                    },
                                    body: JSON.stringify(sendData)
                                })
                                    .then(res => {
                                        if (!res.ok) throw res;
                                        else return res.json();
                                    })
                                    .then(data => {
                                        // console.log(data);
                                        setStatus(data);
                                        dispatch(fetchLoginUserWallet());
                                        // getWithdrawalHistory();
                                    })
                                    .catch(async err => {
                                        // console.log(err);
                                        err.json().then(text => {
                                            console.log(text);
                                            if (text?.error) {
                                                setStatus({});
                                                dispatch(showCustomAlert({
                                                    type: "error",
                                                    message: `${text?.error}`,
                                                }));
                                                return;
                                            }
                                            if (text?.detail === "Invalid token.") {
                                                setStatus({});
                                                // dispatch(fetchLogoutUser());
                                                return;
                                            }
                                        });
                                        dispatch(showCustomAlert({
                                            type: "error",
                                            message: `${err?.statusText}`,
                                        }));
                                    })
                                    .finally(() => {
                                        dispatch(setInnerLoaderFalse());
                                    })
                            } else {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `Try Again!`,
                                }));
                            }
                        })
                        .catch(error => {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `Try Again!`,
                            }));
                        });
                } else {
                    setStatus({});
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `All Field Required`,
                    }));
                }
            }
        } else {
            setStatus({});
            dispatch(showCustomAlert({
                type: "error",
                message: `Minimum withdrawal amount is $${minWithDrawalData.minimum_limit}`,
            }));
        }
    }

    const aPayWithdrawalFunction = () => {

        const payload = {
            ...receiverInputData,
            amount: +payInput,
            payment_gateway: gatewayItem?.gateway_name,
            gateway_title: gatewayItem?.gateway_title,
            bank_country: gatewayItem?.gateway_username,
            gateway_id: gatewayItem?.id,
            request_origin: originNameOnly,
        };

        // if ((gatewayItem?.gateway_name === "easypaisa") || (gatewayItem?.gateway_name === "jazzcash")) {
        //     payload.payment_gateway = gatewayItem?.gatewayItem;
        // }

        // console.log(payload);
        // console.log({ ...receiverInputData, ...payload });
        // console.log(gatewayItem);

        if (+payInput >= minWithDrawalData?.minimum_limit) {
            let stringValue = localStorage.getItem("bet7uUserKey");
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);

                if (payload !== null) {

                    // Make a GET GS balance request
                    fetch(`${rootUrl}/gs/gs-credit/`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Token ${value?.value}`,
                        }
                    })
                        .then(response => response.json())
                        .then(gsData => {
                            // Check if data is received
                            if (gsData && gsData?.errMsg === "SUCCESS") {
                                // Make a POST request with the received data
                                dispatch(setInnerLoaderTrue());
                                fetch(`${rootUrl}/wallet/withdrawal/${origin}`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                        'Authorization': `Token ${value?.value}`
                                    },
                                    body: JSON.stringify(payload)
                                })
                                    .then(res => {
                                        // console.log(res);
                                        if (!res.ok) throw res;
                                        else return res.json();
                                    })
                                    .then(data => {
                                        console.log(data);
                                        setStatus(data);
                                    })
                                    .catch(async err => {
                                        // console.log(err);
                                        err.json().then(text => {
                                            // console.log(text);
                                            if (text?.error) {
                                                setStatus({});
                                                dispatch(showCustomAlert({
                                                    type: "error",
                                                    message: `${text?.error}`,
                                                }));
                                                return;
                                            }
                                            if (text?.detail === "Invalid token.") {
                                                setStatus({});
                                                // dispatch(fetchLogoutUser());
                                                return;
                                            }
                                        });
                                        dispatch(showCustomAlert({
                                            type: "error",
                                            message: `${err?.statusText}`,
                                        }));
                                    })
                                    .finally(() => {
                                        dispatch(setInnerLoaderFalse());
                                    })
                            } else {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `Try Again!`,
                                }));
                            }
                        })
                        .catch(error => {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `Try Again!`,
                            }));
                        });
                } else {
                    setStatus({});
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `All Field Required`,
                    }));
                }
            }
        } else {
            setStatus({});
            dispatch(showCustomAlert({
                type: "error",
                message: `Minimum withdrawal amount is $${minWithDrawalData.minimum_limit}`,
            }));
        }
    };

    return (
        <div className="inner-scroll qr-banking-withdrawal">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="withdrawalPage">Withdrawal</Trans>} />
            </div>
            {
                status?.id ?
                    <div className='my-4'>
                        <TransactionReport report={t('successfulWithdrawalRequest')} amount={status?.withdraw_amount || 0.00} />
                    </div>
                    :
                    <div className='mx-4 my-4'>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <div className='row g-0'>
                                <div className="d-flex flex-column align-items-center justify-content-between">
                                    <img src={gatewayItem?.gateway_logo} className="" height="50px" alt="" />
                                    <p className='text-center fw-bold m-0 p-0 mt-2' style={{ fontSize: "12px", color: "#000", fontFamily: "Roboto", }}>
                                        <small>***If you withdraw money and it matches your deposit payment method, your money will be in your account within 30 minutes to a hour.</small>
                                        <br />
                                        <small>***You can make three withdrawals in one day, if more than that, it will count towards the next day.</small>
                                    </p>
                                    <p className="mb-0 mt-2">Transaction Fees: {withdrawPercent?.withdraw_fee || 0.00}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <div className='mx-4 my-4' style={{ borderTop: "2px dashed #C2C2C2" }}>
                {
                    status?.id ?
                        <>
                            <div className='my-4'>
                                <DataDetails property={"Withdrawal Amount"} info={`${"$" + (status?.withdraw_amount || 0.00)}`} />
                                <DataDetails property={"Withdrawal Fees"} info={`${"$" + (status?.fee_amount || 0.00)}`} />
                                {
                                    ((gatewayItem?.withdrawal_type === "gateway") || (gatewayItem?.withdrawal_type === "bank")) &&
                                    <>
                                        <DataDetails property={"Your Bank Account"} info={status?.to_account || ""} />
                                        <DataDetails property={"Bank Account Name"} info={status?.bank_account_name || ""} />
                                    </>
                                }
                                {
                                    (gatewayItem?.withdrawal_type === "bank") &&
                                    <>
                                        <DataDetails property={"Bank Name"} info={status?.bank_branch_code || ""} />
                                        <DataDetails property={"Branch Name"} info={status?.bank_branch || ""} />
                                        <DataDetails property={"Country Name"} info={status?.bank_country || ""} />
                                        <DataDetails property={"Swift / IBAN"} info={status?.bank_swift_code || ""} />
                                    </>
                                }
                                {
                                    (gatewayItem?.withdrawal_type === "a_pay") &&
                                    <>
                                        {
                                            (
                                                (status?.payment_gateway === "bkash_a") ||
                                                (status?.payment_gateway === "apaybdt") ||
                                                (status?.payment_gateway === "upi_p2p") ||
                                                (status?.payment_gateway === "upay")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"Receiver Account Number"} info={status?.to_account || ""} />
                                                <DataDetails property={"Your Received Email"} info={status?.bank_branch || ""} />

                                                {/* <CustomInputField type="text" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                                <CustomInputField type="email" name="bank_branch" label="Enter Your Received Email" langLabel={<Trans i18nKey="Enter Your Received Email" />} langPlaceHolder={t('Enter Your Received Email')} value={receiverInputData?.bank_branch} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                        {
                                            (
                                                (status?.payment_gateway === "bkash_w") ||
                                                (status?.payment_gateway === "nagad_api_v") ||
                                                (status?.payment_gateway === "paytm")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"Receiver Account Number"} info={status?.to_account || ""} />

                                                {/* <CustomInputField type="text" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                        {
                                            (
                                                (status?.payment_gateway === "upi_fast") ||
                                                (status?.payment_gateway === "upi_fast_v")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"UPI Address"} info={status?.to_account || ""} />

                                                {/* <CustomInputField type="text" name="to_account" label="Enter Your UPI Address" langLabel={<Trans i18nKey="Enter Your UPI Address" />} langPlaceHolder={t('Enter Your UPI Address')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                        {
                                            (
                                                (status?.payment_gateway === "mpesa")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"Phone Number"} info={status?.to_account || ""} />
                                                {/* <CustomInputField type="text" name="to_account" label="Enter Your Phone Number" langLabel={<Trans i18nKey="Enter Your Phone Number" />} langPlaceHolder={t('Enter Your Phone Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                        {
                                            (
                                                (status?.payment_gateway === "easypaisa") ||
                                                (status?.payment_gateway === "jazzcash")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"Account Name"} info={status?.bank_account_name || ""} />
                                                <DataDetails property={"Receiver Account Number"} info={status?.to_account || ""} />

                                                {/* <CustomInputField type="text" name="bank_account_name" label="Your Account Name" langLabel={<Trans i18nKey="Your Account Name" />} langPlaceHolder={t('Your Account Name')} value={receiverInputData?.bank_account_name} func={receiverInputFunc} icon1={false} icon2={false} />
                                                <CustomInputField type="text" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                        {
                                            (
                                                (status?.payment_gateway === "bankalfalah")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"Account Name"} info={status?.bank_account_name || ""} />
                                                <DataDetails property={"Receiver Account Number"} info={status?.to_account || ""} />
                                                <DataDetails property={"IBAN number"} info={status?.bank_swift_code || ""} />

                                                {/* <CustomInputField type="text" name="bank_account_name" label="Your Account Name" langLabel={<Trans i18nKey="Your Account Name" />} langPlaceHolder={t('Your Account Name')} value={receiverInputData?.bank_account_name} func={receiverInputFunc} icon1={false} icon2={false} />
                                                <CustomInputField type="text" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                                <CustomInputField type="text" name="bank_swift_code" label="Enter Your IBAN number" langLabel={<Trans i18nKey="Enter Your IBAN number" />} langPlaceHolder={t('Enter Your IBAN number')} value={receiverInputData?.bank_swift_code} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                        {
                                            (
                                                (status?.payment_gateway === "imps")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"Account Name"} info={status?.bank_account_name || ""} />
                                                <DataDetails property={"Receiver Account Number"} info={status?.to_account || ""} />
                                                <DataDetails property={"IFSC code bank"} info={status?.bank_branch_code || ""} />

                                                {/* <CustomInputField type="text" name="bank_account_name" label="Your Account Name" langLabel={<Trans i18nKey="Your Account Name" />} langPlaceHolder={t('Your Account Name')} value={receiverInputData?.bank_account_name} func={receiverInputFunc} icon1={false} icon2={false} />
                                                <CustomInputField type="text" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                                <CustomInputField type="text" name="bank_branch_code" label="Enter IFSC code bank" langLabel={<Trans i18nKey="Enter IFSC code bank" />} langPlaceHolder={t('Enter IFSC code bank')} value={receiverInputData?.bank_branch_code} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                        {
                                            (
                                                (status?.payment_gateway === "payme")
                                            )
                                            &&
                                            <>
                                                <DataDetails property={"Card Number"} info={status?.to_account || ""} />

                                                {/* <CustomInputField type="text" name="to_account" label="Enter Your Card Number" langLabel={<Trans i18nKey="Enter Your Card Number" />} langPlaceHolder={t('Enter Your Card Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                            </>
                                        }
                                    </>
                                }
                                <DataDetails property={"Transaction Date"} info={(convertDate(status?.created_at) + " " + convertTime(status?.created_at)) || ""} />
                                <DataDetails property={"Trx ID"} info={status?.trx_id || ""} />
                                <DataDetails property={"Status"} info={status?.status || ""} />
                            </div>
                            <div className='row g-0 my-4'>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button onClick={() => navigate(`/withdrawal${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)} className="btn btn-yellow btn-sm w-50"><IoMdRefresh />{<Trans i18nKey="makeAnotherButton" />}</button>
                                </div>
                            </div>
                        </>
                        :
                        <div className="row g-0 mt-4">
                            <>
                                <CustomInputField type="number" name="amountInput" label="Enter Your Amount" langLabel={<Trans i18nKey="amountInputWithdrawalLangLabel" />} langPlaceHolder={t('amountInputWithdrawalLangLabel')} value={payInput} func={(e) => setPayInput(e.target.value)} icon1={false} icon2={false} />
                                <div className='row g-0' style={{ color: "red" }}>
                                    <i>
                                        <small style={{ fontSize: "10px" }}>
                                            *** Minimum Withdrawal Amount : <span className='fw-bold'>${minWithDrawalData.minimum_limit || 0.00}</span>
                                        </small>
                                    </i>
                                </div>
                            </>
                            {
                                ((gatewayItem?.withdrawal_type === "gateway") || (gatewayItem?.withdrawal_type === "bank")) &&
                                <>
                                    <CustomInputField type="text" name="to_account" label="Enter Your Received Account" langLabel={<Trans i18nKey="to_accountWithdrawalLangLabel" />} langPlaceHolder={t('to_accountWithdrawalLangLabel')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                    <CustomInputField type="text" name="receiverName" label="Your Account Name" langLabel={<Trans i18nKey="receiverNameWithdrawalLangLabel" />} langPlaceHolder={t('receiverNameWithdrawalLangLabel')} value={receiverInputData?.receiverName} func={receiverInputFunc} icon1={false} icon2={false} />
                                </>
                            }
                            {
                                (gatewayItem?.withdrawal_type === "bank") &&
                                <>
                                    <CustomInputField type="text" name="bank_branch_code" label="Bank Name" langLabel={<Trans i18nKey="bank_branch_codeWithdrawalLangLabel" />} langPlaceHolder={t('bank_branch_codeWithdrawalLangLabel')} value={receiverInputData?.bank_branch_code} func={receiverInputFunc} icon1={false} icon2={false} />
                                    <CustomInputField type="text" name="bank_branch" label="Branch Name" langLabel={<Trans i18nKey="bank_branchWithdrawalLangLabel" />} langPlaceHolder={t('bank_branchWithdrawalLangLabel')} value={receiverInputData?.bank_branch} func={receiverInputFunc} icon1={false} icon2={false} />
                                    <CustomInputField type="text" name="bank_country" label="Country Name" langLabel={<Trans i18nKey="bank_countryWithdrawalLangLabel" />} langPlaceHolder={t('bank_countryWithdrawalLangLabel')} value={receiverInputData?.bank_country} func={receiverInputFunc} icon1={false} icon2={false} />
                                    <CustomInputField type="text" name="bank_swift_code" label="Swift Code" langLabel={<Trans i18nKey="bank_swift_codeWithdrawalLangLabel" />} langPlaceHolder={t('bank_swift_codeWithdrawalLangLabel')} value={receiverInputData?.bank_swift_code} func={receiverInputFunc} icon1={false} icon2={false} />
                                </>
                            }

                            {
                                (gatewayItem?.withdrawal_type === "a_pay") &&
                                <>
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "bkash_a") ||
                                            (gatewayItem?.gateway_name === "apaybdt") ||
                                            (gatewayItem?.gateway_name === "upi_p2p") ||
                                            (gatewayItem?.gateway_name === "upay")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="number" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                            <CustomInputField type="email" name="bank_branch" label="Enter Your Received Email" langLabel={<Trans i18nKey="Enter Your Received Email" />} langPlaceHolder={t('Enter Your Received Email')} value={receiverInputData?.bank_branch} func={receiverInputFunc} icon1={false} icon2={false} />
                                        </>
                                    }
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "bkash_w") ||
                                            (gatewayItem?.gateway_name === "nagad_api_v") ||
                                            (gatewayItem?.gateway_name === "paytm")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="number" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                        </>
                                    }
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "upi_fast") ||
                                            (gatewayItem?.gateway_name === "upi_fast_v")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="text" name="to_account" label="Enter Your UPI Address" langLabel={<Trans i18nKey="Enter Your UPI Address" />} langPlaceHolder={t('Enter Your UPI Address')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                        </>
                                    }
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "mpesa")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="number" name="to_account" label="Enter Your Phone Number" langLabel={<Trans i18nKey="Enter Your Phone Number" />} langPlaceHolder={t('Enter Your Phone Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                        </>
                                    }
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "easypaisa") ||
                                            (gatewayItem?.gateway_name === "jazzcash")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="text" name="bank_account_name" label="Your Account Name" langLabel={<Trans i18nKey="Your Account Name" />} langPlaceHolder={t('Your Account Name')} value={receiverInputData?.bank_account_name} func={receiverInputFunc} icon1={false} icon2={false} />
                                            <CustomInputField type="number" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                            {/* <CustomInputField type="text" name="payment_gateway" label="Enter Your Phone Number" langLabel={<Trans i18nKey="Enter Your Phone Number" />} langPlaceHolder={t('Enter Your Phone Number')} value={receiverInputData?.payment_gateway} func={receiverInputFunc} icon1={false} icon2={false} /> */}
                                        </>
                                    }
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "bankalfalah")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="text" name="bank_account_name" label="Your Account Name" langLabel={<Trans i18nKey="Your Account Name" />} langPlaceHolder={t('Your Account Name')} value={receiverInputData?.bank_account_name} func={receiverInputFunc} icon1={false} icon2={false} />
                                            <CustomInputField type="number" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                            <CustomInputField type="text" name="bank_swift_code" label="Enter Your IBAN number" langLabel={<Trans i18nKey="Enter Your IBAN number" />} langPlaceHolder={t('Enter Your IBAN number')} value={receiverInputData?.bank_swift_code} func={receiverInputFunc} icon1={false} icon2={false} />
                                        </>
                                    }
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "imps")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="text" name="bank_account_name" label="Your Account Name" langLabel={<Trans i18nKey="Your Account Name" />} langPlaceHolder={t('Your Account Name')} value={receiverInputData?.bank_account_name} func={receiverInputFunc} icon1={false} icon2={false} />
                                            <CustomInputField type="number" name="to_account" label="Enter Receiver Account Number" langLabel={<Trans i18nKey="Enter Receiver Account Number" />} langPlaceHolder={t('Enter Receiver Account Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                            <CustomInputField type="text" name="bank_branch_code" label="Enter IFSC code bank" langLabel={<Trans i18nKey="Enter IFSC code bank" />} langPlaceHolder={t('Enter IFSC code bank')} value={receiverInputData?.bank_branch_code} func={receiverInputFunc} icon1={false} icon2={false} />
                                        </>
                                    }
                                    {
                                        (
                                            (gatewayItem?.gateway_name === "payme")
                                        )
                                        &&
                                        <>
                                            <CustomInputField type="number" name="to_account" label="Enter Your Card Number" langLabel={<Trans i18nKey="Enter Your Card Number" />} langPlaceHolder={t('Enter Your Card Number')} value={receiverInputData?.to_account} func={receiverInputFunc} icon1={false} icon2={false} />
                                        </>
                                    }
                                </>
                            }


                            <div className='row g-0 my-4'>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button onClick={() => {
                                        if (gatewayItem?.withdrawal_type === "a_pay") {
                                            aPayWithdrawalFunction();
                                        }
                                        else {
                                            withdrawalFunction()
                                        }
                                    }
                                    } className="btn btn-yellow btn-sm w-50">{<Trans i18nKey="next" />}</button>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
};

export default QRBankingWithdrawal;