import React, { useState } from 'react';
// import { homeLeftData } from '../../Data/HomePageData';
import GameTitle from '../../Components/GameTitle/GameTitle';
import GameCard from '../../Components/GameCard/GameCard';
// import { useSelector } from 'react-redux';
// import { SlotsGame } from '../../Redux/gameSlice';
// import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
// import { slotsLeftData } from '../../Data/SlotsPageData';
import { leftData } from '../../Data/ProvidersPageData';
import { Trans } from 'react-i18next';
import ProvidersLeftSideBar from '../../Components/ProvidersLeftSideBar/ProvidersLeftSideBar';
import { useProvidersLeftSideBar } from '../../Hooks/useProvidersLeftSideBar';
import useGameData from '../../Hooks/useGameData';

const Slots = () => {
    const { leftSideBarData } = useProvidersLeftSideBar();

    const gameCode = "SL";

    const leftSlotsSideBar = leftSideBarData?.filter((item) => (item?.game_code)?.includes(gameCode));

    const [selectedLeftBarData, setSelectedLeftBarData] = useState(leftData[0]);

    // const slotsGame = useSelector(SlotsGame);
    const slotsGame = useGameData(gameCode, selectedLeftBarData?.provider_code);

    // const showableExtraCards = (selectedLeftBarData?.extra_param === "iframe") ? [selectedLeftBarData] : slotsGame;

    const iframes = leftSlotsSideBar?.filter((item) => item?.extra_param === "iframe");
    const notIframes = leftSlotsSideBar?.filter((item) => item?.extra_param !== "iframe");

    const showableExtraCards = (selectedLeftBarData?.name === "All") ? iframes.concat(slotsGame) : (selectedLeftBarData?.type === "lobby") ? iframes : slotsGame;

    return (
        <section className='row g-0 p-2'>
            {
                leftSlotsSideBar &&
                <ProvidersLeftSideBar selectedLeftBarData={selectedLeftBarData || leftData[0]} setSelectedLeftBarData={setSelectedLeftBarData} leftSideBarData={[...leftData, ...notIframes]} />
            }
            <div className="game-card-area gx-0 col-10">
                <GameTitle title={<Trans i18nKey="slotsSubTitle">Slots</Trans>} />
                <GameCard games={showableExtraCards} providerType={gameCode} />
            </div>
        </section>
    );
};

export default Slots;