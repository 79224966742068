import React from 'react';
import Header from '../../../Components/Header/Header';
import { Trans } from 'react-i18next';

const Payment = () => {
    return (
        <div className="inner-scroll payment">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="paymentPage">Payment</Trans>} />
            </div>
            <div className="row gx-0 d-flex justify-content-center align-items-center" style={{ color: "#000", opacity: 0.3, minHeight: "calc(100vh - 38px)" }} >
                <div className="col-11 d-flex justify-content-center align-items-center">
                    <h3 className="mb-0 text-center">Payment Coming Soon...</h3>
                </div>
            </div>
        </div>
    );
};

export default Payment;