import React from 'react';
// import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import GatewayCards from '../../../../Components/GatewayCards/GatewayCards';
// import { gatewayList } from '../../../../Data/DummyGatewayData';
import { useBankingTransactions } from '../../../../Hooks/useBankingTransactions';
import Header from '../../../../Components/Header/Header';
import { Trans } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Col, Row } from 'react-bootstrap';

const Deposit = () => {
    const { depositGateways } = useBankingTransactions();
    // console.log(allPaymentGateway);
    return (
        <div className="inner-scroll bankingProfile">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="depositPage">Deposit</Trans>} />
            </div>

            <div className='mx-4 my-4'>
                {
                    (depositGateways?.length > 0) ?
                        <GatewayCards transactionGetWays={depositGateways} from={"deposit"} />
                        :
                        <Row xs={3} sm={4} md={6} lg={3} className="">
                            {
                                Array.apply(null, new Array(27))?.map((item, index) => (
                                    <Col key={index}>
                                        <SkeletonTheme baseColor="var(--homeCardColo)" highlightColor="var(--lightGray)">
                                            <Skeleton count={1} width={"100%"} height={60} duration={1} />
                                        </SkeletonTheme>
                                    </Col>
                                ))
                            }
                        </Row>
                }
            </div>
        </div>
    );
};

export default Deposit;