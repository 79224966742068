import React, { useState } from 'react';
import { AiFillLeftCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { origin, originNameOnly, rootUrl } from '../../App';
import OtpInput from '../../Components/OtpInput/OtpInput';
import { showCustomAlert } from '../../Redux/alertSlice';
import { userRegConfirmData } from '../../Redux/userSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';

const RegMailVerify = () => {
    const regConfirmData = useSelector(userRegConfirmData);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [otp, setOtp] = useState('');
    const onChange = (value) => setOtp(value);

    // useEffect(() => {
    //     if (!regConfirmData?.email) {
    //         navigate("/regMailConfirm", { replace: true })
    //     }
    // }, [regConfirmData, navigate])

    const confirmRegistration = async (e) => {
        e.preventDefault();
        dispatch(setInnerLoaderTrue());
        if (!regConfirmData?.email) {
            navigate("/regMailConfirm", { replace: true });
            dispatch(setInnerLoaderFalse());
        }
        let size = otp.split('').length
        if (size < 6) return;

        let parentUrl = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

        let url_val = parentUrl.split('/');
        let make_url = url_val[0] + '//' + url_val[2] + '/';
        if (make_url === 'https://betkingly.com/' || make_url === 'http://localhost:3000/') {
            make_url = make_url + "";
        }

        try {
            let url = `${rootUrl}${'/auth/users/'}${origin}`;

            let newData = {
                ...regConfirmData,
                otp: otp,
                request_origin: originNameOnly,
                registration_link: make_url,
            };

            let options = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newData)
            }
            // console.log("options", options);

            const response = await fetch(url, options);
            const data = await response.json();

            if (data?.status === "OK") {
                navigate("/regSuccess", { replace: true });
                dispatch(setInnerLoaderFalse());
            } else if (data?.error) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `${data.error}`
                }));
                dispatch(setInnerLoaderFalse());
            }

        } catch (error) {
            dispatch(setInnerLoaderFalse());
            let errorData = await error.json();
            console.log(errorData);

            if (errorData?.error) {
                console.log("errorData", errorData);
            } else if (errorData?.detail === "Invalid token.") {
                console.log("errorData", errorData);
            } else {
                console.log("error", error);
            }
        }
    }

    return (
        <div className='inner-scroll registration'>
            <div className='position-relative'>
                <h3 className="text-center p-3">VERIFY YOUR EMAIL</h3>
                <div className='position-absolute' style={{ top: "12px", left: '10px' }}>
                    <AiFillLeftCircle size='35' color='var(--yellow)' onClick={() => { navigate(-1, { replace: true }) }} style={{ cursor: "pointer", }} />
                </div>
            </div>

            <div>
                <p className='p-0 py-2 m-0 text-center'>{"Please check your email & verify your one time OTP"}</p>

                <div className='mx-4 mb-4'>
                    <form className="row g-0 d-flex justify-content-center" onSubmit={confirmRegistration}>
                        <OtpInput value={otp} valueLength={6} onChange={onChange} />

                        <div className='d-flex justify-content-center'>
                            <button type='submit' className="btn btn-yellow my-3 w-50">Confirm</button>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    );
};

export default RegMailVerify;