import React from 'react';
// import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import GatewayCards from '../../../../Components/GatewayCards/GatewayCards';
// import { gatewayList } from '../../../../Data/DummyGatewayData';
import { useBankingTransactions } from '../../../../Hooks/useBankingTransactions';
import Header from '../../../../Components/Header/Header';
import LayoutWithHeaderBNavigate from '../../../../Layout/LayoutWithHeaderBNavigate';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userDetail } from '../../../../Redux/userSlice';
import { Col, Row } from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Withdrawal = () => {
    const { withdrawalGateways } = useBankingTransactions();
    const userInfo = useSelector(userDetail);

    return (
        <LayoutWithHeaderBNavigate>

            <div className="inner-scroll bankingProfile">
                <div className='sticky-top'>
                    <Header pageTitle={<Trans i18nKey="withdrawalPage">Withdrawal</Trans>} />
                </div>
                {
                    userInfo?.user?.agent ?
                        <div className="row gx-0 d-flex justify-content-center align-items-center" style={{ color: "#000", opacity: 0.3, minHeight: "calc(100vh - 38px)" }} >
                            <div className="col-11 d-flex justify-content-center align-items-center">
                                <h3 className="mb-0 text-center">Agent can not make withdrawal...</h3>
                            </div>
                        </div>
                        :
                        <div className='mx-4 my-4'>
                            {
                                (withdrawalGateways?.length > 0) ?
                                    <GatewayCards transactionGetWays={withdrawalGateways} from={"withdrawal"} />
                                    :
                                    <Row xs={3} sm={4} md={6} lg={3} className="">
                                        {
                                            Array.apply(null, new Array(27))?.map((item, index) => (
                                                <Col key={index}>
                                                    <SkeletonTheme baseColor="var(--homeCardColo)" highlightColor="var(--lightGray)">
                                                        <Skeleton count={1} width={"100%"} height={60} duration={1} />
                                                    </SkeletonTheme>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                            }
                        </div>
                }
            </div>
        </LayoutWithHeaderBNavigate>
    );
};

export default Withdrawal;