import React, { useState } from 'react';
import { navbarData, homeLeftData } from '../../Data/HomePageData';
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
// import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
// import { mother_site, } from '../../App';
import { t } from 'i18next';
import './Sport.css';
// import { BsBroadcast } from 'react-icons/bs';
// import { setSelectedItem } from '../../Redux/sportsExchangeSlice';
// import { userDetail } from '../../Redux/userSlice';
// import { useSportsExchange } from '../../Hooks/useSportsExchange';
import { useBanner } from '../../Hooks/useBanner';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import { useNavigate } from 'react-router-dom';
// import { useSportLauncher } from '../../Hooks/useSportLauncer';

const Sport = ({ setSelectedFragmentID }) => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    // const user = useSelector(userDetail);
    // const { exchangeLaunchFunction } = useSportsExchange();
    // const { sportLaunchFunction } = useSportLauncher();

    const [selectedLeftBarData, setSelectedLeftBarData] = useState(homeLeftData);
    // const [thumbnail, setThumbnail] = useState([]);
    // const selectedItem = useSelector(state => state.sportsExchange.selectedItem);
    // const allInplayGame = useSelector(state => state.sportsExchange.inplayGame);

    const { thumbnail } = useBanner();

    // const itemLengthCount = (item) => {
    //     let eventLength = 0;
    //     if (item?.length > 0) {
    //         for (let i = 0; i < item?.length; i++) {
    //             eventLength += item[i]?.gameList?.length;
    //         }
    //         return eventLength;
    //     }
    //     return 0;
    // }

    // const exchangeGameItems = [
    //     {
    //         id: 0,
    //         gameName: "Cricket",
    //         gameItemCount: itemLengthCount(selectedItem?.gameList?.cricket),
    //     },
    //     {
    //         id: 1,
    //         gameName: "Soccer",
    //         gameItemCount: itemLengthCount(selectedItem?.gameList?.soccer),
    //     },
    //     {
    //         id: 2,
    //         gameName: "Tennis",
    //         gameItemCount: itemLengthCount(selectedItem?.gameList?.tennis),
    //     },
    // ]

    // useEffect(() => {
    //     dispatch(setSelectedItem({
    //         id: 1,
    //         navName: `In-Play`,
    //         name: ``,
    //         gameList: allInplayGame
    //     }))
    // }, [dispatch, allInplayGame,]);

    // useEffect(() => {
    //     // dispatch(setInnerLoaderTrue());
    //     fetch(`${rootUrl}/statics/banner/${origin}`)
    //         .then(res => res.json())
    //         .then(data => {
    //             let exchangeThumbnailData = (data.find(item => item?.banner_page?.page_name === ("exchange_thumbnail")));
    //             let sportBookThumbnailData = (data.find(item => item?.banner_page?.page_name === ("sport_thumbnail")));
    //             let lotteryThumbnailData = (data.find(item => item?.banner_page?.page_name === ("lottery_thumbnail")));
    //             let liveCasinoThumbnailData = (data.find(item => item?.banner_page?.page_name === ("live_casino_thumbnail")));
    //             let casinoThumbnailData = (data.find(item => item?.banner_page?.page_name === ("casino_thumbnail")));
    //             let slotsThumbnailData = (data.find(item => item?.banner_page?.page_name === ("slots_thumbnail")));
    //             let virtualSportThumbnailData = (data.find(item => item?.banner_page?.page_name === ("virtual_sport_thumbnail")));

    //             let currentData = [exchangeThumbnailData, sportBookThumbnailData, lotteryThumbnailData, liveCasinoThumbnailData, casinoThumbnailData, slotsThumbnailData, virtualSportThumbnailData];

    //             setThumbnail(currentData);
    //             console.log(currentData);
    //             dispatch(getInPlayGameRequest());
    //             dispatch(setInnerLoaderFalse());
    //         })
    // }, [dispatch]);

    return (
        <section className='row g-0 p-2'>
            <LeftSideBar selectedLeftBarData={selectedLeftBarData} setSelectedLeftBarData={setSelectedLeftBarData} leftSideBarData={homeLeftData} setSelectedFragmentID={setSelectedFragmentID} />

            <div className="home-card-area col-10">
                {
                    (!thumbnail || (thumbnail === null)) ?
                        <SkeletonTheme baseColor="var(--homeCardColo)" highlightColor="var(--lightGray)">
                            <Skeleton count={7} width={"100%"} height={100} duration={1} />
                        </SkeletonTheme>
                        :
                        navbarData?.map((item, index) =>
                            <div key={item.id} className={item.id === 0 ? "d-none" : 'home-card rounded position-relative'}
                                onClick={() => {
                                    dispatch(setInnerLoaderTrue());

                                    // if (((item?.name === "exchangeNav") || (item?.name === "sportsNav")) && (!user?.is_login)) {
                                    //     navigate("/login");
                                    // }
                                    // else if ((item?.name === "exchangeNav") && (user?.is_login)) {
                                    //     exchangeLaunchFunction(1);
                                    // }
                                    // else if ((item?.name === "sportsNav") && (user?.is_login)) {
                                    //     sportLaunchFunction();
                                    // } 
                                    // else {
                                    setSelectedFragmentID(item?.id);
                                    // }

                                    const timer = setTimeout(() => {
                                        dispatch(setInnerLoaderFalse());
                                    }, 2000);
                                    return () => clearTimeout(timer);
                                }}
                                style={{ cursor: "pointer", }}>
                                {/* {
                                    // (item?.name === "exchangeNav" && ((selectedItem?.gameList?.cricket) || (selectedItem?.gameList?.soccer) || (selectedItem?.gameList?.tennis))) &&
                                    (item?.name === "exchangeNav") &&
                                    <div className="fw-bold sport-card-overlay rounded d-flex flex-column" style={{ fontSize: "10px", backgroundImage: "", }}>
                                        <div className="rounded mb-2 d-flex justify-content-between align-items-center" style={{ backgroundColor: "#fff", }}>
                                            <p className={"rounded-pill pill-btn ps-2 mb-0"}>
                                                <BsBroadcast fill={"red"} size={"12"} className="w-100 blink-image" style={{}} />
                                            </p>
                                            <p className="w-50 rounded mb-0 ps-1" style={{ backgroundColor: "red", }}>
                                                {"LIVE"}
                                            </p>
                                        </div>
                                        {
                                            exchangeGameItems?.map((item, index) =>
                                                <div key={item?.id} className="pb-1 d-flex justify-content-between align-items-center">
                                                    <p className="mb-0">
                                                        {item?.gameName}
                                                    </p>
                                                    <p className="mb-0 ms-2 px-1 py-0 bg-light text-dark rounded">{item?.gameItemCount}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                } */}
                                <h5>{t(item?.name)}</h5>
                                {
                                    (thumbnail || (thumbnail !== null)) &&
                                    <img src={thumbnail[--index]?.banner_image} alt={thumbnail[index]?.alt_text} />
                                }
                            </div>
                        )
                }
            </div>
        </section>
    );
};

export default Sport;