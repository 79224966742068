import React, { useCallback, useEffect, useState } from 'react';
import useTime from '../../../../Hooks/useTime';
import { BsExclamationCircle } from 'react-icons/bs';
import { origin, rootUrl } from '../../../../App';
import { useDispatch, useSelector } from 'react-redux';
// import { selectAgentBankingState } from '../../../../Redux/agentAdminSlice';
import { fetchLoginUserWallet, fetchLogoutUser, userDetail } from '../../../../Redux/userSlice';
import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import { agentActivities } from '../../../../Data/AgentAdminData';
import { selectAgentBankingState } from '../../../../Redux/agentAdminSlice';
import { useTablePagination } from '../../../../Hooks/useTablePagination';
import { Form, Modal } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import { Trans } from 'react-i18next';


const buttonList = [
    {
        id: 0,
        name: 'Pending',
        buttonName: <Trans i18nKey="pendingButton">Pending</Trans>,
        status: 'pending',
        url: '',
    },
    {
        id: 1,
        name: 'Success',
        buttonName: <Trans i18nKey="successButton">Success</Trans>,
        status: 'successful',
        url: '',
    },
    {
        id: 2,
        name: 'Cancel',
        buttonName: <Trans i18nKey="calcelButton">Cancel</Trans>,
        status: 'cancel',
        url: '',
    },
];

const DepositRequest = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);
    const { convertDate, convertTime } = useTime();
    const { visibleRow, increaseVisibleDataIndex, decreaseVisibleDataIndex, } = useTablePagination();

    const [adminTransactions, setAdminTransactions] = useState([]);

    const selectAgentAdminBankingState = useSelector(selectAgentBankingState);
    const [toggleState, setToggleState] = useState(agentActivities);

    useEffect(() => {
        if (userInfo.user?.agent) {
            setToggleState(prev => [...prev,])
        }
    }, [userInfo?.user]);

    const [activeButton, setActiveButton] = useState(buttonList[0]);

    const getAgentDepositRequest = useCallback(() => {
        dispatch(setInnerLoaderTrue());
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);

            fetch(`${rootUrl}/wallet/agent-deposit/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    dispatch(fetchLoginUserWallet());
                    //   closeAgentRequestModal();

                    let tempData = data.filter(item => item.deposit_status === activeButton?.status);
                    setAdminTransactions(tempData);
                })
                .catch(err => console.log(err))
                .finally(
                    () => dispatch(setInnerLoaderFalse())
                )
        }
    }, [dispatch, activeButton?.status]);

    useEffect(() => {
        getAgentDepositRequest();
    }, [getAgentDepositRequest]);


    // console.log(adminTransactions);

    // modal functions

    const [agentRequestModalData, setAgentRequestModalData] = useState({
        visible: false,
        item: {},
    });

    // console.log(agentRequestModalData);

    const [editableData, setEditableData] = useState(null);
    // console.log(editableData)

    const openAgentRequestModal = (item) => {
        setEditableData(item);
        setAgentRequestModalData({
            visible: true,
            item,
        });
    }

    const closeAgentRequestModal = () => {
        setAgentRequestModalData({
            visible: false,
            item: {},
        });
    }

    // update button with PATCH method
    const agentRequestUpdateButton = () => {

        if (editableData?.id) {
            let data = {
                id: +editableData?.id,
                amount: editableData?.message === "cancel" ? 0.00 : editableData?.exchange_amount ? editableData?.exchange_amount : 0.00,
                deposit_status: editableData?.deposit_status,
                message: editableData?.message || "",
            }
            // console.log(data);

            let stringValue = localStorage.getItem("bet7uUserKey")
            if (stringValue !== null) {
                if (+data.amount < 0) {
                    // dispatch(setCustomAlertTrue({ massage: `${'Please Fill Exchange Amount field'}`, type: 'danger' }));
                    return;
                }
                let value = JSON.parse(stringValue);

                fetch(`${rootUrl}/wallet/agent-deposit-details/${origin}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Token ${value?.value}`,
                    },
                    body: JSON.stringify(data)
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        // console.log(data);
                        // dispatch(setCustomAlertTrue({ massage: data.report, type: 'success' }));
                        getAgentDepositRequest();
                        closeAgentRequestModal();
                    })
                    .catch(err => {
                        err.json().then(text => {
                            // console.log(text);
                            if (text?.error) {
                                // dispatch(setCustomAlertTrue({ massage: `${text?.error}`, type: 'danger' }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                return;
                            }
                            if (text?.detail) {
                                // dispatch(setCustomAlertTrue({ massage: `${text?.detail}`, type: 'danger' }));
                                return;
                            }
                        });
                        // dispatch(setCustomAlertTrue({ massage: `${err.statusText}`, type: 'danger' }));
                    })
                    .finally(() => {
                        // dispatch(setInnerLoaderFalse());
                    })
            }
        } else {
            // dispatch(setCustomAlertTrue({ massage: `All Field Required`, type: 'danger' }));
        }
    }

    return (
        <>
            <div className="">
                <CustomSectionTitle title={toggleState[selectAgentAdminBankingState]?.cardTitle} page={"agentAdmin"} />
            </div>
            <div className="my-4">
                <div className='d-flex align-items-center justify-content-center'>
                    {
                        buttonList?.map((buttonData, index) =>
                            <button key={buttonData?.id} onClick={() => setActiveButton(buttonData)} className={activeButton?.id === buttonData?.id ? "px-1 py-0 btn btn-yellow btn-sm mx-2" : "px-1 py-0 btn btn-sm btn-green mx-2"} style={{ border: "none", }} >{buttonData?.buttonName}</button>

                        )
                    }
                </div>
            </div>

            <div className='transactionHistoryTable'>

                {
                    (adminTransactions?.length === 0) ?
                        // dataless view
                        <div className="d-flex justify-content-center align-items-center mt-5 history-blurred-text text-center" >
                            <h3 className="mb-0">No Records</h3>
                        </div> :

                        // data table
                        <table className="table table-bordered border-golden">
                            <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                                <tr>
                                    {/* <th className='text-center' scope="col">SL.</th> */}
                                    <th className='text-center' scope="col">Date</th>
                                    <th className='text-center' scope="col">Gateway</th>
                                    <th className='text-center' scope="col">Amount</th>
                                    <th className='text-center' scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    adminTransactions?.length > 0 &&
                                    adminTransactions?.slice(visibleRow, visibleRow + 10).map((item, index) =>
                                        <tr key={item?.id}>
                                            {/* <th className='text-center' scope="row">{adminTransactions.indexOf(item) + 1}</th> */}
                                            <td className='text-center'> {convertDate(item?.created_at)}</td>
                                            <td className='text-center fw-normal'> {item?.gateway_title}</td>
                                            <td className='text-center fw-normal' style={{ color: "#c30010", }}> - {item?.exchange_amount ? "$" + item?.exchange_amount : item?.amount}</td>
                                            <td>
                                                <div className='text-center' style={{ cursor: "pointer" }} onClick={() => {
                                                    openAgentRequestModal(item);
                                                }}>
                                                    <BsExclamationCircle size={20} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                }

                {/* pagination buttons */}
                <div className={(adminTransactions?.length === 0) ? "d-none" : 'd-flex justify-content-end mb-3'}>
                    <div>
                        <button onClick={decreaseVisibleDataIndex} className={visibleRow === 0 ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow === 0 ? true : false}><Trans i18nKey="prev">PREV.</Trans></button>
                        <button onClick={increaseVisibleDataIndex} className={visibleRow + 10 >= adminTransactions?.length ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow + 10 >= adminTransactions?.length ? true : false}><Trans i18nKey="next">NEXT</Trans></button>
                    </div>
                </div>
            </div>

            <Modal className='transactionModal' fullscreen={false} show={agentRequestModalData.visible}>
                <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                    <div className='w-100'>

                        {/* modal top section */}

                        <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                            <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                <button onClick={closeAgentRequestModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>

                                <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{toggleState[selectAgentAdminBankingState]?.cardTitle}</span>

                                <button onClick={closeAgentRequestModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>
                            </div>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <div className='row g-0 m-1'>
                                <div className="d-flex flex-column align-items-center justify-content-between">
                                    <p style={{ color: "#c30010", fontWeight: "bold", textAlign: "center", fontSize: "30px", margin: 0 }}>- {agentRequestModalData?.item?.amount}</p>
                                    <div className='row g-0 mx-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className='me-2'>Deposit Request From : </div>
                                            <div> {agentRequestModalData?.item?.gateway_title}</div>
                                        </div>
                                    </div>
                                    <div className='row g-0 mx-1'>
                                        <div className="d-flex flex-column align-items-center justify-content-between">
                                            <p className='text-center m-0 p-0 mt-2' style={{ fontSize: "16px", color: "red", }}>
                                                *** Exchange rate : <span className='fw-bold'>{agentRequestModalData?.item?.currency_rate || `0.00`}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* modal bottom section */}

                        <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Transaction Date:</div>
                                    <div>{convertDate((agentRequestModalData?.item)?.created_at)} <span className="ms-1">{convertTime((agentRequestModalData?.item)?.created_at)}</span></div>
                                </div>
                            </div>

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Trx ID:</div>
                                    <div>{agentRequestModalData?.item?.transaction_id}</div>
                                </div>
                            </div>

                            <div className={(editableData?.deposit_status === "cancel") ? "d-none" : 'row g-0 m-1'}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Exchange Amount:</div>
                                    {
                                        ((agentRequestModalData?.item?.deposit_status === "pending") || (agentRequestModalData?.item?.deposit_status === "in_review")) ?
                                            <input
                                                className="text-end w-50 form-control px-1 py-0"
                                                value={editableData?.exchange_amount}
                                                onWheel={event => event.target.blur()}
                                                type="number"
                                                onChange={e => {
                                                    let data = { ...editableData };
                                                    data.exchange_amount = e.target.value;
                                                    setEditableData(data);
                                                }} />
                                            :
                                            <div>{(agentRequestModalData?.item?.deposit_status === "successful") && `$`}{agentRequestModalData?.item?.exchange_amount}</div>
                                    }
                                </div>
                            </div>

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Status:</div>
                                    {
                                        ((agentRequestModalData?.item?.deposit_status === "pending") || (agentRequestModalData?.item?.deposit_status === "in_review")) ?
                                            <Form.Select onChange={e => {
                                                let data = { ...editableData };
                                                data.deposit_status = e.target.value;
                                                setEditableData(data);
                                            }}
                                                className="w-50 px-1 py-0 form-control" value={editableData?.deposit_status} aria-label="Default select example">
                                                {
                                                    buttonList?.map(item => <option key={item?.id} value={item?.status}>{item?.name}</option>)
                                                }
                                            </Form.Select>
                                            :
                                            <div className="text-capitalize fw-bold" style={(agentRequestModalData?.item?.deposit_status === "successful") ? { color: "#7DAA2F", } : (agentRequestModalData?.item?.deposit_status === "cancel") ? { color: "red", } : { color: "#000", }}>{agentRequestModalData?.item?.deposit_status}</div>
                                    }
                                </div>
                            </div>

                            <div className={(((editableData?.deposit_status === "cancel") && (agentRequestModalData?.item?.deposit_status === "pending")) || ((agentRequestModalData?.item?.deposit_status === "cancel") && (agentRequestModalData?.item?.message !== ""))) ? "row g-0 m-1 my-2" : "d-none"}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Message:</div>
                                    {
                                        ((agentRequestModalData?.item?.deposit_status === "cancel") && (agentRequestModalData?.item?.message)) ?
                                            <div>{(agentRequestModalData?.item?.message) || "N/A"}</div>
                                            :

                                            <div className="w-50">
                                                <textarea className="form-control" rows="1" placeholder={"Cancelling for..."} value={editableData?.message} onChange={(e) => {
                                                    let data = { ...editableData };
                                                    data.message = e.target.value;
                                                    setEditableData(data);
                                                }}></textarea>
                                            </div>
                                    }
                                </div>
                            </div>

                            {/* screenshot */}
                            {
                                agentRequestModalData?.item?.screenshot &&
                                <div className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='imgModalContainer'>
                                            <div className="modalImage">
                                                <img src={agentRequestModalData?.item?.screenshot} alt="UserImg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className={((agentRequestModalData?.item?.deposit_status === "successful") || (agentRequestModalData?.item?.deposit_status === "cancel")) ? "d-none" : 'my-4 d-flex flex-column align-items-center justify-content-center'}>
                                <button className='btn btn-yellow w-25 py-1' style={{ coursor: 'pointer' }} onClick={agentRequestUpdateButton}>
                                    Continue
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DepositRequest;