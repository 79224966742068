import React, { useEffect, useRef } from 'react';
import './Footer.css';
import androidIcon from '../../Assets/Icons/androidIcon.svg';
import iosIcon from '../../Assets/Icons/iosIcon.svg';
// import SocialIconList from '../SocialIconList/SocialIconList';
import { Link, } from 'react-router-dom';
import { RWebShare } from "react-web-share";
import shareIcon from '../../Assets/Icons/share-icon.svg';

import betfair from '../../Assets/Icons/footer/sponsor/betfair.svg';
import norton from '../../Assets/Icons/footer/sponsor/norton.svg';
import glo from '../../Assets/Icons/footer/sponsor/glo.svg';

import chromeImg from '../../Assets/Icons/footer/chrome-icon.svg'
import firefoxImg from '../../Assets/Icons/footer/firefox-icon.svg'
import { Trans } from 'react-i18next';

const Footer = () => {
    const installableBtnRef = useRef();

    useEffect(() => {
        const ready = (e) => {
            e.preventDefault();
            installableBtnRef.current = e;
        };

        window.addEventListener("beforeinstallprompt", ready);
        return () => {
            window.removeEventListener("beforeinstallprompt", ready);
        };
    }, []);

    const requestForInstallApp = () => {
        if (installableBtnRef.current) {
            installableBtnRef.current.prompt();

            (installableBtnRef.current.userChoice).then((choiceResult) => { // install button function
                // console.log(choiceResult);
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                    installableBtnRef.current = null;
                } else {
                    console.log('User dismissed the A2HS prompt');
                    alert('User dismissed installation');
                }
            });
        } else {
            return;
        }
    }

    return (
        <div className='px-2 pt-3 pb-4 footer-area'>
            <div className='row g-0'>
                <div className='col-12 p-0 m-0 d-flex justify-content-center'>
                    <button id="apkInstall" onClick={requestForInstallApp} className='downloadApkBtn rounded d-flex p-0 align-items-center m-1' disabled={!installableBtnRef.current}>
                        <div className='rounded imgArea'>
                            <img src={androidIcon} alt="download apk" />
                        </div>
                        <div className='col px-4 d-flex flex-column align-items-center justify-content-center'>
                            <p className='p-0 m-0'>Download</p>
                            <h5 className='p-0 m-0'>APK</h5>
                        </div>
                    </button>

                    <button className='downloadApkBtn rounded d-none p-0 align-items-center m-1'>
                        <div className='rounded imgArea'>
                            <img src={iosIcon} alt="download apk" />
                        </div>
                        <div className='col px-4 d-flex flex-column align-items-center justify-content-center'>
                            <p className='p-0 m-0'>Download</p>
                            <h5 className='p-0 m-0'>IOS</h5>
                        </div>
                    </button>
                </div>
                <div className='col-12 p-0 m-0 d-none flex-column justify-content-center align-items-center'>
                    <div>
                        <a href="/app_file/KXK_BET.apk" download className='module'>
                            <img src={androidIcon} alt="cup-img" className="iconImgSize mb-1" />
                            <span className=''>{'ANDROID'}</span>
                        </a>
                    </div>
                </div>
                <div className='col-12 p-0 m-0 d-none flex-column justify-content-center align-items-center mt-3'>
                    {/* <input className="w-75 text-center" type="text" disabled={true} value={"http://www.k7k.asia/media/installables/KXKBET_C3pRZv0.apk"} /> */}
                    <p className="mb-0">Or,</p>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <h6 className="mb-0">Share with your friends</h6>
                        <div>
                            <RWebShare
                                data={{
                                    text: "Click to download the app.",
                                    url: "https://bit.ly/3oTO73T",
                                    title: "Betting App",
                                }}
                                onClick={() => console.log("shared successfully!")}
                            >
                                <button style={{ border: "none", outline: "none", backgroundColor: "transparent", }}>
                                    <img src={shareIcon} alt="share" />
                                </button>
                            </RWebShare>
                        </div>
                    </div>
                </div>
            </div>

            {/* <SocialIconList /> */}
            <div className="d-flex justify-content-center align-items-center gap-3 px-5 my-2">
                <img src={betfair} height={"50px"} width={"25%"} alt={"sponsor-betfair"} />
                <img src={norton} height={"50px"} width={"25%"} alt={"sponsor-norton"} />
                <img src={glo} height={"50px"} width={"25%"} alt={"sponsor-glo"} />
            </div>

            <div className="d-flex flex-column-reverse">
                <p className='text-center py-1 mb-0' style={{ fontSize: "12px", color: "#ADADAD", borderTop: "1px solid #ADADAD", }}><Trans i18nKey="footerstatic">Our website works best in the latest and latest previous versions of these browsers: Google and Firefox</Trans></p>
                <div className='d-flex align-items-center justify-content-center pb-1'>
                    <img src={chromeImg} alt="chrome" style={{ height: '25px', maxWidth: '25px', margin: '3px' }} />
                    <img src={firefoxImg} alt="firefox" style={{ height: '25px', maxWidth: '25px', margin: '3px' }} />
                </div>
            </div>

            <div>
                <p className='text-center my-0' style={{ fontSize: "14px", color: "#ADADAD", lineHeight: '' }}>
                    <Link
                        to="/terms-conditions"
                    >  Privacy Policy
                    </Link> |
                    <Link
                        to="/terms-conditions"
                    >  Terms and Conditions
                    </Link> | KYC  </p>
            </div>
        </div>
    );
};

export default Footer;