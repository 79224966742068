import React from "react";
import Header from "../../Components/Header/Header";
import OfferCard from "../../Components/OfferCard/OfferCard";

import useAffiliate from "../../Hooks/useAffiliate";

const Affiliate = () => {

    const affiliateData = useAffiliate(`affiliate`);

    return (
        <div className="inner-scroll affiliate">
            <div className="sticky-top">
                <Header pageTitle={"Affiliate"} />
            </div>
            <div className="row gx-0 p-2">
                <div className="col-12">
                    {
                        affiliateData &&
                        affiliateData?.map((item, index) =>
                            <OfferCard key={item?.title || index}
                                item={item}
                                // from={"affiliate"}
                            />
                        )
                    }

                </div>
            </div>
        </div>
    );
};

export default Affiliate;