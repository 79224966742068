import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useTime from '../../../Hooks/useTime';
import { rootUrl } from '../../../App';
import { fetchLoginUserWallet, fetchLogoutUser } from '../../../Redux/userSlice';
import { BsExclamationCircle } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import { t } from 'i18next';

const DepositTable = ({ dataList, visibleRow, getDepositHistory }) => {
    const dispatch = useDispatch();
    const { convertDate, convertTime } = useTime();
    const [bankReceiptImg, setBankReceiptImg] = useState(null);
    const [depositHistoryModal, setDepositHistoryModal] = useState({
        visible: false,
        item: {},
    });

    // console.log(dataList);

    const openDepositHistoryModal = (item) => {
        setDepositHistoryModal({
            visible: true,
            item,
        });
    }

    const closeDepositHistoryModal = (item) => {
        setDepositHistoryModal({
            visible: false,
            item: {},
        });
        setBankReceiptImg(null);
    }

    const inputPic = (e) => {
        const newFile = e.target.files[0];
        if (((e.target.files[0].type).split('/')[0]) === 'image') {
            setBankReceiptImg(newFile);
        } else {
            alert("select an image file")
        }
    }


    const paymentScreenShotImgUpload = () => {
        const formData = new FormData();
        formData.append("request_origin", "betkingly");
        if (bankReceiptImg) {
            formData.append("screenshot", bankReceiptImg);
        }

        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/wallet/deposit/${depositHistoryModal.item.id}/`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Token ${value.value}`,
                },
                body: formData
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    dispatch(fetchLoginUserWallet());
                    closeDepositHistoryModal();
                    getDepositHistory();
                    // dispatch(setCustomAlertTrue({ massage: "Deposit Pending", type: 'pending' }));
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.error) {
                            // dispatch(setCustomAlertTrue({ massage: `${text?.error}`, type: 'danger' }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    // dispatch(setCustomAlertTrue({ massage: `${err.statusText}`, type: 'danger' }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }
    return (
        <>{
            (dataList?.length === 0) ?
                // dataless view
                <div className="d-flex justify-content-center align-items-center mt-5 history-blurred-text text-center" >
                    <h3 className="mb-0">No Records</h3>
                </div> :
                <table className="table table-bordered border-golden">
                    <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                        <tr>
                            {/* <th className='text-center' scope="col">SL.</th> */}
                            <th className='text-center' scope="col">Date</th>
                            <th className='text-center' scope="col">Description</th>
                            <th className='text-center' scope="col">Amount</th>
                            <th className='text-center' scope="col">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataList &&
                            dataList?.slice(visibleRow, visibleRow + 10)?.map((item, index) =>
                                <tr key={item?.id || index}>
                                    {/* <th className='text-center' scope="row">{dataList?.indexOf(item) + 1}</th> */}
                                    <td className='text-center'> {convertDate(item.created_at || item.transaction_date)}</td>
                                    <td style={{ color: "#7DAA2F", fontWeight: "bold", textAlign: "center" }}>Deposit</td>
                                    <td style={{ color: "#7DAA2F", fontWeight: "bold", textAlign: "center" }}>+ {item?.exchange_amount ? "$" + item?.exchange_amount : item?.amount}</td>
                                    <td>
                                        <div
                                            className='text-center'
                                            style={{ cursor: "pointer" }}
                                            onClick={() => openDepositHistoryModal(item)}
                                        >
                                            <BsExclamationCircle size={20} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        }

                    </tbody>
                </table>
        }

            <Modal className='transactionModal' fullscreen={false} show={depositHistoryModal.visible}>
                <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                    <div className='w-100'>
                        <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                            <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                <button onClick={closeDepositHistoryModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "16%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>

                                <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{t('transactionDetailsModalTitle')}</span>

                                <button onClick={closeDepositHistoryModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>
                            </div>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <p style={{ color: "#7DAA2F", fontWeight: "bold", textAlign: "center", fontSize: "30px", margin: 0 }}>+ {depositHistoryModal.item.amount}</p>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className='me-2'>Deposit from : </div>
                                    <div> {depositHistoryModal.item.gateway_title ? depositHistoryModal.item.gateway_title : depositHistoryModal.item.payment_gateway}</div>
                                </div>
                            </div>
                        </div>

                        <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Transaction Date :</div>
                                    {
                                        depositHistoryModal.item.created_at ?
                                            <div>{convertDate((depositHistoryModal.item).created_at)} <span>{convertTime((depositHistoryModal.item).created_at)}</span></div>
                                            : <div>{convertDate((depositHistoryModal.item).transaction_date)} <span>{convertTime((depositHistoryModal.item).transaction_date)}</span></div>
                                    }
                                </div>
                            </div>

                            {
                                (depositHistoryModal?.item?.payer_account) &&
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Sender Account :</div>
                                        <div>{depositHistoryModal?.item?.payer_account}</div>
                                    </div>
                                </div>
                            }

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Trx ID :</div>
                                    <div>{depositHistoryModal.item.transaction_id || depositHistoryModal.item.trx_id}</div>
                                </div>
                            </div>

                            {
                                depositHistoryModal?.item?.exchange_amount &&
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className='me-2'>Exchange Amount :</div>
                                        <div>${depositHistoryModal.item.exchange_amount}</div>
                                    </div>
                                </div>
                            }

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div >Status :</div>
                                    <div className="fw-bold" style={{
                                        color: depositHistoryModal.item.deposit_status === "pending" ? "#daaf37" :
                                            depositHistoryModal.item.deposit_status === "successful" ? "green" :
                                                depositHistoryModal.item.deposit_status === "in_review" ? "orange" :
                                                    depositHistoryModal.item.deposit_status === "cancelled" ? "red" :
                                                        depositHistoryModal.item.deposit_status === "busy" ? "#daaf37" : ""
                                    }}>
                                        {
                                            depositHistoryModal.item.deposit_status === "pending" ? "Pending" :
                                                depositHistoryModal.item.deposit_status === "successful" ? "Success" :
                                                    depositHistoryModal.item.deposit_status === "in_review" ? " In-review" :
                                                        depositHistoryModal.item.deposit_status === "busy" ? " Pending" :
                                                            depositHistoryModal.item.deposit_status === "cancelled" ? "Cancel" : ""}
                                    </div>
                                </div>
                            </div>


                            {
                                depositHistoryModal.item?.screenshot ?
                                    <div className='mb-4'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <div className='imgModalContainer'>

                                                <div className="modalImage">
                                                    <img src={depositHistoryModal.item?.screenshot} alt="UserImg" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ((depositHistoryModal.item.deposit_status === "pending" || depositHistoryModal.item.deposit_status === "in_review")) &&
                                    <div className='mb-4'>
                                        {
                                            bankReceiptImg ?
                                                <div className=''>
                                                    <div className='d-flex align-items-center justify-content-center mb-3'>
                                                        <div className='imgModalContainer'>
                                                            <div className="modalImage">
                                                                <img src={`${URL.createObjectURL(bankReceiptImg)}`} alt="UserImg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <div>
                                                            <button onClick={paymentScreenShotImgUpload} className='btn btn-yellow w-100 py-1'>Save Image</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='row g-0'>
                                                    <div className='d-flex justify-content-center'>
                                                        <input className='d-none' type="file" id="profilePic" onChange={inputPic} />
                                                        <label htmlFor='profilePic'>
                                                            <div className='btn btn-primary'>
                                                                <AiOutlineCloudUpload style={{ fontSize: "30px", marginRight: "10px" }} />
                                                                <span>{t('uploadScreenShootButton')}</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                            }


                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DepositTable;