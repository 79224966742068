import React from 'react';
import './CountryLotteryNavbar.css';
import { Navbar } from 'react-bootstrap';
import { AiOutlineHome } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';
import { FaClipboardList, FaHistory } from 'react-icons/fa';
import { HiCurrencyDollar } from 'react-icons/hi';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';

const CountryLotteryNavbar = ({ lotteryType }) => {
    const secondaryNavigationContent = [
        {
            id: 0,
            navName: `HOME`,
            name: <Trans i18nKey="homeLotterySlider">HOME</Trans>,
            icon: <AiOutlineHome className='me-2' />,
            link: `/${lotteryType}-lottery/`,
        },
        {
            id: 1,
            navName: `BET HISTORY`,
            name: <Trans i18nKey="betHistoryLotterySlider">BET HISTORY</Trans>,
            icon: <FaHistory className='me-2' />,
            link: `/${lotteryType}-lottery/bet_history`,
        },
        {
            id: 2,
            navName: `RESULTS`,
            name: <Trans i18nKey="resultsLotterySlider">RESULTS</Trans>,
            icon: <FaClipboardList className='me-2' />,
            link: `/${lotteryType}-lottery/result`,

        },
        {
            id: 3,
            navName: `PRIZE & ODDS`,
            name: <Trans i18nKey="prizeOddsLotterySlider">PRIZE & ODDS</Trans>,
            icon: <HiCurrencyDollar style={{ fontSize: "16px" }} className='me-2' />,
            link: `/${lotteryType}-lottery/prize`,
        },
        {
            id: 4,
            navName: `HOW TO PLAY`,
            name: <Trans i18nKey="howToPlayLotterySlider">HOW TO PLAY</Trans>,
            icon: <RiQuestionnaireLine style={{ fontSize: "16px" }} className='me-2' />,
            link: `/${lotteryType}-lottery/how_to_play`,
        },
        {
            id: 5,
            navName: `ABOUT`,
            name: <Trans i18nKey="aboutLotterySlider">ABOUT</Trans>,
            icon: <BsInfoCircle className='me-2' />,
            link: `/${lotteryType}-lottery/about`,
        },
    ];


    return (
        <Navbar className="secondNavigation d-flex justify-content-center">
            {/* desktop view */}
            <div className="bar scroll-section">
                <div style={{ overflowX: "auto", overflowY: 'hidden', whiteSpace: 'nowrap', scrollbarWidth: 0, scrollBarHeight: 0 }}>
                    <div className="hstack">
                        {
                            secondaryNavigationContent?.map((oneNav, index) =>
                                <NavLink key={index} className={(navInfo) => (navInfo.isActive ? "active nav-link" : "nav-link")} to={oneNav?.link} >
                                    {oneNav.icon}
                                    <span>{oneNav?.name}</span>
                                </NavLink>
                            )
                        }
                    </div>
                </div>
            </div>
        </Navbar>
    );
};

export default CountryLotteryNavbar;