import { useEffect, useState } from "react";
import { origin, rootUrl } from "../App";
import { useLocation } from "react-router-dom";
import { addDataIntoCache, getCacheData, } from "../Redux/sportsExchangeSlice";
import { useDispatch } from "react-redux";
// import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

export const useBanner = () => {
    const [sliderImgList, setSliderImgList] = useState(null);
    const [appBG, setAppBG] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [getStartedData, setGetStartedData] = useState([]);
    const [getStartedDataState, setGetStartedDataState] = useState(true);


    // const setStartStateTrue = () => {
    //     setGetStartedDataState(true);
    // }

    // const setStartStateFalse = () => {
    //     setGetStartedDataState(false);
    // }

    const dispatch = useDispatch();

    const location = useLocation();
    const pageURL = location?.pathname.split("/")[2];
    let pageName = "";

    if (pageURL === "casino" || pageURL === "live_casino" || pageURL === "slots" || pageURL === "lottery" || pageURL === "virtual_sport") {
        pageName = location?.pathname.split("/")[2];
    } else if (location?.pathname.split("/")[1] === "home" || location?.pathname.split("/")[1] === "login") {
        pageName = location?.pathname.split("/")[1];
    }

    useEffect(() => {

        const fetchBannerData = async () => {

            const cacheDataOfAppBG = await getCacheData('appBGCache', `${rootUrl}/statics/banner/`);

            const cacheDataOfHomeSliders = await getCacheData(`homeSlidersCache`, `${rootUrl}/statics/banner/home`);

            const cacheDataOfLoginSliders = await getCacheData(`loginSlidersCache`, `${rootUrl}/statics/banner/login`);

            const cacheDataOfThumbnails = await getCacheData(`thumbnailCache`, `${rootUrl}/statics/banner/thumbnail`);

            if (cacheDataOfAppBG && cacheDataOfHomeSliders && cacheDataOfLoginSliders && cacheDataOfThumbnails) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfAppBG = cacheDataOfAppBG?.updateDate; /* Get the cached time from wherever it's stored */
                const cachedTimeStringOfHomeSliders = cacheDataOfHomeSliders?.updateDate;
                const cachedTimeStringOfLoginSliders = cacheDataOfLoginSliders?.updateDate;
                const cachedTimeStringOfThumbnails = cacheDataOfThumbnails?.updateDate;

                // Convert the cached time string to a Date object
                const cachedTimeOfAppBG = new Date(cachedTimeStringOfAppBG);
                const cachedTimeOfHomeSliders = new Date(cachedTimeStringOfHomeSliders);
                const cachedTimeOfLoginSliders = new Date(cachedTimeStringOfLoginSliders);
                const cachedTimeOfThumbnails = new Date(cachedTimeStringOfThumbnails);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 60 * 24; // 24 hour (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfAppBG = currentTime - cachedTimeOfAppBG;
                const timeDifferenceOfHomeSliders = currentTime - cachedTimeOfHomeSliders;
                const timeDifferenceOfLoginSliders = currentTime - cachedTimeOfLoginSliders;
                const timeDifferenceOfThumbnails = currentTime - cachedTimeOfThumbnails;

                if ((timeDifferenceOfAppBG < cacheExpirationThreshold) && (timeDifferenceOfHomeSliders < cacheExpirationThreshold) && (timeDifferenceOfLoginSliders < cacheExpirationThreshold) && (timeDifferenceOfThumbnails < cacheExpirationThreshold)) {
                    setAppBG(cacheDataOfAppBG?.data);
                    setSliderImgList(pageName === "login" ? cacheDataOfLoginSliders?.data : cacheDataOfHomeSliders?.data);
                    setThumbnail(cacheDataOfThumbnails?.data);
                    return null;
                }

                if (cacheDataOfAppBG) {
                    if (timeDifferenceOfAppBG < cacheExpirationThreshold) {
                        setAppBG(cacheDataOfAppBG?.data);
                        return null;
                    }
                }

                if (cacheDataOfHomeSliders || cacheDataOfLoginSliders) {
                    if ((timeDifferenceOfHomeSliders < cacheExpirationThreshold) || (timeDifferenceOfLoginSliders < cacheExpirationThreshold)) {
                        setSliderImgList(pageName === "login" ? cacheDataOfLoginSliders?.data : cacheDataOfHomeSliders?.data);
                        return null;
                    }
                }

                if (cacheDataOfThumbnails) {
                    if (timeDifferenceOfThumbnails < cacheExpirationThreshold) {
                        setThumbnail(cacheDataOfThumbnails?.data);
                        return null;
                    }
                }
            }


            fetch(`${rootUrl}/statics/banner/${origin}`)
                .then(res => res.json())
                .then(data => {

                    let homeSliderData = (data?.filter(item => item?.banner_page?.page_name === "home"));
                    addDataIntoCache(`homeSlidersCache`, `${rootUrl}/statics/banner/home`, {
                        data: homeSliderData,
                        updateDate: new Date()
                    });

                    let loginSliderData = (data?.filter(item => item?.banner_page?.page_name === "login"));
                    addDataIntoCache(`loginSlidersCache`, `${rootUrl}/statics/banner/login`, {
                        data: loginSliderData,
                        updateDate: new Date()
                    });

                    // app background image is fixed not dependent on page
                    let bgData = (data?.filter(item => item?.banner_page?.page_name === "app_background_image"));
                    addDataIntoCache('appBGCache', `${rootUrl}/statics/banner/`, {
                        data: bgData,
                        updateDate: new Date()
                    });

                    // let exchangeThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("exchange_thumbnail")));
                    let sportBookThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("sport_thumbnail")));
                    let lotteryThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("lottery_thumbnail")));
                    let liveCasinoThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("live_casino_thumbnail")));
                    // let casinoThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("casino_thumbnail")));
                    let slotsThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("slots_thumbnail")));
                    // let virtualSportThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("virtual_sport_thumbnail")));
                    let fishingThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("fishing_thumbnail")));
                    let cardsThumbnailData = (data?.find(item => item?.banner_page?.page_name === ("cards_thumbnail")));

                    let thumbnailData = [sportBookThumbnailData, lotteryThumbnailData, liveCasinoThumbnailData, slotsThumbnailData, fishingThumbnailData, cardsThumbnailData];

                    addDataIntoCache('thumbnailCache', `${rootUrl}/statics/banner/thumbnail`, {
                        data: thumbnailData,
                        updateDate: new Date()
                    }
                    );
                    fetchBannerData();
                })

        };
        fetchBannerData();

    }, [dispatch, pageName,]);

    useEffect(() => {
        const fetchGetStartedData = async () => {
            const cacheDataOfPromoBanner = await getCacheData('appPromoCache', `${rootUrl}/statics/promoBanner/`);

            if (cacheDataOfPromoBanner) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfPromoBanner = cacheDataOfPromoBanner?.updateDate; /* Get the cached time from wherever it's stored */

                // Convert the cached time string to a Date object
                const cachedTimeOfPromoBanner = new Date(cachedTimeStringOfPromoBanner);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 60 * 24; // 24 hour (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfPromoBanner = currentTime - cachedTimeOfPromoBanner;

                if (timeDifferenceOfPromoBanner < cacheExpirationThreshold) {
                    setGetStartedData(cacheDataOfPromoBanner?.data);
                    setGetStartedDataState(false);
                    return null;
                }
            }

            try {
                let response = await fetch(`${rootUrl}/statics/app_promo_banner/${origin}`);

                if (!response.ok) throw response;
                else {
                    let data = await response.json();
                    addDataIntoCache(`appPromoCache`, `${rootUrl}/statics/promoBanner/`, {
                        data: data,
                        updateDate: new Date()
                    });
                    setGetStartedData(data);
                    return data;
                }
            } catch (error) {
                console.log(error);
            } finally {
                setGetStartedDataState(false);
            }
        }
        fetchGetStartedData();
    }, []);


    return {
        sliderImgList,
        appBG,
        thumbnail,
        getStartedData,
        getStartedDataState,
        // setStartStateTrue,
        // setStartStateFalse,
    }
}