import React from 'react';
import './PcsoArrowBar.css'
import { rootUrl } from '../../../App';


const PcsoArrowBar = ({ category, totalDigits, lotteryDigits }) => {
    return (
        <div className='row g-0 pcsoArrowBar mb-1'>
            <div className='col' style={{ maxWidth: "36%" }}>
                <div className='whiteArea rounded-0 rounded-start d-flex align-items-center p-1'>
                    <img src={`${rootUrl}${category?.category_logo}`} alt="logo" style={{ width: "30px", height: "auto", marginRight: "5px" }} />
                    <span style={{ fontSize: "10px" }}>{category?.lottery_category?.category_name} ({category?.time_category?.lottery_time})</span>
                </div>
            </div>

            <div className='col'>
                <div className='goldenArea rounded-0 rounded-end d-flex align-items-center justify-content-end p-1' style={{ background: "var(--yellow)", }}>
                    <div className='row g-0 justify-content-end'>
                        {
                            lotteryDigits?.split(",")?.map((digit, index) =>
                                <span key={index} className='darkCircle'>{digit}</span>
                            )
                        }
                    </div>
                    <div className='vr'></div>
                    <span style={{ fontSize: "12px", padding: "0 4px", }}>TOTAL</span>
                    <span className='greenCircle'>{totalDigits}</span>
                </div>
            </div>
        </div>
    );
};

export default PcsoArrowBar;