import React from 'react';
// import Header from '../../Components/Header/Header';
// import { Trans } from 'react-i18next';

const Sportsbook = () => {
    return (
        <div className='inner-scroll sportsPage'>
            {/* <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="sportsbookPage">Sportsbook</Trans>} />
            </div> */}
            <iframe
                id="gameIframe2"
                className='gameIframe'
                title="gameIframe"
                // src={`https://betkingly.com/sports/}
                src={`https://betkingly.com/sportsbook/?tfkhgjfecb646c6c2b7fe0fae3fb183d0036d25ba7f50eslkgdhjl=${(JSON.parse(localStorage.getItem("bet7uUserKey"))?.value) || "static_demo"}`}
                allow="autoplay"
                allowFullScreen={true}
                style={{
                    minHeight: '100vh',
                    minWidth: '100%',
                    height: "100vh",
                    overflow: "hidden !important",
                    display: 'block',
                    zIndex: 3
                }}
            ></iframe>
        </div >
    );
};

export default Sportsbook;