import React from 'react';
import Header from '../../Components/Header/Header';
import { languagesData, } from '../../Data/LanguagesPageData';

import { Trans, useTranslation, } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userDetail } from '../../Redux/userSlice';
import { useNavigate } from 'react-router-dom';

const Languages = () => {

    const navigate = useNavigate();
    const user = useSelector(userDetail);
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        navigate('/');
    };

    return (
        <div className="inner-scroll languagesPage">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="languagePage">Language</Trans>} />
            </div>


            <div className="py-3"
                style={{
                    backgroundColor: '#EDEDED',
                    minHeight: user?.is_login ? 'calc(100dvh - 38px)' : 'calc(100dvh - 38px)',
                    fontFamily: "Advent Pro",
                }}
            >
                <div className='row g-0 d-flex align-items-center justify-content-center' >
                    {
                        languagesData?.slice(0, 2)?.map((oneNav, index) =>
                            <div key={index} className="col-3 m-2 p-0" onClick={() => changeLanguage(oneNav?.code)} style={{

                            }}>
                                <div
                                    className="d-flex flex-column align-items-center justify-content-center rounded"
                                    style={{
                                        border: "none",
                                        height: '70px',
                                        width: 'auto',
                                        fontSize: "0.8rem",
                                        fontWeight: 700,
                                        textTransform: "uppercase",
                                        color: "#000",
                                        // backgroundColor: '#fff',
                                        backgroundColor: (localStorage.getItem("i18nextLng") === oneNav?.code) ? "var(--yellow)" : "var(--light)",
                                        cursor: "pointer",
                                        boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.25)",
                                    }}>
                                    <img style={{ height: '30px', maxWidth: '30px', borderRadius: "50%", }} src={oneNav?.flag} alt="" />
                                    <span className="mt-2">{oneNav?.language}</span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

        </div>
    );
};

export default Languages;