import React, { useCallback, useEffect, useState } from 'react';
// import CustomSectionTitle from '../../Components/CustomSectionTitle/CustomSectionTitle';
import { useLive } from '../../Hooks/useLive';
import './LivePage.css';
import Header from '../../Components/Header/Header';
import { Trans } from 'react-i18next';

const LivePage = () => {
    const { liveEvents } = useLive();

    const [selectedGame, setSelectedGame] = useState({});

    const openLive = (liveEvent) => {
        setSelectedGame(liveEvent);
    }

    // useEffect(() => {
    //     setSelectedGame(live);
    // }, [live]);

    // console.log(selectedGame);



    const liveTVLauncher = useCallback(() => {

        if (!selectedGame?.link) return;

        const mainDiv = document.getElementById("main");
        const getHeight = mainDiv.scrollHeight;
        const getWidth = mainDiv.clientWidth;

        const gameLaunchWindow = window.open(`${selectedGame?.link}`, `<div id="liveTVLauncher"></div>`, `width=${getWidth},height=${getHeight}`);
        window.onload = () => {
            gameLaunchWindow.opener.document.getElementById("liveTVLauncher").innerHTML = `<div id=""></div>`;
        };
    }, [selectedGame])

    useEffect(() => {
        liveTVLauncher();
    }, [liveTVLauncher, selectedGame])




    return (
        <div className="inner-scroll livePage">
            <div className='sticky-top'>
                {/* <Header pageTitle={<Trans i18nKey="liveTVPage">Live TV</Trans>} /> */}
                <Header pageTitle={<Trans i18nKey="webinarPage">Webinar</Trans>} />
            </div>


            <div className="p-3"
                style={{
                    backgroundColor: '#1F1F1F',
                    minHeight: '100px',
                }}
            >
                <div className='row g-0 d-flex align-items-center justify-content-center rounded' >
                    {
                        liveEvents?.map((oneNav, index) =>
                            <div key={index} className="col-3 p-2" onClick={() => openLive(oneNav)}>
                                <div
                                    className="d-flex align-items-center justify-content-center rounded"
                                    style={{
                                        // border: "1px solid red",
                                        height: '60px',
                                        width: 'auto',
                                        backgroundColor: `${selectedGame?.id === oneNav?.id ? '#FFC800' : '#3d3d3d'}`,
                                        cursor: "pointer",
                                    }}>
                                    <img style={{ height: '80%', maxWidth: '80%' }} src={oneNav?.image || oneNav?.logo} alt="" />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

        </div>
    );
};

export default LivePage;