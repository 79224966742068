import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import './Lottery.css';
// import { homeLeftData } from '../../Data/HomePageData';
import GameTitle from '../../Components/GameTitle/GameTitle';
import GameCard from '../../Components/GameCard/GameCard';
// import { LotteryGame } from '../../Redux/gameSlice';
import { lotteryExtraData } from '../../Data/LotteryPageData';
import { leftData } from '../../Data/ProvidersPageData';
// import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import { Trans } from 'react-i18next';
import ProvidersLeftSideBar from '../../Components/ProvidersLeftSideBar/ProvidersLeftSideBar';
import { useProvidersLeftSideBar } from '../../Hooks/useProvidersLeftSideBar';

import useGameData from '../../Hooks/useGameData';

const Lottery = () => {

    const { leftSideBarData } = useProvidersLeftSideBar();

    const gameCode = "LK";

    const leftLotterySideBar = leftSideBarData?.filter((item) => (item?.game_code)?.includes(gameCode));

    const [selectedLeftBarData, setSelectedLeftBarData] = useState(leftData[0]);

    // const lotteryGame = useSelector(LotteryGame);

    const lotteryGame = useGameData(gameCode, selectedLeftBarData?.provider_code);

    // const showableExtraCards = (selectedLeftBarData?.extra_param === "iframe") ? [selectedLeftBarData] : lotteryGame;

    const iframes = leftLotterySideBar?.filter((item) => item?.extra_param === "iframe");
    const notIframes = leftLotterySideBar?.filter((item) => item?.extra_param !== "iframe");

    const showableExtraCards = (selectedLeftBarData?.name === "All") ? iframes.concat(lotteryGame) : (selectedLeftBarData?.type === "lobby") ? iframes : lotteryGame;

    return (
        <section className='row g-0 p-2'>
            {
                leftLotterySideBar &&
                <ProvidersLeftSideBar selectedLeftBarData={selectedLeftBarData || leftData[0]} setSelectedLeftBarData={setSelectedLeftBarData} leftSideBarData={[...leftData, ...notIframes]} />
            }
            {
                lotteryGame &&
                <div className="game-card-area gx-0 col-10">
                    <GameTitle title={<Trans i18nKey="lotterySubTitle">Lottery</Trans>} />
                    <GameCard games={selectedLeftBarData?.name === "All" ? [...lotteryExtraData, ...showableExtraCards] : selectedLeftBarData?.type === "iframe" ? [...iframes] : showableExtraCards} providerType={gameCode} />
                </div>
            }
        </section>
    );
};

export default Lottery;