import React from 'react';
import YoutubeVideo from '../../../Components/CountryLotteryComponent/YoutubeVideo/YoutubeVideo';
import { Trans } from 'react-i18next';

const ThHowToPlay = () => {
    return (
        <div className="pcsoHowToPlay m-3">
            <YoutubeVideo title={<Trans i18nKey="howToPlayTitle">HOW TO PLAY</Trans>} link="https://www.youtube.com/embed/GofaVehTDYI?autoplay=1&mute=1&enablejsapi=1" />
        </div>
    );
};

export default ThHowToPlay;