import React from 'react';
import './Slider.css';

import { Carousel } from 'react-bootstrap';
import { useBanner } from '../../Hooks/useBanner';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Slider = () => {
    const { sliderImgList } = useBanner();
    return (
        <Carousel>
            {

                (!sliderImgList || (sliderImgList === null)) ?
                    <SkeletonTheme baseColor="var(--homeCardColo)" highlightColor="var(--lightGray)">
                        <Skeleton count={1} width={"100%"} height={"22vh"} duration={1} style={{ lineHeight: 2, }} />
                    </SkeletonTheme>
                    :
                    sliderImgList?.map((item, index) =>
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100 h-100"
                                src={item?.banner_image}
                                alt={item?.alt_text}
                            />
                        </Carousel.Item>
                    )
            }
        </Carousel>
    );
};

export default Slider;