import React from 'react';
import './LeftSideBar.css';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { setSelectedItem } from '../../Redux/sportsExchangeSlice';
import { userDetail } from '../../Redux/userSlice';
// import { useSportsExchange } from '../../Hooks/useSportsExchange';
// import { mother_site } from '../../App';

const LeftSideBar = ({ selectedLeftBarData, setSelectedLeftBarData, leftSideBarData, setSelectedFragmentID, }) => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    const user = useSelector(userDetail);
    // const { exchangeLaunchFunction } = useSportsExchange();

    const changeStateFunc = (item) => {
        // console.log(user?.is_login, item?.name);
        // if ((item?.name === "In-Play") || (item?.name === "Today") || (item?.name === "Tomorrow")) {
        //     if (!user?.is_login) {
        //         navigate("/login");
        //     } else {
        //         exchangeLaunchFunction(1);
        //     }
        // } else {
        setSelectedLeftBarData(item);
        // }
    }
    return (
        <div className='col-2  pe-2'>
            <div className="left-bar rounded p-1">
                {
                    leftSideBarData?.map(item =>
                        <Link
                            key={item?.id}
                            to={item?.to || (((!user?.is_login) && ((item?.name === "In-Play") || (item?.name === "Today") || (item?.name === "Tomorrow"))) && "/login")}
                            className={(selectedLeftBarData?.name === item?.name) ? 'active rounded mb-2' : 'sqrBtn rounded mb-2'}
                            onClick={() => changeStateFunc(item)}
                            style={{ textDecoration: "none" }}
                        >
                            <div className='d-flex flex-column justify-content-center align-items-center m-0 p-0 text-center'>
                                <img src={(selectedLeftBarData?.name === item?.name) ? item?.icon1 : item?.icon2} alt={item?.name} />
                                <p>{item?.name}</p>
                            </div>
                        </Link>
                    )
                }
            </div>
        </div>
    );
};

export default LeftSideBar;