import { useState } from 'react'


function useCopyToClipboard() {
    const [copiedText, setCopiedText] = useState(``);

    const copy = async text => {
        if (!navigator?.clipboard) {
            // console.warn('Clipboard not supported')
            return false
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text)
            setCopiedText(text);
            alert(`Copied : ${text}`);
            return copiedText;
        } catch (error) {
            // console.warn('Copy failed', error)
            setCopiedText(null)
            return false;
        }
    }

    // return [copiedText, copy];
    return copy;
}

export default useCopyToClipboard
