import React, { useEffect, useState } from 'react';
// import CustomSectionTitle from '../../Components/CustomSectionTitle/CustomSectionTitle';
import { origin, rootUrl } from '../../App';
import Header from '../../Components/Header/Header';
import { Trans } from 'react-i18next';

const Contact = () => {
    const [contactList, setContactList] = useState(null);
    // console.log(contactList)
    useEffect(() => {
        fetch(`${rootUrl}/statics/social-connections/${origin}`)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                setContactList(data)
            })
    }, [])
    return (
        <div className="inner-scroll contact">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="contactPage">Contact</Trans>} />
            </div>
            {/* <div className='mx-4 my-4'>
                <CustomSectionTitle title={"Contact Us"} page={"contact"} />
            </div> */}
            <div className='mx-4 my-4'>
                <div className="row gx-0 d-flex justify-content-center align-items-center">
                    <h3 className="text-capitalize text-center fw-bold">At your Service</h3>
                </div>
                <div className="row gx-0 d-flex justify-content-center align-items-center">
                    <div className='col-12'>
                        {/* <p className='m-0 mx-3 mt-3 pb-4' style={{ color: "#000", fontSize: "20px" }}>At Your Service.....</p> */}
                        <ul className="row g-0 align-items-center" style={{ color: "#000", listStyle: "none", margin: 0, padding: 0 }}>
                            {
                                contactList?.map((item, index) =>
                                    <li className='col-12' key={index}>
                                        <div className='d-flex align-items-center'>
                                            <img className='me-3 my-2' src={item?.logo} alt="" style={{ height: "30px", width: "30px", objectFit: "cover" }} />
                                            <a href={item?.social?.prefix + item?.resource} target="_blank" rel="noreferrer" style={{ fontSize: "18px", color: "#000", textDecoration: "none", }} >
                                                {item?.social?.name} <small style={{ fontSize: "14px" }}> - <span className="green-text">{item?.resource}</span></small>
                                            </a>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;