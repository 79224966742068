import { useEffect, useState } from "react";
import { origin, rootUrl } from "../App";
import { useDispatch } from "react-redux";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";



export const useLive = () => {
    const dispatch = useDispatch();
    // const [live, setLive] = useState();
    const [liveEvents, setLiveEvents] = useState([]);

    useEffect(() => {
        dispatch(setInnerLoaderTrue());
        fetch(`${rootUrl}/live_game/${origin}`)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                // setLive(data[0]);
                fetch(`${rootUrl}/live_game/events/${origin}`)
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(dataList => {
                        setLiveEvents([data[0], ...dataList]);
                        dispatch(setInnerLoaderFalse());
                    })
            })

    }, [dispatch])


    return {
        // live,
        liveEvents,
    }
}
