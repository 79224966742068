import lotteryExtra0 from '../Assets/Images/Lottery/GameCard/LotteryCards/pcso.jpg';
import lotteryExtra1 from '../Assets/Images/Lottery/GameCard/LotteryCards/th.jpg';

import lottery0 from '../Assets/Images/Lottery/GameCard/lottery0.png';
import lottery1 from '../Assets/Images/Lottery/GameCard/lottery1.png';
import lottery2 from '../Assets/Images/Lottery/GameCard/lottery2.png';
import lottery3 from '../Assets/Images/Lottery/GameCard/lottery3.png';
import lottery4 from '../Assets/Images/Lottery/GameCard/lottery4.png';
import lottery5 from '../Assets/Images/Lottery/GameCard/lottery5.png';
import lottery6 from '../Assets/Images/Lottery/GameCard/lottery6.png';
import lottery7 from '../Assets/Images/Lottery/GameCard/lottery7.png';

import lotteryLeftIconDark0 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconDark0.svg';
import lotteryLeftIconDark1 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconDark1.svg';
import lotteryLeftIconDark2 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconDark2.svg';
import lotteryLeftIconDark3 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconDark3.svg';
import lotteryLeftIconDark4 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconDark4.svg';
import lotteryLeftIconDark5 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconDark5.svg';

import lotteryLeftIconGray0 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconGray0.svg';
import lotteryLeftIconGray1 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconGray1.svg';
import lotteryLeftIconGray2 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconGray2.svg';
import lotteryLeftIconGray3 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconGray3.svg';
import lotteryLeftIconGray4 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconGray4.svg';
import lotteryLeftIconGray5 from '../Assets/Icons/lotteryLeftIcons/lotteryLeftIconGray5.svg';

export const lotteryExtraData = [
    {
        ID: 0,
        MerchantName: "Philippine Charity Lottery",
        Trans: {
            en: `PCSO Lottery`,
        },
        to: '/pcso-lottery/',
        ImageFullPath: lotteryExtra0,
    },
    {
        ID: 1,
        MerchantName: "Thai Government Lottery",
        Trans: {
            en: `TH Lottery`,
        },
        to: '/th-lottery/',
        ImageFullPath: lotteryExtra1,
    },
]
export const lotteryData = [
    {
        id: 0,
        name: "PCSO Lottery",
        text: `Philippine Charity Lottery`,
        to: '/sports',
        img: lottery0,
    },
    {
        id: 1,
        name: "TH Lottery",
        text: `Thai Government Lottery`,
        to: '/lottery',
        img: lottery1,
    },
    {
        id: 2,
        name: "Unlimited Blackjack",
        text: `XPG`,
        to: '/live_casino',
        img: lottery2,
    },
    {
        id: 3,
        name: "Blackjack 7",
        text: `LuckyStreak`,
        to: '/casino',
        img: lottery3,
    },
    {
        id: 4,
        name: "Unlimited Blackjack",
        text: `XPG`,
        to: '/slots',
        img: lottery4,
    },
    {
        id: 5,
        name: "Blackjack 7",
        text: `LuckyStreak`,
        to: '/virtual_sport',
        img: lottery5,
    },
    {
        id: 6,
        name: "Blackjack 2 VIP",
        text: `Bombay Live`,
        to: '/slots',
        img: lottery6,
    },
    {
        id: 7,
        name: "J2 Blackjack",
        text: `Ho Gaming`,
        to: '/virtual_sport',
        img: lottery7,
    },
]

export const lotteryLeftData = [
    {
        id: 0,
        name: 'All',
        to: "",
        icon1: lotteryLeftIconDark0,
        icon2: lotteryLeftIconGray0,
    },
    {
        id: 1,
        name: 'Lottery',
        to: "",
        icon1: lotteryLeftIconDark1,
        icon2: lotteryLeftIconGray1,
    },
    {
        id: 2,
        name: 'Lotto',
        to: "",
        icon1: lotteryLeftIconDark2,
        icon2: lotteryLeftIconGray2,
    },
    {
        id: 3,
        name: 'Toto',
        to: "",
        icon1: lotteryLeftIconDark3,
        icon2: lotteryLeftIconGray3,
    },
    {
        id: 4,
        name: 'Bingo',
        to: "",
        icon1: lotteryLeftIconDark4,
        icon2: lotteryLeftIconGray4,
    },
    {
        id: 5,
        name: 'Today',
        to: "",
        icon1: lotteryLeftIconDark5,
        icon2: lotteryLeftIconGray5,
    },
]
