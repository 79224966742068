import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { origin, originNameOnly, rootUrl } from "../App";
import { showCustomAlert } from "./alertSlice";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "./loaderSlice";

//user_login
export const fetchLoginUserAuth = createAsyncThunk("user/fetchLoginUserAuth", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
        let url_val = parentUrl.split('/');
        let make_url = url_val[0] + '//' + url_val[2] + '/';

        let newData = { ...payload, request_origin: originNameOnly, user_base_origin: make_url };

        const response = await fetch(`${rootUrl}/auth/users/login/`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(newData)
        });

        let data = await response.json();
        if (data.error || data.login_error) {
            dispatch(showCustomAlert({
                type: "error",
                message: `${data.error || data.login_error}`
            }));
            return rejectWithValue(data.error || data.login_error);
        } else {
            localStorage.setItem("bet7uUserKey", JSON.stringify({ is_login: true, value: data.token, old_user: data.old_user }));
            dispatch(fetchLoginUserData());
            return data;
        }
    } catch (error) {
        if (error.message === 'Failed to fetch') {
            dispatch(showCustomAlert(`Currently not available our service please try again later sometime!!!`));
            throw rejectWithValue('Currently not available our service please try again later sometime!!!');
        } else {
            // console.log("error", error);
            dispatch(showCustomAlert({
                type: "error",
                message: `${error.message}`
            }));
            throw rejectWithValue(error.message);
        }
    }
})


//get_user_data
export const fetchLoginUserData = createAsyncThunk("user/fetchLoginUserData", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    let stringValue = localStorage.getItem("bet7uUserKey");
    if (stringValue === null) {
        localStorage.removeItem("bet7uUserKey");
        const currentUser = {
            is_login: false,
            userData: null,
        };
        throw rejectWithValue(currentUser);
    }

    try {
        // dispatch(setInnerLoaderTrue());
        let value = JSON.parse(stringValue);

        const response = await fetch(`${rootUrl}/auth/users-detail/${origin}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value.value}`
            },
        });

        if (!response.ok) throw response;
        else {
            let data = await response.json();
            dispatch(fetchLoginUserWallet());
            return data[0];
        }
    }
    catch (error) {
        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            window.open(JSON.parse(stringValue).loginUrl, "_parent");
            localStorage.removeItem("bet7uUserKey");
            localStorage.removeItem("campaignVisible");
            throw rejectWithValue(errorData?.detail);
        } else {
            throw rejectWithValue(error.statusText);
        }
    }
    finally {
        // dispatch(setInnerLoaderFalse());
    }
})


//get_user_wallet
export const fetchLoginUserWallet = createAsyncThunk("user/fetchLoginUserWallet", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    let stringValue = localStorage.getItem("bet7uUserKey")
    if (stringValue === null) {
        localStorage.removeItem("bet7uUserKey");
        const currentUser = {
            is_login: false,
            userData: null,
        };
        return currentUser;
    }
    try {
        // dispatch(setInnerLoaderTrue());
        let value = JSON.parse(stringValue);
        const response = await fetch(`${rootUrl}/auth/user-balance/${origin}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value.value}`,
            },
        });
        if (!response.ok) {
            throw response;
        } else {
            let data = await response.json();
            data.total_balance = (data?.credit_balance + data?.reserve_balance + data?.coin_balance + data?.gs_balance);
            // console.log(data);
            return data;
        }
    }
    catch (error) {
        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            localStorage.removeItem("bet7uUserKey");
            localStorage.removeItem("campaignVisible");
            throw rejectWithValue(errorData?.detail);
        } else {
            throw rejectWithValue(error.statusText);
        }
    }
    finally {
        // dispatch(setInnerLoaderFalse());
    }
})


//user logout
export const fetchLogoutUser = createAsyncThunk("user/fetchLogoutUser", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    let stringValue = localStorage.getItem("bet7uUserKey");
    if (stringValue === null) {
        localStorage.removeItem("bet7uUserKey");
        return null;
    }

    dispatch(setInnerLoaderTrue());
    try {
        let value = JSON.parse(stringValue);
        const response = await fetch(`${rootUrl}/auth/users/logout/${origin}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value.value}`,
            }
        });
        if (!response.ok) throw response;
        else {
            localStorage.removeItem("bet7uUserKey");
            localStorage.removeItem("campaignVisible");
            return null;
        }
    } catch (error) {
        if (error.message === 'Failed to fetch') {
            dispatch(showCustomAlert({
                type: "error",
                message: `Internet Connection Error !!! `
            }));
            throw rejectWithValue('Internet Connection Error !!! ');
        }

        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            window.open(JSON.parse(stringValue).loginUrl, "_parent");
            localStorage.removeItem("bet7uUserKey");
            localStorage.removeItem("campaignVisible");
            return null;
        } else {
            throw rejectWithValue(error.statusText);
        }
    } finally {
        dispatch(setInnerLoaderFalse());
    }

})

const userSlice = createSlice({
    name: 'user',
    initialState: {
        status: "loading",
        registrationData: null,
        login: false,
        userData: null,
        currentUserWallet: {},
    },

    reducers: {
        setRegistrationData: (state, action) => {
            state.registrationData = action.payload;
        },

    },

    extraReducers: {
        //user_login
        [fetchLoginUserAuth.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchLoginUserAuth.fulfilled]: (state, action) => {
            state.status = "success";
            // const { payload } = action;
            // console.log("success", payload);
        },
        [fetchLoginUserAuth.rejected]: (state, action) => {
            state.status = "failed";
            // const { payload } = action;
            // console.log("error", payload);
        },

        //user_data
        [fetchLoginUserData.pending]: (state, action) => {
            state.status = "loading"
        },
        [fetchLoginUserData.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;
            // console.log("success", payload);
            state.login = true;
            state.userData = payload;
        },
        [fetchLoginUserData.rejected]: (state, action) => {
            state.status = "failed";
            // const { payload } = action;
            // console.log("error", payload);
        },

        //get_login_user_wallet
        [fetchLoginUserWallet.pending]: (state, action) => {
            // state.status = "loading";
        },
        [fetchLoginUserWallet.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;
            state.currentUserWallet = payload;
        },
        [fetchLoginUserWallet.rejected]: (state, action) => {
            state.status = "failed";
            // const { payload } = action;
        },

        //user_log_out
        [fetchLogoutUser.pending]: (state, action) => {
            state.status = "loading";
        },
        [fetchLogoutUser.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;
            state.login = false;
            state.userData = payload;
        },
        [fetchLogoutUser.rejected]: (state, action) => {
            state.status = "failed";
            // const { payload } = action;
        },
    }
});

export const userDetail = state => {
    let data = {
        is_login: state.user.login,
        user: state.user.userData,
    }
    return data;
};

export const userLoading = state => state.user.status;
export const userRegConfirmData = state => state.user.registrationData;

export const { setRegistrationData } = userSlice.actions;

export const currentUserWallet = state => state.user.currentUserWallet;

export default userSlice.reducer;