import React, { useEffect, useState } from 'react';
// import { BiEdit } from 'react-icons/bi';
import CustomInputField from '../../Components/CustomInputField/CustomInputField';
import { FaUserAlt, FaUsers } from 'react-icons/fa';
import { AiOutlineGlobal } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { showCustomAlert } from '../../Redux/alertSlice';
import { origin, originNameOnly, rootUrl } from '../../App';
import { useCountry } from '../../Hooks/useCountry';
import { fetchLoginUserData } from '../../Redux/userSlice';
import { useNavigate } from 'react-router-dom';

import regSuccessImg from '../../Assets/Images/Registration/Group 414.svg'

const OldUser = ({ setOldUser }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { countries } = useCountry();
    const initialState = {
        username: '',
        gender: "Male",
        country_name: '',
        dob: '',
    };
    const [oldUserFormData, setOldUserFormData] = useState(initialState);
    const [selectCountry, setSelectCountry] = useState(null);
    const [userAlert, setUserAlert] = useState(null);
    const [success, setSuccess] = useState(false);

    const updateCountry = (e) => {
        const findCountry = countries.find(item => item.country_name === e.target.value);
        setSelectCountry(findCountry);
        updateOldUserFormData(e);
    }

    useEffect(() => {
        if (countries) {
            setSelectCountry(countries[0]);
            setOldUserFormData(prev => ({ ...prev, "country_name": countries[0].country_name }));
        }
    }, [countries])

    const checkExistData = async (e) => {
        let url = `${rootUrl}${'/auth/check_user/'}${origin}`;

        let options = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                username: `${e.target.value}`,
                request_origin: originNameOnly,
            })
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) throw response;
            const data = await response.json();

            if (!data.success && e.target.name === "username") {
                setUserAlert("Username Exist");
            } else {
                setUserAlert(null);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const checkDOB = (e) => {
        let currDate = new Date();
        let dob = new Date(oldUserFormData.dob);
        var Difference_In_Time = currDate.getTime() - dob.getTime();
        let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        return parseInt(Difference_In_Days / 365);
    }

    const updateOldUserFormData = e => {
        let createUser = { ...oldUserFormData };
        if (e.target.name === 'email' || e.target.name === 'username' || e.target.name === 'password') {
            let list = (e.target.value).split(' ');
            if (list.length > 1) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Space is not allowed`
                }));
                return;
            } else {
                if (e.target.name === 'email' || e.target.name === 'username') {
                    createUser[e.target.name] = e.target.value.toLowerCase();
                } else {
                    createUser[e.target.name] = e.target.value;
                }
                setOldUserFormData(createUser);
            }
        } else {
            createUser[e.target.name] = e.target.value;
            setOldUserFormData(createUser);
        }
    }

    const submitOldUserForm = (e) => {
        e.preventDefault();
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            // dispatch(setInnerLoaderTrue());
            let age = checkDOB();
            if (age < 18) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Hay Bro You Are A Child !!! `
                }));
                return;
            }
            if (!((/^\b[-a-zA-Z0-9_]{4,}$\b/.test(oldUserFormData?.username)) && !userAlert)) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Username Not Acceptable.`
                }));
                return;
            }
            if (userAlert !== null) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Username Already Exist`
                }));
                return;
            }
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/auth/old-user/${origin}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
                body: JSON.stringify({
                    username: oldUserFormData?.username,
                    gender: oldUserFormData?.gender,
                    dob: oldUserFormData?.dob,
                    country_name: selectCountry?.country_name,
                })
            })
                .then(async res => {
                    // dispatch(setInnerLoaderFalse());
                    if (!res.ok) return await res.json().then(text => {
                        console.log(text.error);
                        throw text?.error;
                    });
                    else return await res.json();
                })
                .then(data => {
                    if (data?.status === "ok") {
                        console.log("data", data?.status);
                        setSuccess(true);
                        // setOldUser(false);
                        localStorage.setItem("bet7uUserKey", JSON.stringify({
                            is_login: true,
                            value: value.value,
                            old_user: false,
                        }))
                        setOldUserFormData(initialState);
                        dispatch(fetchLoginUserData());
                        // navigate("/");
                    }
                })
                .catch(err => {
                    dispatch(showCustomAlert({
                        type: "danger",
                        message: `${err}`
                    }));
                })
        }
    }
    return (
        <div className='inner-scroll oldUserProfile'>
            {
                success &&
                <>
                    <div className='position-relative'>
                        <h3 className="text-center p-3">Profile Update Successful</h3>
                    </div>

                    <div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img className='confirmMailImg' src={regSuccessImg} alt="confirm mail img" />
                        </div>
                        <p className='p-0 pt-2 m-0 text-center'>
                            <small>You have updated your profile successfully.</small>
                        </p>
                        <p className='p-0 pb-2 m-0 text-center'>
                            <small>Please try to use your updated Username for future login.</small>
                        </p>

                        <div className='mx-4 mb-4'>
                            <div className='d-flex justify-content-center'>
                                <button onClick={() => {
                                    navigate("/", { replace: true });
                                    setOldUser(false);
                                }} type='button' className="btn btn-yellow my-2 w-100">Back to Home</button>
                            </div>
                        </div>

                    </div>
                </>
            }
            {
                !success &&
                <>
                    <div className="form-title text-center">
                        <h3 className="text-uppercase text-center fw-bold p-3">Update profile</h3>
                        <small>Please, update your profile to access your account.</small>
                    </div>

                    <section className='m-4'>
                        <form className="row g-0" onSubmit={submitOldUserForm}>
                            <CustomInputField type="text" name="username" label="Username" value={oldUserFormData.username} func={updateOldUserFormData} icon1={false} icon2={<FaUserAlt />} errorSms={userAlert} checkExist={checkExistData} />
                            <div className="custom-input">
                                <div className="module my-2">
                                    <label className="" htmlFor="gender">Gender</label>
                                    <div className="input-group">
                                        <select onChange={updateOldUserFormData} name="gender" id="oldUserGender" value={oldUserFormData.gender} className="form-select">
                                            <option value={"Male"}>Male</option>
                                            <option value={"Female"}>Female</option>
                                            <option value={"Other"}>Other</option>
                                        </select>
                                        <span className="input-group-text">
                                            <FaUsers />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-input">
                                <div className="module my-2">
                                    <label className="" htmlFor="country_name">Select Country</label>
                                    <div className="input-group">
                                        {
                                            selectCountry &&
                                            <span className="input-group-text">
                                                <img src={`${rootUrl}${selectCountry?.country_flag_icon}`} alt="Img" className='countryIconStyle' />
                                            </span>
                                        }

                                        <select onChange={updateCountry} name="country_name" id="oldUser_country_name" value={oldUserFormData.country_name} className="form-select">
                                            {
                                                countries &&
                                                countries.map(country =>
                                                    <option key={country.id} value={country.country_name}>{country.country_name}</option>
                                                )
                                            }
                                        </select>

                                        <span className="input-group-text"> <AiOutlineGlobal /> </span>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-input">
                                <div className="module my-2">
                                    <label className="">Date of Birth</label>
                                    <div className="input-group">
                                        <input
                                            type="date"
                                            name="dob"
                                            className="form-control"
                                            title="Date of Birth"
                                            onChange={updateOldUserFormData}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <button type='submit' className="btn btn-green mt-3">Update</button>
                        </form>

                    </section>
                </>
            }
        </div>
    );
};

export default OldUser;