
import leftAllIconDark0 from '../Assets/Icons/ProvidersPageStaticCommonIcons/allDark.svg';
import leftLobbyIconDark1 from '../Assets/Icons/ProvidersPageStaticCommonIcons/lobbyDark.svg';

import leftAllIconGray0 from '../Assets/Icons/ProvidersPageStaticCommonIcons/allGray.svg';
import leftLobbyIconGray1 from '../Assets/Icons/ProvidersPageStaticCommonIcons/lobbyGray.svg';

export const leftData = [
    {
        id: 100,
        name: 'All',
        to: "",
        icon1: leftAllIconDark0,
        icon2: leftAllIconGray0,
    },
    {
        id: 101,
        name: 'Lobby',
        to: "",
        type: "lobby",
        icon1: leftLobbyIconDark1,
        icon2: leftLobbyIconGray1,
    },
]