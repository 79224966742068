import useFetchData from "./useFetchData";

export const useCountry = () => {
    const state = useFetchData("/countries_api/list/");
    // console.log('object');
    return {
        countries: state.data
    }
};

