import deposit from '../Assets/Icons/AgentAdminIcons/deposit.svg';
import withdrawal from '../Assets/Icons/AgentAdminIcons/withdrawal.svg';
import addGateway from '../Assets/Icons/AgentAdminIcons/add-gateway.svg';
import gatewayStatus from '../Assets/Icons/AgentAdminIcons/gateway-status.svg';
import DepositRequest from '../Pages/BankingPages/AgentBanking/DepositRequest/DepositRequest';
import WithdrawalRequest from '../Pages/BankingPages/AgentBanking/WithdrawalRequest/WithdrawalRequest';
import AddGateway from '../Pages/BankingPages/AgentBanking/AddGateway/AddGateway';
import GatewayStatus from '../Pages/BankingPages/AgentBanking/GatewayStatus/GatewayStatus';
import { Trans } from 'react-i18next';

export const agentActivities = [
    {
        id: 0,
        title: `Deposit Request`,
        cardTitle: <Trans i18nKey="depositRequestCardTitle">Deposit Request</Trans>,
        icon: deposit,
        component: <DepositRequest />,
    },
    {
        id: 1,
        title: `Withdrawal Request`,
        cardTitle: <Trans i18nKey="withdrwalRequestCardTitle">Withdrawal Request</Trans>,
        icon: withdrawal,
        component: <WithdrawalRequest />,
    },
    {
        id: 2,
        title: `Add Gateway`,
        cardTitle: <Trans i18nKey="addGatewayCardTitle">Add Gateway</Trans>,
        icon: addGateway,
        component: <AddGateway />,
    },
    {
        id: 3,
        title: `Gateway Status`,
        cardTitle: <Trans i18nKey="getwayStatusCardTitle">Gateway Status</Trans>,
        icon: gatewayStatus,
        component: <GatewayStatus />,
    },
];