import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userDetail } from "../Redux/userSlice";
import { origin, rootUrl } from "../App";

export const useLiveNotifications = () => {
    const [liveNotifications, setLiveNotifications] = useState([]);
    const [seenNotifications, setSeenNotifications] = useState([]);
    const [notificationStatus, setNotificationStatus] = useState({});
    const [currentSocket, setCurrentSocket] = useState(null);
    const userInfo = useSelector(userDetail);

    // let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
    // let url_val = parentUrl.split('/');
    // let make_url = url_val[0] + '//' + url_val[2] + '/';


    useEffect(() => {
        if (userInfo?.user?.username) {
            // const ws = new WebSocket(`ws://192.168.0.131:8000/notification/${userInfo?.user?.username}/`);
            const ws = new WebSocket(`wss://k7k.asia/notification/${userInfo?.user?.username}/`);
            setCurrentSocket(ws)

            // ws.onopen = (event) => {
            //     console.log("object", event);
            // };

            ws.onmessage = function (event) {
                const json = JSON.parse(event.data);
                try {
                    if (Array.isArray(json.message)) {
                        // console.log(json.message);
                        setLiveNotifications(json.message);
                        const seenData = json.message?.filter((seenItem, index) => seenItem.delivered === false);
                        // setSeenNotifications(seenData);
                        // console.log(seenData);
                        if (seenData) {
                            for (let i = 0; i < seenData.length; i++) {
                                const title = `Notification`;
                                const options = {
                                    body: `${seenData[i]?.notice}`,
                                    icon: 'favicon-32x32.png',
                                    badge: 'favicon-32x32.png'
                                };
                                pushNotification(title, options);
                            }

                        }
                    } else {
                        // const newData = [...dataObject, json.message];
                        // console.log(json.message);
                        setLiveNotifications(prev => [json.message, ...prev]);
                        // const seenData = [json.message]?.filter((seenItem, index) => seenItem.seen === false);
                        // setSeenNotifications(seenData);
                        if (json.message) {
                            const title = `Notification`;
                            const options = {
                                body: `${json?.message?.notice}`,
                                icon: 'favicon-32x32.png',
                                badge: 'favicon-32x32.png'
                            };

                            pushNotification(title, options);
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            };

            //clean up function
            // return () => ws.close();
            return () => {
                if (ws.readyState === 1) { // <-- This is important
                    ws.close();
                }
            };
        }
    }, [userInfo?.user?.username]);

    useEffect(() => {
        const seenData = liveNotifications?.filter((seenItem, index) => seenItem.seen === false);
        setSeenNotifications(seenData);
    }, [liveNotifications]);

    // notification status update start

    const updateLiveNotificationStatus = (data) => {

        currentSocket.send(JSON.stringify(data));
        const newData = [...liveNotifications]
        const index = liveNotifications.findIndex(obj => obj.id === data.id);
        newData[index].seen = true

        setLiveNotifications(newData);
        setNotificationStatus(data);
        // console.log(data);
    }

    // delete single notification

    const deleteNotification = (data) => {
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/notification/notification_delete/${data?.id}/${origin}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    console.log(res);
                    if (!res.ok) {
                        throw res
                    }
                    else {
                        const seenData = liveNotifications?.filter((seenItem, index) => seenItem.id !== data?.id);
                        setLiveNotifications(seenData);

                    }
                }
                )
                .then(data => {
                    // console.log("data", data);
                    // setNotificationStatus(data);
                    // setNotifications([data]);
                })
                .catch(err => {
                    console.log(err?.detail);
                })
        }
    }

    // push notifications 
    const pushNotification = (title, options) => {
        if (Notification.permission === "granted") {
            navigator.serviceWorker.ready.then((registration) => {
                registration.showNotification(title, options);
                registration.update();
            })
                .catch((error) => console.log(error))
        }
    }


    return {
        updateLiveNotificationStatus,
        liveNotifications,
        seenNotifications,
        notificationStatus,
        setNotificationStatus,
        deleteNotification,
    };
};