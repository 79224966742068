
import sports from "../Assets/Icons/MenuIcons/AllFeatures/sports.svg";
// import sportsExchange from "../Assets/Icons/MenuIcons/AllFeatures/sports-exchange.svg";
import lottery from "../Assets/Icons/MenuIcons/AllFeatures/lottery.svg";
import liveCasino from "../Assets/Icons/MenuIcons/AllFeatures/live-casino.svg";
// import casino from "../Assets/Icons/MenuIcons/AllFeatures/casino.svg";
import slots from "../Assets/Icons/MenuIcons/AllFeatures/slots.svg";
// import vSports from "../Assets/Icons/MenuIcons/AllFeatures/v-sports.svg";
import fishing from '../Assets/Icons/MenuIcons/AllFeatures/fishing.svg';

// import profile from "../Assets/Icons/MenuIcons/OthersMenu/profile.svg";
// import deposit from "../Assets/Icons/MenuIcons/OthersMenu/deposit.svg";
// import withdrawal from "../Assets/Icons/MenuIcons/OthersMenu/withdrawal.svg";
// import p2pTransfer from "../Assets/Icons/MenuIcons/OthersMenu/p2p-transfer.svg";
import payment from "../Assets/Icons/MenuIcons/OthersMenu/payment.svg";
// import convert from "../Assets/Icons/MenuIcons/OthersMenu/convert.svg";
import agentAdmin from "../Assets/Icons/MenuIcons/OthersMenu/agent-admin.svg";
import transactionHistory from "../Assets/Icons/MenuIcons/OthersMenu/transaction-history.svg";
import { Trans } from "react-i18next";


export const allFeatures = [
    // {
    //     id: 1,
    //     navName: <Trans i18nKey="sportsExchangeFeaturedMenu">Exchange</Trans>,
    //     img: sportsExchange,
    //     // link: `/`,
    // },
    {
        id: 2,
        navName: <Trans i18nKey="sportsbookFeaturedMenu">Sportsbook</Trans>,
        img: sports,
        link: `/`,
    },
    {
        id: 3,
        navName: <Trans i18nKey="lotteryFeaturedMenu">Lottery</Trans>,
        img: lottery,
        link: `/`,
    },
    {
        id: 4,
        navName: <Trans i18nKey="casinoFeaturedMenu">Casino</Trans>,
        img: liveCasino,
        link: `/`,
    },
    // {
    //     id: 5,
    //     navName: <Trans i18nKey="casinoFeaturedMenu">Casino</Trans>,
    //     img: casino,
    //     link: `/`,
    // },
    {
        id: 6,
        navName: <Trans i18nKey="slotsFeaturedMenu">Slots</Trans>,
        img: slots,
        link: `/`,
    },
    // {
    //     id: 7,
    //     navName: <Trans i18nKey="virtualsportsFeaturedMenu">ESports</Trans>,
    //     img: vSports,
    //     link: `/`,
    // },
    {
        id: 8,
        navName: "Fishing",
        img: fishing,
        link: `/`,
    },
    // {
    //     id: 9,
    //     navName: "Cards",
    //     img: fishing,
    //     link: `/`,
    // },
];

export const othersMenu = [
    // {
    //     id: 0,
    //     navName: <Trans i18nKey="profileOthersMenu">Profile</Trans>,
    //     img: profile,
    //     link: `/user-profile-edit`,
    // },
    // {
    //     id: 1,
    //     navName: <Trans i18nKey="depositOthersMenu">Deposit</Trans>,
    //     img: deposit,
    //     link: `/deposit`,
    // },
    // {
    //     id: 2,
    //     navName: <Trans i18nKey="withdrawalOthersMenu">Withdrawal</Trans>,
    //     img: withdrawal,
    //     link: `/withdrawal`,
    // },
    // {
    //     id: 3,
    //     navName: <Trans i18nKey="transferOthersMenu">P2P Transfer</Trans>,
    //     img: p2pTransfer,
    //     link: `/transfer`,
    // },
    {
        id: 4,
        navName: <Trans i18nKey="paymentOthersMenu">Payment</Trans>,
        img: payment,
        link: `/payment`,
    },
    // {
    //     id: 5,
    //     navName: <Trans i18nKey="convertOthersMenu">Convert</Trans>,
    //     img: convert,
    //     link: `/convert`,
    // },
    {
        id: 6,
        navName: <Trans i18nKey="agentAdminOthersMenu">Agent Admin</Trans>,
        img: agentAdmin,
        link: `/agent-admin`,
    },
    {
        id: 7,
        navName: <Trans i18nKey="transactionHistoryOthersMenu">Transaction History</Trans>,
        img: transactionHistory,
        link: `/banking-profile`,
    },
    // {
    //     id: 8,
    //     navName: `Logout`,
    //     img: logout,
    //     link: `/`,
    // },
];