import React from "react";

import { Modal } from "react-bootstrap";
import { ImCross } from "react-icons/im";

import { rootUrl } from "../../App";

const OfferDetailsModal = ({ item, close }) => {

    const detailsDescriptionData = item?.item?.description?.split("title: ")
        .map(e => e.split("des: "))
        .map(([title, description]) => ({ title, description }));
    detailsDescriptionData.shift();

    return (
        <Modal className="transactionModal offerDetailsModal" fullscreen={false} show={item?.visible}>
            <Modal.Body className="d-flex justify-content-center"> {/* .modal-content */}
                <div className="w-100">
                    <div className="px-0 fw-bold" style={{ borderRadius: "10px 10px 0 0" }}>
                        <div className="d-flex align-items-center justify-content-center p-0" style={{ position: "relative" }}>

                            {
                                item?.item?.image &&
                                <img className="" src={rootUrl + item?.item?.image} alt={item?.item?.alt_text} width={"100%"} height={""} style={{ borderRadius: "10px 10px 0 0" }} />
                            }
                            {
                                ((item?.item?.video_url) && (!item?.item?.image)) &&
                                <iframe
                                    className=""
                                    width="100%"
                                    // height="130"
                                    src={item?.item?.video_url}
                                    title={item?.item?.sub_title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                    style={{ borderRadius: "10px 10px 0 0" }}
                                >

                                </iframe>
                            }

                            <button onClick={close} style={{ color: "#fff", backgroundColor: "var(--yellow)", position: "absolute", top: "12%", right: "3%", opacity: "0.9", }} type="button" className="border-0 rounded p-1 d-flex d-md-flex" data-bs-dismiss="modal" aria-label="Close">
                                <ImCross fill={"#000"} />
                            </button>
                        </div>
                    </div>

                    <div className="my-2 d-flex flex-column align-items-center justify-content-center">
                        <p style={{ color: "var(--green)", fontWeight: "bold", textAlign: "center", fontSize: "24px", margin: 0 }}>
                            {item?.item?.title}
                        </p>
                    </div>

                    <div className="mx-0 my-2 pt-0" style={{ borderTop: "2px dashed #C2C2C2" }}>
                        <div className='row g-0 rounded contactMain pt-2'>
                            <div className='col-12 rounded-bottom'>
                                <ul className="row g-0 align-items-center" style={{ color: "#000", listStyle: "none", margin: 0, padding: 0 }}>
                                    {
                                        detailsDescriptionData?.map((detailsItem, index) =>
                                            <li className='col-12' key={index}>
                                                <div className='d-flex flex-column align-items-start m-0 mx-3 my-1 pb-0' style={{ whiteSpace: "pre-line" }}>
                                                    <p className='fw-bold mb-0' style={{ color: "#000", fontSize: "20px" }}>{detailsItem?.title}</p>
                                                    <p className='text-start mb-0' style={{ color: "#000", fontSize: "14px" }}>{detailsItem?.description}</p>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default OfferDetailsModal;