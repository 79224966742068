import React from 'react';

import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./PcsoAbout.css";

// import required modules
import { Pagination, Navigation } from "swiper";

import client0 from "../../../Assets/Images/Lottery/PcsoTestimonial/clients/client0.png";
import client1 from "../../../Assets/Images/Lottery/PcsoTestimonial/clients/client1.png";
import pcsoBG from "../../../Assets/Images/Lottery/PcsoTestimonial/pcso-bg.png";
import flag from "../../../Assets/Images/Lottery/PcsoTestimonial/flag.png";

const PcsoAbout = () => {
    const clientsReview = [
        {
            id: 1,
            number: 2417,
            image: client0,
            flagIcon: flag,
            name: `จุดเริ่มต้นลอตเตอรี่`,
            comments:
                `พระบาทสมเด็จพระจุลจอมเกล้าเจ้าอยู่หัวได้โปรดเกล้าฯ พระราชทานพระบรม ราชานุญาตให้กรมทหารมหาดเล็กออกลอตเตอรี่เป็นครั้งแรกในประเทศไทย เนื่องในงานพระราชพิธีเฉลิมพระชนมพรรษา โดยชาวอังกฤษชื่อ ครูอาลบาสเตอร์ ได้รับมอบหมายให้เป็นผู้อํานวยการออกลอตเตอรี่ตามแบบ ยุโรป เพื่อช่วยเหลือพ่อค้าต่างชาติที่นําสินค้ามาร่วมแสดงในการจัดพิพิธภัณฑ์ (โรงมิวเซียม) ที่ตึกคองคาเดียในพระบรมมหาราชวัง (ศาลาสหทัยสมาคมใน ปัจจุบัน)`,
        },
        {
            id: 2,
            number: 2460,
            image: client1,
            flagIcon: flag,
            name: `ลอตเตอรี่ของสภารักชาติแห่งประเทศอังกฤษ`,
            comments:
                `ลอตเตอรี่ของสภารักชาติแห่งประเทศอังกฤษ
                ได้รับพระราชทานพระบรมราชานุญาตจาก พระบาทสมเด็จพระมงกุฎเกล้าเจ้า อยู่หัว ให้ออกสลากลอตเตอรี่ของสภารักชาติ ประเทศอังกฤษ จําหน่ายราคา สลากละ 5 บาท โดยมีเงื่อนไขการจ่ายรางวัลเป็นสัญญาเงินกู้ของคณะกู้เงินใน การสงคราม โดยสภารักชาติแห่งประเทศอังกฤษ สาขากรุงเทพฯ เป็นผู้ลงนาม และประเทศสหพันธรัฐมลายูเป็นผู้ค้ําประกัน`,
        },
    ];
    return (
        <>
            <div className="row gx-0 d-flex justify-content-center align-items-center pt-5 m-3" style={{ backgroundColor: "#1F1F1F", backgroundImage: 'url(' + pcsoBG + ')', backGroundRepeat: "no-repeat", }}>
                <div className="pcsoAbout position-relative col-11 col-md-8 position d-flex-justify-content-center align-items-center">
                    <div className="row gx-0">
                        <div className="col-12 col-md-12">
                            <AiOutlineArrowLeft className="button-previous position-absolute" style={{ top: 0, left: 0, zIndex: 2 }} />
                            <AiOutlineArrowRight className="button-next position-absolute" style={{ top: 0, right: 0, zIndex: 2 }} />
                            <Swiper
                                spaceBetween={0}
                                navigation={{
                                    clickable: false,
                                    nextEl: '.button-next',
                                    prevEl: '.button-previous',
                                }}
                                modules={[Navigation, Pagination]}
                                className="pcsoAboutSlider"
                                loop={true}
                                breakpoints={
                                    {
                                        // when window width is >= 480px
                                        480: {
                                            slidesPerView: 1,
                                        },
                                        // when window width is >= 640px
                                        767: {
                                            slidesPerView: 1,
                                        }
                                    }
                                }
                            >
                                {
                                    clientsReview.map((client, index) =>
                                        <SwiperSlide key={index}>
                                            <div className="row gx-0 d-flex justify-content-around align-items-center mb-5">
                                                <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                                    <img src={client?.flagIcon} alt="flag" style={{ width: "15%" }} />
                                                    <p className="text-white text-center">{client?.number}</p>
                                                </div>

                                                <div className="col-12">
                                                    <div className="row gx-0 px-2 pt-3 mt-3" style={{ color: "#fff", backgroundColor: "transparent" }}>
                                                        <div className="col-12 d-flex justify-content-center align-items-center">
                                                            <img className="" height="auto" width="100%" src={client.image} alt="client" />
                                                        </div>
                                                        <div className="col-12 py-2">
                                                            <h6 className="fw-bold text-white text-center text-md-start mt-3">{client.name}</h6>
                                                            <p className="text-start types-card-text text-center text-md-start mt-3">{client.comments}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PcsoAbout;