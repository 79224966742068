import React, { useState } from 'react';
import { originNameOnly, rootUrl } from '../../../App';
import CustomInputField from '../../../Components/CustomInputField/CustomInputField';
import { IoIosLock } from 'react-icons/io';
// import CustomSectionTitle from '../../../Components/CustomSectionTitle/CustomSectionTitle';
import SuccessComponent from '../../../Components/SuccessComponent/SuccessComponent';
import { useDispatch } from 'react-redux';
import { showCustomAlert } from '../../../Redux/alertSlice';
import { fetchLogoutUser } from '../../../Redux/userSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import Header from '../../../Components/Header/Header';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const ChangePassword = () => {
    const dispatch = useDispatch();
    const initialState = {
        'old_password': "",
        'new_password': "",
        'reTypePass': "",
    };

    const [inputChangePassData, setInputChangePassData] = useState(initialState);
    const [status, setStatus] = useState({});

    const updateChangePasswordData = e => {
        const { name, value } = e.target
        setInputChangePassData(prev => ({ ...prev, [name]: value }))
    }

    const changePassSubmitFunc = () => {
        const payload = {
            old_password: inputChangePassData.old_password,
            new_password: inputChangePassData.new_password,
            request_origin: originNameOnly
        }

        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/auth/change-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
                body: JSON.stringify(payload)
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    if (data?.status === "OK") {
                        setStatus(data);
                        setInputChangePassData(initialState);
                    }
                    // dispatch(fetchLoginUserWallet());
                })
                .catch(err => {
                    err.json().then(text => {
                        // console.log(text);
                        if (text?.error) {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.error}`,
                            }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.detail}`,
                            }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    // dispatch(setCustomAlertTrue({ massage: `${err.statusText}`, type: 'danger' }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse());
                })
        }
    }

    const formSubmit = (e) => {
        e.preventDefault();
        if (inputChangePassData.reTypePass === inputChangePassData.new_password && inputChangePassData.reTypePass && inputChangePassData.reTypePass) {
            changePassSubmitFunc()
        } else {
            dispatch(showCustomAlert({
                type: "error",
                message: `Re-Type password Dose not Match`,
            }));
        }
    }
    return (
        <div className="inner-scroll change-password">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="changepasswordPage">Change Password</Trans>} />
            </div>
            {
                (status?.status === "OK") ?
                    <SuccessComponent title={<Trans i18nKey="titleChangePasswordSuccess" />} />
                    :
                    <>
                        {/* <div className='mx-4 my-4'>
                            <CustomSectionTitle title={"Change Password"} page={"changePassword"} />
                        </div> */}
                        <div className="mx-4">
                            <form onSubmit={formSubmit} autoComplete="off" className="mt-4">
                                <CustomInputField type="password" name="old_password" label="Old Password" langLabel={<Trans i18nKey="oldPasswordChangePasswordLangLabel" />} langPlaceHolder={t('oldPasswordChangePasswordLangLabel')} value={inputChangePassData?.old_password} func={updateChangePasswordData} icon1={<IoIosLock />} icon2={<IoIosLock />} />
                                <CustomInputField type="password" name="new_password" label="New Password" langLabel={<Trans i18nKey="newPasswordChangePasswordLangLabel" />} langPlaceHolder={t('newPasswordChangePasswordLangLabel')} value={inputChangePassData?.new_password} func={updateChangePasswordData} icon1={<IoIosLock />} icon2={<IoIosLock />} />
                                <CustomInputField type="password" name="reTypePass" label="Retype Password" langLabel={<Trans i18nKey="reTypePasswordChangePasswordLangLabel" />} langPlaceHolder={t('reTypePasswordChangePasswordLangLabel')} value={inputChangePassData?.reTypePass} func={updateChangePasswordData} icon1={<IoIosLock />} icon2={<IoIosLock />} />

                                <div className='row g-0'>
                                    <button type='submit' className="btn btn-green mt-2"><Trans i18nKey="savebutton">Save</Trans></button>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </div>
    );
};

export default ChangePassword;