import React from 'react';
// import { useSelector } from 'react-redux';
// import BottomNavigation from '../Components/BottomNavigation/BottomNavigation';
// import Header from '../Components/Header/Header';
// import { userDetail } from '../Redux/userSlice';
import { motion as M } from 'framer-motion';


const LayoutWithHeaderBNavigate = ({ children }) => {
    // const user = useSelector(userDetail);
    // let stringValue = localStorage.getItem("bet7uUserKey");
    // let value = JSON.parse(stringValue);

    return (
        <div className='home'>
            <M.div
                initial={{ opacity: 0, x: "-50%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "50%" }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            >
                <div style={{ minHeight: "100dvh" }}>
                    {/* <div className='sticky-top'>
                        <Header />
                    </div> */}
                    {children}
                </div>
            </M.div>


            {/* {
                ((value?.is_login) || (user?.is_login)) &&
                <div className='sticky-bottom'>
                    <BottomNavigation />
                </div>
            } */}
        </div>
    );
};

export default LayoutWithHeaderBNavigate;