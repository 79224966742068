import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // bankingToggleState: 1,
    bankingAgentToggleState: 0,
    // transactionHistoryState: null,
}

const agentAdminSlice = createSlice({
    name: 'agent',
    initialState: initialState,
    reducers: {
        // setSelectBankingState: (state, action) => {
        //     const { payload } = action;
        //     state.bankingToggleState = payload;
        // },

        setSelectAgentBankingState: (state, action) => {
            const { payload } = action;
            state.bankingAgentToggleState = payload;
        },

        // setTransactionHistoryState: (state, action) => {
        //     const { payload } = action;
        //     state.transactionHistoryState = payload;
        // }
    }
})

export const { setSelectAgentBankingState, } = agentAdminSlice.actions;

// export const selectBankingState = state => state.banking.bankingToggleState;
export const selectAgentBankingState = state => state.agent.bankingAgentToggleState;
// export const selectTransactionHistoryState = state => state.banking.transactionHistoryState;

export default agentAdminSlice.reducer;