import en from '../Assets/Images/Language/us.svg';
import cn from '../Assets/Images/Language/cn.svg';
import th from '../Assets/Images/Language/th.svg';
import hi from '../Assets/Images/Language/hi.svg';
import bn from '../Assets/Images/Language/bn.svg';
import ar from '../Assets/Images/Language/ar.svg';
import ko from '../Assets/Images/Language/ko.svg';
import ja from '../Assets/Images/Language/ja.svg';
import my from '../Assets/Images/Language/my.svg';
import vi from '../Assets/Images/Language/vi.svg';
import km from '../Assets/Images/Language/km.svg';
import ne from '../Assets/Images/Language/ne.svg';
import si from '../Assets/Images/Language/si.svg';


export const languagesData = [
    {
        id: 0,
        language: 'English',
        code: "en",
        flag: en,
    },
    {
        id: 1,
        language: 'Chinese',
        code: "cn",
        flag: cn,
    },
    {
        id: 2,
        language: 'Thai',
        code: "th",
        flag: th,
    },
    {
        id: 3,
        language: 'Hindi',
        code: "hi",
        flag: hi,
    },
    {
        id: 4,
        language: 'Bangla',
        code: "bn",
        flag: bn,
    },
    {
        id: 5,
        language: 'Arabic',
        code: "ar",
        flag: ar,
    },
    {
        id: 6,
        language: 'Korean',
        code: "ko",
        flag: ko,
    },
    {
        id: 7,
        language: 'Japanese',
        code: "ja",
        flag: ja,
    },
    {
        id: 8,
        language: 'Burmese',
        code: "my",
        flag: my,
    },
    {
        id: 9,
        language: 'Vietnamese',
        code: "vi",
        flag: vi,
    },
    {
        id: 10,
        language: 'Khmer',
        code: "km",
        flag: km,
    },
    {
        id: 11,
        language: 'Nepali',
        code: "ne",
        flag: ne,
    },
    {
        id: 12,
        language: 'Sinhalese',
        code: "si",
        flag: si,
    },
]