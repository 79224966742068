import React from 'react';
import { useSelector } from 'react-redux';
import { selectedGame } from '../../Redux/launcherSlice';
// import { selectedGame } from '../../Redux/launcherSilice';

const Launcher = () => {
    const launchedGame = useSelector(selectedGame);

    return (
        <>
            {
                (launchedGame === null) ?
                    <></>
                    :
                    <>
                        <iframe
                            title="launcher"
                            className="p-0"
                            src={`${launchedGame}`}
                            width="100%"
                            height="750"
                            minHeight="calc(100vh)"
                            frameBorder="0"
                            scrolling="no"
                            marginHeight="0"
                            marginWidth="0"
                            allowFullScreen
                            webkitallowfullscreen={"true"}
                            mozallowfullscreen={"true"}
                        // style={{ minHeight: '', minWidth: '', height: "", marginTop: "", overflow: "hidden", display: 'block', zIndex: 3 }}
                        />
                    </>
            }
        </>
    );
};

export default Launcher;