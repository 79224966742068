import React, { useState } from 'react';
import { homeLeftData } from '../../Data/HomePageData';
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import GameTitle from '../../Components/GameTitle/GameTitle';
import GameCard from '../../Components/GameCard/GameCard';
// import { sportsCardData } from '../../Data/SportsPageData';
import { Trans } from 'react-i18next';
import { useProvidersLeftSideBar } from '../../Hooks/useProvidersLeftSideBar';

const Sports = () => {
    const { leftSideBarData } = useProvidersLeftSideBar();

    const gameCode = "SB";

    const sportCardData = leftSideBarData?.filter((item) => (item?.game_code)?.includes(gameCode));

    const [selectedLeftBarData, setSelectedLeftBarData] = useState(homeLeftData[0]);

    const showableExtraCards = (selectedLeftBarData?.extra_param === "iframe") ? [selectedLeftBarData] : sportCardData;

    return (
        <section className='row g-0 p-2'>
            <LeftSideBar selectedLeftBarData={selectedLeftBarData} setSelectedLeftBarData={setSelectedLeftBarData} leftSideBarData={homeLeftData} />

            <div className="game-card-area gx-0 col-10">
                <GameTitle title={<Trans i18nKey="sportsSubTitle">Sports</Trans>} />
                <GameCard games={showableExtraCards} providerType={gameCode} />
            </div>
        </section>
    );
};

export default Sports;