import React from 'react';
import { useNavigate } from 'react-router-dom';
import successTick from '../../Assets/Images/Registration/Group 414.svg';
import Header from '../Header/Header';
import { Trans } from 'react-i18next';

const BetSuccessComponent = ({ title = `Bet Placed ` }) => {
    const navigate = useNavigate();
    return (
        <>
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="betSuccessPage">Bet Success</Trans>} />
            </div>
            <div className='inner-scroll'>
                <div className='position-relative'>
                    <h3 className="text-center p-3">{title + `Successfull`}</h3>
                </div>

                <div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img className='successTick' src={successTick} alt="successTick" />
                    </div>
                    <p className='p-0 py-2 m-0 text-center'></p>

                    <div className='mx-4 mb-4'>
                        <div className='d-flex justify-content-center'>
                            <button onClick={() => navigate(-1)} type='button' className="btn btn-yellow my-2 w-100">Make Another Bet</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default BetSuccessComponent;