import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const backend = new Backend({
    // path where resources get loaded from
    loadPath: '/locales/{{lng}}/{{ns}}.json',
});

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(backend)
    .init({
        // we init with resources
        // resources: {

        // en: {
        //     translations: {
        //         // navigation and authorization
        //         login: "Login",
        //         signup: "Sign up",
        //         registration: "Registration",
        //         // languageTitle: "Language",
        //         rememberme: "Remember me",
        //         forgot: "Forgot Password?",
        //         support: "Support",
        //         newaccount: "Create a new account",
        //         acceptTC: "I Accept Terms and Conditions",
        //         verifyemailbutton: "Verify Email",
        //         alreadyhaveanaccountbutton: "Already have an account?",

        //         // navigation
        //         accounts: "Accounts",
        //         profile: "Profile",
        //         logout: "Logout",

        //         // footer
        //         footerstatic: "Our website works best in the latest and most recent versions of these browsers",

        //         // accounts edit profile
        //         changepassword: "Change Password",
        //         savebutton: "Save",
        //         // cancelbutton: "Cancel",
        //         // continuebutton: "Continue",
        //         // socialmedia: "Social Media",
        //         uploadimage: "Upload Image",
        //         addsocialmedia: "Add Social Media",
        //         // notifications: "Notifications",

        //         // banking profile
        //         editprofile: "Edit Profile",
        //         referralcode: "Your Referral Code",
        //         viewall: "View all",
        //         username: "Username",
        //         accountstatus: "Account Status",
        //         usercategory: "User Category",
        //         prev: "PREV.",
        //         next: "NEXT",

        //         deposit: "Deposit",
        //         withdrawal: "Withdrawal",
        //         mybets: "My Bets",

        //         // nav titles
        //         languagePage: "Language",
        //         liveTVPage: "Live TV",
        //         agentAdminPage: "Agent",
        //         convertPage: "Convert",
        //         depositPage: "Deposit",
        //         qrDepositPage: "QR Deposit",
        //         paymentPage: "Payment",
        //         referralPage: "Referral",
        //         transferPage: "P2P Transfer",
        //         withdrawalPage: "Withdrawal",
        //         contactPage: "Contact",
        //         menuPage: "Menu",
        //         notificationsPage: "Notifications",
        //         sportsbookPage: "Sportsbook",
        //         sportsExchangePage: "Sports Exchange",
        //         sportsPage: "Sports",
        //         tcPage: "Terms & Conditions",
        //         editProfilePage: "Edit Profile",
        //         agentListPage: "Agent List",
        //         accountsPage: "Accounts",
        //         changepasswordPage: "Change Password",
        //         betSuccessPage: "Bet Success",

        //         // subtitles
        //         bankingSubtitle: "Banking",
        //         transactionHistorySubtitle: "Transaction History",
        //         paymentStatusSubtitle: "Payment Status",
        //         allfeaturesSubtitle: "All Features",
        //         othersMenuSubtitle: "Others Menu",
        //         socialMediaSubtitle: "Social Media",
        //         depositRequestSubtitle: "Deposit Request",

        //         // game titles
        //         sportsSubTitle: "Sports",
        //         lotterySubTitle: "Lottery",
        //         liveCasinoSubTitle: "Live Casino",
        //         casinoSubTitle: "Casino",
        //         slotsSubTitle: "Slots",
        //         virtualSportsSubTitle: "Virtual Sports",

        //         // navbar data
        //         homeNav: "HOME",
        //         sportsNav: "SPORTS",
        //         lotteryNav: "LOTTERY",
        //         liveCasinoNav: "LIVE CASINO",
        //         casinoNav: "CASINO",
        //         slotsNav: "SLOTS",
        //         virtualSportsNav: "VIRTUAL SPORTS",

        //         // bottom navigation data 
        //         homeBottomNav: "Home",
        //         depositBottomNav: "Deposit",
        //         withdrawalBottomNav: "Withdrawal",
        //         transferBottomNav: "Transfer",
        //         menuBottomNav: "Menu",

        //         // menu data starts
        //         // featured menu data
        //         sportsbookFeaturedMenu: "Sportsbook",
        //         sportsExchangeFeaturedMenu: "Sports Exchange",
        //         lotteryFeaturedMenu: "Lottery",
        //         liveCasinoFeaturedMenu: "Live Casino",
        //         casinoFeaturedMenu: "Casino",
        //         slotsFeaturedMenu: "Slots",
        //         virtualsportsFeaturedMenu: "Virtual Sports",
        //         // others menu data
        //         profileOthersMenu: "Profile",
        //         depositOthersMenu: "Deposit",
        //         withdrawalOthersMenu: "Withdrawal",
        //         transferOthersMenu: "P2P Transfer",
        //         paymentOthersMenu: "Payment",
        //         convertOthersMenu: "Convert",
        //         agentAdminOthersMenu: "Agent Admin",
        //         transactionHistoryOthersMenu: "Transaction History",

        //         // banking card items data list
        //         creditBalance: "Credit Balance",
        //         reserveBalance: "Reserve Balance",
        //         coinBalance: "Coin Balance",
        //         totalBalance: "Total Balance",
        //         depositButton: "Deposit",
        //         withdrawalButton: "Withdrawal",
        //         agentAdminButton: "Agent Admin",
        //         // sub
        //         transferButton: "P2P Transfer",
        //         convertButton: "Convert",
        //         paymentButton: "Payment",

        //         // agent admin
        //         depositRequestCardTitle: "Deposit Request",
        //         withdrwalRequestCardTitle: "Withdrwal Request",
        //         addGatewayCardTitle: "Add Gateway",
        //         getwayStatusCardTitle: "Gateway Status",

        //         // buttons
        //         pendingButton: "Pending",
        //         successButton: "Success",
        //         calcelButton: "Cancel",
        //         inreviewButton: "In Review",
        //         busyButton: "I'm busy",

        //         // lottery starts
        //         // sliders
        //         homeLotterySlider: "HOME",
        //         betHistoryLotterySlider: "BET HISTORY",
        //         resultsLotterySlider: "RESULTS",
        //         prizeOddsLotterySlider: "PRIZE & ODDS",
        //         howToPlayLotterySlider: "HOW TO PLAY",
        //         aboutLotterySlider: "ABOUT",

        //         // lottery titles
        //         liveStreamingTitle: "LIVE STREAMING",
        //         betHistoryTitle: "BET HISTORY",
        //         paytableOddsTitle: "PAYTABLE AND ODDS",
        //         howToPlayTitle: "HOW TO PLAY",

        //         // button
        //         searchButton: "Search",
        //         betNowButton: "Bet Now",


        //         // input fields starts

        //         // login inputs
        //         credentialLoginLangLabel: "Username/Email Address",
        //         passwordLoginLangLabel: "Password",

        //         // registration fields inputs
        //         first_nameRegistrationLangLabel: "First Name",
        //         last_nameRegistrationLangLabel: "Last Name",
        //         usernameRegistrationLangLabel: "Username",
        //         genderRegistrationLangLabel: "Gender",
        //         contactRegistrationLangLabel: "Mobile Number",
        //         country_nameRegistrationLangLabel: "Select Country",
        //         dobRegistrationLangLabel: "Date of Birth",
        //         referralRegistrationLangLabel: "Referral Code (if applicable)",
        //         passwordRegistrationLangLabel: "Password",

        //         // input fields ends
        //     }
        // },
        // cn: {
        //     translations: {
        //         languageName: "Chinese",
        //         login: "登录",
        //         signup: "报名",
        //         registration: "登记",
        //         // languageTitle: "语言",
        //         rememberme: "记住账号",
        //         forgot: "忘记密码?",
        //         support: "支持",
        //         newaccount: "创建一个新账户",
        //         acceptTC: "我接受条款和条件",
        //         verifyemailbutton: "验证邮件",
        //         alreadyhaveanaccountbutton: "已有帐号",

        //         accounts: "帐户",
        //         profile: "轮廓",
        //         logout: "登出",
        //         footerstatic: "我们的网站在这些浏览器的最新版本中效果最佳",

        //         // accounts edit profile
        //         changepassword: "更改密码",
        //         savebutton: "节省",
        //         // cancelbutton: "取消",
        //         // continuebutton: "继续",
        //         uploadimage: "上传图片",
        //         // socialmedia: "社交媒体",
        //         addsocialmedia: "添加社交媒体",
        //         // notifications: "通知",

        //         editprofile: "编辑个人资料",
        //         referralcode: "您的推荐码",
        //         viewall: "查看全部",
        //         username: "用户名",
        //         accountstatus: "帐户状态",
        //         usercategory: "用户类别",
        //         prev: "以前的",
        //         next: "下一个",

        //         deposit: "订金",
        //         withdrawal: "退出",
        //         mybets: "我的赌注",

        //         // nav titles
        //         languagePage: "语言",
        //         liveTVPage: "电视直播",
        //         agentAdminPage: "代理人",
        //         convertPage: "转变",
        //         depositPage: "订金",
        //         qrDepositPage: "二维码存款",
        //         paymentPage: "支付",
        //         referralPage: "转介",
        //         transferPage: "P2P 转移",
        //         withdrawalPage: "退出",
        //         contactPage: "接触",
        //         menuPage: "菜单",
        //         notificationsPage: "通知",
        //         sportsbookPage: "体育博彩",
        //         sportsExchangePage: "体育交流",
        //         sportsPage: "运动的",
        //         tcPage: "条款和条件",
        //         editProfilePage: "编辑个人资料",
        //         agentListPage: "代理名单",
        //         accountsPage: "帐户",
        //         changepasswordPage: "更改密码",
        //         betSuccessPage: "投注成功",

        //         // subtitles
        //         bankingSubtitle: "银行业",
        //         transactionHistorySubtitle: "交易记录",
        //         paymentStatusSubtitle: "支付状态",
        //         allfeaturesSubtitle: "所有功能",
        //         othersMenuSubtitle: "其他菜单",
        //         socialMediaSubtitle: "社交媒体",
        //         depositRequestSubtitle: "存款请求",

        //         // game titles
        //         sportsSubTitle: "运动的",
        //         lotterySubTitle: "彩票",
        //         liveCasinoSubTitle: "在线直播赌场",
        //         casinoSubTitle: "语言",
        //         slotsSubTitle: "老虎机",
        //         virtualSportsSubTitle: "虚拟运动",

        //         // navbar data
        //         homeNav: "家",
        //         sportsNav: "运动的",
        //         lotteryNav: "彩票",
        //         liveCasinoNav: "在线直播赌场",
        //         casinoNav: "语言",
        //         slotsNav: "老虎机",
        //         virtualSportsNav: "虚拟运动",

        //         // bottom navigation data 
        //         homeBottomNav: "家",
        //         depositBottomNav: "订金",
        //         withdrawalBottomNav: "退出",
        //         transferBottomNav: "转移",
        //         menuBottomNav: "菜单",

        //         // menu data starts
        //         // featured menu data
        //         sportsbookFeaturedMenu: "体育博彩",
        //         sportsExchangeFeaturedMenu: "体育交流",
        //         lotteryFeaturedMenu: "彩票",
        //         liveCasinoFeaturedMenu: "在线直播赌场",
        //         casinoFeaturedMenu: "语言",
        //         slotsFeaturedMenu: "老虎机",
        //         virtualsportsFeaturedMenu: "虚拟运动",
        //         // others menu data
        //         profileOthersMenu: "轮廓",
        //         depositOthersMenu: "订金",
        //         withdrawalOthersMenu: "退出",
        //         transferOthersMenu: "P2P 转移",
        //         paymentOthersMenu: "支付",
        //         convertOthersMenu: "转变",
        //         agentAdminOthersMenu: "代理管理员",
        //         transactionHistoryOthersMenu: "交易记录",

        //         // banking card items data list
        //         creditBalance: "Credit 平衡",
        //         reserveBalance: "Reserve 平衡",
        //         coinBalance: "Coin 平衡",
        //         totalBalance: "总余额",
        //         depositButton: "订金",
        //         withdrawalButton: "退出",
        //         agentAdminButton: "代理管理员",
        //         // sub
        //         transferButton: "P2P 转移",
        //         convertButton: "转变",
        //         paymentButton: "支付",

        //         // agent admin
        //         depositRequestCardTitle: "存款请求",
        //         withdrwalRequestCardTitle: "提款请求",
        //         addGatewayCardTitle: "添加网关",
        //         getwayStatusCardTitle: "网关状态",

        //         // buttons
        //         pendingButton: "待办的",
        //         successButton: "成功",
        //         calcelButton: "取消",
        //         inreviewButton: "审查中",
        //         busyButton: "我很忙",

        //         // lottery starts
        //         // sliders
        //         homeLotterySlider: "家",
        //         betHistoryLotterySlider: "投注历史",
        //         resultsLotterySlider: "结果",
        //         prizeOddsLotterySlider: "奖金和赔率",
        //         howToPlayLotterySlider: "怎么玩",
        //         aboutLotterySlider: "关于",

        //         // lottery titles
        //         liveStreamingTitle: "直播",
        //         betHistoryTitle: "投注历史",
        //         paytableOddsTitle: "奖金和赔率",
        //         howToPlayTitle: "怎么玩",

        //         // button
        //         searchButton: "搜索",
        //         betNowButton: "现在下注",

        //         // input fields starts

        //         // login inputs
        //         credentialLoginLangLabel: "用户名/电子邮件地址",
        //         passwordLoginLangLabel: "更改密码",

        //         // registration fields inputs
        //         first_nameRegistrationLangLabel: "名",
        //         last_nameRegistrationLangLabel: "姓",
        //         usernameRegistrationLangLabel: "用户名",
        //         genderRegistrationLangLabel: "性别",
        //         contactRegistrationLangLabel: "手机号码",
        //         country_nameRegistrationLangLabel: "选择国家",
        //         dobRegistrationLangLabel: "出生日期",
        //         referralRegistrationLangLabel: "推荐代码（如适用）",
        //         passwordRegistrationLangLabel: "密码",

        //         // input fields ends
        //     }
        // },
        // },
        fallbackLng: "en",
        debug: false,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
