import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { origin, originNameOnly, rootUrl } from '../../App';
import './UserProfile.css';
import CustomInputField from '../../Components/CustomInputField/CustomInputField';
import CustomSectionTitle from '../../Components/CustomSectionTitle/CustomSectionTitle';
// import ProfileImgModal from '../../Components/ProfileImgModal/ProfileImgModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUserData, fetchLogoutUser, userDetail } from '../../Redux/userSlice';
import { showCustomAlert } from '../../Redux/alertSlice';
// import { AiFillCamera, } from 'react-icons/ai';
import { FaLock, } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { ImArrowRight2 } from 'react-icons/im';
import { AiFillSave, AiFillDelete } from 'react-icons/ai';
import { GiCancel } from 'react-icons/gi';
import { TiPlus } from 'react-icons/ti';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import Header from '../../Components/Header/Header';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const UserProfile = () => {
    const userInfo = useSelector(userDetail);
    const dispatch = useDispatch();

    const [selectedAddableMedia, setSelectedAddableMedia] = useState(null);
    const [agentsSocialMediaField, setAgentsSocialMediaField] = useState(null);
    const [allSocialMediaList, setAllSocialMediaList] = useState(null);
    const [selectedEditableMedia, setSelectedEditableMedia] = useState(null);
    // const [profileImageModal, setProfileImageModal] = useState(false);
    const initialState = {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        country_name: '',
        contact: '',
        dob: null,
        gender: "Male",
        password: '',
        referral: "",
        request_origin: originNameOnly
    };
    const [updateUserFormData, setUpdateUserFormData] = useState(initialState);

    // update user profile functionalities

    const updateUserProfileData = e => {
        const { name, value } = e.target
        setUpdateUserFormData(prev => ({ ...prev, [name]: value }));
    }

    const updateUserProfile = (e) => {
        e.preventDefault();

        if (updateUserFormData.gender === null) {
            updateUserFormData.gender = "Male";
        }
        // console.log(updateUserFormData);
        dispatch(setInnerLoaderTrue());
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            if (
                (updateUserFormData?.first_name !== "") &&
                (updateUserFormData?.last_name !== "") &&
                (updateUserFormData?.gender !== null) &&
                (updateUserFormData?.dob !== null)
            ) {
                fetch(`${rootUrl}/auth/users-detail/${origin}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Token ${value.value}`,
                    },
                    body: JSON.stringify(updateUserFormData),
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        if (data) {
                            dispatch(fetchLoginUserData());
                            setUpdateUserFormData(initialState);
                            dispatch(showCustomAlert({
                                type: "success",
                                message: `Profile Updated Successfully`,
                            }));
                        }
                    })
                    .catch(err => {
                        err.json().then(text => {
                            if (text?.error) {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${text?.error}`,
                                }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${text?.detail}`,
                                }));
                                dispatch(fetchLogoutUser());
                                return;
                            }
                        });
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${err?.statusText}`,
                        }));
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse())
                    });
            }
        }
    }

    useEffect(() => {
        setUpdateUserFormData({
            'first_name': userInfo?.user?.first_name,
            'last_name': userInfo?.user?.last_name,
            'gender': userInfo?.user?.gender,
            'dob': userInfo?.user?.dob,
            'address': userInfo?.user?.address,
        });
    }, [userInfo?.user]);

    // agent social functionalities

    const addAgentSocialUrl = (item) => {
        let sendData = {};
        // console.log(item);

        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            dispatch(setInnerLoaderTrue());
            let value = JSON.parse(stringValue);
            sendData = {
                agent_prefix: item.id,
                resource: item.resource,
            };
            fetch(`${rootUrl}/statics/agent-social-prefix/${origin}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
                body: JSON.stringify(sendData),
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok") {
                        getAgentSocialList();
                        setSelectedAddableMedia(null);
                        dispatch(fetchLoginUserData());
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.error) {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.error}`,
                            }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`,
                    }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                });
        }

    }

    const editAgentSocialUrl = (item) => {
        let sendData = {};

        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            dispatch(setInnerLoaderTrue());
            let value = JSON.parse(stringValue);
            sendData = {
                id: item.id,
                resource: item.resource,
            };

            fetch(`${rootUrl}/statics/agent-social-prefix/${origin}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
                body: JSON.stringify(sendData),
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    if (data?.status === "ok") {
                        getAgentSocialList();
                        setSelectedEditableMedia(null);
                        dispatch(fetchLoginUserData());
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.error) {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.error}`,
                            }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`,
                    }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                });
        }

    }

    const deleteAgentSocial = (item) => {
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/statics/agent-social-prefix/${origin}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
                body: JSON.stringify(item)
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.status === "ok") {
                        getAgentSocialList();
                        setSelectedEditableMedia(null);
                        dispatch(fetchLoginUserData());
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }

    }

    const getAgentSocialList = useCallback(() => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null && userInfo?.user?.agent) {
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);
                fetch(`${rootUrl}/statics/agent-social-connection-list/${origin}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Token ${value.value}`,
                    },
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        // console.log(data);
                        if (data.length > 0) {
                            setAgentsSocialMediaField(data);
                        } else {
                            setAgentsSocialMediaField(null);
                        }
                    })
                    .catch(err => {
                        err.json().then(text => {
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                return;
                            }
                        });
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse())
                    })
            }
        }
    }, [dispatch, userInfo?.user?.agent])

    const getAllSocialList = useCallback(() => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null && userInfo?.user?.agent) {
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);
                fetch(`${rootUrl}/statics/agent-social-prefix/${origin}`, {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Token ${value.value}`,
                    },
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(jsonData => {
                        // console.log(jsonData);
                        if (jsonData && agentsSocialMediaField) {
                            for (let i = 0; i < agentsSocialMediaField.length; i++) {
                                jsonData = jsonData?.filter(item => item.name !== agentsSocialMediaField[i].name);
                            }
                            // console.log("all social", jsonData);
                            setAllSocialMediaList(jsonData);
                        } else {
                            setAllSocialMediaList(jsonData);
                        }
                    })
                    .catch(err => {
                        err.json().then(text => {
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                return;
                            }
                        });
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse())
                    })
            }
        }
    }, [dispatch, agentsSocialMediaField, userInfo?.user?.agent])

    useEffect(() => {
        getAgentSocialList();
    }, [getAgentSocialList])

    useEffect(() => {
        getAllSocialList();
    }, [getAllSocialList])

    // user profile image field

    // const setProfileImageModalClose = () => {
    //     setProfileImageModal(false);
    // }

    return (
        <div className='inner-scroll userProfile'>

            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="editProfilePage">Edit Profile</Trans>} />
            </div>

            {/* <div className='mx-4 my-4'>
                <CustomSectionTitle title={"Profile Edit"} page={"userEdit"} />
            </div> */}

            {/* <section className="user-header">
                <div className="row gx-0 d-flex justify-content-center align-items-center py-3">
                    <div className="col-12 d-flex justify-content-center align-items-center mb-3">
                        <div className='userImgBorder'>
                            <AiFillCamera style={{ color: "#555555", fontSize: '60px' }} />
                            <img src={rootUrl + userInfo?.user?.photo} className="" width={"120px"} height={"120px"} alt="user" style={{ backgroundColor: "var(--lightGray)", padding: "0px 0px 0px 0px", borderRadius: "40%", }} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section className='m-4'>
                <form className="row g-0" onSubmit={updateUserProfile}>
                    <CustomInputField type="text" name="first_name" label="First Name" langLabel={<Trans i18nKey="first_nameProfileLangLabel" />} langPlaceHolder={t('first_nameProfileLangLabel')} value={(userInfo?.user?.first_name !== "") ? userInfo?.user?.first_name : updateUserFormData?.first_name} func={updateUserProfileData} icon1={false} icon2={(userInfo?.user?.first_name !== "") ? <FaLock /> : <BiEdit />} disabled={(userInfo?.user?.first_name !== "") ? true : false} />
                    <CustomInputField type="text" name="last_name" label="Last Name" langLabel={<Trans i18nKey="last_nameProfileLangLabel" />} langPlaceHolder={t('last_nameProfileLangLabel')} value={(userInfo?.user?.last_name !== "") ? userInfo?.user?.last_name : updateUserFormData?.last_name} func={updateUserProfileData} icon1={false} icon2={(userInfo?.user?.last_name !== "") ? <FaLock /> : <BiEdit />} disabled={(userInfo?.user?.last_name !== "") ? true : false} />
                    {
                        (userInfo?.user?.gender !== null) ?
                            <CustomInputField type="text" name="gender" label="Gender" langLabel={<Trans i18nKey="genderProfileLangLabel" />} langPlaceHolder={t('genderProfileLangLabel')} value={userInfo?.user?.gender} func={updateUserProfileData} icon1={false} icon2={<FaLock />} disabled={true} />
                            :
                            <div className="custom-input">
                                <div className="module my-2">
                                    <label className="" htmlFor="gender">{<Trans i18nKey="genderProfileLangLabel" />}</label>
                                    <div className="input-group">
                                        <select onChange={updateUserProfileData} name="gender" id="gender" value={updateUserFormData?.gender} className="form-select">
                                            <option value={"Male"}>Male</option>
                                            <option value={"Female"}>Female</option>
                                            <option value={"Other"}>Other</option>
                                        </select>
                                        <span className="input-group-text">
                                            <BiEdit />
                                        </span>
                                    </div>
                                </div>
                            </div>
                    }

                    {
                        (userInfo?.user?.dob !== null) ?
                            <CustomInputField type="text" name="dob" label="Date of Birth" langLabel={<Trans i18nKey="dobProfileLangLabel" />} langPlaceHolder={t('dobProfileLangLabel')} value={userInfo?.user?.dob} func={updateUserProfileData} icon1={false} icon2={<FaLock />} disabled={true} />
                            :
                            <div className="custom-input">
                                <div className="module my-2">
                                    <label className="">{<Trans i18nKey="dobProfileLangLabel" />}</label>
                                    <div className="input-group">
                                        <input
                                            type="date"
                                            name="dob"
                                            // value={regFormData.dob}
                                            className="form-control"
                                            title="Date of Birth"
                                            // max={new Date().getDate}
                                            onChange={updateUserProfileData}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                    }

                    <CustomInputField type="text" name="date_joined" label="Joining Date" langLabel={<Trans i18nKey="joiningDateProfileLangLabel" />} langPlaceHolder={t('joiningDateProfileLangLabel')} value={userInfo?.user?.date_joined?.slice(0, 10)} icon1={false} icon2={<FaLock />} disabled={true} />

                    <CustomInputField type="text" name="address" label="Address" langLabel={<Trans i18nKey="addressProfileLangLabel" />} langPlaceHolder={t('addressProfileLangLabel')} value={updateUserFormData.address} func={updateUserProfileData} icon1={false} icon2={<BiEdit />} disabled={false} />
                    <CustomInputField type="text" name="country_name" label="Country Name" langLabel={<Trans i18nKey="country_nameProfileLangLabel" />} langPlaceHolder={t('country_nameProfileLangLabel')} value={userInfo?.user?.country_name} icon1={false} icon2={<FaLock />} disabled={true} />

                    <CustomInputField type="number" name="contact" label="Mobile Number" langLabel={<Trans i18nKey="contactProfileLangLabel" />} langPlaceHolder={t('contactProfileLangLabel')} onWheel={event => event.target.blur()} value={userInfo?.user?.contact} icon1={false} icon2={<FaLock />} disabled={true} />
                    <CustomInputField type="text" name="currency" label="Currency" langLabel={<Trans i18nKey="currencyProfileLangLabel" />} langPlaceHolder={t('currencyProfileLangLabel')} value={userInfo?.user?.currency} icon1={false} icon2={<FaLock />} disabled={true} />

                    <div className="custom-input">
                        <Link to={`/change-password${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`} style={{ textDecoration: "none", color: "#000" }}>
                            <div className='ps-3 rounded d-flex align-items-center justify-content-between btn btn-yellow my-3' style={{ border: "none", }}>
                                <span><Trans i18nKey="changepassword">Change Password</Trans></span>
                                <ImArrowRight2 />
                            </div>
                        </Link>
                    </div>
                    <button type='submit' className="btn btn-green"><Trans i18nKey="savebutton">Save</Trans></button>
                </form>
            </section>
            {
                (userInfo?.user?.agent) &&
                <section className="social-info mx-4 mb-4">
                    <CustomSectionTitle title={<Trans i18nKey="socialMediaSubtitle">Social Media</Trans>} page={"userEdit"} />

                    <div className='mediaArea my-2'>

                        {/* for add new social link  */}
                        {
                            selectedAddableMedia &&
                            <div className="rounded d-flex flex-column justify-content-center align-items-start p-2 ps-0">
                                <div className='row g-1 justify-content-start align-items-center'>
                                    <div className="col">
                                        <div className='d-flex align-items-center justify-content-evenly' style={{ height: "50px", width: "250px", backgroundColor: "#ECECEC", borderRadius: "20px", }}>
                                            <img src={selectedAddableMedia?.logo} style={{ height: "40px", width: "40px", objectFit: "cover", }} alt={selectedAddableMedia?.name} />
                                            <input style={{ borderRadius: "0 20px 20px 0", border: "none", }} type={'text'} placeholder="url/Link" name="resource" onBlur={(e) => {
                                                let newData = { ...selectedAddableMedia };
                                                newData[e.target.name] = e.target.value;
                                                setSelectedAddableMedia(newData);
                                            }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row gx-3 justify-content-start align-items-center my-3'>
                                    <div className="col">
                                        <button onClick={() => addAgentSocialUrl(selectedAddableMedia)} className="d-flex justify-content-center align-items-center agent-social-button">
                                            <AiFillSave size={22} />
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button onClick={() => setSelectedAddableMedia(null)} className="d-flex justify-content-center align-items-center agent-social-button">
                                            <GiCancel size={20} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* for edit existing social link  */}
                        {
                            selectedEditableMedia &&
                            <div className="rounded d-flex flex-column justify-content-center align-items-start p-2 ps-0">
                                <div className='row g-1 justify-content-start align-items-center'>
                                    <div className="col">
                                        <div onDoubleClick={() => setSelectedEditableMedia(false)} className='d-flex align-items-center justify-content-evenly' style={{ height: "50px", width: "250px", backgroundColor: "#ECECEC", borderRadius: "25px" }}>
                                            <img src={selectedEditableMedia?.logo} style={{ height: "40px", width: "40px", objectFit: "cover", }} alt={selectedEditableMedia.name} />
                                            {
                                                selectedEditableMedia?.status === "pending" ?
                                                    <input style={{ borderRadius: "0 20px 20px 0", border: "none", color: 'red' }} type={'text'} placeholder={'Your status is on pending'} name="resource" onBlur={(e) => { }} disabled />
                                                    :
                                                    <input style={{ borderRadius: "0 20px 20px 0", border: "none", }} type={'text'} placeholder={selectedEditableMedia?.resource || "url/Link"} name="resource" onBlur={(e) => {
                                                        let newData = { ...selectedEditableMedia };
                                                        newData[e.target.name] = e.target.value;
                                                        setSelectedEditableMedia(newData);
                                                    }} />
                                            }
                                        </div>
                                    </div>
                                </div>

                                {
                                    selectedEditableMedia?.status !== "pending" ?
                                        <div className='row gx-3 justify-content-start align-items-center my-3'>
                                            <div className="col">
                                                <button onClick={() => setSelectedEditableMedia(null)} className="d-flex justify-content-center align-items-center agent-social-button">
                                                    <GiCancel size={20} />
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className='row gx-3 justify-content-start align-items-center my-3'>
                                            <div className="col">
                                                <button onClick={() => editAgentSocialUrl(selectedEditableMedia)} className="d-flex justify-content-center align-items-center agent-social-button">
                                                    <AiFillSave size={22} />
                                                </button>
                                            </div>
                                            <div className="col">
                                                <button onClick={() => setSelectedEditableMedia(null)} className="d-flex justify-content-center align-items-center agent-social-button">
                                                    <GiCancel size={20} />
                                                </button>
                                            </div>
                                            <div className="col">
                                                <button onClick={() => deleteAgentSocial(selectedEditableMedia)} className="d-flex justify-content-center align-items-center agent-social-button">
                                                    <AiFillDelete size={20} />
                                                </button>
                                            </div>
                                        </div>
                                }

                            </div>
                        }

                        {/* for edit exiting meadia link ---> complete */}
                        {
                            (!selectedEditableMedia && !selectedAddableMedia) &&
                            <div className="rounded d-flex flex-column justify-content-center align-items-start p-2 ps-0">
                                <div className='row g-1 justify-content-start align-items-center'>
                                    {
                                        agentsSocialMediaField?.map(item =>
                                            <div key={item.id} className="col" onClick={() => setSelectedEditableMedia(item)}>
                                                <img src={item.logo} style={{ height: "40px", width: "40px", objectFit: "cover", cursor: "pointer", }} alt={item.name} />
                                            </div>)
                                    }
                                </div>
                            </div>
                        }

                        {/* only for view --------> complete*/}

                        {
                            (allSocialMediaList && !selectedEditableMedia && !selectedAddableMedia) &&
                            <div className="row gx-0 d-flex justify-content-start align-items-center mb-2">
                                <div className="col-12">
                                    <div className='px-3 rounded d-flex align-items-center justify-content-between w-100 fw-bold'
                                        style={{ border: "0px solid gray", boxShadow: "inset 0px 0px 9px -2px  rgba(28, 0, 0, 0.3)", padding: "5px", }}>
                                        <span className="text-capitalize"><Trans i18nKey="addsocialmedia">Add social Media</Trans> </span> <TiPlus />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* for add new media for this agent ----> complete */}
                        {
                            (!selectedEditableMedia && !selectedAddableMedia) &&
                            <div className="rounded d-flex flex-column justify-content-center align-items-start p-2 ps-0">
                                <div className='row g-1 justify-content-start align-items-center'>
                                    {
                                        allSocialMediaList?.map((item, index) =>
                                            <div key={item.id} className="col" onClick={() => setSelectedAddableMedia(item)}>
                                                <img src={item.logo} style={{ height: "40px", width: "40px", objectFit: "cover", cursor: "pointer", }} alt={item.name} />
                                            </div>)
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </section>
            }
            {/* <ProfileImgModal profileImageModal={profileImageModal} setProfileImageModalClose={setProfileImageModalClose} ></ProfileImgModal> */}

        </div>
    );
};

export default UserProfile;