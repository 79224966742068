import React, { useState } from 'react';
import { originNameOnly, rootUrl } from '../../App';
import CustomInputField from '../../Components/CustomInputField/CustomInputField';
import { IoIosLock } from 'react-icons/io';
import OtpInput from '../../Components/OtpInput/OtpInput';
import { AiFillLeftCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import confirmMail from '../../Assets/Images/Registration/email-icon-verify.svg';
import { HiMail } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { showCustomAlert } from '../../Redux/alertSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { userDetail } from '../../Redux/userSlice';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);

    const [inputForgotPasswordData, setInputForgotPasswordData] = useState({});

    const [showForgotPassOtp, setShowForgotPassOtp] = useState(false);
    const [forgotPasswordOtpData, setForgotPasswordOtpData] = useState("");

    const onChange = (value) => setForgotPasswordOtpData(value);

    const updateForgotPasswordData = e => {
        const { name, value } = e.target;
        setInputForgotPasswordData(prev => ({ ...prev, [name]: name === "email" ? value.toLowerCase() : value }))
    }

    const forgotPassMailFunction = (e) => {
        e.preventDefault();
        dispatch(setInnerLoaderTrue());
        // console.log(inputForgotPasswordData.email,);
        fetch(`${rootUrl}/auth/forgot-password/`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: inputForgotPasswordData?.email,
                request_origin: originNameOnly
            })
        })
            .then(async res => {
                if (!res.ok) throw res;
                else return await res.json();
            })
            .then(data => {
                if (data?.status === "ok") {
                    setShowForgotPassOtp(true);
                    // document.getElementById("forgotPass").reset();
                }
            })
            .catch(err => {
                err.json().then(text => {
                    if (text?.error) {
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${text?.error}`
                        }));
                        return;
                    }
                });
                dispatch(showCustomAlert({
                    type: "error",
                    message: `${err?.statusText}`
                }));
            })
            .finally(
                () => dispatch(setInnerLoaderFalse())
            )
    }

    const forgotPassOtpFunction = (e) => {
        e.preventDefault();

        if (inputForgotPasswordData?.newPassword === inputForgotPasswordData?.reTypePassword) {
            dispatch(setInnerLoaderTrue())
            fetch(`${rootUrl}/auth/forgot-password/`, {
                method: 'PUT',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: inputForgotPasswordData.email,
                    new_password: inputForgotPasswordData.newPassword,
                    otp: +forgotPasswordOtpData,
                    request_origin: originNameOnly,
                })
            })
                .then(async res => {
                    if (!res.ok) throw res;
                    else return await res.json();
                })
                .then(data => {
                    if (data?.status === "ok") {
                        e.target.reset();
                        navigate(`/forgot-password-success${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`);
                    } else {
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${data?.report}`
                        }));
                    }
                    setForgotPasswordOtpData({});
                    setInputForgotPasswordData({});
                    setShowForgotPassOtp(false);

                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.error) {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.error}`
                            }));
                            return;
                        }
                    });
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`
                    }));
                })
                .finally(
                    () => dispatch(setInnerLoaderFalse())
                )
        } else {
            dispatch(showCustomAlert({
                type: "error",
                message: `Password dosen't match`
            }));
        }
    }

    return (
        <div className="inner-scroll forgot-password">
            {
                showForgotPassOtp ?
                    <div className="inner-scroll">
                        <div className='position-relative'>
                            <h3 className="text-center p-3"><Trans i18nKey="titleForgotPasswordOTP" /></h3>
                            <div className='position-absolute' style={{ top: "12px", left: '10px' }}>
                                <AiFillLeftCircle size='35' color='var(--yellow)' onClick={() => { navigate(-1, { replace: true }) }} style={{ cursor: "pointer", }} />
                            </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img className='confirmMailImg' src={confirmMail} alt="confirm mail img" />
                        </div>
                        <form onSubmit={forgotPassOtpFunction} autoComplete="off" id="" >
                            <div className='text-center py-2'>
                                <span>{<Trans i18nKey="textForgotPasswordOTP" />}</span>
                            </div>

                            <div className='mx-4 mb-4 row g-0 d-flex justify-content-center'>
                                <OtpInput value={forgotPasswordOtpData} valueLength={6} onChange={onChange} />

                                <CustomInputField type="password" name="newPassword" label="New Password" langLabel={<Trans i18nKey="newPasswordForgotPasswordLangLabel" />} langPlaceHolder={t('newPasswordForgotPasswordLangLabel')} value={inputForgotPasswordData?.newPassword} func={updateForgotPasswordData} icon1={<IoIosLock />} icon2={<IoIosLock />} />
                                <CustomInputField type="password" name="reTypePassword" label="Retype Password" langLabel={<Trans i18nKey="reTypePasswordPasswordForgotPasswordLangLabel" />} langPlaceHolder={t('reTypePasswordPasswordForgotPasswordLangLabel')} value={inputForgotPasswordData?.reTypePassword} func={updateForgotPasswordData} icon1={<IoIosLock />} icon2={<IoIosLock />} />
                            </div>

                            <div className='mx-4 mb-4'>
                                <button type='submit' className="btn btn-yellow my-3 w-100">{<Trans i18nKey="submitButton" />}</button>
                            </div>
                        </form>
                    </div>
                    :
                    <div className="inner-scroll">
                        <div className='position-relative'>
                            <h3 className="text-center p-3"><Trans i18nKey="titleForgotPassword" /></h3>
                            <div className='position-absolute' style={{ top: "12px", left: '10px' }}>
                                <AiFillLeftCircle size='35' color='var(--yellow)' onClick={() => { navigate(-1, { replace: true }) }} style={{ cursor: "pointer", }} />
                            </div>
                        </div>

                        <div className="">
                            <div className='d-flex align-items-center justify-content-center'>
                                <img className='confirmMailImg' src={confirmMail} alt="confirm mail img" />
                            </div>
                            <p className='p-0 pt-2 m-0 text-center'><Trans i18nKey="textForgotPassword" /></p>

                            <div className='mx-4 mb-4'>
                                <form className="row g-0" onSubmit={forgotPassMailFunction}>
                                    <CustomInputField type="text" name="email" label="Your Account Email" langLabel={<Trans i18nKey="emailForgotPasswordLangLabel" />} langPlaceHolder={t('emailForgotPasswordLangLabel')} value={inputForgotPasswordData.email} func={updateForgotPasswordData} icon1={false} icon2={<HiMail />} />
                                    <div className='d-flex justify-content-center'>
                                        <button type='submit' className="btn btn-yellow my-3 w-50">{<Trans i18nKey="continueButton" />}</button>
                                    </div>

                                </form>
                            </div>

                        </div>
                    </div>
            }

        </div>
    );
};

export default ForgotPasswordPage;