import { useState } from "react";



export const useTablePagination = () => {

    const [visibleRow, setVisibleRow] = useState(0);

    const increaseVisibleDataIndex = () => {
        setVisibleRow(visibleRow + 10);
    }

    const decreaseVisibleDataIndex = () => {
        setVisibleRow(visibleRow - 10);
    }


    return {
        visibleRow,
        increaseVisibleDataIndex,
        decreaseVisibleDataIndex,
    }
}
