import React, { useEffect, useState } from 'react';
import "./MovableHome.css";
import { useLocation, useNavigate } from 'react-router-dom';

import Draggable from 'react-draggable';

import home from "../../Assets/Icons/DraggableHomeIcon/home-icon.svg";


const MovableHome = ({ setSelectedFragmentID }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [bounds, setBounds] = useState({ left: 0, top: 0, right: 0, bottom: 0 });


    // const eventHandler = (e, data) => {
    //     console.log('Event Type', e.type);
    //     console.log({ e, data });
    // }

    const handleMovableHome = () => {
        if (location.pathname !== "/") {
            navigate("/")
            setSelectedFragmentID(0);
        } else {
            setSelectedFragmentID(0);
        }
    }
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    useEffect(() => {
        const calculateBounds = () => {

            const baseLeftPosition = 13;
            const leftPosition = screenWidth >= 769 ? baseLeftPosition : baseLeftPosition;

            const baseRightPosition = 13;
            const rightPosition = screenWidth >= 769 ? ((screenWidth * 0.29) - baseRightPosition) : ((screenWidth * 0.9) - (baseRightPosition + baseLeftPosition));

            const topPosition = -(screenHeight * 0.9);

            const baseBottomPosition = 13;
            const bottomPosition = -baseBottomPosition;

            setBounds({
                left: leftPosition,
                top: topPosition,
                right: rightPosition,
                bottom: bottomPosition
            });
        };

        calculateBounds();

        window.addEventListener('resize', calculateBounds);

        return () => {
            window.removeEventListener('resize', calculateBounds);
        };
    }, [screenWidth, screenHeight]);

    return (
        <Draggable
            handle=".handle"
            defaultPosition={{ x: 13, y: -13 }}
            bounds={bounds}
            onMouseDown={handleMovableHome}
            onStart={handleMovableHome}
            onDrag={handleMovableHome}
            onStop={handleMovableHome}
        >
            <div
                className="draggable-home-area"
                onClick={() => handleMovableHome()}
            >
                <div className="handle">
                    <img className="draggable-home" src={home} alt="home" width={"45px"} height={"45px"} />
                </div>
            </div>
        </Draggable>
    );
};

export default MovableHome;