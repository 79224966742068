import React, { useEffect, useState } from 'react';
import './Login.css';
import { useLocation, useNavigate } from 'react-router-dom';

// import CustomInputField from "../../Components/CustomInputField/CustomInputField";

// import loginImg from '../../Assets/Images/Login/Group 429.png';
import betfair from '../../Assets/Icons/footer/sponsor/betfair.svg';
import norton from '../../Assets/Icons/footer/sponsor/norton.svg';
import glo from '../../Assets/Icons/footer/sponsor/glo.svg';

import { AiFillLeftCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import SocialIconList from '../../Components/SocialIconList/SocialIconList';
import CustomInputField from '../../Components/CustomInputField/CustomInputField';
import { useDispatch } from 'react-redux';
import { fetchLoginUserAuth } from '../../Redux/userSlice';
import { showCustomAlert } from '../../Redux/alertSlice';
import { useCookies } from 'react-cookie';
import { useBanner } from '../../Hooks/useBanner';
import { Trans, } from 'react-i18next';
import { t } from 'i18next';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Spinner } from 'react-bootstrap';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { sliderImgList } = useBanner();

    const [loginSubmissionState, setLoginSubmissionState] = useState(false);

    // console.log(sliderImgList);

    const from = location?.state?.from || "/";
    // console.log(from);
    const initialState = {
        credential: '',
        password: ''
    };
    const [loginFormData, setLoginFormData] = useState(initialState);
    // cookies for remember
    const [cookies, setCookie] = useCookies(['user']);
    const [rememberMeCheck, setRememberMeCheck] = useState(false);

    const setRememberMeCheckFunc = (e) => {
        setRememberMeCheck(!rememberMeCheck);
    }

    const handle = () => {
        setCookie('Name', loginFormData.credential, { path: '/' });
        setCookie('Password', loginFormData.password, { path: '/' });
    };

    useEffect(() => {
        if (cookies.Name) {
            setLoginFormData({
                credential: cookies.Name,
                password: cookies.Password
            })
        }
    }, [cookies?.Name, cookies?.Password]);

    const updateLoginFormData = (e) => {
        let newUser = { ...loginFormData };
        let list = (e.target.value).split(' ');
        if (list.length > 1) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Space is not allowed`
            }));
        } else {
            if (e.target.name === 'password') {
                newUser[e.target.name] = e.target.value;
            } else {
                newUser[e.target.name] = e.target.value.toLowerCase();
            }
            setLoginFormData(newUser)
        }
    }

    const submitLogin = async (e) => {
        e.preventDefault();
        setLoginSubmissionState(true);
        dispatch(setInnerLoaderTrue());
        if (rememberMeCheck) {
            handle();
        }
        let data = await dispatch(fetchLoginUserAuth(loginFormData));
        // console.log(data?.payload);
        if (data?.payload?.token) {
            // dispatch(fetchLoginUserData());
            // dispatch(fetchLoginUserWallet());
            setLoginFormData(initialState);
            e.target.reset();
            setLoginSubmissionState(false);
            navigate(from);
        }
        dispatch(setInnerLoaderFalse());
        setLoginSubmissionState(false);
    }

    return (
        <div className='inner-scroll login'>
            <div className='position-relative' style={{ height: "32%", overflow: "hidden" }}>
                {
                    sliderImgList === null ?
                        <SkeletonTheme baseColor="var(--homeCardColo)" highlightColor="var(--lightGray)">
                            <Skeleton count={1} width={"100%"} height={"32dvh"} duration={1} style={{ lineHeight: 2, }} />
                        </SkeletonTheme>
                        :
                        <img className='w-100' src={sliderImgList[0]?.banner_image} alt={sliderImgList[0]?.alt_text} />
                }
                <div className='position-absolute top-0 m-2'>
                    <AiFillLeftCircle size='35' color='#FFFFFF' onClick={() => { navigate(-1) }} style={{ cursor: "pointer", }} />
                </div>
            </div>

            <div style={{ height: "68%" }}>
                <h1>
                    <Trans i18nKey="login">
                        LOGIN
                    </Trans>
                </h1>
                <div className='mx-4 mb-md-4'>
                    <form className="row g-0" onSubmit={(e) => submitLogin(e)}>

                        {/* <div className="custom-input">
                            <div className="module my-2">
                                <label className="" >Username/Email Address</label>
                                <div className="input-group">
                                    <span className="input-group-text"><FaUserAlt /></span>
                                    <input
                                        type="text"
                                        name="credential"
                                        className="form-control"
                                        aria-label="Amount (to the nearest dollar)"
                                        title="Username/Email Address"
                                        onChange={updateLoginFormData}
                                    />
                                </div>
                            </div>
                        </div> */}

                        <CustomInputField type="text" name="credential" label="Username/Email Address" langLabel={<Trans i18nKey="credentialLoginLangLabel" />} langPlaceHolder={t('credentialLoginLangLabel')} value={loginFormData.credential} func={updateLoginFormData} icon1={<FaUserAlt />} icon2={false} />
                        <CustomInputField type="password" name="password" label="Password" langLabel={<Trans i18nKey="passwordLoginLangLabel">Password</Trans>} langPlaceHolder={t('passwordLoginLangLabel')} value={loginFormData.password} func={updateLoginFormData} icon1={<IoIosLock />} icon2={<IoIosLock />} />

                        <div className="d-flex justify-content-between">
                            <div className="form-check">
                                <input onChange={setRememberMeCheckFunc} className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={rememberMeCheck} style={{ cursor: "pointer", }} />
                                <label className="form-check-label" htmlFor="flexCheckDefault" style={{ cursor: "pointer", }}><Trans i18nKey="rememberme">Remember me</Trans></label>
                            </div>

                            <div onClick={() => navigate("/forgot-password")} style={{ color: 'var(--tomato)', cursor: "pointer", }}><Trans i18nKey="forgot">Forgot Password?</Trans></div>
                        </div>

                        <button type='submit' className="btn btn-yellow my-2">
                            {
                                loginSubmissionState &&
                                <Spinner className="me-2" animation="border" variant="dark" as="span" style={{ color: "", width: "15px", height: "15px", borderWidth: "2px", }} />
                            }
                            <Trans i18nKey="login">Log in</Trans>
                        </button>
                    </form>

                    <div>
                        <p className='text-center p-0 m-0'><Trans i18nKey="support">Support</Trans></p>
                        <SocialIconList />
                        <div className="d-flex justify-content-center align-items-center gap-3 px-5 my-3">
                            <img src={betfair} height={"50px"} width={"25%"} alt={"sponsor-betfair"} />
                            <img src={norton} height={"50px"} width={"25%"} alt={"sponsor-norton"} />
                            <img src={glo} height={"50px"} width={"25%"} alt={"sponsor-glo"} />
                        </div>
                    </div>

                    <div className='row g-0'>
                        <button onClick={() => navigate("/registration")} className="btn btn-white mt-2"><Trans i18nKey="newaccount">Create a new account</Trans></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;