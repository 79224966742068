import React, { useState } from 'react';
import useTime from '../../../Hooks/useTime';
import { BsExclamationCircle } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import { userDetail } from '../../../Redux/userSlice';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

const AccountsTable = ({ dataList, visibleRow, user, }) => {
    const userInfo = useSelector(userDetail);
    // console.log(userInfo?.user?.agent);
    const { convertDate, convertTime } = useTime();
    const color = ['#c30010', '#7DAA2F', '#D4AF37', '#5181FC'];
    // Transaction Purpose, Received from, Transfer to, to wallet, 

    const [historyDetailModal, setHistoryDetailModal] = useState({
        visible: false,
        item: null
    });
    // console.log(historyDetailModal);
    // console.log(dataList);

    const makeWalletName = (str) => {
        let split_data = str.split('_').join(' ');
        return split_data.toUpperCase();
    }

    const openHistoryDetailModal = (item) => {
        // console.log(item);

        let tempData = {
            header: {},
            body: {},
        }

        tempData.body['Transaction Date'] = `${convertDate(item.transaction_date)}  ${convertTime(item.transaction_date)}`;
        if (item.transaction_purpose === "th_win" || item.transaction_purpose === "th_lottery" || item.transaction_purpose === "pcso_win" || item.transaction_purpose === "pcso_lottery") {
            if (+item?.receivers_transaction_amount > 0) {
                tempData.header['amount'] = `+ $${item.receivers_transaction_amount}`;
                tempData.header['amount_color'] = color[1];
                tempData.header['purpose_key'] = 'Transaction Purpose';
                if (item.transaction_purpose === "th_win") {
                    tempData.header['purpose_value'] = 'TH Win';
                } else {
                    tempData.header['purpose_value'] = 'PCSO Win';
                }
            } else {
                tempData.header['amount'] = `- $${item.senders_transaction_amount}`;
                tempData.header['amount_color'] = color[0];
                tempData.header['purpose_key'] = 'Transaction Purpose';
                if (item.transaction_purpose === "th_lottery") {
                    tempData.header['purpose_value'] = 'TH Lottery';
                } else {
                    tempData.header['purpose_value'] = 'PCSO Lottery';
                }
            }


            tempData.body['Type'] = 'Lottery';
            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "Deposit Commission" || item.transaction_purpose === "Withdrawal Commission") {
            tempData.header['amount'] = `+ $${item.receivers_transaction_amount}`;
            tempData.header['amount_color'] = color[1];
            tempData.header['purpose_key'] = 'Transaction Purpose';
            tempData.header['purpose_value'] = item.transaction_purpose;
            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "referral_deposit") {
            tempData.header['amount'] = `$${item.receivers_transaction_amount}`;
            tempData.header['amount_color'] = color[3];
            tempData.header['purpose_key'] = 'Referral Bonus From';
            tempData.header['purpose_value'] = item.senders_username;
            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "w2w") {
            tempData.header['amount'] = `$${item.receivers_transaction_amount}`;
            tempData.header['amount_color'] = color[2];
            tempData.header['purpose_key'] = 'To wallet';
            tempData.header['purpose_value'] = makeWalletName(item.receivers_wallet_id);

            tempData.body['Convert from'] = makeWalletName(item.senders_wallet_id);
            tempData.body[`Transaction fees(${item.transaction_fees_percentage}%)`] = `$${item.transaction_fees_total_amount}`;
            tempData.body['Original amount'] = `$${parseFloat(+item.senders_transaction_amount + +item.transaction_fees_total_amount).toFixed(2)}`;
            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "p2p") {
            if (item.receivers_username === user.user.username) { // receive
                tempData.header['amount'] = `+ $${item.receivers_transaction_amount}`;
                tempData.header['amount_color'] = color[1];
                tempData.header['purpose_key'] = 'Received from';
                tempData.header['purpose_value'] = item.senders_username;

                tempData.body['Trx ID'] = item.transaction_id;
            } else { // transfer
                tempData.header['amount'] = `- $${item.receivers_transaction_amount}`;
                tempData.header['amount_color'] = color[0];
                tempData.header['purpose_key'] = 'Transfer to';
                tempData.header['purpose_value'] = item.receivers_username;

                tempData.body['Trx ID'] = item.transaction_id;
            }

        }

        if (item.transaction_purpose === "Debited") {
            tempData.header['amount'] = `${userInfo?.user?.agent ? "-" : "+"} $${item.senders_transaction_amount}`;
            tempData.header['amount_color'] = userInfo?.user?.agent ? color[0] : color[1];

            tempData.header['purpose_key'] = 'Transaction Purpose';
            tempData.header['purpose_value'] = 'Deposit Request';

            tempData.body['Trx ID'] = item.transaction_id;
        }


        if (item.transaction_purpose === "Credit") {
            tempData.header['amount'] = `${userInfo?.user?.agent ? "+" : "-"} $${item.senders_transaction_amount}`;
            tempData.header['amount_color'] = userInfo?.user?.agent ? color[1] : color[0];

            tempData.header['purpose_key'] = 'Transaction Purpose';
            tempData.header['purpose_value'] = 'Withdrawal Request';

            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "betfair_betplace") {
            tempData.header['amount'] = `- $${item.senders_transaction_amount}`;
            tempData.header['amount_color'] = color[0];
            tempData.header['purpose_key'] = 'Transaction Purpose';
            tempData.header['purpose_value'] = "Exchange";
            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "betfair_win") {
            tempData.header['amount'] = `+ $${item.receivers_transaction_amount}`;
            tempData.header['amount_color'] = color[1];
            tempData.header['purpose_key'] = 'Transaction Purpose';
            tempData.header['purpose_value'] = "Exchange Win";
            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "betfair_refund") {
            tempData.header['amount'] = `+ $${item.receivers_transaction_amount}`;
            tempData.header['amount_color'] = color[1];
            tempData.header['purpose_key'] = 'Transaction Purpose';
            tempData.header['purpose_value'] = "Bet Settle";
            tempData.body['Trx ID'] = item.transaction_id;
        }

        if (item.transaction_purpose === "Deposit Bonus") {
            tempData.header['amount'] = `+ $${item.receivers_transaction_amount}`;
            tempData.header['amount_color'] = color[1];

            tempData.header['purpose_key'] = 'Transaction Purpose';
            tempData.header['purpose_value'] = item.transaction_purpose;

            tempData.body['Trx ID'] = item.transaction_id;
        }

        tempData.body['Status'] = 'Success';

        // console.log(tempData);
        setHistoryDetailModal({
            visible: true,
            item: tempData,
        });
    }

    const closeHistoryDetailModal = (item) => {
        setHistoryDetailModal({
            visible: false,
            item: null,
        });
    }
    return (
        <>
            {
                (dataList?.length === 0) ?
                    // dataless view
                    <div className="d-flex justify-content-center align-items-center mt-5 history-blurred-text text-center" >
                        <h3 className="mb-0">No Records</h3>
                    </div> :
                    <table className="table table-bordered border-golden">
                        <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                            <tr>
                                {/* <th className='text-center' scope="col">SL.</th> */}
                                <th className='text-center' scope="col">Date</th>
                                <th className='text-center' scope="col">Description</th>
                                <th className='text-center' scope="col">Amount</th>
                                <th className='text-center' scope="col">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataList &&
                                dataList?.slice(visibleRow, visibleRow + 10)?.map((item, index) =>
                                    <tr key={item?.id || item?.transaction_id || index}>
                                        {/* <th className='text-center' scope="row">{dataList?.indexOf(item) + 1}</th> */}
                                        <td className='text-center'> {convertDate(item.created_at || item.transaction_date)}</td>

                                        {
                                            (item.senders_username === user.user.username && item.transaction_purpose === 'p2p') ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>Transfer</td>
                                                : (item.receivers_username === user.user.username && item.transaction_purpose === 'p2p') ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>Received</td>
                                                    : (item.transaction_purpose === 'w2w') ? <td style={{ color: "#D4AF37", fontWeight: "normal", textAlign: "center" }}>Convert</td>
                                                        : (item.transaction_purpose === "referral_deposit") ? <td style={{ color: "#5181FC", fontWeight: "normal", textAlign: "center" }}>Referral Bonus</td>
                                                            : (item.transaction_purpose === "pcso_lottery") ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>PCSO Lottery</td>
                                                                : (item.transaction_purpose === "th_lottery") ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>TH Lottery</td>
                                                                    : (item.transaction_purpose === "pcso_win") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>PCSO Win</td>
                                                                        : (item.transaction_purpose === "th_win") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>TH Win</td>
                                                                            : (item.transaction_purpose === "Deposit Commission") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{item.transaction_purpose}</td>
                                                                                : (item.transaction_purpose === "Deposit Bonus") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{item.transaction_purpose}</td>
                                                                                    : (item.transaction_purpose === "Withdrawal Bonus") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{item.transaction_purpose}</td>
                                                                                        : (item.transaction_purpose === "Debited") ? <td style={{ color: userInfo?.user?.agent ? "#c30010" : "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{"Deposit Request"}</td>
                                                                                            : (item.transaction_purpose === "Credit") ? <td style={{ color: userInfo?.user?.agent ? "#7DAA2F" : "#c30010", fontWeight: "normal", textAlign: "center" }}>{"Withdrawal Request"}</td>
                                                                                                : (item.transaction_purpose === "Withdrawal Commission") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{item.transaction_purpose}</td>
                                                                                                    : (item.transaction_purpose === "betfair_betplace") ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>{"Exchange"}</td>
                                                                                                        : (item.transaction_purpose === "betfair_win") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{"Exchange Win"}</td>
                                                                                                            : (item.transaction_purpose === "betfair_refund") && <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{"Bet Settle"}</td>
                                        }

                                        {
                                            (item.senders_username === user.user.username && item.transaction_purpose === 'p2p') ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>- ${item.receivers_transaction_amount}</td>
                                                : (item.receivers_username === user.user.username && item.transaction_purpose === 'p2p') ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                    : (item.transaction_purpose === 'w2w') ? <td style={{ color: "#D4AF37", fontWeight: "normal", textAlign: "center" }}>${item.receivers_transaction_amount}</td>
                                                        : (item.transaction_purpose === 'referral_deposit') ? <td style={{ color: "#5181FC", fontWeight: "normal", textAlign: "center" }}>${item.receivers_transaction_amount}</td>
                                                            : (item.transaction_purpose === "pcso_lottery" && +item?.senders_transaction_amount > 0) ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>- ${item.senders_transaction_amount}</td>
                                                                : (item.transaction_purpose === "th_lottery" && +item?.senders_transaction_amount > 0) ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>- ${item.senders_transaction_amount}</td>
                                                                    : (item.transaction_purpose === "pcso_win" && +item?.receivers_transaction_amount > 0) ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                                        : (item.transaction_purpose === "th_win" && +item?.receivers_transaction_amount > 0) ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                                            : (item.transaction_purpose === "Deposit Commission" && +item?.receivers_transaction_amount > 0) ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                                                : (item.transaction_purpose === "Deposit Bonus" && +item?.receivers_transaction_amount > 0) ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                                                    : (item.transaction_purpose === "Withdrawal Bonus" && +item?.receivers_transaction_amount > 0) ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                                                        : (item.transaction_purpose === "Debited") ? <td style={{ color: userInfo?.user?.agent ? "#c30010" : "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>{userInfo?.user?.agent ? "-" : "+"} ${item.receivers_transaction_amount}</td>
                                                                                            : (item.transaction_purpose === "Credit") ? <td style={{ color: userInfo?.user?.agent ? "#7DAA2F" : "#c30010", fontWeight: "normal", textAlign: "center" }}>{userInfo?.user?.agent ? "+" : "-"} ${item.receivers_transaction_amount}</td>
                                                                                                : (item.transaction_purpose === "Withdrawal Commission" && +item?.receivers_transaction_amount > 0) ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                                                                    : (item.transaction_purpose === "betfair_betplace") ? <td style={{ color: "#c30010", fontWeight: "normal", textAlign: "center" }}>- ${item.senders_transaction_amount}</td>
                                                                                                        : (item.transaction_purpose === "betfair_win") ? <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                                                                                            : (item.transaction_purpose === "betfair_refund") && <td style={{ color: "#7DAA2F", fontWeight: "normal", textAlign: "center" }}>+ ${item.receivers_transaction_amount}</td>
                                        }

                                        <td>
                                            <div
                                                className='text-center'
                                                style={{ cursor: "pointer" }}
                                                onClick={() => openHistoryDetailModal(item)}
                                            >
                                                <BsExclamationCircle size={20} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
            }

            <Modal className='transactionModal' fullscreen={false} show={historyDetailModal.visible}>
                <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                    <div className='w-100'>
                        <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                            <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                <button onClick={closeHistoryDetailModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                    {/* <i style={{ fontSize: '22px', fontWeight: 'normal' }} className='fa fa-angle-left'></i> */}
                                </button>

                                <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{t('transactionDetailsModalTitle')}</span>

                                <button onClick={closeHistoryDetailModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                    {/* <i className='fa fa-times'></i> */}
                                </button>
                            </div>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <p style={{ color: `${historyDetailModal?.item?.header?.amount_color}`, fontWeight: "bold", textAlign: "center", fontSize: "30px", margin: 0 }}>
                                {historyDetailModal?.item?.header?.amount}
                            </p>

                            {
                                historyDetailModal?.item?.header?.purpose_key &&
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className='me-2'>{historyDetailModal?.item?.header?.purpose_key} :</div>
                                        <div>{historyDetailModal?.item?.header?.purpose_value}</div>
                                    </div>
                                </div>
                            }
                        </div>

                        <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>
                            {
                                historyDetailModal?.item &&
                                Object.keys(historyDetailModal?.item?.body).map((key, index) =>
                                    <div key={key?.id || index} className='row g-0 m-1'>
                                        {
                                            (key === 'Status') ?
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>{key} :</div>
                                                    <div style={{ color: `${color[1]}`, fontWeight: "normal", }}>{historyDetailModal?.item?.body[key]}</div>
                                                </div>
                                                :
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>{key} :</div>
                                                    <div>{historyDetailModal?.item?.body[key]}</div>
                                                </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AccountsTable;