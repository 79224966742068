import React from 'react';
import { Col, Row } from 'react-bootstrap';
// import { useBankingTransactions } from '../../Hooks/useBankingTransactions';
// import QRMethodDeposit from '../../Pages/BankingPages/DepositPages/QRMethodDeposit/QRMethodDeposit';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogoutUser, userDetail } from '../../Redux/userSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { origin, rootUrl } from '../../App';
import { setGatewayItem } from '../../Redux/bankingTransactionSlice';
import { showCustomAlert } from '../../Redux/alertSlice';
// import { useNavigate } from 'react-router-dom';

const GatewayCards = ({ transactionGetWays, from, }) => {
    // const navigate = useNavigate();
    // const { qrDeposit, } = useBankingTransactions();
    // console.log(qrDeposit);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);

    const getPaymentGateWayDetail = (item, from) => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/payment/gateway/${item?.id}/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (from === "deposit") {
                        if (data[0].gateway_name === "perfect_money") {
                            dispatch(setGatewayItem(data[0]));
                            navigate(`/qr-deposit${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`);
                        } else if (!item.gateway_url) {
                            dispatch(setGatewayItem(data[0]));
                            navigate(`/qr-deposit${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`);
                        }
                    } else if (from === "withdrawal") {
                        dispatch(setGatewayItem(data[0]));
                        navigate(`/qr-banking-withdrawal${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`);
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        // console.log(text);
                        if (text?.error) {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.error}`,
                            }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`,
                    }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse());
                })
        }
    }

    return (
        <>
            <Row xs={3} sm={4} md={6} lg={3} className="">
                {transactionGetWays?.map((item, index) => (
                    <Col key={index}>
                        <div
                            onClick={() => { getPaymentGateWayDetail(item, from) }}
                            className='w-100 d-flex align-items-center justify-content-center mb-3 mb-md-2'
                            style={{ borderRadius: "0.3em", border: "1px solid #999999", height: "60px", width: "99%", position: "relative", cursor: "pointer", overflow: "hidden" }}>
                            <img src={item?.gateway_logo} className="" height="85%" alt="" style={{ maxWidth: "90%" }} />
                        </div>
                    </Col>
                ))}
            </Row>
            {/* {
                (qrDeposit) &&
                <QRMethodDeposit gatewayItem={qrDeposit} />
            } */}
        </>
    );
};

export default GatewayCards;