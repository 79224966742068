import React from 'react';
import { Outlet } from 'react-router-dom';
import CountryLotteryNavbar from '../Components/CountryLotteryComponent/CountryLotteryNavbar/CountryLotteryNavbar';
import HeaderWithoutBackButton from '../Components/Header/HeaderWithoutBackButton';

const LayoutForCountryLottery = ({ logo, lotteryType, setSelectedFragmentID }) => {
    // let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
    return (
        <div className=''>
            <div className='sticky-top'>
                <HeaderWithoutBackButton logo={logo} setSelectedFragmentID={setSelectedFragmentID} />
            </div>
            <CountryLotteryNavbar lotteryType={lotteryType}></CountryLotteryNavbar>
            <Outlet></Outlet>
        </div>
    );
};

export default LayoutForCountryLottery;