import { useEffect, useState } from "react";
import { origin, rootUrl } from "../App";
import { useDispatch, useSelector } from "react-redux";

import { showCustomAlert } from "../Redux/alertSlice";
import { fetchLogoutUser, userDetail } from "../Redux/userSlice";

export const useConvert = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);

    // const [withdrawPercent, setWithdrawPercent] = useState([]);
    // const [minWithDrawalData, setMinWithDrawalData] = useState([]);

    const [convertCharge, setConvertCharge] = useState(0);
    const [minimumConvertCharge, setMinimumConvertCharge] = useState(0);
    // const [transferCharge, setTransferCharge] = useState(0);

    const [minTransferAmount, setMinTransferAmount] = useState(null);

    useEffect(() => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            // dispatch(setInnerLoaderTrue());
            let value = JSON.parse(stringValue);

            // convert charge, transfer charge
            fetch(`${rootUrl}/wallet/transaction-charge/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data, userInfo);
                    if (userInfo?.user?.agent) {
                        let agentConvert = (data?.filter(item => item?.code === "agent_wallet_convert"));
                        setConvertCharge(agentConvert[0]?.amount);
                        setMinimumConvertCharge(agentConvert[0]?.min_transfer_amount);
                        let agentTransfer = (data?.filter(item => item?.code === "agent_wallet_transfer"));
                        // setTransferCharge(agentTransfer[0].amount);
                        setMinTransferAmount(agentTransfer[0].min_transfer_amount);
                    } else {
                        let userConvert = (data?.filter(item => item?.code === "wallet_to_wallet_percentage"));
                        setConvertCharge(userConvert[0]?.amount);
                        setMinimumConvertCharge(userConvert[0]?.min_transfer_amount);
                        let userTransfer = (data.filter(item => item.code === "internal_transfer_percentage"));
                        // setTransferCharge(userTransfer[0].amount);
                        setMinTransferAmount(userTransfer[0].min_transfer_amount);
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.detail}`,
                            }));
                            // dispatch(setCustomAlertTrue({ massage: `${text?.detail}`, type: 'danger' }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`,
                    }));
                    // dispatch(setCustomAlertTrue({ massage: `${err.statusText}`, type: 'danger' }));
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse()) 
                })
        }

    }, [dispatch, userInfo?.user?.agent]);

    return {
        convertCharge,
        minimumConvertCharge,
        // transferCharge,
        minTransferAmount,
    };
};