import React, { useEffect, useState } from 'react';
import './BankingProfile.css';
import CustomSectionTitle from '../../../Components/CustomSectionTitle/CustomSectionTitle';
// import { AiFillCamera } from 'react-icons/ai';
// import { Card, Col, ListGroup, Row, } from 'react-bootstrap';
// import { HiCheckCircle, } from 'react-icons/hi';
// import { bankingCardData } from '../../../Data/BankingPageData';
// import { useNavigate } from 'react-router-dom';
// import { FaUserEdit, FaUserTie } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUserWallet, userDetail } from '../../../Redux/userSlice';
// import { rootUrl } from '../../../App';
// import { useBankingProfile } from '../../../Hooks/useBankingProfile';
import useBankingActivities from '../../../Hooks/useBankingActivities';
import AccountsTable from '../../../Components/UserTables/AccountsTable/AccountsTable';
import WithdrawalTable from '../../../Components/UserTables/WithdrawalTable/WithdrawalTable';
import DepositTable from '../../../Components/UserTables/DepositTable/DepositTable';
import MyBetsTable from '../../../Components/UserTables/MyBetsTable/MyBetsTable';
import Header from '../../../Components/Header/Header';
// import { AiFillCamera } from 'react-icons/ai';
// import ProfileImgModal from '../../../Components/ProfileImgModal/ProfileImgModal';
// import shareIcon from '../../../Assets/Icons/share-icon.svg';

import { Trans } from 'react-i18next';
// import useCopyToClipboard from '../../../Hooks/useCopyToClipboard';
// import { RWebShare } from 'react-web-share';
// import WalletTransferTable from '../../../Components/UserTables/WalletTransferTable/WalletTransferTable';
// import ExchangeTable from '../../../Components/UserTables/ExchangeTable/ExchangeTable';

const BankingProfile = () => {
    // const copy = useCopyToClipboard();
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);
    // const userWallet = useSelector(currentUserWallet);
    const [whichHistory, setWhichHistory] = useState("accounts");
    const [visibleRow, setVisibleRow] = useState(0);
    // const [profileImageModal, setProfileImageModal] = useState(false);

    // const { referralBonus } = useBankingProfile();
    const { getAccountsHistory, getDepositHistory, getWithdrawalHistory, getMyBetsHistoryGS, transactionHistoryList, } = useBankingActivities();

    useEffect(() => {
        if (whichHistory === "accounts") {
            getAccountsHistory();
        }
        if (whichHistory === "deposit") {
            getDepositHistory();
        }
        if (whichHistory === "withdrawal") {
            getWithdrawalHistory();
        }
        if (whichHistory === "mybetsGS") {
            getMyBetsHistoryGS();
        }
        // if (whichHistory === "walletTransfer") {
        //     getWalletTransferHistoryGS();
        // }
        // else if (whichHistory === "exchangebets") {
        //     getExchangeBetsHistory();
        // } 
        else {
            setVisibleRow(0);
        }
    }, [whichHistory, getAccountsHistory, getDepositHistory, getWithdrawalHistory, getMyBetsHistoryGS]);

    const increaseVisibleDataIndex = () => {
        setVisibleRow(visibleRow + 10)
    }

    const decreaseVisibleDataIndex = () => {
        setVisibleRow(visibleRow - 10)
    }

    // user profile image field

    // const setProfileImageModalClose = () => {
    //     setProfileImageModal(false);
    // }

    useEffect(() => {
        dispatch(fetchLoginUserWallet());
    }, [dispatch]);

    return (
        <div className="inner-scroll bankingProfile">
            <div className='sticky-top'>
                <Header pageTitle={
                    // <Trans i18nKey="accountsPage">
                    "Transactions"
                    // </Trans>
                } />
            </div>
            {/* <div className='mx-4 my-4'>
                <div className="row gx-0 d-flex justify-content-between align-items-start">
                    <div className="col-7">
                    </div>
                    <div className="col-5 d-flex justify-content-end align-items-start">
                        <button type='submit' className="btn btn-sm btn-dark" onClick={() => navigate(`/user-profile-edit${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)}> <FaUserEdit /> <Trans i18nKey="editprofile">Edit Profile</Trans></button>
                    </div>
                </div>
            </div>
            <div className="mx-4">

                <section className="user-header">
                    <div className="row gx-0 d-flex justify-content-center align-items-center py-3">
                        <div className="col-12 d-flex justify-content-center align-items-center mb-3">
                            {
                                userInfo?.user?.photo ?
                                    <div className='userImgBorder' onClick={() => setProfileImageModal(true)}>
                                        <div className="selectedUserImage">
                                            <img src={`${rootUrl}${userInfo?.user?.photo}`} alt="UserImg" />
                                        </div>
                                    </div>
                                    :
                                    <div className='userImgBorder' onClick={() => setProfileImageModal(true)}>
                                        <div className="userImage" htmlFor='profilePic'>
                                            <AiFillCamera width="60px" />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </section>
                <div className="referral mb-3">
                    <div className="row gx-0 d-flex justify-content-center align-items-center">
                        <div className="col-12 d-flex justify-content-center align-items-center">

                            <div className="d-flex flex-column flex-sm-column justify-content-center align-items-center">
                                <p className="mb-0" style={{ fontSize: "14px", }}><Trans i18nKey="referralcode">Your Referral Code</Trans> : <span className="rounded px-2 py-1" style={{ backgroundColor: "#000", color: "var(--yellow)", }}>{userInfo?.user?.referral_code}</span><span className="ms-2" style={{ color: "var(--green)", cursor: "pointer", }} onClick={() => navigate(`/referral${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)}>(<Trans i18nKey="viewall">View all</Trans>)</span></p>
                                <div className="d-none">
                                    <button onClick={() => copy(`${userInfo?.user?.user_base_origin + "registration/?ref=" + userInfo?.user?.referral_code}`)} style={{ border: "none", outline: "none", backgroundColor: "transparent", }}>
                                        <img src={shareIcon} alt="share" />
                                    </button>
                                </div>
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <p className="mb-0 px-2 rounded" style={{ backgroundColor: "#EDEDED", fontSize: "10px", }}>{`${userInfo?.user?.user_base_origin + "registration/?ref=" + userInfo?.user?.referral_code}`}</p>
                                    <div>
                                        <RWebShare
                                            data={{
                                                text: "Click to register the app.",
                                                url: `${userInfo?.user?.user_base_origin + "registration/?ref=" + userInfo?.user?.referral_code}`,
                                                title: "Betting App",
                                            }}
                                            onClick={() => console.log("shared successfully!")}
                                        >
                                            <button style={{ border: "none", outline: "none", backgroundColor: "transparent", }}>
                                                <img src={shareIcon} alt="share" width="20px" />
                                            </button>
                                        </RWebShare>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <small style={{ fontSize: "12px", }}> Referral commission every deposit <strong> {referralBonus || 0.00}% </strong> lifetime.</small>
                        </div>
                    </div>
                </div>
                <div className="user-info">
                    <div className="row gx-0 d-flex justify-content-center align-items-center">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <ListGroup className="w-100">
                                <ListGroup.Item><Trans i18nKey="username">Username</Trans> : <span className="fw-bold">{userInfo?.user?.username}</span></ListGroup.Item>
                                <ListGroup.Item><Trans i18nKey="accountstatus">Account Status</Trans> : <span className="fw-bold text-capitalize" style={{ color: "var(--green)" }}>{userInfo?.user?.status} <HiCheckCircle /></span></ListGroup.Item>
                                <ListGroup.Item><Trans i18nKey="usercategory">User Category</Trans> : <span className="fw-bold">{userInfo?.user?.category}</span></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mx-4 my-4">
                <CustomSectionTitle title={<Trans i18nKey="bankingSubtitle">Banking</Trans>} page={""} />
            </div>
            <div className="mx-4 my-4" style={{ fontWeight: 500, }}>
                <Row xs={2} md={2} className="g-3">
                    {bankingCardData?.map((item, idx) => (
                        <Col key={item?.id}>
                            {
                                (item?.name === "Deposit") ?
                                    <button onClick={() => navigate(`/deposit${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)} type='submit' className="btn btn-yellow w-100 fw-bold btn-sm">{item?.icon} {item?.cardTitle}</button>
                                    :
                                    (item?.name === "Withdrawal") ?
                                        <button onClick={() => navigate(`/withdrawal${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)} type='submit' className="btn btn-green w-100 fw-bold btn-sm">{item?.icon} {item?.cardTitle}</button>
                                        :
                                        <Card style={(item?.name === "Total Balance") ? { backgroundColor: "#3C84AB", border: "none", color: "#fff" } : { backgroundColor: "var(--lightGray)", border: "none", }}>
                                            <Card.Body className="text-center p-2">
                                                <Card.Text className="mb-1">{item?.cardTitle}</Card.Text>
                                                <Card.Text className="mb-1">$
                                                    {
                                                        item?.name === "Credit Balance" ? parseFloat(+userWallet?.credit_balance).toFixed(2)
                                                            :
                                                            item?.name === "Reserve Balance" ? parseFloat(+userWallet?.reserve_balance).toFixed(2)
                                                                :
                                                                item?.name === "Coin Balance" ? parseFloat(+userWallet?.coin_balance).toFixed(2)
                                                                    :
                                                                    item?.name === "Total Balance" && parseFloat(+userWallet?.total_balance).toFixed(2)

                                                    }
                                                </Card.Text>
                                                <Card.Text className="mb-1">
                                                    <button onClick={() => navigate(item?.to) + ((userInfo?.user?.username) ? ("?" + userInfo?.user?.username) : '')} type='submit' className="btn btn-yellow btn-sm">{item?.icon} {item?.cardSubTitle}</button>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                            }
                        </Col>
                    ))}
                </Row>
                {
                    userInfo?.user?.agent &&
                    <button onClick={() => navigate(`/agent-admin${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)} type='submit' className="btn btn-yellow w-100 mt-3 fw-bold"><FaUserTie className="mb-1" /> <Trans i18nKey="agentAdminButton">Agent Admin</Trans></button>
                }
            </div> */}
            <div className="mx-4 my-4">
                <CustomSectionTitle title={<Trans i18nKey="transactionHistorySubtitle">Transaction History</Trans>} page={""} />
            </div>
            <div className="mx-4 my-4">
                <div className='d-flex align-items-center justify-content-center'>
                    <button onClick={() => setWhichHistory("accounts")} className={whichHistory === "accounts" ? "btn btn-yellow btn-sm px-1 py-0" : "btn btn-sm btn-green px-1 py-0"} style={{ border: "none", }} ><Trans i18nKey="accounts">Accounts</Trans></button>
                    <button onClick={() => setWhichHistory("deposit")} className={whichHistory === "deposit" ? "btn btn-yellow ms-1 ms-md-2 btn-sm px-1 py-0" : "btn btn-sm ms-1 ms-md-2 btn-green px-1 py-0"} style={{ border: "none", }} ><Trans i18nKey="deposit">Deposit</Trans></button>
                    <button onClick={() => setWhichHistory("withdrawal")} className={whichHistory === "withdrawal" ? "btn btn-yellow ms-1 ms-md-2 btn-sm px-1 py-0" : "btn btn-sm ms-1 ms-md-2 btn-green px-1 py-0"} style={{ border: "none", }}><Trans i18nKey="withdrawal">Withdrawal</Trans></button>
                    <button onClick={() => setWhichHistory("mybetsGS")} className={whichHistory === "mybetsGS" ? "btn btn-yellow ms-1 ms-md-2 btn-sm px-1 py-0" : "btn btn-sm ms-1 ms-md-2 btn-green px-1 py-0"} style={{ border: "none", }}><Trans i18nKey="mybets">My Bets</Trans></button>
                    {/* <button onClick={() => setWhichHistory("walletTransfer")} className={whichHistory === "walletTransfer" ? "btn btn-yellow mx-1 mx-md-2 btn-sm px-1 py-0" : "btn btn-sm mx-1 mx-md-2 btn-green px-1 py-0"} style={{ border: "none", }}>Wallet Transfer</button> */}
                </div>
                {/* <div className="">
                    <button onClick={() => setWhichHistory("walletTransfer")} className={whichHistory === "walletTransfer" ? "btn btn-yellow btn-sm mt-2 w-100" : "btn btn-sm btn-green mt-2 w-100"} style={{ border: "none", }}>Wallet Transfer</button>
                    <button onClick={() => setWhichHistory("exchangebets")} className={whichHistory === "exchangebets" ? "btn btn-yellow btn-sm mt-2 w-100" : "btn btn-sm btn-green mt-2 w-100"} style={{ border: "none", }}><Trans i18nKey="exchangebets">Exchange Bet History</Trans></button>
                </div> */}
            </div>
            <div className='mx-4 my-4 transactionHistoryTable'>
                {
                    (transactionHistoryList?.length === 0) ?
                        <div className="d-flex justify-content-center align-items-center my-5 history-blurred-text" >
                            <h3 className="mb-0">No Records</h3>
                        </div>
                        :
                        (whichHistory === "accounts") ?
                            <AccountsTable dataList={transactionHistoryList} visibleRow={visibleRow} user={userInfo} />

                            : (whichHistory === "deposit") ?
                                <DepositTable dataList={transactionHistoryList} visibleRow={visibleRow} getDepositHistory={getDepositHistory} />

                                : (whichHistory === "withdrawal") ?
                                    <WithdrawalTable dataList={transactionHistoryList} visibleRow={visibleRow} getTransactionHistory={getWithdrawalHistory} user={userInfo} />

                                    : (whichHistory === "mybetsGS") &&
                                    <MyBetsTable dataList={transactionHistoryList} visibleRow={visibleRow} />
                    // : (whichHistory === "walletTransfer") &&
                    // <WalletTransferTable dataList={transactionHistoryList} visibleRow={visibleRow} />

                    // : (whichHistory === "exchangebets") &&
                    // <ExchangeTable dataList={transactionHistoryList} visibleRow={visibleRow} />
                }
                <div className={(transactionHistoryList?.length > 0) ? "d-flex justify-content-end mb-3" : 'd-none'}>
                    <div>
                        <button onClick={decreaseVisibleDataIndex} className={visibleRow === 0 ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow === 0 ? true : false}><Trans i18nKey="prev">PREV.</Trans></button>
                        <button onClick={increaseVisibleDataIndex} className={visibleRow + 10 >= transactionHistoryList?.length ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow + 10 >= transactionHistoryList?.length ? true : false}><Trans i18nKey="next">NEXT</Trans></button>
                    </div>
                </div>
            </div>
            {/* <ProfileImgModal profileImageModal={profileImageModal} setProfileImageModalClose={setProfileImageModalClose} ></ProfileImgModal> */}
        </div>
    );
};

export default BankingProfile;