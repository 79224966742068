import { useCallback, useState } from "react";
import { origin, rootUrl } from "../App";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogoutUser, userDetail } from "../Redux/userSlice";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

const useBankingActivities = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);

    const [transactionHistoryList, setTransactionHistoryList] = useState([]);
    const [exchangeHistorySkeleton, setExchangeHistorySkeleton] = useState(true);

    const getReferralHistory = useCallback(() => {
        setTransactionHistoryList(null);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            dispatch(setInnerLoaderTrue());
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/referrals/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    setTransactionHistoryList(data);
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch]);

    const getAccountsHistory = useCallback(() => {
        setTransactionHistoryList(null);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/wallet/transaction_history/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log("accounts", data);
                    // setWhichHistory("transaction");

                    if (userInfo?.user?.agent) {
                        setTransactionHistoryList(data);
                    } else {
                        let currData = data.filter(item => item.transaction_purpose !== "Debited" && item.transaction_purpose !== "Credit");
                        setTransactionHistoryList(currData);
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch, userInfo?.user?.agent]);

    const getDepositHistory = useCallback(() => {
        setTransactionHistoryList(null);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/wallet/deposit/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log("deposit", data);
                    setTransactionHistoryList(data);
                    // setWhichHistory("deposit");
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            // dispatch(setCustomAlertTrue({ massage: `${text?.detail}`, type: 'danger' }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch]);

    const getWithdrawalHistory = useCallback(() => {
        setTransactionHistoryList(null);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/wallet/withdrawal/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log("withdrawal", data);
                    setTransactionHistoryList(data);
                    // setWhichHistory("withdrawal");
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            // dispatch(setCustomAlertTrue({ massage: `${text?.detail}`, type: 'danger' }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch]);

    // const getMyBetsHistory = useCallback(() => {
    //     let stringValue = localStorage.getItem("bet7uUserKey")
    //     if (stringValue !== null) {
    //         let value = JSON.parse(stringValue);
    //         dispatch(setInnerLoaderTrue());
    //         fetch(`${rootUrl}/fundist/transactions/${userInfo?.user.id}/${origin}`, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Accept': 'application/json',
    //                 'Authorization': `Token ${value.value}`,
    //             }
    //         })
    //             .then(res => {
    //                 if (!res.ok) throw res;
    //                 else return res.json();
    //             })
    //             .then(data => {
    //                 // console.log("mybets", data);
    //                 let tempData = data.filter(item => item.status === "success");
    //                 setTransactionHistoryList(tempData);
    //                 // setWhichHistory("activity");
    //             })
    //             .catch(err => {
    //                 err.json().then(text => {
    //                     if (text?.detail === "Invalid token.") {
    //                         dispatch(fetchLogoutUser());
    //                         return;
    //                     }
    //                 });
    //             })
    //             .finally(() => {
    //                 dispatch(setInnerLoaderFalse())
    //             })
    //     }
    // }, [dispatch, userInfo?.user]);

    const getMyBetsHistoryGS = useCallback(() => {
        setTransactionHistoryList(null);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/gs/user-history/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value?.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log("mybets", data);
                    // let tempData = data.filter(item => item.status === "success");
                    setTransactionHistoryList(data);
                    // setWhichHistory("activity");
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch]);

    const getWalletTransferHistoryGS = useCallback(() => {
        setTransactionHistoryList(null);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/gs/gs-record/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value?.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log("getWalletTransferHistoryGS", data);
                    // let tempData = data.filter(item => item.status === "success");
                    setTransactionHistoryList(data);
                    // setWhichHistory("activity");
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch]);

    const getExchangeBetsHistory = useCallback(() => {
        setTransactionHistoryList(null);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            // dispatch(setInnerLoaderTrue());
            setExchangeHistorySkeleton(true);
            fetch(`${rootUrl}/betfair/bet-history/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log("exchangebets", data);
                    // let tempData = data.filter(item => item.status === "success");
                    // setTransactionHistoryList(data);
                    // setWhichHistory("activity");

                    fetch(`${rootUrl}/betfair/bet-history-fancy/${origin}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Token ${value.value}`,
                        }
                    })
                        .then(res => {
                            if (!res.ok) throw res;
                            else return res.json();
                        })
                        .then(dataFancy => {
                            // console.log("exchangebets", [...data, ...dataFancy]);
                            // let tempData = data.filter(item => item.status === "success");
                            const sortedList = [...data, ...dataFancy]?.sort((a, b) => {
                                return new Date(a?.updated_at) - new Date(b?.updated_at);
                            });
                            // console.log(sortedList);
                            sortedList.reverse();
                            setTransactionHistoryList(sortedList);
                            setExchangeHistorySkeleton(false);
                            // setTransactionHistoryList([...data, ...dataFancy]);
                            // setWhichHistory("activity");
                        })
                        .catch(err => {
                            err.json().then(text => {
                                if (text?.detail === "Invalid token.") {
                                    console.log(text?.detail)
                                    // dispatch(fetchLogoutUser());
                                    return;
                                }
                            });
                        })
                        .finally(() => {
                            // dispatch(setInnerLoaderFalse());
                        })
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            console.log(text?.detail);
                            // dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse());
                })
        }
    }, []);

    return {
        getReferralHistory,
        getAccountsHistory,
        getDepositHistory,
        getWithdrawalHistory,
        // getMyBetsHistory,
        getMyBetsHistoryGS,
        getExchangeBetsHistory,
        getWalletTransferHistoryGS,
        transactionHistoryList,
        exchangeHistorySkeleton,
    };
};

export default useBankingActivities;