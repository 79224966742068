import React from 'react';
// import { origin, rootUrl } from '../../App';
import './SocialIconList.css'
import { NavLink } from 'react-router-dom';

import verifiedAgent from '../../Assets/Icons/verified-agent.svg';

const SocialIconList = () => {
    // const [contactList, setContactList] = useState(null);

    // useEffect(() => {
    //     fetch(`${rootUrl}/statics/social-connections/${origin}`)
    //         .then(res => {
    //             if (!res.ok) throw res;
    //             else return res.json();
    //         })
    //         .then(data => {
    //             setContactList(data)
    //         })
    // }, [])

    return (
        <div className='row g-0 justify-content-center align-items-center my-2'>
            <div className="col d-flex justify-content-center align-items-center">
                {/* {
                    contactList &&
                    contactList?.map(item =>
                        <a key={item.id} className='d-none socialIcons' href={item?.social.prefix + item?.resource} rel="noreferrer" target="_blank" style={{ color: "#fff" }}>
                            <img src={item?.logo} alt="" />
                        </a>)
                } */}
                <NavLink className="" to="/verified_agent">
                    <img src={verifiedAgent} width={"50px"} height={"50px"} alt="verified-admin-agents" />
                </NavLink>
            </div>
        </div>
    );
};

export default SocialIconList;