import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { rootUrl } from '../../../App';
import PcsoResultAccordion from '../../../Components/CountryLotteryComponent/PcsoResultAccordion/PcsoResultAccordion';
// import { setCustomAlertTrue, setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
// import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import './PcsoResults.css'
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import { Trans } from 'react-i18next';

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

const PcsoResults = () => {
    const dispatch = useDispatch();

    const [timePeriod, setTimePeriod] = useState(null);
    const [monthListForSelectedYear, setMonthListForSelectedYear] = useState(null);
    const [lotteryResults, setLotteryResults] = useState(null);
    const [active, setActive] = useState(null);
    // console.log(lotteryResults, active);

    const [selectedMonthYear, setSelectedMonthYear] = useState({
        year: '',
        month: ''
    });

    const handleYearChange = (event) => {
        let selectData = timePeriod.find(item => item.year === +event.target.value);
        setMonthListForSelectedYear(selectData.month);

        let data = { ...selectedMonthYear };
        data.year = +event.target.value;
        data.month = selectData.month[0];
        setSelectedMonthYear(data);
    };

    const handleMonthChange = (event) => {
        let data = { ...selectedMonthYear };
        data.month = +event.target.value;
        setSelectedMonthYear(data);
    };

    const getFilteredData = useCallback((year, month) => {
        dispatch(setInnerLoaderTrue());

        const pcsoFullUrl = `${rootUrl}/lottery/result-year-month/`;
        fetch(`${pcsoFullUrl}?year=${year}&month=${month}`)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                setLotteryResults(data);
                setActive(data[0]);
                // console.log(year, month, data);
            })
            .catch((err) => console.log(err))
            .finally(() => dispatch(setInnerLoaderFalse()))
    }, [dispatch]);

    useEffect(() => {
        dispatch(setInnerLoaderTrue());
        const controller = new AbortController();
        let signal = controller.signal;

        const pcsoFullUrl = `${rootUrl}/lottery/result-year/`;

        // fetch(`${rootUrl}/lottery/result-year-month/${origin}`, {
        fetch(`${pcsoFullUrl}`, {
            signal,
        })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                // console.log(data);
                if (data.length > 0) {
                    let currData = data.reverse();
                    setTimePeriod(currData);
                    setSelectedMonthYear({
                        year: currData[0].year,
                        month: currData[0].month[0]
                    })

                    getFilteredData(currData[0].year, currData[0].month[0]);
                    setMonthListForSelectedYear(currData[0].month);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => dispatch(setInnerLoaderFalse()))

        return (() => {
            controller.abort();
        })

    }, [dispatch, getFilteredData]);

    return (
        <div className='pcsoResults p-3 mb-1'>

            <div className="filter-area rounded">
                <div className="row g-0 justify-content-between">
                    <select className="col rounded form-select" value={selectedMonthYear.year} onChange={handleYearChange}>
                        {timePeriod?.map(item => <option key={item?.year} value={item?.year}>{item?.year}</option>)}
                    </select>

                    <select className="col rounded ms-2 form-select" value={selectedMonthYear.month} onChange={handleMonthChange}>
                        {monthListForSelectedYear &&
                            monthListForSelectedYear?.map((item, index) => <option key={index} value={item}>{months[item - 1]}</option>)
                        }
                    </select>

                    <button
                        className="col py-1 rounded px-2 ms-2"
                        onClick={() => getFilteredData(selectedMonthYear.year, selectedMonthYear.month)}
                        style={{ backgroundImage: "linear-gradient(90deg, #DD4BEC, #5A7CF7)", color: "#fff", border: "none", outline: "none", }}
                    >
                        <Trans i18nKey="searchButton">Search</Trans>
                    </button>
                </div>
            </div>

            {
                lotteryResults && active &&
                lotteryResults?.map((item) =>
                    <div key={item?.day} onClick={() => setActive(item)}>
                        <PcsoResultAccordion item={item} active={active} setActive={setActive} />
                    </div>)
            }
        </div>
    );
};

export default PcsoResults;