import React, { useEffect, useState } from 'react';
// import CustomSectionTitle from '../../../Components/CustomSectionTitle/CustomSectionTitle';
import useBankingActivities from '../../../Hooks/useBankingActivities';
import useTime from '../../../Hooks/useTime';
import Header from '../../../Components/Header/Header';
import { Trans } from 'react-i18next';

const ReferralPage = () => {
    const { transactionHistoryList, getReferralHistory } = useBankingActivities();
    const { convertDate, } = useTime();
    const [visibleRow, setVisibleRow] = useState(0);

    useEffect(() => {
        getReferralHistory();
        setVisibleRow(0);
    }, [getReferralHistory]);

    const increaseVisibleDataIndex = () => {
        setVisibleRow(visibleRow + 10)
    }

    const decreaseVisibleDataIndex = () => {
        setVisibleRow(visibleRow - 10)
    }

    return (
        <div className="inner-scroll bankingProfile">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="referralPage">Referral</Trans>} />
            </div>

            {/* <div className='mx-4 my-4'>
                <CustomSectionTitle title={"My Referral"} page={"referral"} />
            </div> */}
            <div className='mx-4 my-4 transactionHistoryTable'>
                {
                    transactionHistoryList?.length === 0 ?
                        <div className="d-flex justify-content-center align-items-center history-blurred-text my-5" >
                            <h3 className="mb-0">No Records</h3>
                        </div>
                        :
                        <>
                            <table className="table table-bordered border-golden">
                                <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                                    <tr>
                                        <th className='text-center' scope="col">SL.</th>
                                        <th className='text-center' scope="col">Date</th>
                                        <th className='text-center' scope="col">Username</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        transactionHistoryList?.length > 0 &&
                                        transactionHistoryList?.slice(visibleRow, visibleRow + 10)?.map((item, index) =>
                                            <tr key={item?.id}>
                                                <th className='text-center' scope="row">{transactionHistoryList?.indexOf(item) + 1}</th>
                                                <td className='text-center'> {convertDate(item.created_at)}</td>
                                                <td className='text-center'> {item?.user?.username}</td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                            <div className='d-flex justify-content-end mb-3'>
                                <div>
                                    <button onClick={decreaseVisibleDataIndex} className={visibleRow === 0 ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow === 0 ? true : false}><Trans i18nKey="prev">PREV.</Trans></button>
                                    <button onClick={increaseVisibleDataIndex} className={visibleRow + 10 >= transactionHistoryList?.length ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow + 10 >= transactionHistoryList?.length ? true : false}><Trans i18nKey="next">NEXT</Trans></button>
                                </div>
                            </div>
                        </>
                }

            </div>
        </div>
    );
};

export default ReferralPage;