import React from 'react';
import './SlidingNavbar.css';
import { useDispatch } from 'react-redux';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { t } from 'i18next';
// import { BsBroadcast } from 'react-icons/bs';
// import { useSportsExchange } from '../../Hooks/useSportsExchange';
// import { userDetail } from '../../Redux/userSlice';
// import { mother_site } from '../../App';
// import { useNavigate } from 'react-router-dom';
// import { useSportLauncher } from '../../Hooks/useSportLauncer';

const SlidingNavbar = ({ navbarData, selectedFragmentId, setSelectedFragmentID, pageName, }) => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    // const user = useSelector(userDetail);

    // const { exchangeLaunchFunction } = useSportsExchange();
    // const { sportLaunchFunction } = useSportLauncher();

    // const selectedItem = useSelector(state => state.sportsExchange.selectedItem);
    // console.log(selectedFragmentId, navbarData, selectedItem?.gameList);

    // const itemLengthCount = (item) => {
    //     let eventLength = 0;
    //     if (item?.length > 0) {
    //         for (let i = 0; i < item?.length; i++) {
    //             eventLength += item[i]?.gameList?.length;
    //         }
    //         return eventLength;
    //     }
    //     return 0;
    // }

    return (
        <div className='mt-0 mb-0 slidingNavbar'>
            <div className='w-100 d-flex justify-content-start align-items-center py-1' style={{ backgroundColor: '#1F1F1F', color: '#fff' }}>
                <div style={{ overflowX: "auto", overflowY: 'hidden', whiteSpace: 'nowrap', scrollbarWidth: 0, }}>
                    <div className={pageName === "Home" ? "hstack gap-0" : pageName === "Sports Exchange" && "hstack gap-2"}>
                        {
                            navbarData?.map((item) =>
                                (item.id !== 0) &&
                                <div key={item?.id}
                                    className="exchange-item-counter"
                                >
                                    <div
                                        className={item?.id === selectedFragmentId ? "nav-active" : "nav-link"}
                                        onClick={() => {

                                            // if (((item?.name === "exchangeNav") || (item?.name === "sportsNav")) && (!user?.is_login)) {
                                            //     console.log(item.name);
                                            //     navigate("/login");
                                            // } else if ((item?.name === "exchangeNav") && (user?.is_login)) {
                                            //     exchangeLaunchFunction(1);
                                            // }
                                            // else if ((item?.name === "sportsNav") && (user?.is_login)) {
                                            //     sportLaunchFunction();
                                            // }
                                            // else {
                                            dispatch(setInnerLoaderTrue());
                                            setSelectedFragmentID(item?.id);
                                            // }

                                            const timer = setTimeout(() => {
                                                dispatch(setInnerLoaderFalse());
                                            }, 2000);
                                            return () => clearTimeout(timer);
                                        }}>
                                        <div className={"d-flex flex-column justify-content-center align-items-center m-0 p-0 text-center"}>
                                            <img src={item?.icon1} alt={t(item?.name)} />
                                            <p>{t(item?.name)}</p>

                                        </div>
                                    </div>
                                    {/* <div className="">
                                        <span className={((pageName === "Sports Exchange") && (selectedItem?.navName === "In-Play")) ? "rounded-pill pill-btn" : "d-none"}>
                                            <BsBroadcast fill={"red"} size={"10"} className="w-50 blink-image" style={{}} />
                                            <span className="w-50 rounded-pill" style={{ backgroundColor: "red", }}>
                                                {(pageName === "Sports Exchange") && ((item?.name === "CRICKET") ? itemLengthCount(selectedItem?.gameList?.cricket) : (item?.name === "SOCCER") ? itemLengthCount(selectedItem?.gameList?.soccer) : (item?.name === "TENNIS") ? itemLengthCount(selectedItem?.gameList?.tennis) : (item?.name === "ALL") ? (itemLengthCount(selectedItem?.gameList?.cricket) + itemLengthCount(selectedItem?.gameList?.soccer) + itemLengthCount(selectedItem?.gameList?.tennis)) : "")}
                                            </span>
                                        </span>
                                    </div> */}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SlidingNavbar;