import React, { useEffect, useState } from 'react';
import './Registration.css';
import { AiFillLeftCircle, AiOutlineGlobal } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import { MdLocalPhone } from "react-icons/md";
import { IoIosLock } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import CustomInputField from '../../Components/CustomInputField/CustomInputField';
import { useCountry } from '../../Hooks/useCountry';
import { rootUrl, origin, originNameOnly } from '../../App';
import { useDispatch } from 'react-redux';
import { showCustomAlert } from '../../Redux/alertSlice';
import { setRegistrationData } from '../../Redux/userSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { HiMail } from 'react-icons/hi';

const Registration = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { countries } = useCountry();
    const initialState = {
        // first_name: '',
        // last_name: '',
        username: '',
        email: '',
        country_name: '',
        contact: '',
        // dob: '',
        // gender: "Male",
        password: '',
        referral: localStorage.getItem("ref") || "",
        request_origin: originNameOnly
    };
    const [regFormData, setRegFormData] = useState(initialState);
    const [acceptTermCondition, setAcceptTermCondition] = useState(false);
    const [selectCountry, setSelectCountry] = useState(null);
    const [userAlert, setUserAlert] = useState(null);
    const [referralAlert, setReferralAlert] = useState("");
    const [verifiedEmailError, setVerifiedEmailError] = useState(null);

    const checkExistData = async (e) => {

        if (e.target.name === "username") {
            let usernameLen = (e.target.value).length;
            if (usernameLen < 3) {
                setUserAlert(null);
                return;
            }
        }

        if (e.target.name === "email") {
            let emailLen = (e.target.value).length;
            if (emailLen < 3) {
                setVerifiedEmailError(null);
                return;
            }
        }

        dispatch(setInnerLoaderTrue());
        let url = `${rootUrl}${'/auth/check_user/'}${origin}`;

        let tempData = {};

        if (e.target.name === "username") {
            tempData.username = `${e.target.value}`;
        }
        if (e.target.name === "email") {
            tempData.email = `${e.target.value}`;
        }
        tempData.request_origin = originNameOnly;

        let options = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ...tempData
            })
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) throw response;
            const data = await response.json();

            if (!data.success && e.target.name === "username") {
                setUserAlert(data?.error);
            }
            if (data.success && e.target.name === "username") {
                setUserAlert(null);
            }
            if (!data.success && e.target.name === "email") {
                setVerifiedEmailError(data?.error);
            }
            if (data.success && e.target.name === "email") {
                setVerifiedEmailError(null);
            }
        } catch (error) {
            console.log(error);
        } finally {
            dispatch(setInnerLoaderFalse());
        }

        // if (e.target.name === "email" && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
        //     options = {
        //         method: 'POST',
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //             email: `${e.target.value}`,
        //             request_origin: 'betkingly',
        //         })
        //     }
        // }
    }

    const checkReferralCode = async (e) => {
        if (e.target.value === "") {
            return;
        }
        dispatch(setInnerLoaderTrue());
        let url = `${rootUrl}${'/auth/check_user/'}${origin}`;

        let options = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                referral: `${e.target.value}`,
                request_origin: originNameOnly,
            })
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) throw response;
            const data = await response.json();
            // console.log(data);

            if (!data.success) {
                setReferralAlert("doesn't exist");
            } else {
                setReferralAlert("");
            }

        } catch (error) {
            // console.log(error);
            setReferralAlert("doesn't exist");
        } finally {
            dispatch(setInnerLoaderFalse());
        }
    }

    // const checkDOB = (e) => {
    //     let currDate = new Date();
    //     let dob = new Date(regFormData.dob);
    //     var Difference_In_Time = currDate.getTime() - dob.getTime();
    //     let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    //     return parseInt(Difference_In_Days / 365);
    // }

    const updateRegFormData = e => {
        let createUser = { ...regFormData };
        if (e.target.name === 'referral') {
            setReferralAlert("");
        }

        if (e.target.name === 'email' || e.target.name === 'username' || e.target.name === 'password') {
            let list = (e.target.value).split(' ');
            if (list.length > 1) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Space is not allowed`
                }));
                return;
            } else {
                if (e.target.name === 'email' || e.target.name === 'username') {
                    createUser[e.target.name] = e.target.value.toLowerCase();
                } else {
                    createUser[e.target.name] = e.target.value;
                }
                setRegFormData(createUser);
            }
        } else {
            createUser[e.target.name] = e.target.value;
            setRegFormData(createUser);
        }
    }

    const updateCountry = (e) => {
        const findCountry = countries.find(item => item.country_name === e.target.value);
        setSelectCountry(findCountry);
        updateRegFormData(e);
    }

    const checkAllRegFormData = async (e) => {

        e.preventDefault();
        // let age = checkDOB();

        if ((regFormData.password).length < 6) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Password should be minimum 6 characters`
            }));
            return;
        }

        if (referralAlert === "doesn't exist") {
            dispatch(showCustomAlert({
                type: "error",
                message: `Referral Code Not Acceptable.`
            }));
            return;
        }

        if (!((/^\b[-a-zA-Z0-9_]{4,}$\b/.test(regFormData?.username)) && !userAlert)) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Username Not Acceptable.`
            }));
            return;
        }

        if (!acceptTermCondition) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Please accept the terms and conditions.`
            }));
            return;
        }

        if (userAlert !== null) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Username Already Exist`
            }));
            return;
        }

        if (verifiedEmailError !== null) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Email Already Exist`
            }));
            return;
        }

        // if (age < 18) {
        //     dispatch(showCustomAlert({
        //         type: "error",
        //         message: `Hay Bro You Are A Child !!! `
        //     }));
        //     return;
        // }

        // dispatch(setRegistrationData(regFormData));
        // e.target.reset();
        // setAcceptTermCondition(false);
        // setRegFormData(initialState);
        // navigate('/regMailConfirm');

        let parentUrl = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

        let url_val = parentUrl.split('/');
        let make_url = url_val[0] + '//' + url_val[2] + '/';
        if (make_url === 'https://betkingly.com/' || make_url === 'http://localhost:3000/') {
            make_url = make_url + "";
        }

        try {
            let url = `${rootUrl}${'/auth/request-otp/'}${origin}`;

            let newData = {
                ...regFormData,
                request_origin: originNameOnly,
                registration_link: make_url,
            };

            let options = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newData)
            }
            // console.log("options", options);

            const response = await fetch(url, options);
            if (!response.ok) throw response;

            const data = await response.json();
            // console.log(data);
            if (data?.status === "ok") {
                // dispatch(setRegistrationData(regFormData));
                // e.target.reset();
                // setAcceptTermCondition(false);
                // setRegFormData(initialState);

                dispatch(setRegistrationData(regFormData));
                e.target.reset();
                navigate('/regMailVerify');
                dispatch(setInnerLoaderFalse());
            }

        } catch (error) {
            dispatch(setInnerLoaderFalse());
            let errorData = await error.json();
            // console.log(errorData);

            if (errorData?.error) {
                console.log("errorData", errorData);
            } else if (errorData?.detail === "Invalid token.") {
                console.log("errorData", errorData);
            } else {
                console.log("error", error);
            }
        }
    }

    useEffect(() => {
        if (countries) {
            setSelectCountry(countries[0]);
            setRegFormData(prev => ({ ...prev, "country_name": countries[0].country_name }));
        }
    }, [countries])

    useEffect(() => {
        dispatch(setRegistrationData(regFormData));
    }, [dispatch, regFormData])

    return (
        <div className='inner-scroll registration'>
            <div className='position-relative'>
                <h3 className="text-center p-3"><Trans i18nKey="registration">REGISTRATION</Trans></h3>
                <div className='position-absolute' style={{ top: "12px", left: '10px' }}>
                    <AiFillLeftCircle size='35' color='var(--yellow)' onClick={() => { navigate(-1) }} style={{ cursor: "pointer", }} />
                </div>
            </div>

            <div className='mx-4 mb-4'>
                <form className="row g-0" onSubmit={checkAllRegFormData}>
                    {/* <CustomInputField type="text" name="first_name" label="First Name" langLabel={<Trans i18nKey="first_nameRegistrationLangLabel" />} langPlaceHolder={t('first_nameRegistrationLangLabel')} value={regFormData.first_name} func={updateRegFormData} icon1={false} icon2={<FaUserAlt />} /> */}
                    {/* <CustomInputField type="text" name="last_name" label="Last Name" langLabel={<Trans i18nKey="last_nameRegistrationLangLabel" />} langPlaceHolder={t('last_nameRegistrationLangLabel')} value={regFormData.last_name} func={updateRegFormData} icon1={false} icon2={<FaUserAlt />} /> */}
                    <CustomInputField type="text" name="username" label="Username" langLabel={<Trans i18nKey="usernameRegistrationLangLabel" />} langPlaceHolder={t('usernameRegistrationLangLabel')} value={regFormData.username} func={updateRegFormData} icon1={false} icon2={<FaUserAlt />} errorSms={userAlert} checkExist={checkExistData} />
                    {/* <div className="custom-input">
                        <div className="module my-2">
                            <label className="" htmlFor="gender">{<Trans i18nKey="genderRegistrationLangLabel" />}</label>
                            <div className="input-group">
                                <select onChange={updateRegFormData} name="gender" id="gender" value={regFormData.gender} className="form-select">
                                    <option value={"Male"}>Male</option>
                                    <option value={"Female"}>Female</option>
                                    <option value={"Other"}>Other</option>
                                </select>
                                <span className="input-group-text">
                                    <FaUsers />
                                </span>
                            </div>
                        </div>
                    </div> */}

                    <div className="custom-input">
                        <div className="module my-2">
                            <label className="" htmlFor="country_name">{<Trans i18nKey="country_nameRegistrationLangLabel" />}</label>
                            <div className="input-group">
                                {
                                    selectCountry &&
                                    <span className="input-group-text">
                                        <img src={`${rootUrl}${selectCountry?.country_flag_icon}`} alt="Img" className='countryIconStyle' />
                                    </span>
                                }

                                <select onChange={updateCountry} name="country_name" id="country_name" value={regFormData.country_name} className="form-select">
                                    {
                                        countries &&
                                        countries.map(country =>
                                            <option key={country.id} value={country.country_name}>{country.country_name}</option>
                                        )
                                    }
                                </select>

                                <span className="input-group-text"> <AiOutlineGlobal /> </span>
                            </div>
                        </div>
                    </div>

                    <CustomInputField type="number" name="contact" label="Mobile Number" langLabel={<Trans i18nKey="contactRegistrationLangLabel" />} langPlaceHolder={t('contactRegistrationLangLabel')} value={regFormData.contact} func={updateRegFormData} icon1={selectCountry?.country_phone_code} icon2={<MdLocalPhone />} />

                    {/* <div className="custom-input">
                        <div className="module my-2">
                            <label className="">{<Trans i18nKey="dobRegistrationLangLabel" />}</label>
                            <div className="input-group">
                                <input
                                    type="date"
                                    name="dob"
                                    // value={regFormData.dob}
                                    className="form-control"
                                    title="Date of Birth"
                                    // max={new Date().getDate}
                                    onChange={updateRegFormData}
                                    required
                                />
                            </div>
                        </div>
                    </div> */}

                    <div className="custom-input">
                        <div className="module my-2">
                            <label className="">{t('referralRegistrationLangLabel')}</label>
                            {
                                (referralAlert === "Email Already Exist") ? <small className="">{referralAlert}</small> : ""
                            }

                            <small className="">{referralAlert}</small>

                            <div className="input-group">
                                <input
                                    type="text"
                                    name="referral"
                                    value={localStorage.getItem("ref") || regFormData.referral}
                                    className="form-control"
                                    aria-label="Referral Code"
                                    title="Referral Code"
                                    onChange={updateRegFormData}
                                    onBlur={checkReferralCode}
                                    placeholder="xxxx:xxxxxx"
                                    disabled={localStorage.getItem("ref") ? true : false}
                                />
                                {/* <span className="input-group-text"><FaUserAlt /></span> */}
                            </div>
                        </div>
                    </div>

                    <CustomInputField type="password" name="password" label="Password" langLabel={<Trans i18nKey="passwordRegistrationLangLabel" />} langPlaceHolder={t('passwordRegistrationLangLabel')} value={regFormData.password} func={updateRegFormData} icon1={false} icon2={<IoIosLock />} />

                    <div className="custom-input">
                        <div className="module my-2">
                            <label className="">Email</label>
                            {
                                (verifiedEmailError) ? <small className="">{verifiedEmailError}</small> : ""
                            }
                            <div className="input-group">
                                <input
                                    type="text"
                                    name="email"
                                    value={regFormData?.email}
                                    className="form-control"
                                    aria-label="Email"
                                    title="Email"
                                    onChange={updateRegFormData}
                                    onBlur={checkExistData}
                                    placeholder="yourmail@example.com"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    required
                                />
                                <span className="input-group-text"><HiMail /></span>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={acceptTermCondition} id="flexCheckDefault" onChange={e => setAcceptTermCondition(e.target.checked)} style={{ cursor: "pointer", }} />
                            <label className="form-check-label" htmlFor="flexCheckDefault" style={{ cursor: "pointer", }}><Trans i18nKey="acceptTC">I Accept Terms and Conditions</Trans></label>
                        </div>
                    </div>

                    <button type='submit' className="btn btn-yellow my-3"><Trans i18nKey="verifyemailbutton">Verify Email</Trans></button>
                </form>

                <p className='text-center termsArea'>
                    By clicking this button you confirm that you have read and agree to
                    the <Link to='/terms-conditions'>Terms and Conditions</Link> and <Link to='/terms-conditions'>Privacy Policy</Link> of the company and
                    confirm that you are of legal age
                </p>

                <div className='row g-0'>
                    <button onClick={() => navigate("/login")} type="button" className="btn btn-white mt-2"><Trans i18nKey="alreadyhaveanaccountbutton">Already have an account?</Trans></button>
                </div>
            </div>

        </div>
    );
};

export default Registration;