import React, { useState } from 'react';
import './QRMethodDeposit.css';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import CustomInputField from '../../../../Components/CustomInputField/CustomInputField';
import { FaRegCopy } from 'react-icons/fa';
import useCopyToClipboard from '../../../../Hooks/useCopyToClipboard';
import TransactionReport from '../../../../Components/TransactionReport/TransactionReport';
import { useDispatch, useSelector } from 'react-redux';
import { gatewayItemData } from '../../../../Redux/bankingTransactionSlice';
import { showCustomAlert } from '../../../../Redux/alertSlice';
import { origin, rootUrl } from '../../../../App';
import { fetchLoginUserWallet, fetchLogoutUser, userDetail } from '../../../../Redux/userSlice';
import { useNavigate } from 'react-router-dom';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import Header from '../../../../Components/Header/Header';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const QRMethodDeposit = () => {

    const copy = useCopyToClipboard();
    const [payInput, setPayInput] = useState();
    const [walletTID, setWalletTID] = useState();
    const [walletAddress, setWalletAddress] = useState();
    const [qrDepositSteps, setQRDepositSteps] = useState(1);
    const [bankReceiptImg, setBankReceiptImg] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);
    const gatewayItem = useSelector(gatewayItemData);
    const [success, setSuccess] = useState({});

    const inputPic = (e) => {
        const newFile = e.target.files[0];
        if (((e.target.files[0].type).split('/')[0]) === 'image') {
            setBankReceiptImg(newFile);
        } else {
            alert("select an image file")
        }
    }

    const qrDepositFunction = () => {
        // let confirmData = {
        //     payment_gateway: gatewayItem?.gateway_name,
        //     receiver: userInfo?.user?.id,
        //     amount: +payInput,
        //     transaction_id: walletTID,
        //     payer_account: walletAddress,
        //     screenshot: bankReceiptImg
        // };
        // console.log(confirmData);

        const formData = new FormData();
        formData.append("request_origin", "betkingly");
        formData.append("gateway_title", gatewayItem?.gateway_title);
        formData.append("payment_gateway", gatewayItem?.gateway_name);
        formData.append("receiver", userInfo?.user?.id);
        formData.append("amount", +payInput);
        formData.append("transaction_id", walletTID);
        formData.append("payer_account", walletAddress);
        formData.append("gateway_id", gatewayItem.id);
        if (bankReceiptImg) {
            formData.append("screenshot", bankReceiptImg);
        }

        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            if (walletAddress && walletTID && +payInput > 0 && userInfo?.user?.id) {
                dispatch(setInnerLoaderTrue());
                fetch(`${rootUrl}/wallet/deposit/${origin}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${value.value}`,
                    },
                    body: formData
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        // console.log(data);
                        setSuccess(data);
                        setQRDepositSteps(4);
                        setBankReceiptImg(null);
                        dispatch(fetchLoginUserWallet());
                    })
                    .catch(err => {
                        // console.log(err);
                        setPayInput();
                        setQRDepositSteps(1);
                        err.json().then(text => {
                            if (text?.transaction_id) {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${"transaction id already exists."}`,
                                }));
                                return;
                            }
                            if (text?.error) {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${text?.error}`,
                                }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                return;
                            }
                        });
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${err?.statusText}`,
                        }));
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse());
                    })
            }
            else {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `All Field Required`,
                }));
            }
        }
    }

    const baseDomain = userInfo?.user?.user_base_origin;

    const aPayDepositFunction = () => {

        const payload = {
            currency: gatewayItem.gateway_username,
            return_url: baseDomain + `deposit`
        }

        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);

            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/wallet/create-apay-deposit/${origin}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value?.value}`,
                },
                body: JSON.stringify(payload)
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.success) {
                        window.open(data?.url, "_parent");
                    }
                })
                .catch(err => {
                    console.log(err);

                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`,
                    }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse());
                })
        }
    };

    return (
        <div className="inner-scroll qr-deposit">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="qrDepositPage">QR Deposit</Trans>} />
            </div>
            <div className={(qrDepositSteps === 4) ? "d-none" : 'mx-4 my-4'}>
                <CustomSectionTitle title={`${t('depositStep')} ${qrDepositSteps}`} page={"qrDeposit"} />
            </div>
            <div className='mx-4 my-4'>
                <div className={(qrDepositSteps === 4) ? "d-none" : 'd-flex flex-column align-items-center justify-content-center'}>
                    <div className='row g-0'>
                        <div className="d-flex flex-column align-items-center justify-content-between">
                            <img src={gatewayItem?.gateway_logo} className="" height="50px" alt={gatewayItem?.gateway_name || "gateway"} />
                            <p className='text-center fw-bold m-0 p-0 mt-2' style={{ fontSize: "12px", color: "#000", fontFamily: "Roboto", }}>
                                <small>***Deposit time online getaway instant.</small>
                                <br />
                                <small>***Deposit request within 10 to 20 minutes.</small>
                                <br />
                                <small>***Only transfer don't call any requests getaway if you call block your account.</small>
                            </p>
                        </div>
                    </div>
                </div>

                {
                    qrDepositSteps === 1 &&
                    <div className='my-4' style={{ borderTop: "2px dashed #C2C2C2" }}>
                        <div className="row g-0 mt-4 mx-4">
                            <CustomInputField type="number" name="amountInput" label="Enter Your Amount" langLabel={<Trans i18nKey="amountInputDepositLangLabel" />} langPlaceHolder={t('amountInputDepositLangLabel')} value={payInput} func={(e) => setPayInput(e.target.value)} icon1={false} icon2={false} />
                        </div>
                        <div className='row g-0 mx-4' style={{ color: "red" }}>
                            <i>
                                <small style={{ fontSize: "10px" }}>
                                    *** Deposit Amount limit : <span className='fw-bold'>{gatewayItem?.min_deposit_limit || "00.00"} to {gatewayItem?.max_deposit_limit || "00.00"}</span>
                                </small>
                            </i>

                            <i>
                                <small style={{ fontSize: "10px" }}>
                                    *** Exchange rate : <span className='fw-bold'>{gatewayItem?.currency_rate || "1.00"}</span>
                                </small>
                            </i>
                        </div>


                        <div className='row g-0 my-4'>
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={() => {
                                    if (+payInput >= +gatewayItem?.min_deposit_limit && +payInput <= +gatewayItem?.max_deposit_limit) {
                                        if (gatewayItem?.gateway_name === "perfect_money") {
                                            navigate(`/prefectMoney/${payInput}/${gatewayItem?.id}/30.12.98`);
                                        } else if (gatewayItem?.gateway_name === "a_pay") {
                                            aPayDepositFunction();
                                        }
                                        else {
                                            setQRDepositSteps(2);
                                        }
                                    } else {
                                        dispatch(showCustomAlert({
                                            type: "error",
                                            message: `Deposit Limit : ${gatewayItem?.min_deposit_limit} to ${gatewayItem?.max_deposit_limit}`
                                        }));
                                    }
                                }} className="btn btn-yellow btn-sm w-50">{<Trans i18nKey="next" />}</button>
                            </div>
                        </div>
                    </div>
                }

                {
                    qrDepositSteps === 2 &&
                    <div className='my-4' style={{ borderTop: "2px dashed #C2C2C2" }}>
                        <div className="row g-0 mt-4 mx-4">
                            <div className='d-flex align-items-center justify-content-center'>
                                <img style={{ width: "220px", border: "2px solid gray", borderRadius: "5px" }} src={gatewayItem?.gateway_image} alt="QR code payment gateway" />
                            </div>
                        </div>

                        <div className="row g-0 mt-4 mx-4">
                            <div className="input-group">
                                <div className="input-group">
                                    <textarea style={{ position: "relative", background: "#ffffff", height: "100px", }} value={gatewayItem.gateway_id} type="text" name="QRDepositGatewayID" id="QRDepositGatewayID" className="form-control" row="3" placeholder="Account Details" disabled />
                                    {
                                        <label style={{ position: "absolute", top: '-12px', left: "15px", background: "#ffffff", color: "#000000", zIndex: 10, fontWeight: "600", fontSize: "14px" }} htmlFor="QRDepositGatewayID">{t('QRDepositGatewayIDDepositLangLabel')}</label>
                                    }
                                    <span className="input-group-text">
                                        <FaRegCopy style={{ cursor: "pointer" }} onClick={() => copy(`${gatewayItem?.gateway_id}`)} />
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className='row g-0 my-4'>
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={() => setQRDepositSteps(3)} className="btn btn-yellow btn-sm w-50">{<Trans i18nKey="next" />}</button>
                            </div>
                        </div>
                    </div>
                }

                {
                    qrDepositSteps === 3 &&
                    <div className='my-4' style={{ borderTop: "2px dashed #C2C2C2" }}>
                        <div className="my-4 mx-4">
                            <CustomInputField type="text" name="walletTID" label="Enter Your Transaction ID" langLabel={<Trans i18nKey="walletTIDDepositLangLabel" />} langPlaceHolder={t('walletTIDDepositLangLabel')} value={walletTID} func={(e) => setWalletTID(e.target.value)} icon1={false} icon2={false} />
                            {/* <CustomInputWhite labelName="Enter Your Transaction ID" idName="binanceTID" func={(e) => setBinanceTID(e.target.value)} value={binanceTID}></CustomInputWhite> */}
                            <CustomInputField type="text" name="walletAddress" label="Enter Sender Account" langLabel={<Trans i18nKey="walletAddressDepositLangLabel" />} langPlaceHolder={t('walletAddressDepositLangLabel')} value={walletAddress} func={(e) => setWalletAddress(e.target.value)} icon1={false} icon2={false} />
                            {/* <CustomInputWhite labelName="Your Account/Wallet Address" idName="walletAddress" value={walletAddress} func={(e) => setWalletAddress(e.target.value)}></CustomInputWhite> */}
                        </div>

                        <div className='row g-0 mx-4 text-center'>
                            <p>{t('referenceImageOptionalTextDeposit')}</p>
                        </div>


                        {
                            bankReceiptImg ?
                                <div className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='imgModalContainer'>

                                            <div className="modalImage">
                                                <img src={`${URL.createObjectURL(bankReceiptImg)}`} alt="UserImg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='row g-0'>
                                    <div className='d-flex justify-content-center'>
                                        <input className='d-none' type="file" id="profilePic" onChange={inputPic} />
                                        <label htmlFor='profilePic'>
                                            <div className='btn btn-primary'>
                                                <AiOutlineCloudUpload style={{ fontSize: "30px", marginRight: "10px" }} />
                                                <span>{t('uploadScreenShootButton')}</span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                        }



                        <div className='row g-0 my-4'>
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={() => {
                                    // setQRDepositSteps(4);
                                    // binancePaymentFunc();
                                    qrDepositFunction();
                                }} className="btn btn-yellow btn-sm w-50">{<Trans i18nKey="continueButton" />}</button>
                            </div>
                        </div>
                    </div>
                }

                {
                    (qrDepositSteps === 4 && success?.id) &&
                    <div className='my-4'>
                        {/* <div className="row g-0 mx-4 text-center fw-bold">
                            <p className="" style={{ color: "var(--green)" }}>Successful Your Deposit Request</p>
                        </div>
                        <div className="row g-0 mx-4 d-flex align-items-center justify-content-center text-center fw-bold">
                            <p className="py-4 rounded-4 me" style={{ backgroundImage: "linear-gradient(to right,#D1C026, #EBFF00)", fontSize: "40px", }}>$400.00
                                <BsPatchCheckFill className="mb-2 ms-2" size={30} color={"var(--green)"} />
                            </p>
                        </div> */}
                        <TransactionReport report={t('successfulDepositRequest')} amount={success?.amount || "00.00"} />

                        <div className='row g-0 my-4'>
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={() => setQRDepositSteps(1)} className="btn btn-yellow btn-sm w-50">{t('checkStatusButton')}</button>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
};

export default QRMethodDeposit;