import React, { useState } from 'react';
import { origin, originNameOnly, rootUrl } from '../../../App';
import { fetchLogoutUser } from '../../../Redux/userSlice';
import useTime from '../../../Hooks/useTime';
import { useDispatch } from 'react-redux';
import { BsExclamationCircle } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { ImCross } from 'react-icons/im';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import { t } from 'i18next';

const WithdrawalTable = ({ dataList, visibleRow, getTransactionHistory, user }) => {
    const dispatch = useDispatch();
    const { convertDate, convertTime } = useTime();

    const [withdrawalHistoryModal, setWithdrawalHistoryModal] = useState({
        visible: false,
        item: {},
    });
    // console.log(withdrawalHistoryModal);

    const openWithdrawalHistoryModal = (item) => {
        setWithdrawalHistoryModal({
            visible: true,
            item,
        });
    }

    const closeWithdrawalHistoryModal = () => {
        setWithdrawalHistoryModal({
            visible: false,
            item: {},
        });
    }

    const cancelWithdrawal = () => {
        let fakeData = {
            transaction_id: withdrawalHistoryModal.item.trx_id,
            request: "cancel",
            request_origin: originNameOnly,
        }
        // console.log(fakeData);
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());
            fetch(`${rootUrl}/wallet/withdrawal/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
                body: JSON.stringify(fakeData)
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    if (data.status === "cancell") {
                        closeWithdrawalHistoryModal();
                        // dispatch(setCustomAlertTrue({ massage: "Transaction Canceled", type: 'success' }));
                        getTransactionHistory();
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            // dispatch(setCustomAlertTrue({ massage: `${text?.detail}`, type: 'danger' }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse())
                })
        }
    }
    return (
        <>
            {
                (dataList?.length === 0) ?
                    // dataless view
                    <div className="d-flex justify-content-center align-items-center mt-5 history-blurred-text text-center" >
                        <h3 className="mb-0">No Records</h3>
                    </div> :
                    <table className="table table-bordered border-golden">
                        <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                            <tr>
                                {/* <th className='text-center' scope="col">SL.</th> */}
                                <th className='text-center' scope="col">Date</th>
                                <th className='text-center' scope="col">Description</th>
                                <th className='text-center' scope="col">Amount</th>
                                <th className='text-center' scope="col">Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataList &&
                                dataList?.slice(visibleRow, visibleRow + 10)?.map((item, index) =>
                                    <tr key={item?.id || index}>
                                        {/* <th className='text-center' scope="row">{dataList?.indexOf(item) + 1}</th> */}
                                        <td className='text-center'> {convertDate(item.created_at || item.transaction_date)}</td>
                                        <td style={{ color: "#c30010", fontWeight: "bold", textAlign: "center" }}>Withdrawal</td>
                                        <td style={{ color: "#c30010", fontWeight: "bold", textAlign: "center" }}>- ${item.amount}</td>
                                        {/* <td style={{ color: "#c30010", fontWeight: "bold", textAlign: "center" }}>- ${item.senders_transaction_amount}</td> */}
                                        <td>
                                            <div
                                                className='text-center'
                                                style={{ cursor: "pointer" }}
                                                onClick={() => openWithdrawalHistoryModal(item)}
                                            >
                                                <BsExclamationCircle size={20} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </table>
            }

            <Modal className='transactionModal' fullscreen={false} show={withdrawalHistoryModal.visible}>
                <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                    <div className='w-100'>
                        <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                            <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                <button onClick={closeWithdrawalHistoryModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "16%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>

                                <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{t('transactionDetailsModalTitle')}</span>

                                <button onClick={closeWithdrawalHistoryModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>
                            </div>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <p style={{ color: "#c30010", fontWeight: "bold", textAlign: "center", fontSize: "30px", margin: 0 }}>- ${withdrawalHistoryModal.item.withdraw_amount}</p>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className='me-2'>Withdrawal to: </div>
                                    <div> {withdrawalHistoryModal.item.gateway_title ? withdrawalHistoryModal.item.gateway_title : withdrawalHistoryModal.item.payment_gateway}</div>
                                </div>
                            </div>
                        </div>

                        <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Transaction Date:</div>
                                    {
                                        withdrawalHistoryModal.item.created_at ?
                                            <div>{convertDate((withdrawalHistoryModal.item).created_at)} <span>{convertTime((withdrawalHistoryModal.item).created_at)}</span></div>
                                            : <div>{convertDate((withdrawalHistoryModal.item).transaction_date)} <span>{convertTime((withdrawalHistoryModal.item).transaction_date)}</span></div>
                                    }
                                </div>
                            </div>

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Transaction fees: ({withdrawalHistoryModal.item.withdrawal_fee_percentage}%)</div>
                                    <div>${withdrawalHistoryModal.item.fee_amount}</div>
                                </div>
                            </div>

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Original amount:</div>
                                    <div>- ${withdrawalHistoryModal.item.amount}</div>
                                </div>
                            </div>

                            {
                                withdrawalHistoryModal?.item?.exchange_amount &&
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Exchange amount:</div>
                                        <div>{withdrawalHistoryModal.item.exchange_amount}</div>
                                    </div>
                                </div>
                            }

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Trx ID:</div>
                                    <div>{withdrawalHistoryModal.item.transaction_id || withdrawalHistoryModal.item.trx_id}</div>
                                </div>
                            </div>



                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div >Status:</div>
                                    <div className="fw-bold" style={{
                                        color: ((withdrawalHistoryModal.item.status === "pending") || (withdrawalHistoryModal.item.status === "busy")) ? "#daaf37" :
                                            withdrawalHistoryModal.item.status === "successful" ? "green" :
                                                withdrawalHistoryModal.item.status === "in_review" ? "orange" :
                                                    withdrawalHistoryModal.item.status === "cancel" ? "red" : ""
                                    }}>
                                        {
                                            ((withdrawalHistoryModal.item.status === "pending") || (withdrawalHistoryModal.item.status === "busy")) ? "Pending" :
                                                withdrawalHistoryModal.item.status === "successful" ? "Success" :
                                                    withdrawalHistoryModal.item.status === "in_review" ? " In-review" :
                                                        withdrawalHistoryModal.item.status === "cancel" ? "Cancel" : ""}</div>
                                </div>
                            </div>

                            {
                                (withdrawalHistoryModal.item?.busy_reason !== "") &&
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className='col-2 fw-bold' style={{ color: "red" }}>Reason :</div>
                                        <div><i>{withdrawalHistoryModal.item?.busy_reason}</i></div>
                                    </div>
                                </div>
                            }

                            {
                                (withdrawalHistoryModal.item?.busy_screenshot) &&
                                <div className='row g-0 m-1 align-items-center justify-content-center'>
                                    <div className="modalImage">
                                        <img src={`${rootUrl}${withdrawalHistoryModal.item?.busy_screenshot}${origin}`} className="img-fluid" alt="busy-ss" />
                                    </div>
                                </div>
                            }

                            {
                                withdrawalHistoryModal.item?.status === "pending" &&
                                <div className='d-flex align-items-center justify-content-center mt-4'><button onClick={cancelWithdrawal} className='btn btn-yellow w-25 py-1'>Cancel</button></div>
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default WithdrawalTable;