import React, { useState } from 'react';

import GameTitle from '../../Components/GameTitle/GameTitle';
import GameCard from '../../Components/GameCard/GameCard';
import ProvidersLeftSideBar from '../../Components/ProvidersLeftSideBar/ProvidersLeftSideBar';

import { leftData } from '../../Data/ProvidersPageData';
import { useProvidersLeftSideBar } from '../../Hooks/useProvidersLeftSideBar';
import useGameData from '../../Hooks/useGameData';

const BoardGame = () => {
    const { leftSideBarData } = useProvidersLeftSideBar();

    const gameCode = "CB";

    const leftBoardGameSideBar = leftSideBarData?.filter((item) => (item?.game_code)?.includes(gameCode));

    // const virtualGames = useSelector(Vir2alGames);
    const [selectedLeftBarData, setSelectedLeftBarData] = useState(leftData[0]);
    const boardGames = useGameData(gameCode, selectedLeftBarData?.provider_code);

    // const showableExtraCards = (selectedLeftBarData?.extra_param === "iframe") ? [selectedLeftBarData] : fishingGames;

    const iframes = leftBoardGameSideBar?.filter((item) => item?.extra_param === "iframe");
    const notIframes = leftBoardGameSideBar?.filter((item) => item?.extra_param !== "iframe");

    const showableExtraCards = (selectedLeftBarData?.name === "All") ? iframes.concat(boardGames) : (selectedLeftBarData?.type === "lobby") ? iframes : boardGames;


    return (
        <section className='row g-0 p-2'>
            {
                leftBoardGameSideBar &&
                <ProvidersLeftSideBar selectedLeftBarData={selectedLeftBarData || leftData[0]} setSelectedLeftBarData={setSelectedLeftBarData} leftSideBarData={[...leftData, ...notIframes]} />
            }
            <div className="game-card-area gx-0 col-10">
                <GameTitle title={"Table"} />
                <GameCard games={showableExtraCards} providerType={gameCode} />
            </div>
        </section>
    );
};

export default BoardGame;