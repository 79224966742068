import React from 'react';
import './CustomAlert.css';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertData, closeCustomAlert } from '../../Redux/alertSlice';
import { MdClose, MdOutlineCancel } from 'react-icons/md';
import { BsCheck2Circle } from 'react-icons/bs';

const CustomAlert = () => {
    const status = useSelector(alertData);
    // const message = useSelector(alertMessage);
    const dispatch = useDispatch();
    // console.log(status);

    return (
        <Modal
            show={status.status}
            onHide={() => dispatch(closeCustomAlert())}
            backdrop="static"
            keyboard={false}
            className="alertModal"
        >
            <Modal.Body
                style={{
                    backgroundColor: `${status.type === 'success' ? '#7DAA2F' : status.type === 'pending' ? '#D4AF37' : "#c30010"}`,
                    borderRadius: 'inherit',
                    padding: '0.2em'
                }}
                onBlur={() => dispatch(closeCustomAlert())}
            >
                <div style={{ height: "50px", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff" }}>
                    <div onClick={() => dispatch(closeCustomAlert())} style={{ backgroundColor: 'transparent', position: "absolute", top: "0", right: "10px", height: "100%", cursor: "pointer" }} className="d-flex align-items-center justify-content-center">
                        <MdClose style={{ fontSize: "22px", color: 'white' }} />
                    </div>
                    <span style={{ fontSize: "16px", textTransform: "capitalize" }}> {status.type === 'error' ? <MdOutlineCancel style={{ fontSize: "24px", marginBottom: "2px" }} /> : <BsCheck2Circle style={{ fontSize: "24px", marginBottom: "2px" }} />} {status.message}</span>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CustomAlert;