import { useEffect, useState } from "react";
import { origin, rootUrl } from "../App";
import { useDispatch, } from "react-redux";
// import { useNavigate } from "react-router-dom";

// import { setGatewayItem } from './../Redux/bankingTransactionSlice';
// import { showCustomAlert } from "../Redux/alertSlice";
import { fetchLogoutUser, } from "../Redux/userSlice";
// import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

export const useBankingTransactions = () => {
    const dispatch = useDispatch();

    const [depositGateways, setDepositGateWays] = useState([]);
    const [withdrawalGateways, setWithdrawalGateWays] = useState([]);

    useEffect(() => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            // for deposit and withdrawal gateways
            // dispatch(setInnerLoaderTrue());

            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/payment/gateway/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value?.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(currData);
                    let depositData = (data.filter(item => item.purpose !== "withdrawal"));
                    let withdrawalData = (data.filter(item => item.purpose !== "deposit"));

                    setDepositGateWays(depositData);
                    setWithdrawalGateWays(withdrawalData);
                    // dispatch(setInnerLoaderFalse());
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse());
                })
        }
    }, [dispatch]);

    return {
        depositGateways,
        withdrawalGateways,
        // getPaymentGateWayDetail,
    };
};