import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { rootUrl } from '../../../App';
import useTime from '../../../Hooks/useTime';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import { fetchLogoutUser } from '../../../Redux/userSlice';
import { Trans } from 'react-i18next';


const PcsoBetHistory = () => {
    const dispatch = useDispatch();
    const { convertDate, convertTime } = useTime();
    const [betHistoryTableData, setBetHistoryTableData] = useState([]);
    // console.log(betHistoryTableData);

    useEffect(() => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            dispatch(setInnerLoaderTrue());
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/lottery/current-lottery/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data)
                    if (data.status === "active") {
                        return fetch(`${rootUrl}/lottery/lotteries/history/`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': `Token ${value.value}`,
                            }
                        })
                    } else {
                        throw new Error("get error from back server")
                    }
                })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    setBetHistoryTableData(data);
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    // console.log("its working");
                    dispatch(setInnerLoaderFalse())
                })
        }
    }, [dispatch]);

    return (
        <div className="p-1 mb-1 mb-md-2" style={{}}>
            <section className="row gx-0 py-1 py-md-3 d-flex flex-column justify-content-center align-items-center">
                <div className="col-12 col-md-6 text-white text-uppercase text-center fw-bold rounded p-1 mb-3" style={{ backgroundColor: "var(--green)" }}>
                    <p className="p-0 m-0"><Trans i18nKey="betHistoryTitle">Bet History</Trans></p>
                </div>
            </section>


            <section className="row gx-0 d-flex justify-content-center align-items-center" style={{ fontSize: "12px" }}>
                <div className="col-12 col-md-12">
                    <div className="row gx-0 d-flex justify-content-between align-items-start">
                        <div className="col-12 col-md-12 text-center mb-3 px-md-3" style={{ overflowX: "auto", fontSize: "10px" }}>
                            <table className="table table-bordered border-golden">
                                <thead className="text-uppercase" style={{}}>
                                    <tr valign="top">
                                        <th className='text-center' scope="col">SL</th>
                                        <th className='text-center' scope="col">Date & Time</th>
                                        <th className='text-center' scope="col">Bet Code</th>
                                        <th className='text-center' scope="col">Game Of Type</th>
                                        <th className='text-center' scope="col">Bet Number</th>
                                        <th className='text-center' scope="col">D Amount</th>
                                        <th className='text-center' scope="col">R Amount</th>
                                        <th className='text-center' scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody style={{}}>
                                    {
                                        betHistoryTableData?.map((item, index) =>
                                            <tr key={index} className="">
                                                <th className='text-center' scope="row">{++index}.</th>
                                                <td className='text-center'>
                                                    <div>{convertDate(item?.created_at)}</div>
                                                    <div>{convertTime(item?.created_at)}</div>
                                                </td>
                                                <td className='text-center'>{item?.trx_id} </td>
                                                <td className='text-center text-uppercase'>{item?.lottery_category?.lottery_category?.category_name} ({item?.time_category?.lottery_time})</td>
                                                <td className="text-center">{item?.bet_number}</td>
                                                <td className="text-center">${item?.d_amount}</td>
                                                <td className="text-center">${item?.r_amount}</td>
                                                <td className='text-center text-uppercase' style={{ color: (item?.status === "win") ? "rgba(173, 238, 60, 1)" : (item?.status === "lose") ? "red" : (item?.status === "waiting") ? "orange" : "transparent", }}>{(item?.status) ? item?.status : ""}</td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PcsoBetHistory;