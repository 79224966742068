import React from 'react';

const DataDetails = ({ property, info, }) => {
    return (
        <div className='row g-0 my-2'>
            <div className="d-flex align-items-center justify-content-between">
                <div>{property} : </div>
                <div className="green-text">{info}</div>
            </div>
        </div>
    );
};

export default DataDetails;