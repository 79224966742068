import { useEffect, useReducer, useRef } from "react";
import { origin, rootUrl } from "../App";
import { addDataIntoCache, getCacheData } from "../Redux/sportsExchangeSlice";


const useAffiliate = (path, options) => {
    let url = `${rootUrl}/statics/${path}/${origin}`;

    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef(false);

    const initialState = {
        error: undefined,
        data: undefined,
    }

    // Keep state logic separated
    const fetchReducer = (state, action) => {
        switch (action.type) {
            case 'loading':
                return { ...initialState }
            case 'fetched':
                return { ...initialState, data: action.payload }
            case 'error':
                return { ...initialState, error: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    useEffect(() => {
        // Do nothing if the url is not given
        if (!url) return

        cancelRequest.current = false

        const fetchData = async () => {
            dispatch({ type: 'loading' })

            const cacheDataOfAffiliate = await getCacheData('affiliateDataCache', url);

            if (cacheDataOfAffiliate) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfAffiliate = cacheDataOfAffiliate?.updateDate; /* Get the cached time from wherever it's stored */

                // Convert the cached time string to a Date object
                const cachedTimeOfAffiliate = new Date(cachedTimeStringOfAffiliate);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 60 * 1; // 1 hour (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfAffiliate = currentTime - cachedTimeOfAffiliate;

                if (timeDifferenceOfAffiliate < cacheExpirationThreshold) {

                    dispatch({ type: 'fetched', payload: cacheDataOfAffiliate?.data });
                    return cacheDataOfAffiliate?.data;
                }
            }

            try {
                const response = await fetch(url, options)
                if (!response.ok) {
                    throw new Error(response.statusText)
                }

                const data = (await response.json())
                // cache.current[url] = data
                
                addDataIntoCache(`affiliateDataCache`, url, {
                    data: data,
                    updateDate: new Date()
                });
                if (cancelRequest.current) return

                dispatch({ type: 'fetched', payload: data })
            } catch (error) {
                if (cancelRequest.current) return

                dispatch({ type: 'error', payload: error })
            }
        }

        fetchData();

        // Use the cleanup function for avoiding a possibly...
        // ...state update after the component was unmounted
        return () => {
            cancelRequest.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path])

    return state?.data;
};

export default useAffiliate;