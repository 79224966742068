import React, { useState } from 'react';
import CustomInputField from '../../../../Components/CustomInputField/CustomInputField';
import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
// import MailOTPConfirmation from '../../../../Components/MailOTPConfirmation/MailOTPConfirmation';
import TransactionReport from '../../../../Components/TransactionReport/TransactionReport';
import { IoMdRefresh } from 'react-icons/io';
import DataDetails from '../../../../Components/DataDetails/DataDetails';
import { rootUrl, origin, originNameOnly } from '../../../../App';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUserWallet, fetchLogoutUser, userDetail } from '../../../../Redux/userSlice';
import { showCustomAlert } from '../../../../Redux/alertSlice';
import OtpInput from '../../../../Components/OtpInput/OtpInput';
import confirmMail from '../../../../Assets/Images/Registration/email-icon-verify.svg';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import Header from '../../../../Components/Header/Header';
import { Trans } from 'react-i18next';
import { FaUserAlt } from 'react-icons/fa';
import { t } from 'i18next';

import { useConvert } from '../../../../Hooks/useConvert';


const Transfer = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);
    const [status, setStatus] = useState({});
    const [transferInputData, setTransferInputData] = useState({});
    const [transferOTP, setTransferOTP] = useState(0);

    const [userAlert, setUserAlert] = useState("");

    const { minTransferAmount } = useConvert();

    const [otp, setOtp] = useState('');
    const onChange = (value) => setOtp(value);

    const transferInputFunc = (e) => {
        let data = { ...transferInputData };
        if (e.target.name === 'username' || e.target.name === 'receiverNameTransfer') {
            setUserAlert("");
            let list = (e.target.value).split(' ');
            if (list.length > 1) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Space is not allowed`,
                }));
                return;
            }
            data[e.target.name] = e.target.value.toLowerCase();
        } else {
            data[e.target.name] = e.target.value;
        }
        setTransferInputData(data);
    }

    const transferFunction = () => {

        let sendData = {
            receiver_data: transferInputData?.receiverNameTransfer,
            amount: parseFloat(transferInputData?.amountInput),
            otp: otp,
            request_origin: originNameOnly,
        };
        // console.log(sendData);

        dispatch(setInnerLoaderTrue());
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);

            fetch(`${rootUrl}/wallet/confirm/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value?.value}`,
                },
                body: JSON.stringify(sendData)
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    if (data?.success) {
                        setTransferOTP(2);
                        setStatus(data);
                        dispatch(fetchLoginUserWallet());
                    }
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.error) {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.error}`,
                            }));
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `${err?.statusText}`,
                    }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse());
                })

        }
    }

    const requestOtpForTransfer = () => {
        if (
            userInfo?.user?.first_name === "" ||
            userInfo?.user?.last_name === "" ||
            userInfo?.user?.gender === null ||
            userInfo?.user?.dob === null
        ) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Update Your Profile.`,
            }));
            return;
        }

        let sendData = {
            receiver_data: transferInputData?.receiverNameTransfer,
            amount: parseFloat(transferInputData?.amountInput),
            request_origin: originNameOnly,
        };
        // console.log(sendData);

        if (sendData.receiver_data !== userInfo?.user?.username) {
            if (parseFloat(transferInputData?.amountInput) > 0) {
                dispatch(setInnerLoaderTrue());
                let stringValue = localStorage.getItem("bet7uUserKey")
                if (stringValue !== null) {
                    let value = JSON.parse(stringValue);
                    // Make a GET GS balance request
                    fetch(`${rootUrl}/gs/gs-credit/`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': `Token ${value?.value}`,
                        }
                    })
                        .then(response => response.json())
                        .then(data => {
                            // Check if data is received
                            if (data && data?.errMsg === "SUCCESS") {
                                // Make a POST request with the received data
                                fetch(`${rootUrl}/wallet/external/`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                        'Authorization': `Token ${value?.value}`,
                                    },
                                    body: JSON.stringify(sendData)
                                })
                                    .then(res => {
                                        if (!res.ok) throw res;
                                        else return res.json();
                                    })
                                    .then(data => {
                                        // console.log(data);
                                        if (data?.total_amount) {
                                            setTransferOTP(1);
                                            dispatch(fetchLoginUserWallet());
                                        }
                                    })
                                    .catch(err => {
                                        err.json().then(text => {
                                            if (text?.error) {
                                                dispatch(showCustomAlert({
                                                    type: "error",
                                                    message: `${text?.error}`,
                                                }));
                                                return;
                                            }
                                            if (text?.detail === "Invalid token.") {
                                                dispatch(showCustomAlert({
                                                    type: "error",
                                                    message: `${text?.detail}`,
                                                }));
                                                dispatch(fetchLogoutUser());
                                                return;
                                            }
                                        });
                                        dispatch(showCustomAlert({
                                            type: "error",
                                            message: `${err?.statusText}`,
                                        }));
                                    })
                                    .finally(() => {
                                        dispatch(setInnerLoaderFalse())
                                    })
                            } else {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `Try Again!`,
                                }));

                            }
                        })
                        .catch(error => {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `Try Again!`,
                            }));
                        });

                }
            } else {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Please Set Valid Amount`,
                }));
            }
        } else {
            dispatch(showCustomAlert({
                type: "error",
                message: `Sorry you can not send to you`,
            }));
        }
    }

    const checkExistData = async (e) => {
        let lenChar = (e.target.value).length;
        // console.log(lenChar);
        if (lenChar < 3) {
            setUserAlert("");
            return;
        }
        dispatch(setInnerLoaderTrue());


        let url = `${rootUrl}${'/auth/check_user/'}${origin}`;

        let options = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                username: `${e.target.value}`,
                request_origin: originNameOnly,
            })
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) throw response;
            const data = await response.json();

            // console.log(e.target.name);

            if (!data.success && e.target.name === "receiverNameTransfer") {
                setUserAlert("Username Exist");
            } else {
                setUserAlert("Username Not Found");
            }

        } catch (error) {
            console.log(error);
            setUserAlert("Username Not Found");
        }
        finally {
            dispatch(setInnerLoaderFalse());
        }
    }

    return (
        <div className="inner-scroll transfer">
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="transferPage">P2P Transfer</Trans>} />
            </div>

            {
                (status && transferOTP === 2) ?
                    <>
                        <div className="mx-4 my-4">
                            <CustomSectionTitle title={<Trans i18nKey="paymentStatusSubtitle">Payment Status</Trans>} page={"Transfer"} />
                        </div>
                        <div className='my-4'>
                            <TransactionReport report={<Trans i18nKey="transferSuccessTitle" />} amount={status?.amount || 0.00} />
                        </div>
                        <div className='mx-4 my-4' style={{ borderTop: "2px dashed #C2C2C2" }}>
                            <div className='my-4'>
                                <DataDetails property={"Transfer Fees"} info={`${"$" + (status?.transaction_fees_total_amount || 0.00)}`} />
                                <DataDetails property={"Receiver"} info={status?.receiver || ""} />
                                {/* <DataDetails property={"Transaction Date"} info={status?.created_at || ""} /> */}
                                <DataDetails property={"Trx ID"} info={status?.transaction_id || ""} />
                                <DataDetails property={"Status"} info={status?.status || "Successful"} />
                            </div>
                            <div className='row g-0 my-4'>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button onClick={() => {
                                        setTransferOTP(0);
                                        setTransferInputData({});
                                        setOtp("");
                                    }} className="btn btn-yellow btn-sm w-50 text-uppercase"><IoMdRefresh />{<Trans i18nKey="makeAnotherButton" />}</button>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    transferOTP === 1 ?
                        <>
                            {/* <MailOTPConfirmation title={"Email Confirmation"} text={"Please Enter Your OTP"} buttonName={"Continue"} submitFunction={transferFunction} /> */}
                            <div className="">
                                <h3 className="text-center text-uppercase fw-bold my-3">{<Trans i18nKey="titleTransferConfirmation" />}</h3>
                            </div>
                            <div>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <img className='confirmMailImg' src={confirmMail} alt="confirm mail img" />
                                </div>
                                <p className='p-0 py-2 m-0 text-center'>{<Trans i18nKey="textTransferConfirmation" />}</p>

                                <div className='mx-4 mb-4'>
                                    <div className="row g-0 d-flex justify-content-center">
                                        <OtpInput value={otp} valueLength={6} onChange={onChange} />

                                        <div className='d-flex justify-content-center'>
                                            <button onClick={transferFunction} type='submit' className="btn btn-yellow my-3 w-50">{<Trans i18nKey="continueButton" />}</button>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        transferOTP === 0 &&
                        <>
                            {/* <div className='mx-4 my-4'>
                                <CustomSectionTitle title={"P2P Transfer"} page={"transfer"} />
                            </div> */}
                            <div className='mx-4 my-4'>
                                {/* <CustomInputField type="text" name="receiverName" label="Username" value={transferInputData?.receiverName} func={transferInputFunc} icon1={false} icon2={false} /> */}
                                <CustomInputField type="text" name="receiverNameTransfer" label="Username" langLabel={<Trans i18nKey="receiverNameTransferLangLabel" />} langPlaceHolder={t('receiverNameTransferLangLabel')} value={transferInputData?.receiverNameTransfer} func={transferInputFunc} icon1={false} icon2={(userAlert === "Username Exist") ? <FaUserAlt color="var(--green)" /> : false} errorSms={(userAlert === "Username Not Found") ? "Username Not Found" : false} checkExist={checkExistData} />

                                <CustomInputField type="number" name="amountInput" label="Enter Amount" langLabel={<Trans i18nKey="amountInputTransferLangLabel" />} langPlaceHolder={t('amountInputTransferLangLabel')} value={transferInputData?.amountInput} func={transferInputFunc} icon1={false} icon2={false} />
                                <i className="d-block" style={{ fontSize: "10px" }}> <span style={{ color: "red" }}>***</span> Your balance will transfer from your <span style={{ color: "red", fontWeight: 'bold' }}>“Credit Balance”</span> to the receiver.  </i>
                                <i className="d-block" style={{ fontSize: "10px" }}> <span style={{ color: "red" }}>***</span> Your minimum transfer amount is <span style={{ color: "red", fontWeight: 'bold' }}>${minTransferAmount || 0.00}</span></i>
                            </div>
                            <div className='row g-0 mx-4 my-4'>
                                <div className="d-flex align-items-center justify-content-center">
                                    <button onClick={requestOtpForTransfer} className="btn btn-yellow btn-sm w-100" disabled={!(userAlert === "Username Exist") || !(transferInputData?.amountInput > 0)}><Trans i18nKey="verifyemailbutton">Verify Email</Trans></button>
                                </div>
                            </div>
                        </>
            }
        </div>
    );
};

export default Transfer;