import React from 'react';
// import { useSelector } from 'react-redux';
// import { userDetail } from '../Redux/userSlice';
import HeaderWithoutBackButton from '../Components/Header/HeaderWithoutBackButton';
// import BottomNavigation from '../Components/BottomNavigation/BottomNavigation';
import { motion as M } from 'framer-motion';


const LayoutWithoutHeaderBackButton = ({ getStartRef, logo, children, setSelectedFragmentID }) => {
    // const user = useSelector(userDetail);
    // console.log(user);
    // let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;

    let exchangeRedirected = localStorage.getItem("exchngd") || false;
    // let stringValue = localStorage.getItem("bet7uUserKey");
    // let value = JSON.parse(stringValue);
    // console.log(value?.is_login);

    return (
        <div className='home'>
            <M.div
                initial={{ opacity: 0, x: "-50%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "50%" }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
            >
                <div style={{ minHeight: "100dvh" }}>
                    {
                        (!exchangeRedirected) &&
                        <div className={(getStartRef === null) ? "d-none" : 'sticky-top'}>
                            <HeaderWithoutBackButton logo={logo} setSelectedFragmentID={setSelectedFragmentID} />
                        </div>
                    }
                    {children}
                </div>
            </M.div>


            {/* {
                ((value?.is_login) || (user?.is_login)) &&
                <div className='sticky-bottom'>
                    <BottomNavigation setSelectedFragmentID={setSelectedFragmentID} />
                </div>
            } */}
        </div>
    );
};

export default LayoutWithoutHeaderBackButton;