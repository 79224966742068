import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { userLoading } from '../Redux/userSlice';
import { RotatingLines } from 'react-loader-spinner';

const PrivateRoute = ({ children }) => {
    const location = useLocation();
    const loading = useSelector(userLoading);
    // const user = useSelector(userDetail);
    // console.log(user);

    if (loading === "loading") {
        // return <div>loading...</div>
        return (
            <section className="loader-spinner">
                <div className="d-flex flex-column justify-content-center align-items-center spinner-square">
                    <RotatingLines
                        strokeColor="white"
                        strokeWidth="3"
                        animationDuration="0.9"
                        width="40"
                        visible={true}
                    />
                    <p className="text-white fw-bold pt-md-1 mb-0">Loading</p>
                </div>
            </section >)
    }

    let stringValue = localStorage.getItem("bet7uUserKey");
    if (stringValue) {
        return children;
    }

    // if (user.is_login) {
    // }

    return (<Navigate to="/login" state={{ from: location }} replace></Navigate>);
};

export default PrivateRoute;