import React from 'react';
import successImg from '../../Assets/Images/Registration/Group 414.svg'
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

const ForgotPasswordSuccess = () => {
    const navigate = useNavigate();
    return (
        <div className='inner-scroll forgot-password-success'>
            <div className='position-relative'>
                <h3 className="text-center p-3"><Trans i18nKey="titleForgotPasswordOTP" /></h3>
            </div>

            <div>
                <div className='d-flex align-items-center justify-content-center'>
                    <img className='confirmMailImg' src={successImg} alt="success img" />
                </div>
                <p className='p-0 py-2 m-0 text-center'></p>

                <div className='mx-4 mb-4'>
                    <div className='d-flex justify-content-center'>
                        <button onClick={() => navigate("/login", { replace: true })} type='button' className="btn btn-yellow my-2 w-100"><Trans i18nKey="backToLoginButton" /></button>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button onClick={() => navigate("/", { replace: true })} type='button' className="btn btn-white my-2 w-100"><Trans i18nKey="backToHomeButton" /></button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ForgotPasswordSuccess;