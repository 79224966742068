import React, { useState } from 'react';

const HoveredImageButton = ({ defaultIcon, activeIcon, altText, navigateTo }) => {
    
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    const handleClick = () => {
        navigateTo && navigateTo();
    };

    return (
        <button
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleClick}
            className={"btn btn-sm dark-button w-100 rounded"}
        >
            <img
                className="me-1 me-md-2 mb-1"
                src={isHovered ? activeIcon : defaultIcon}
                alt={altText}
            />
            {altText}
        </button>
    );
};

export default HoveredImageButton;