import React, { useEffect, useState } from 'react';
import Header from '../../../Components/Header/Header';
import './Account.css';
import { motion as M } from 'framer-motion';

import CustomSectionTitle from '../../../Components/CustomSectionTitle/CustomSectionTitle';
import MenuList from '../../../Components/MenuList/MenuList';
import { othersMenu } from '../../../Data/MenuData';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserWallet, fetchLoginUserWallet, fetchLogoutUser, userDetail } from '../../../Redux/userSlice';
import { BsChevronRight } from 'react-icons/bs';
import logout from "../../../Assets/Icons/MenuIcons/OthersMenu/logout.svg";
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import { Trans } from 'react-i18next';
// import { mother_site } from '../../App';
// import { useSportsExchange } from '../../Hooks/useSportsExchange';
import useGetGSBalance from '../../../Hooks/useGetGSBalance';
import { AiFillCamera } from 'react-icons/ai';
// import { useSportLauncher } from '../../Hooks/useSportLauncer';


import { RWebShare } from 'react-web-share';
// import { Card, Col, Row, } from 'react-bootstrap';
// import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { RiVerifiedBadgeFill } from "react-icons/ri";
// import { bankingCardData } from '../../../Data/BankingPageData';
// import { useNavigate } from 'react-router-dom';
import { FaUserEdit } from 'react-icons/fa';
import useCopyToClipboard from '../../../Hooks/useCopyToClipboard';
import { rootUrl } from '../../../App';
import ProfileImgModal from '../../../Components/ProfileImgModal/ProfileImgModal';
import shareIcon from '../../../Assets/Icons/share-icon.svg';
import { useBankingProfile } from '../../../Hooks/useBankingProfile';

import convertIcon from "../../../Assets/Icons/AccountIcons/convert.svg";
// import refreshIcon from "../../../Assets/Icons/HeaderIcon/refreshIcon.svg";
import refreshIcon2 from "../../../Assets/Icons/AccountIcons/refresh.svg";

import depositIcon from "../../../Assets/Icons/AccountIcons/deposit.svg";
import withdrawalIcon from "../../../Assets/Icons/AccountIcons/withdrawal.svg";
import transferIcon from "../../../Assets/Icons/AccountIcons/transfer.svg";

import depositActiveIcon from "../../../Assets/Icons/AccountIcons/depositActive.svg";
import withdrawalActiveIcon from "../../../Assets/Icons/AccountIcons/withdrawalActive.svg";
import transferActiveIcon from "../../../Assets/Icons/AccountIcons/transferActive.svg";

import { showCustomAlert } from '../../../Redux/alertSlice';
import HoveredImageButton from '../../../Components/HoveredImageButton/HoveredImageButton';

const Account = () => {

    const copy = useCopyToClipboard();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);
    const userWallet = useSelector(currentUserWallet);
    const { referralBonus } = useBankingProfile();

    const { gsBalanceGetFetchAsync } = useGetGSBalance();

    const [profileImageModal, setProfileImageModal] = useState(false);
    const [activeRefresh, setActiveRefresh] = useState(false);

    useEffect(() => {
        let refreshTimer;
        if (activeRefresh) {
            // Start refreshing

            // console.log('Refreshing...');
            dispatch(fetchLoginUserWallet());
            refreshTimer = setTimeout(() => {
                // Stop refreshing after 1 second
                // console.log('Stopped refreshing.');
                setActiveRefresh(false);
            }, 1000);
        }
        return () => clearInterval(refreshTimer);
    }, [activeRefresh, dispatch]);

    const refreshBtn = () => {
        setActiveRefresh(true);
    };

    const setProfileImageModalClose = () => {
        setProfileImageModal(false);
    }

    const logoutFunction = async (item) => {
        // console.log('object');
        try {
            dispatch(setInnerLoaderTrue());
            const successData = await gsBalanceGetFetchAsync();
            if (successData) {
                // gsBalanceGet();
                let currData = dispatch(fetchLogoutUser());
                if (currData?.error) return;
                navigate("/");
            }

        } catch (error) {
            handleFetchError(error);
        }
    }
    const handleFetchError = (error) => {
        dispatch(showCustomAlert({
            type: "error",
            message: `Try Again!`,
        }));
        dispatch(setInnerLoaderFalse());
    };

    return (
        <M.div
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
        >
            <div className="inner-scroll menu">
                <div className='sticky-top'>
                    <Header pageTitle={
                        // <Trans i18nKey="menuPage">
                        "Account"
                        // </Trans>
                    } />
                </div>
                <main className="mx-4">


                    <section>
                        <div className="my-4">
                            <div className="user-header">
                                <div className="" style={{ position: "absolute", right: 0, top: 0, }}>
                                    <button type='' className="btn btn-sm btn-dark py-0" onClick={() => navigate(`/user-profile-edit${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)}> <FaUserEdit /> <Trans i18nKey="editprofile">Edit Profile</Trans></button>
                                </div>
                                <div className="row gx-0 d-flex justify-content-center align-items-start">
                                    <div className="col-3 d-flex justify-content-start align-items-start mb-3">
                                        {
                                            userInfo?.user?.photo ?
                                                <div className='userImgBorder' onClick={() => setProfileImageModal(true)}>
                                                    <div className="selectedUserImage">
                                                        <img src={`${rootUrl}${userInfo?.user?.photo}`} alt="UserImg" />
                                                    </div>
                                                </div>
                                                :
                                                <div className='userImgBorder' onClick={() => setProfileImageModal(true)}>
                                                    <div className="userImage" htmlFor='profilePic'>
                                                        <AiFillCamera width="60px" />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div className="col-9">
                                        <div className="row gx-0 d-flex justify-content-start align-items-center">
                                            <div className="col-12 d-flex justify-content-start align-items-center">
                                                <div className="d-flex flex-column flex-sm-column justify-content-start align-items-start">
                                                    <div style={{ fontSize: "14px", }}>
                                                        <p className="mb-0">{userInfo?.user?.username} <RiVerifiedBadgeFill style={{ color: "var(--green)" }} /></p>
                                                        <p className="mb-0">Currency: {userInfo?.user?.currency}</p>
                                                        <p className="mb-0">Category: {userInfo?.user?.category}</p>
                                                        <p className="mb-0"><Trans i18nKey="referralcode">Your Referral Code</Trans> : <span className="rounded px-2 py-1" style={{ backgroundColor: "#000", color: "var(--yellow)", }}>{userInfo?.user?.referral_code}</span><span className="ms-2" style={{ color: "var(--green)", cursor: "pointer", }} onClick={() => navigate(`/referral${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`)}>(<Trans i18nKey="viewall">View all</Trans>)</span></p>
                                                    </div>
                                                    <div className="d-none">
                                                        <button onClick={() => copy(`${userInfo?.user?.user_base_origin + "registration/?ref=" + userInfo?.user?.referral_code}`)} style={{ border: "none", outline: "none", backgroundColor: "transparent", }}>
                                                            <img src={shareIcon} alt="share" />
                                                        </button>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                                        <p className="mb-0 px-2 rounded" style={{ backgroundColor: "#EDEDED", fontSize: "10px", }}>{`${userInfo?.user?.user_base_origin + "registration/?ref=" + userInfo?.user?.referral_code}`}</p>
                                                        <div>
                                                            <RWebShare
                                                                data={{
                                                                    text: "Click to register the app.",
                                                                    url: `${userInfo?.user?.user_base_origin + "registration/?ref=" + userInfo?.user?.referral_code}`,
                                                                    title: "Betting App",
                                                                }}
                                                                onClick={() => console.log("shared successfully!")}
                                                            >
                                                                <button style={{ border: "none", outline: "none", backgroundColor: "transparent", }}>
                                                                    <img src={shareIcon} alt="share" width="20px" />
                                                                </button>
                                                            </RWebShare>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 d-flex justify-content-start align-items-start">
                                                <small style={{ fontSize: "12px", }}> Referral commission every deposit <strong> {referralBonus || 0.00}% </strong> lifetime.</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProfileImgModal profileImageModal={profileImageModal} setProfileImageModalClose={setProfileImageModalClose} ></ProfileImgModal>
                    </section>

                    <section>
                        <div className="my-4">
                            <div className="my-0 px-3 py-1 py-md-3 rounded available-balance-section">
                                <div className="row gx-0">
                                    <div className="col-10 bottom-underlined">
                                        <h5 className="text-start mb-0">Credit Balance</h5>
                                    </div>
                                </div>
                                <div className="row gx-0">
                                    <div className="col-12 d-flex py-1">
                                        <h4 className="text-start mb-0 px-3 rounded" style={{ backgroundColor: "var(--dark)" }}>
                                            <span>$ {(userWallet?.credit_balance)?.toFixed(2)}</span>
                                            <img
                                                onClick={refreshBtn}
                                                style={{ cursor: "pointer", }}
                                                // className="ms-3 mb-0" 
                                                className={activeRefresh ? "rotating ms-3 mb-0" : "ms-3 mb-0"}
                                                src={refreshIcon2} alt="refresh" height={"30px"} />
                                        </h4>
                                    </div>
                                    <div className="col-12 d-flex py-0 py-md-1">
                                        <p className="text-start mb-0">$ {(userWallet?.reserve_balance)?.toFixed(10)} (Reserve)</p>
                                        <p className="ms-3 mb-0">
                                            <img
                                                style={{ cursor: "pointer", }}
                                                onClick={() => navigate("/convert")}
                                                src={convertIcon} alt="convert" width={"20px"} />
                                        </p>
                                    </div>
                                    <div className="col-12 d-flex py-0 py-md-1">
                                        <p className="text-start mb-0">$ {(userWallet?.coin_balance)?.toFixed(10)} (Coin)</p>
                                        <p className="ms-3 mb-0">
                                            <img
                                                style={{ cursor: "pointer", }}
                                                onClick={() => navigate("/convert")}
                                                src={convertIcon} alt="convert" width={"20px"} />
                                        </p>
                                    </div>
                                    <div className="col-12 d-flex gap-1 pt-md-2">
                                        <HoveredImageButton
                                            defaultIcon={depositIcon}
                                            activeIcon={depositActiveIcon}
                                            altText="Deposit"
                                            navigateTo={() => navigate("/deposit")}
                                        />
                                        <HoveredImageButton
                                            defaultIcon={withdrawalIcon}
                                            activeIcon={withdrawalActiveIcon}
                                            altText="Withdrawal"
                                            navigateTo={() => navigate("/withdrawal")}
                                        />
                                        <HoveredImageButton
                                            defaultIcon={transferIcon}
                                            activeIcon={transferActiveIcon}
                                            altText="Transfer"
                                            navigateTo={() => navigate("/transfer")}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="my-4">
                            <CustomSectionTitle title={<Trans i18nKey="othersMenuSubtitle">Others Menu</Trans>} page={"menu"} />
                        </div>
                        <div className="my-4">
                            <MenuList list={othersMenu} />

                            <button
                                type="button"
                                onClick={() => logoutFunction()}
                                className={"col-12 d-flex justify-content-between align-items-center item mb-2 px-3 py-2 logout"}
                                style={{ outline: "none", border: "none", color: "var(--tomato)" }}>
                                <div className="nav-link">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={logout} alt="menu-item" className="" height="23" width="23" />
                                        <span className="item-text ms-3">{<Trans i18nKey="logout">LOGOUT</Trans>}</span>
                                    </div>
                                </div>
                                <BsChevronRight />
                            </button>
                        </div>
                    </section>

                </main>
            </div>
        </M.div>
    );
};

export default Account;