import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ selectedFragmentId }) => {
    const { pathname } = useLocation();
    // console.log(pathname);

    useEffect(() => {
        // console.log("this is call scroll to op");
        document.getElementById("main").scrollTo(0, 0);
    }, [pathname, selectedFragmentId]);

    return null;
};

export default ScrollToTop;