import React from 'react';
import './YoutubeVideo.css';

const YoutubeVideo = ({ title = "", link = "" }) => {
    return (
        <section className="row gx-0 d-flex flex-column justify-content-center align-items-center">
            <div className="col-12 text-white text-uppercase text-center fw-bold rounded p-1 mb-3" style={{ backgroundColor: "var(--green)" }}>
                <p className="p-0 m-0">{title}</p>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center video-frame">
                <iframe className="rounded-4" width="" height="" src={link} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" marginWidth="0" marginHeight="0" allowFullScreen></iframe>
            </div> */}
            <div className={link ? "d-flex justify-content-center align-items-center result-container" : "d-none"}
                style={{}}
            >
                <iframe
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" marginWidth="0" marginHeight="0" allowFullScreen
                    className='result-iframe rounded-4'
                    title={title}
                    src={link}
                ></iframe>
            </div>
        </section>
    );
};

export default YoutubeVideo;