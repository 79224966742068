import React, { useEffect, useState } from 'react';
import { origin, rootUrl } from '../../App';
// import CustomSectionTitle from '../../Components/CustomSectionTitle/CustomSectionTitle';
import Header from '../../Components/Header/Header';
import { Trans } from 'react-i18next';

const TermsConditionsPage = () => {
    const [termsConditions, setTermsConditions] = useState(null);

    useEffect(() => {
        fetch(`${rootUrl}/site-related/terms-and-conditions/${origin}`)
            .then(res => {
                if (!res.ok) return res.json().then(text => { throw new Error(text?.error) });
                else return res.json();
            })
            .then(data => {
                const tcData = data[0]?.description?.split("title: ")
                    .map(e => e.split("des: "))
                    .map(([title, description]) => ({ title, description }));
                tcData.shift();
                setTermsConditions(tcData);
            })
    }, []);

    return (
        <div className='inner-scroll termsConditions'>
            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="tcPage">Terms & Conditions</Trans>} />
            </div>

            {/* <div className='mx-4 my-4'>
                <CustomSectionTitle title={"Terms & Conditions"} page={"termsConditions"} />
            </div> */}

            <div className='row g-0 rounded contactMain p-2'>
                <div className='col-12 rounded-bottom'>
                    <ul className="row g-0 align-items-center" style={{ color: "#000", listStyle: "none", margin: 0, padding: 0 }}>
                        {
                            termsConditions?.map((item, index) =>
                                <li className='col-12' key={index}>
                                    <div className='d-flex flex-column align-items-start m-0 mx-3 my-1 pb-0' style={{ whiteSpace: "pre-line" }}>
                                        <p className='fw-bold mb-0' style={{ color: "#000", fontSize: "20px" }}>{item?.title}</p>
                                        <p className='text-start mb-0' style={{ color: "#000", fontSize: "14px" }}>{item?.description}</p>
                                    </div>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TermsConditionsPage;