import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useLiveNotifications } from '../../Hooks/useLiveNotifications';
// import CustomSectionTitle from '../../Components/CustomSectionTitle/CustomSectionTitle';
import Header from '../../Components/Header/Header';
import { ImCross } from 'react-icons/im';
import { Trans } from 'react-i18next';

const NotificationsPage = () => {
    const { liveNotifications, notificationStatus, setNotificationStatus, updateLiveNotificationStatus, deleteNotification, } = useLiveNotifications();

    return (
        <>
            <div className="inner-scroll livePage">
                <div className='sticky-top'>
                    <Header pageTitle={<Trans i18nKey="notificationsPage">Notifications</Trans>} />
                </div>
                {/* <div className='mx-4 my-4'>
                    <CustomSectionTitle title={"Notifications"} page={"notifications"} />
                </div> */}
            </div>
            <div className='row g-0 rounded px-1 pt-2 mb-5'>

                {
                    <div className='col-12 rounded mb-5 pb-0 px-1' style={{ backgroundColor: "#000", }}>

                        {
                            liveNotifications?.length === 0 ?
                                <div className="text-center py-2" style={{ backgroundColor: "var(--green)", color: "#fff", }}>
                                    <h6 className="fw-bold text-center" style={{ cursor: "", }} >
                                        No New Notifications!
                                    </h6>
                                    <Spinner animation="border" role="status" variant="light">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                                :
                                liveNotifications?.map((notification, index) =>
                                    <div key={notification?.id} className={notification?.id ? "rounded my-1" : "d-none"} style={!notification?.seen ? { backgroundColor: "var(--yellow)", } : { backgroundColor: "var(--dark)", color: "#fff" }}>
                                        <p className="fw-normal px-1 mb-0 py-2" onClick={() => {
                                            updateLiveNotificationStatus(notification);
                                        }} style={{ cursor: "pointer", }} >
                                            {notification?.notice}
                                        </p>
                                        {
                                            (notificationStatus && notificationStatus?.types && (notification?.id === notificationStatus?.id)) &&
                                            <div key={notificationStatus?.id} className="d-flex justify-content-between px-1 py-2 rounded" style={{ backgroundColor: "var(--green)", }} onClick={() => setNotificationStatus({})}>
                                                <div className="">
                                                    <h6 className="text-capitalize fw-bold" >{notificationStatus?.types}</h6>
                                                    <p className="text-white"  >{notificationStatus?.notice}</p>
                                                </div>
                                                <div className="">
                                                    <ImCross onClick={() => deleteNotification(notification)} className="" color={"var(--light)"} style={{ cursor: "pointer", fontSize: "0.7rem", }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                        }
                    </div>
                }
            </div>
        </>
    );
};

export default NotificationsPage;