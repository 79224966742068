import React, { useEffect, useState } from 'react';

import './CustomMarquee.css'
import { origin, rootUrl } from '../../App';
import { addDataIntoCache, getCacheData } from '../../Redux/sportsExchangeSlice';
// import { GiTrophy } from 'react-icons/gi';

const CustomMarquee = () => {
    const [winData, setWinData] = useState(null);

    useEffect(() => {
        const fetchWinData = async () => {
            const cacheDataOfWinData = await getCacheData('cacheDataOfWinData', `${rootUrl}/statics/winData/`);

            if (cacheDataOfWinData) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfWinData = cacheDataOfWinData?.updateDate; /* Get the cached time from wherever it's stored */

                // Convert the cached time string to a Date object
                const cachedTimeOfWinData = new Date(cachedTimeStringOfWinData);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 5; // 5 miniutes (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfWinData = currentTime - cachedTimeOfWinData;

                if (timeDifferenceOfWinData < cacheExpirationThreshold) {
                    setWinData(cacheDataOfWinData?.data);
                    return null;
                }
            }

            fetch(`${rootUrl}/site-related/announcement/${origin}`)
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data[0]);
                    // let visibleData = [];

                    // let dataLength = data?.length;
                    // let marqueePart = Math.ceil(dataLength / 5);

                    // for (let i = 0; i < marqueePart; i++) {
                    //     // const element = data[i];
                    //     let fastIndex = i * 5;
                    //     let secondIndex = fastIndex + 5;
                    //     const firstHalf = data?.slice(fastIndex, secondIndex);
                    //     visibleData.push(firstHalf);
                    // }
                    // // console.log(visibleData);
                    addDataIntoCache(`cacheDataOfWinData`, `${rootUrl}/statics/winData/`, {
                        data: data[0],
                        updateDate: new Date()
                    });
                    setWinData(data[0]);
                })
                .catch(error => {
                    console.log(error.type);
                })
        }
        fetchWinData();
    }, [])

    return (
        <div className="marquee">
            <div className="marquee__content" style={{ width: `calc(${winData?.length} * 650px)` }}>
                {
                    winData &&
                    <p className="list-inline">
                        {/* <span className="fw-bold">{winData?.title}</span> */}
                        <span>{winData?.announcement}</span>
                    </p>
                    // winData?.map((item, index) =>
                    //     <ul key={index} className="list-inline">
                    //         <li>
                    //             <span className="mx-0 mx-md-1">{item[0]?.user?.slice(0, 3)}xxx : </span>
                    //             <span className="">${item[0]?.amount}</span>
                    //         </li>
                    //         <li>
                    //             <span className="mx-0 mx-md-1">{item[1]?.user?.slice(0, 3)}xxx : </span>
                    //             <span className="">${item[1]?.amount}</span>
                    //         </li>
                    //         <li>
                    //             <span className="mx-0 mx-md-1">{item[2]?.user?.slice(0, 3)}xxx : </span>
                    //             <span className="">${item[2]?.amount}</span>
                    //         </li>
                    //         <li>
                    //             <span className="mx-0 mx-md-1">{item[3]?.user?.slice(0, 3)}xxx : </span>
                    //             <span className="">${item[3]?.amount}</span>
                    //         </li>
                    //         <li>
                    //             <span className="mx-0 mx-md-1">{item[4]?.user?.slice(0, 3)}xxx : </span>
                    //             <span className="">${item[4]?.amount}</span>
                    //         </li>
                    //     </ul>
                    // )
                }
            </div>
        </div>
    );
};

export default CustomMarquee;