import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import alertReducer from "./alertSlice";
import gameReducer from "./gameSlice";
import gatewayReducer from "./bankingTransactionSlice";
import agentReducer from "./agentAdminSlice";
import loaderReducer from "./loaderSlice";
import launcherSlice from "./launcherSlice";
import sportsExchangeReducer from "./sportsExchangeSlice";

const rootReducer = combineReducers({
    user: userReducer,
    customAlert: alertReducer,
    game: gameReducer,
    gateway: gatewayReducer,
    agent: agentReducer,
    loader: loaderReducer,
    launchGame: launcherSlice,
    sportsExchange: sportsExchangeReducer,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: false,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
    })
})

export default store;