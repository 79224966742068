import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PerfectMoneyNoPayment = () => {
    const navigate = useNavigate();

    const getQueryParams = (query = null) => (query || window.location.search.replace('?', '')).split('&').map(e => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});
    // console.log("no payment", getQueryParams());

    useEffect(() => {
        navigate("/deposit");
    }, [navigate])


    return (
        <div>

        </div>
    );
};

export default PerfectMoneyNoPayment;