import React, { useState } from 'react';
import './RegMailConfirm.css';
import { AiFillLeftCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import confirmMail from '../../Assets/Images/Registration/email-icon-verify.svg';
import { HiMail } from "react-icons/hi";
import { useDispatch, useSelector } from 'react-redux';
import { setRegistrationData, userRegConfirmData } from '../../Redux/userSlice';
import { origin, originNameOnly, rootUrl } from '../../App';
// import { showCustomAlert } from '../../Redux/alertSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';

const RegMailConfirm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const regConfirmData = useSelector(userRegConfirmData);
    const [confirmRegData, setConfirmRegData] = useState(regConfirmData);
    const [verifiedEmailError, setVerifiedEmailError] = useState("");

    // useEffect(() => {
    //     if (!regConfirmData?.username) {
    //         navigate("/registration", { replace: true })
    //     }
    // }, [regConfirmData, navigate])

    const updateRegFormData = e => {
        const { name, value } = e.target;
        setVerifiedEmailError("");

        setConfirmRegData(prev => ({ ...prev, [name]: value.toLowerCase() }));
    }

    const checkExistData = async (e) => {
        e.preventDefault();


        // if (e.target.name === "email" && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
        //     options = {
        //         method: 'POST',
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //             email: `${e.target.value}`,
        //             request_origin: 'betkingly',
        //         })
        //     }
        // }

        dispatch(setInnerLoaderTrue());

        let url = `${rootUrl}${'/auth/check_user/'}${origin}`;

        let options = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: `${confirmRegData.email}`,
                request_origin: originNameOnly,
            })
        };

        try {
            const response = await fetch(url, options);
            if (!response.ok) throw response;
            const data = await response.json();
            // console.log(data);
            if (!data.success) {
                setVerifiedEmailError("Email Already Exist");
            } else {
                setVerifiedEmailError("accept email");
            }
        } catch (error) {
            // dispatch(showCustomAlert({
            //     type: "error",
            //     message: `Email Already Exist`
            // }));
            console.log(error);
        } finally {
            dispatch(setInnerLoaderFalse());
        }
    }

    const confirmRegFunc = async (e) => {
        e.preventDefault();
        dispatch(setInnerLoaderTrue());

        // console.log(regConfirmData);
        if (!regConfirmData?.username) {
            navigate("/registration", { replace: true });
            dispatch(setInnerLoaderFalse());
        }

        let parentUrl = (window.location !== window.parent.location)
            ? document.referrer
            : document.location.href;

        let url_val = parentUrl.split('/');
        let make_url = url_val[0] + '//' + url_val[2] + '/';
        if (make_url === 'https://betkingly.com/' || make_url === 'http://localhost:3000/') {
            make_url = make_url + "";
        }

        try {
            let url = `${rootUrl}${'/auth/request-otp/'}${origin}`;

            let newData = {
                ...confirmRegData,
                request_origin: originNameOnly,
                registration_link: make_url,
            };

            let options = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newData)
            }
            // console.log("options", options);

            const response = await fetch(url, options);
            if (!response.ok) throw response;

            const data = await response.json();
            // console.log(data);
            if (data?.status === "ok") {
                dispatch(setRegistrationData(confirmRegData));
                e.target.reset();
                navigate('/regMailVerify');
                dispatch(setInnerLoaderFalse());
            }

        } catch (error) {
            dispatch(setInnerLoaderFalse());
            let errorData = await error.json();
            // console.log(errorData);

            if (errorData?.error) {
                console.log("errorData", errorData);
            } else if (errorData?.detail === "Invalid token.") {
                console.log("errorData", errorData);
            } else {
                console.log("error", error);
            }
        }
    }

    return (
        <div className='inner-scroll registration'>
            <div className='position-relative'>
                <h3 className="text-center p-3">CONFIRM YOUR EMAIL</h3>
                <div className='position-absolute' style={{ top: "12px", left: '10px' }}>
                    <AiFillLeftCircle size='35' color='var(--yellow)' onClick={() => { navigate(-1, { replace: true }) }} style={{ cursor: "pointer", }} />
                </div>
            </div>

            <div>
                <div className='d-flex align-items-center justify-content-center'>
                    <img className='confirmMailImg' src={confirmMail} alt="confirm mail img" />
                </div>
                <p className='p-0 pt-2 m-0 text-center'>Please verify your email.</p>

                <div className='mx-4 mb-4'>
                    <form className="row g-0" onSubmit={confirmRegFunc}>
                        <div className="custom-input">
                            <div className="module my-2">
                                <label className="">Email</label>
                                {
                                    (verifiedEmailError === "Email Already Exist") ? <small className="">{verifiedEmailError}</small> : ""
                                }
                                <div className="input-group">
                                    <input
                                        type="text"
                                        name="email"
                                        value={confirmRegData?.email}
                                        className="form-control"
                                        aria-label="Email"
                                        title="Email"
                                        onChange={updateRegFormData}
                                        onBlur={checkExistData}
                                        placeholder="yourmail@example.com"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                        required
                                    />
                                    <span className="input-group-text"><HiMail /></span>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button type='submit' className="btn btn-yellow my-3 w-50" disabled={!(verifiedEmailError === "accept email") || (verifiedEmailError === "")}>Verify</button>
                        </div>

                    </form>
                </div>

            </div>
        </div>
    );
};

export default RegMailConfirm;