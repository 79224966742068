import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { rootUrl } from '../../../App';
// import { setCustomAlertTrue, setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
// import PcsoResultAccordion from '../../../../Components/PcsoComponents/PcsoResultAccordion/PcsoResultAccordion';

// import './THResults.css';
import PcsoResultAccordion from '../../../Components/CountryLotteryComponent/PcsoResultAccordion/PcsoResultAccordion';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import { Trans } from 'react-i18next';



const THResults = () => {

    const dispatch = useDispatch();

    const [yearList, setYearList] = useState(null);
    const [selectedYearList, setSelectedYearList] = useState("");
    // console.log(yearList, selectedYearList);

    const [lotteryResults, setLotteryResults] = useState(null);
    const [active, setActive] = useState(null);
    // console.log(lotteryResults, active);


    const handleYearChange = (event) => {
        setSelectedYearList(event.target.value);
    };

    const getFilteredData = useCallback((year) => {
        dispatch(setInnerLoaderTrue());

        const thFullUrl = `${rootUrl}/lottery/th/th-result-year-month/?year=${year}`;

        fetch(thFullUrl)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                // console.log(data);
                setLotteryResults(data);
                setActive(data[0]);
            })
            .catch((err) => console.log(err))
            .finally(() => dispatch(setInnerLoaderFalse()))
    }, [dispatch]);

    useEffect(() => {
        dispatch(setInnerLoaderTrue());
        const controller = new AbortController();
        let signal = controller.signal;

        fetch(`${rootUrl}/lottery/th/th-result-year/`, {
            signal,
        })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                console.log(data);
                if (data.length > 0) {
                    setYearList(data);
                    setSelectedYearList(data[0]);
                    getFilteredData(data[0]);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => dispatch(setInnerLoaderFalse()))

        return (() => {
            controller.abort();
        })

    }, [dispatch, getFilteredData]);


    return (
        <div className='pcsoResults p-3 mb-1'>

            <div className="filter-area rounded">
                <div className="row g-0 justify-content-between">
                    <select className="col rounded form-select" value={selectedYearList} onChange={handleYearChange}>
                        {yearList?.map(item => <option key={item} value={item}>{item}</option>)}
                    </select>

                    <button
                        className="col py-1 rounded px-2 ms-2"
                        onClick={() => getFilteredData(selectedYearList)}
                        style={{ backgroundImage: "linear-gradient(90deg, #DD4BEC, #5A7CF7)", color: "#fff", border: "none", outline: "none", }}
                    >
                        <Trans i18nKey="searchButton">Search</Trans>
                    </button>
                </div>
            </div>

            {
                lotteryResults && active &&
                lotteryResults?.map((item) =>
                    <div key={item?.day} onClick={() => setActive(item)}>
                        <PcsoResultAccordion item={item} active={active} setActive={setActive} />
                    </div>)
            }
        </div>
    );
};

export default THResults;
