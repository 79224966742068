import React, { useState } from 'react';
// import CustomSectionTitle from '../../../Components/CustomSectionTitle/CustomSectionTitle';
import CustomInputField from '../../../Components/CustomInputField/CustomInputField';
import { HiRefresh } from 'react-icons/hi';
import './Convert.css';
import TransactionReport from '../../../Components/TransactionReport/TransactionReport';
import { originNameOnly, rootUrl } from '../../../App';
import { fetchLoginUserWallet, fetchLogoutUser } from '../../../Redux/userSlice';
import { useDispatch } from 'react-redux';
import { showCustomAlert } from '../../../Redux/alertSlice';

import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import Header from '../../../Components/Header/Header';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useConvert } from '../../../Hooks/useConvert';

const Convert = () => {
    const dispatch = useDispatch();
    const [convertInputData, setConvertInputData] = useState({});

    const [opposite, setOpposite] = useState(["credit_balance"]);
    const [fromWallet, setFromWallet] = useState("coin_balance");
    const [toWallet, setToWallet] = useState(opposite[0]);
    const [status, setStatus] = useState({});

    const { convertCharge, minimumConvertCharge } = useConvert();

    const convertInputFunc = (e) => {
        let data = { ...convertInputData };
        data[e.target.name] = e.target.value;
        setConvertInputData(data);
    }


    const selectFromWallet = (e) => {
        if (e.target.value === "coin_balance") {
            setFromWallet("coin_balance");
            setOpposite(["credit_balance"]);
        }
        // if (e.target.value === "credit_balance") {
        //     setFromWallet("credit_balance");
        //     setOpposite(["reserve_balance", "coin_balance"])
        // } else if (e.target.value === "reserve_balance") {
        //     setFromWallet("reserve_balance")
        //     setOpposite(["credit_balance", "coin_balance"])
        // } else if (e.target.value === "coin_balance") {
        //     setFromWallet("coin_balance")
        //     setOpposite(["credit_balance", "reserve_balance"])
        // }
    }

    const selectToWallet = e => {
        setToWallet(e.target.value);
    }

    const convertFunction = () => {
        // e.preventDefault()
        let sendData = {
            from_wallet: fromWallet,
            to_wallet: toWallet,
            amount: parseFloat(convertInputData?.amountInput),
            request_origin: originNameOnly,
        }
        if (parseFloat(convertInputData?.amountInput) > 0) {
            dispatch(setInnerLoaderTrue());
            let stringValue = localStorage.getItem("bet7uUserKey")
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);
                fetch(`${rootUrl}/wallet/internal/`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Token ${value.value}`,
                    },
                    body: JSON.stringify(sendData)
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        dispatch(fetchLoginUserWallet());
                        // console.log(data);
                        setStatus(data);
                    })
                    .catch(err => {
                        err.json().then(text => {
                            setStatus({});
                            if (text?.error) {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${text?.error}`,
                                }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                return;
                            }
                        });
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${err?.statusText}`,
                        }));
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse());
                    })
            }
        } else {
            setStatus({});
            dispatch(showCustomAlert({
                type: "error",
                message: `Please Set Valid Amount`,
            }));
        }
    }

    return (
        <div className="inner-scroll convert">

            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="convertPage">Convert</Trans>} />
            </div>
            {/* <div className="row gx-0 d-flex justify-content-center align-items-center" style={{ color: "#000", opacity: 0.3, minHeight: "calc(100vh - 38px)" }} >
                <div className="col-11 d-flex justify-content-center align-items-center">
                    <h3 className="mb-0 text-center">Convert Unavailable...</h3>
                </div>
            </div> */}
            {
                status?.transaction === "complete" ?
                    <div className='my-4'>
                        <TransactionReport report={<Trans i18nKey="convertSuccessTitle" />} amount={status?.amount || 0.00} />
                    </div>
                    :
                    <>
                        <div className='mx-4 my-4'>
                            <div className='row g-0 d-flex align-items-center justify-content-between mb-3'>
                                <div className="col-12 col-md-12">
                                    <div className="input-group">
                                        <select onChange={selectFromWallet} name="from_wallet" value={fromWallet} id="fromInput" className="form-select">
                                            {/* <option value={"credit_balance"}>Credit Balance</option> */}
                                            {/* <option value={"reserve_balance"}>Reserve Balance</option> */}
                                            <option value={"coin_balance"}>{"Coin Balance"}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 d-flex align-items-center justify-content-center my-3">
                                    <HiRefresh className="text-center" size={25} />
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="input-group">
                                        <select onChange={selectToWallet} value={toWallet} name="to_wallet" id="toInput" className="form-select">
                                            {
                                                opposite?.map((wallet, index) => <option key={index} value={wallet}>{
                                                    (wallet === "credit_balance") && "Credit Balance"
                                                    // (wallet === "credit_balance") ? "Credit Balance"
                                                    //     : (wallet === "reserve_balance") ? "Reserve Balance"
                                                    //         : "Coin Balance"
                                                }</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <CustomInputField type="number" name="amountInput" label="Enter Amount" langLabel={<Trans i18nKey="amountInputConvertLangLabel" />} langPlaceHolder={t('amountInputConvertLangLabel')} value={convertInputData?.amountInput} func={convertInputFunc} icon1={false} icon2={false} />
                            <p className="my-0">
                                <i style={{ fontSize: "10px" }}> <span style={{ color: "red" }}>***</span> Your balance convert fees is <span style={{ color: "red", fontWeight: 'bold' }}>{convertCharge || 0.00}%</span></i>
                            </p>
                            <p className="my-0">
                                <i style={{ fontSize: "10px" }}> <span style={{ color: "red" }}>***</span> Your minimum convert amount is <span style={{ color: "red", fontWeight: 'bold' }}>${minimumConvertCharge || 0.00}</span></i>
                            </p>
                        </div>
                        <div className='row g-0 mx-4 my-4'>
                            <div className="d-flex align-items-center justify-content-center">
                                <button onClick={() => {
                                    convertFunction();
                                }} className="btn btn-yellow btn-sm w-100"><Trans i18nKey="convertButton">Convert</Trans></button>
                            </div>
                        </div>
                    </>
            }
        </div>
    );
};

export default Convert;