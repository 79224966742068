import { useDispatch } from "react-redux";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";
import { rootUrl } from "../App";
import { showCustomAlert } from "../Redux/alertSlice";

const useGetGSBalance = () => {
    const dispatch = useDispatch();

    const gsBalanceGetFetch = async (url = `${rootUrl}/gs/gs-credit/`, method = 'GET', headers = {}, body = null) => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);
            try {
                const response = await fetch(url, {
                    method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Token ${value?.value}`,
                    },
                    body: body ? JSON.stringify(body) : null,
                });

                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }

                const data = await response.json();
                return data;
            } catch (error) {
                console.error('Fetch error:', error);
                throw error;
            }
        }
    };

    const gsBalanceGetFetchAsync = async () => {
        try {
            const successData = await gsBalanceGetFetch();

            if (successData?.errMsg === "SUCCESS") {
                return successData;
            } else {
                throw new Error('Error in gsBalanceGetFetch');
            }
        } catch (error) {
            throw error;
        }
    };

    const gsBalanceGet = () => {

        dispatch(setInnerLoaderTrue());
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);

            // Make a GET GS balance request
            fetch(`${rootUrl}/gs/gs-credit/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value?.value}`,
                }
            })
                .then(response => response.json())
                .then(data => {
                    // Check if data is received
                    if (data && data?.errMsg === "SUCCESS") {
                        // Make a POST request with the received data
                        dispatch(setInnerLoaderFalse());
                        return true;

                    } else {
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `Try Again!`,
                        }));

                    }
                })
                .catch(error => {
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `Try Again!`,
                    }));
                });
        }
    }

    return {
        gsBalanceGet,
        gsBalanceGetFetch,
        gsBalanceGetFetchAsync
    };
};

export default useGetGSBalance;