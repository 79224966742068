import React from 'react';
import "./ProvidersLeftSideBar.css";

import { Link } from 'react-router-dom';
import { rootUrl } from '../../App';
// import { useDispatch } from 'react-redux';
// import { fetchLoginUserWallet } from '../../Redux/userSlice';

const ProvidersLeftSideBar = ({ selectedLeftBarData, setSelectedLeftBarData, leftSideBarData, setSelectedFragmentID, }) => {

    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    // const gsProviderGameLaunch = (redirectToken, providerCode, providerType) => {

    //     // const gameLaunchExtraparams = `&html5=1`;

    //     const mainDiv = document.getElementById("main");
    //     const getHeight = mainDiv.scrollHeight;
    //     const getWidth = mainDiv.clientWidth;

    //     // let fullUrl = `${rootUrl}/gs/game-launch/${providerCode}/${providerType}/?g_code=${gameCode}/${gameLaunchExtraparams}`;
    //     let fullUrl = `${rootUrl}/gs/game-launch/${providerCode}/${providerType}/`;

    //     fetch(fullUrl, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //             'Authorization': `Token ${redirectToken}`,
    //         }
    //     })
    //         .then(res => {
    //             if (!res.ok) throw res;
    //             else return res.json();
    //         })
    //         .then(data => {
    //             if (data?.errMsg === "SUCCESS") {
    //                 const launchWindow = window.open(`${data?.gameUrl}`, `<div id="launchWindow"></div>`, `width=${getWidth},height=${getHeight}`);
    //                 window.onload = () => {
    //                     launchWindow.opener.document.getElementById("launchWindow").innerHTML = `<div id=""></div>`;
    //                 }

    //                 dispatch(fetchLoginUserWallet());
    //             }
    //         })
    //         .catch(err => {
    //             err.json().then(text => {
    //                 if (text?.detail === "Invalid token.") {
    //                     // dispatch(fetchLogoutUser());
    //                     return;
    //                 }
    //             });
    //         })
    //         .finally(() => {
    //             // dispatch(setInnerLoaderFalse());
    //             // setPwaLoader(true);
    //         })
    // };

    const changeStateFunc = (item) => {

        // if (item?.extra_param === "iframe") {
        //     let stringValue = localStorage.getItem("bet7uUserKey");
        //     let value = JSON.parse(stringValue);
        //     if (value?.value) {
        //         setSelectedLeftBarData(item);
        //         gsProviderGameLaunch(value?.value, item?.provider_code, item?.game_code);
        //     } else {
        //         navigate("/login");
        //     }
        // } else {
        // }
        setSelectedLeftBarData(item);
    }
    return (
        <div className='col-2  pe-2'>
            <div className="left-bar-provider rounded p-1">
                {
                    leftSideBarData?.map(item =>
                        <Link
                            key={item?.name || item?.game_provider}
                            to={item?.to}
                            className={
                                (
                                    ((item?.name) && (selectedLeftBarData?.name === item?.name))
                                    ||
                                    ((item?.game_provider) && (selectedLeftBarData?.game_provider === item?.game_provider))
                                )
                                    ?
                                    'active rounded mb-2'
                                    : 'sqrBtn rounded mb-2'}
                            onClick={() => changeStateFunc(item)}
                            style={{ textDecoration: "none" }}
                        >
                            <div className='d-flex flex-column justify-content-center align-items-center m-0 p-0 text-center'>
                                <img src={(((item?.name) && (selectedLeftBarData?.name === item?.name)) ? item?.icon1 : item?.icon2) || (rootUrl + item?.thumbnail)} alt={item?.name} width={item?.icon1 || "28px"} />
                                <p>{item?.name || item?.game_provider}</p>
                            </div>
                        </Link>
                    )
                }
            </div>
        </div>
    );
};

export default ProvidersLeftSideBar;