import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showCurrGameUrl: null
}

export const launcherSlice = createSlice({
    name: 'launcherSlice',
    initialState,
    reducers:{
        setgameFrame:  (state, action) => {
            state.showCurrGameUrl = action.payload
        }
    }
})

export const selectedGame = state => state.launchGame.showCurrGameUrl;
export const { setgameFrame} = launcherSlice.actions

export default launcherSlice.reducer