import { useEffect, useState } from "react";
import { rootUrl } from "../App";
import { useDispatch } from "react-redux";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";



export const useAdminAgentGateways = () => {
    const dispatch = useDispatch();
    const [addAgentAdminGateways, setAddAgentAdminGateways] = useState([]);

    useEffect(() => {
        dispatch(setInnerLoaderTrue());
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);

            fetch(`${rootUrl}/payment/gateway-agent-list/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => setAddAgentAdminGateways(data))
                .catch(err => {
                    err.json().then(text => {
                        if (text?.error) {
                            return;
                        }
                        if (text?.detail === "Invalid token.") {
                            return;
                        }
                    });
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse());
                })
        }

    }, [dispatch]);


    return {
        addAgentAdminGateways,
    }
}
