import React from 'react';
import { useNavigate } from 'react-router-dom';
import successTick from '../../Assets/Images/Registration/Group 414.svg';
import { useSelector } from 'react-redux';
import { userDetail } from '../../Redux/userSlice';
import { Trans } from 'react-i18next';

const SuccessComponent = ({ title }) => {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);

    // useEffect(() => {
    //     dispatch(setRegistrationData(null));
    // }, [dispatch])
    return (
        <div className='inner-scroll'>
            <div className='position-relative'>
                <h3 className="text-center p-3">{title || `Successfull`}</h3>
            </div>

            <div>
                <div className='d-flex align-items-center justify-content-center'>
                    <img className='successTick' src={successTick} alt="successTick" />
                </div>
                <p className='p-0 py-2 m-0 text-center'></p>

                <div className='mx-4 mb-4'>
                    <div className='d-flex justify-content-center'>
                        {/* <button onClick={() => navigate("/login", { replace: true })} type='button' className="btn btn-yellow my-2 w-100">Log in your account</button> */}
                        <button onClick={() => navigate(`/deposit${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`, { replace: true })} type='button' className="btn btn-yellow my-2 w-100"><Trans i18nKey="backToLoginButton" /></button>
                    </div>

                    <div className='d-flex justify-content-center'>
                        <button onClick={() => navigate("/", { replace: true })} type='button' className="btn btn-white my-2 w-100"><Trans i18nKey="backToHomeButton" /></button>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default SuccessComponent;