import { useEffect, useState } from "react";
import { origin, rootUrl } from "../App";
import { useDispatch, useSelector } from "react-redux";

import { showCustomAlert } from "../Redux/alertSlice";
import { fetchLogoutUser, userDetail } from "../Redux/userSlice";

export const useWithdrawal = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);

    const [withdrawPercent, setWithdrawPercent] = useState([]);
    const [minWithDrawalData, setMinWithDrawalData] = useState([]);

    useEffect(() => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            // dispatch(setInnerLoaderTrue());
            let value = JSON.parse(stringValue);

            fetch(`${rootUrl}/wallet/withdraw/percentage/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    setWithdrawPercent(data[0]);
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.detail}`,
                            }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse()) 
                })

            fetch(`${rootUrl}/wallet/limits/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    setMinWithDrawalData(data[0]);
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(() => {
                    // dispatch(setInnerLoaderFalse()) 
                })

        }

    }, [dispatch, userInfo]);

    return {
        withdrawPercent,
        minWithDrawalData,
    };
};