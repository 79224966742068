import React, { useEffect, useState } from 'react';
// import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import { Col, Row } from 'react-bootstrap';
import { agentActivities } from '../../../../Data/AgentAdminData';
import './AgentAdmin.css';
import { selectAgentBankingState, setSelectAgentBankingState } from '../../../../Redux/agentAdminSlice';
import { useDispatch, useSelector } from 'react-redux';
import { userDetail } from '../../../../Redux/userSlice';
import Header from '../../../../Components/Header/Header';
import { Trans } from 'react-i18next';

const AgentAdmin = () => {

    const dispatch = useDispatch();

    const userInfo = useSelector(userDetail);
    const selectAgentAdminBankingState = useSelector(selectAgentBankingState);
    const [toggleState, setToggleState] = useState(agentActivities);

    useEffect(() => {
        if (userInfo.user?.agent) {
            setToggleState(prev => [...prev,])
        }
    }, [userInfo?.user]);

    return (
        <div className="inner-scroll agent-admin">

            <div className='sticky-top'>
                <Header pageTitle={<Trans i18nKey="agentAdminPage">Agent</Trans>} />
            </div>

            {/* <div className='mx-4 my-4'>
                <CustomSectionTitle title={"Agent Admin"} page={"agentAdmin"} />
            </div> */}
            <div className='mx-4 my-4'>
                <div className="row gx-0">
                    <div className="col-12 text-center">
                        <Row xs={2} sm={2} md={2} className="g-2">
                            {
                                agentActivities?.map((item, index) =>
                                    <Col key={item?.id} className="activity-item">
                                        <div className={toggleState[selectAgentAdminBankingState]?.id === item?.id ? "selected-item border-0 rounded px-0 py-2" : "regular-item border-0 rounded px-0 py-2"} style={{
                                            backgroundColor: ((toggleState[selectAgentAdminBankingState]?.id === item?.id) && (item?.id === 0)) ? "red" : ((toggleState[selectAgentAdminBankingState]?.id === item?.id) && (item?.id === 1)) && "var(--green)",
                                            color: ((toggleState[selectAgentAdminBankingState]?.id === item?.id) && (item?.id === 0)) ? "#fff" : ((toggleState[selectAgentAdminBankingState]?.id === item?.id) && (item?.id === 1)) && "#fff",
                                        }}
                                            onClick={() =>
                                                // setToggleState(item)
                                                dispatch(setSelectAgentBankingState(item?.id))
                                            } >
                                            <img src={item?.icon} alt="admin-activity" width="24px" style={{
                                                filter: ((toggleState[selectAgentAdminBankingState]?.id === item?.id) && (item?.id === 0)) ? "invert(100%)" : ((toggleState[selectAgentAdminBankingState]?.id === item?.id) && (item?.id === 1)) && "invert(100%)",
                                            }} />
                                            <h6 className="mt-2 fw-bold">{item?.cardTitle}</h6>
                                        </div>
                                        {/* agentActions[SelectAgentBankingState].id === action.id */}
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                </div>
            </div>
            <div className="mx-4 my-4 row g-0">
                <div className="col">
                    <div className="d-flex flex-column justify-content-center" style={{}}>
                        {toggleState[selectAgentAdminBankingState]?.component || ``}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentAdmin;