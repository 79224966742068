import sportGoldenIcon from '../Assets/Icons/NavIcon/Sposrts_golden.svg';
import sportGrayIcon from '../Assets/Icons/NavIcon/Sposrts_gray.svg';

import lotteryGoldenIcon from '../Assets/Icons/NavIcon/Lottery_golden.svg';
import lotteryGrayIcon from '../Assets/Icons/NavIcon/Lottery_gray.svg';

import liveCasinoGoldenIcon from '../Assets/Icons/NavIcon/liveCasino_golden.svg';
import liveCasinoGrayIcon from '../Assets/Icons/NavIcon/liveCasino_gray.svg';

// import casinoGoldenIcon from '../Assets/Icons/NavIcon/Casino_gold.svg';
// import casinoGrayIcon from '../Assets/Icons/NavIcon/Casino_gray.svg';

import slotsGoldenIcon from '../Assets/Icons/NavIcon/Slots_golden.svg';
import slotsGrayIcon from '../Assets/Icons/NavIcon/Slots_gray.svg';

// import virtualGamesGoldenIcon from '../Assets/Icons/NavIcon/virtualGamesGoldenIcon.svg';
// import virtualGamesGrayIcon from '../Assets/Icons/NavIcon/virtualGamesGrayIcon.svg';

import fishingGoldenIcon from '../Assets/Icons/NavIcon/fishingGoldenIcon.svg';
import fishingGrayIcon from '../Assets/Icons/NavIcon/fishingGrayIcon.svg';

import cardsGoldenIcon from '../Assets/Icons/NavIcon/cardsGoldenIcon.svg';
import cardsGrayIcon from '../Assets/Icons/NavIcon/cardsGrayIcon.svg';

// import exchangeGoldenIcon from '../Assets/Icons/NavIcon/exchangeGoldenIcon.svg';
// import exchangeGrayIcon from '../Assets/Icons/NavIcon/exchangeGrayIcon.svg';


// import inPlayDarkIcon from '../Assets/Icons/HomeLeftIcons/inPlayDark.svg';
// import inPlayGrayIcon from '../Assets/Icons/HomeLeftIcons/inplayGray.svg';

import promotionDarkIcon from '../Assets/Icons/HomeLeftIcons/promotionDark.svg';
import promotionGrayIcon from '../Assets/Icons/HomeLeftIcons/promotionGray.svg';

import affiliateDarkIcon from '../Assets/Icons/HomeLeftIcons/affiliateDark.svg';
import affiliateGrayIcon from '../Assets/Icons/HomeLeftIcons/affiliateGray.svg';

// import liveEventDarkIcon from '../Assets/Icons/HomeLeftIcons/liveEventDark.svg';
// import liveEventGrayIcon from '../Assets/Icons/HomeLeftIcons/liveEventGray.svg';

import webinarDarkIcon from '../Assets/Icons/HomeLeftIcons/webinarDarkIcon.svg';
import webinarGrayIcon from '../Assets/Icons/HomeLeftIcons/webinarGrayIcon.svg';

import languageDarkIcon from '../Assets/Icons/HomeLeftIcons/languageDark.svg';
import languageGrayIcon from '../Assets/Icons/HomeLeftIcons/languageGray.svg';

import agentListDarkIcon from '../Assets/Icons/HomeLeftIcons/agentListDark.svg';
import agentListGrayIcon from '../Assets/Icons/HomeLeftIcons/agentListGray.svg';
// import { Trans } from 'react-i18next';



export const navbarData = [
    {
        id: 0,
        name: 'homeNav',
        to: '/home/sports',
        icon1: sportGoldenIcon,
        icon2: sportGrayIcon,
    },
    // {
    //     id: 1,
    //     name: 'exchangeNav',
    //     // to: '',
    //     icon1: exchangeGoldenIcon,
    //     icon2: exchangeGrayIcon,
    // },
    {
        id: 2,
        name: 'sportsNav',
        to: '/home/sports',
        icon1: sportGoldenIcon,
        icon2: sportGrayIcon,
    },
    {
        id: 3,
        name: 'lotteryNav',
        to: '/home/lottery',
        icon1: lotteryGoldenIcon,
        icon2: lotteryGrayIcon,
    },
    {
        id: 4,
        name: 'casinoNav',
        to: '/home/live_casino',
        icon1: liveCasinoGoldenIcon,
        icon2: liveCasinoGrayIcon,
    },
    // {
    //     id: 5,
    //     name: 'casinoNav',
    //     to: '/home/casino',
    //     icon1: casinoGoldenIcon,
    //     icon2: casinoGrayIcon,
    // },
    {
        id: 6,
        name: 'slotsNav',
        to: '/home/slots',
        icon1: slotsGoldenIcon,
        icon2: slotsGrayIcon,
    },
    // {
    //     id: 7,
    //     name: 'virtualSportsNav',
    //     to: '/home/virtual_sport',
    //     icon1: virtualGamesGoldenIcon,
    //     icon2: virtualGamesGrayIcon,
    // },
    {
        id: 8,
        name: 'FISHING',
        to: '/home/fishing',
        icon1: fishingGoldenIcon,
        icon2: fishingGrayIcon,
    },
    {
        id: 9,
        name: 'TABLE',
        to: '/home/table',
        icon1: cardsGoldenIcon,
        icon2: cardsGrayIcon,
    },
]

export const homeLeftData = [
    // {
    //     id: 0,
    //     name: 'In-Play',
    //     // to: "/login",
    //     icon1: inPlayDarkIcon,
    //     icon2: inPlayGrayIcon,
    // },
    // {
    //     id: 1,
    //     name: 'Today',
    //     // to: "/login",
    //     icon1: todayDarkIcon,
    //     icon2: todayGrayIcon,
    // },
    // {
    //     id: 2,
    //     name: 'Tomorrow',
    //     // to: "/login",
    //     icon1: tomorrowDarkIcon,
    //     icon2: tomorrowGrayIcon,
    // },
    {
        id: 1,
        name: 'Promotion',
        to: "/promotion",
        icon1: promotionDarkIcon,
        icon2: promotionGrayIcon,
    },
    {
        id: 2,
        name: 'Affiliate',
        to: "/affiliate",
        icon1: affiliateDarkIcon,
        icon2: affiliateGrayIcon,
    },
    {
        id: 3,
        name: 'Webinar',
        to: "/webinar",
        icon1: webinarDarkIcon,
        icon2: webinarGrayIcon,
    },
    {
        id: 4,
        name: 'Language',
        to: "/languages",
        icon1: languageDarkIcon,
        icon2: languageGrayIcon,
    },
    {
        id: 5,
        name: 'Agent List',
        to: "/verified_agent",
        icon1: agentListDarkIcon,
        icon2: agentListGrayIcon,
    },
]