import { useEffect, useState } from "react";
import { rootUrl } from "../App";
import { addDataIntoCache, getCacheData, } from "../Redux/sportsExchangeSlice";
// import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

export const useProvidersLeftSideBar = () => {
    const [leftSideBarData, setLeftSideBarData] = useState([]);


    useEffect(() => {
        const fetchLeftSideBarData = async () => {
            const cacheDataOfLeftSideBar = await getCacheData('leftSideBarDataCache', `${rootUrl}/gs/provider-list/`);

            if (cacheDataOfLeftSideBar) {

                // Get the current time
                const currentTime = new Date();

                // Assuming you have a cached time stored as a Date object
                const cachedTimeStringOfLeftSideBar = cacheDataOfLeftSideBar?.updateDate; /* Get the cached time from wherever it's stored */

                // Convert the cached time string to a Date object
                const cachedTimeOfLeftSideBar = new Date(cachedTimeStringOfLeftSideBar);

                // Define a threshold (in milliseconds) for when to hit the API
                const cacheExpirationThreshold = (60 * 1000) * 60 * 24; // 24 hour (adjust as needed)

                // Calculate the time difference in milliseconds
                const timeDifferenceOfLeftSideBar = currentTime - cachedTimeOfLeftSideBar;

                if (timeDifferenceOfLeftSideBar < cacheExpirationThreshold) {
                    setLeftSideBarData(cacheDataOfLeftSideBar?.data);
                    // setLeftSideBarDataState(false);
                    return null;
                }
            }

            try {
                let response = await fetch(`${rootUrl}/gs/provider-list/`);

                if (!response.ok) throw response;
                else {
                    let data = await response.json();
                    addDataIntoCache(`leftSideBarDataCache`, `${rootUrl}/gs/provider-list/`, {
                        data: data,
                        updateDate: new Date()
                    });
                    setLeftSideBarData(data);
                    return data;
                }
            } catch (error) {
                console.log(error);
            } finally {
                // setLeftSideBarData(false);
            }
        }
        fetchLeftSideBarData();
    }, []);


    return {
        leftSideBarData,
    }
}