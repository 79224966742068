import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
// import { Vir2alGames } from '../../Redux/gameSlice';
// import { homeLeftData } from '../../Data/HomePageData';
// import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';
import GameTitle from '../../Components/GameTitle/GameTitle';
import GameCard from '../../Components/GameCard/GameCard';
import ProvidersLeftSideBar from '../../Components/ProvidersLeftSideBar/ProvidersLeftSideBar';
// import { fishingLeftData } from '../../Data/FishingPageData';
import { leftData } from '../../Data/ProvidersPageData';
import { useProvidersLeftSideBar } from '../../Hooks/useProvidersLeftSideBar';
import useGameData from '../../Hooks/useGameData';

const Fishing = () => {
    const { leftSideBarData } = useProvidersLeftSideBar();

    const gameCode = "FH";

    const leftFishingSideBar = leftSideBarData?.filter((item) => (item?.game_code)?.includes(gameCode));

    // const virtualGames = useSelector(Vir2alGames);
    const [selectedLeftBarData, setSelectedLeftBarData] = useState(leftData[0]);
    const fishingGames = useGameData(gameCode, selectedLeftBarData?.provider_code);

    // const showableExtraCards = (selectedLeftBarData?.extra_param === "iframe") ? [selectedLeftBarData] : fishingGames;

    const iframes = leftFishingSideBar?.filter((item) => item?.extra_param === "iframe");
    const notIframes = leftFishingSideBar?.filter((item) => item?.extra_param !== "iframe");

    const showableExtraCards = (selectedLeftBarData?.name === "All") ? iframes.concat(fishingGames) : (selectedLeftBarData?.type === "lobby") ? iframes : fishingGames;


    return (
        <section className='row g-0 p-2'>
            {
                leftFishingSideBar &&
                <ProvidersLeftSideBar selectedLeftBarData={selectedLeftBarData || leftData[0]} setSelectedLeftBarData={setSelectedLeftBarData} leftSideBarData={[...leftData, ...notIframes]} />
            }
            <div className="game-card-area gx-0 col-10">
                <GameTitle title={"Fishing"} />
                <GameCard games={showableExtraCards} providerType={gameCode} />
            </div>
        </section>
    );
};

export default Fishing;