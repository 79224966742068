import React, { useCallback, useEffect, useState } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import useTime from '../../../../Hooks/useTime';
import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { selectAgentBankingState } from '../../../../Redux/agentAdminSlice';
import { agentActivities } from '../../../../Data/AgentAdminData';
import { fetchLoginUserWallet, fetchLogoutUser, userDetail } from '../../../../Redux/userSlice';
import { origin, rootUrl } from '../../../../App';
import { useTablePagination } from '../../../../Hooks/useTablePagination';
import { Form, Modal } from 'react-bootstrap';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import { showCustomAlert } from '../../../../Redux/alertSlice';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const buttonList = [
    {
        id: 0,
        name: 'In Review',
        buttonName: <Trans i18nKey="inreviewButton">In Review</Trans>,
        status: 'in_review',
        url: '',
    },
    {
        id: 1,
        name: 'Success',
        buttonName: <Trans i18nKey="successButton">Success</Trans>,
        status: 'successful',
        url: '',
    },
    {
        id: 2,
        name: `I'm busy`,
        buttonName: <Trans i18nKey="busyButton">I'm busy</Trans>,
        status: 'busy',
        url: '',
    },
];


const WithdrawalRequest = () => {
    const dispatch = useDispatch();
    const { convertDate, convertTime } = useTime();
    const userInfo = useSelector(userDetail);
    const { visibleRow, increaseVisibleDataIndex, decreaseVisibleDataIndex, } = useTablePagination();

    const [adminTransactions, setAdminTransactions] = useState([]);

    const selectAgentAdminBankingState = useSelector(selectAgentBankingState);
    const [toggleState, setToggleState] = useState(agentActivities);

    useEffect(() => {
        if (userInfo.user?.agent) {
            setToggleState(prev => [...prev,])
        }
    }, [userInfo?.user]);

    const [activeButton, setActiveButton] = useState(buttonList[0]);

    const getAgentWithdrawalRequest = useCallback(() => {
        dispatch(setInnerLoaderTrue());
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);

            fetch(`${rootUrl}/wallet/agent-withdrawal-list/${origin}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    dispatch(fetchLoginUserWallet());
                    // closeAgentRequestModal();
                    // getWithdrawHistory();
                    let tempData = data.filter(item => item.status === activeButton?.status);
                    setAdminTransactions(tempData);
                })
                .catch(err => console.log(err))
                .finally(
                    () => dispatch(setInnerLoaderFalse())
                )
        }
    }, [dispatch, activeButton]);

    useEffect(() => {
        getAgentWithdrawalRequest();
    }, [getAgentWithdrawalRequest, activeButton]);


    // console.log(adminTransactions);

    // modal functionalities

    const [selectImage, setSelectImage] = useState(null);
    const [agentRequestModalData, setAgentRequestModalData] = useState({
        visible: false,
        item: {},
    });
    // console.log(agentRequestModalData);

    const [editableData, setEditableData] = useState(null);
    // console.log(editableData)

    const openAgentRequestModal = (item) => {
        setEditableData(item);
        setAgentRequestModalData({
            visible: true,
            item,
        });
    }

    const closeAgentRequestModal = () => {
        setAgentRequestModalData({
            visible: false,
            item: {},
        });
        setSelectImage(null);
    }

    const inputPic = (e) => {
        const newFile = e.target.files[0];
        if (((e.target.files[0].type).split('/')[0]) === 'image') {
            setSelectImage(newFile);
        } else {
            alert("select an image file")
            //   dispatch(setCustomAlertTrue({ massage: `select an image file`, type: 'danger' }));
        }
    }

    // update button with PATCH method
    const agentRequestUpdateButton = () => {

        if (editableData?.id) {
            // let data = {
            //     id: +editableData?.id,
            //     amount: editableData?.exchange_amount,
            //     status: editableData?.status,
            //     busy_reason: editableData?.busy_reason,
            //     busy_screenshot: selectImage || (editableData?.busy_screenshot?.split("/").pop()) || "",
            // }
            // console.log(data);

            const formData = new FormData();
            formData.append("id", +editableData?.id);
            formData.append("amount", editableData?.exchange_amount);
            formData.append("status", editableData?.status);
            formData.append("busy_reason", editableData?.busy_reason || "");
            formData.append("busy_screenshot", selectImage || editableData?.busy_screenshot || "");
            // formData.append("busy_screenshot", selectImage || editableData?.busy_screenshot?.split("/").pop() || "");

            // if (editableData?.busy_reason) {
            //     formData.append("busy_reason", editableData?.busy_reason);
            // }
            // if (selectImage) {
            //     formData.append("busy_screenshot", selectImage);
            // }

            let stringValue = localStorage.getItem("bet7uUserKey")
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);
                dispatch(setInnerLoaderTrue());
                fetch(`${rootUrl}/wallet/agent-withdrawal-details/${origin}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Token ${value.value}`,
                    },
                    body: formData
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        // console.log(data);
                        // dispatch(setCustomAlertTrue({ massage: data.report, type: 'success' }));
                        getAgentWithdrawalRequest();
                        closeAgentRequestModal();
                    })
                    .catch(err => {
                        err.json().then(text => {
                            // console.log(text);
                            if (text?.error) {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${text?.error}`,
                                }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                return;
                            }
                            if (text?.detail) {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${text?.detail}`,
                                }));
                                return;
                            }
                        });
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${err.statusText}`,
                        }));
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse());
                    })
            }
        } else {
            // dispatch(setCustomAlertTrue({ massage: `All Field Required`, type: 'danger' }));
        }
    }
    // console.log(agentRequestModalData);

    return (
        <>
            <div className="">
                <CustomSectionTitle title={toggleState[selectAgentAdminBankingState]?.cardTitle} page={"agentAdmin"} />
            </div>
            <div className="my-4">
                <div className='d-flex align-items-center justify-content-center'>
                    {
                        buttonList?.map((buttonData, index) =>
                            <button key={buttonData?.id || index} onClick={() => setActiveButton(buttonData)} className={activeButton?.id === buttonData?.id ? "px-1 py-0 btn btn-yellow btn-sm mx-2" : "px-1 py-0 btn btn-sm btn-green mx-2"} style={{ border: "none", }} >{buttonData?.buttonName}</button>

                        )
                    }
                </div>
            </div>
            <div className='transactionHistoryTable'>
                {
                    (adminTransactions?.length === 0) ?
                        // dataless view
                        <div className="d-flex justify-content-center align-items-center mt-5 history-blurred-text text-center" >
                            <h3 className="mb-0">No Records</h3>
                        </div> :

                        // data table
                        <table className="table table-bordered border-golden">
                            <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                                <tr>
                                    {/* <th className='text-center' scope="col">SL.</th> */}
                                    <th className='text-center' scope="col">Date</th>
                                    <th className='text-center' scope="col">Gateway</th>
                                    <th className='text-center' scope="col">Amount</th>
                                    <th className='text-center' scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    adminTransactions?.length > 0 &&
                                    adminTransactions?.slice(visibleRow, visibleRow + 10).map((item, index) =>
                                        <tr key={item.id}>
                                            {/* <th className='text-center' scope="row">{adminTransactions.indexOf(item) + 1}</th> */}
                                            <td className='text-center'> {convertDate(item.created_at)}</td>
                                            <td className='text-center fw-bold'> {item?.gateway_title}</td>
                                            <td className='text-center fw-bold' style={{ color: "var(--green)", }}> + ${item?.withdraw_amount}</td>
                                            <td>
                                                <div className='text-center' style={{ cursor: "pointer" }} onClick={() => {
                                                    openAgentRequestModal(item);
                                                }}>
                                                    <BsExclamationCircle size={20} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                }

                {/* pagination buttons */}
                <div className={(adminTransactions?.length === 0) ? "d-none" : 'd-flex justify-content-end mb-3'}>
                    <div>
                        <button onClick={decreaseVisibleDataIndex} className={visibleRow === 0 ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow === 0 ? true : false}><Trans i18nKey="prev">PREV.</Trans></button>
                        <button onClick={increaseVisibleDataIndex} className={visibleRow + 10 >= adminTransactions?.length ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow + 10 >= adminTransactions?.length ? true : false}><Trans i18nKey="next">NEXT</Trans></button>
                    </div>
                </div>
            </div>

            <Modal className='transactionModal' fullscreen={false} show={agentRequestModalData.visible}>
                <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                    <div className='w-100'>

                        {/* modal top section */}

                        <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                            <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                <button onClick={closeAgentRequestModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>

                                <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{toggleState[selectAgentAdminBankingState]?.cardTitle}</span>

                                <button onClick={closeAgentRequestModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>
                            </div>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <div className='row g-0 m-1'>
                                <div className="d-flex flex-column align-items-center justify-content-between">
                                    <p style={{ color: "var(--green)", fontWeight: "bold", textAlign: "center", fontSize: "30px", margin: 0 }}>+ ${agentRequestModalData?.item?.withdraw_amount}</p>
                                    <div className='row g-0 mx-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className='me-2'>Withdrawal Request From : </div>
                                            <div> {agentRequestModalData?.item?.gateway_title}</div>
                                        </div>
                                    </div>
                                    <div className='row g-0 mx-1'>
                                        <div className="d-flex flex-column align-items-center justify-content-between">
                                            <p className='text-center m-0 p-0 mt-2' style={{ fontSize: "16px", color: "red", }}>
                                                *** Exchange rate : <span className='fw-bold'>{agentRequestModalData?.item?.currency_rate || `0.00`}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* modal bottom section */}

                        <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Transaction Date :</div>
                                    <div>{convertDate((agentRequestModalData?.item)?.created_at)} <span className="ms-1">{convertTime((agentRequestModalData?.item)?.created_at)}</span></div>
                                </div>
                            </div>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Trx ID :</div>
                                    <div>{agentRequestModalData?.item?.trx_id}</div>
                                </div>
                            </div>

                            {
                                agentRequestModalData?.item?.payment_gateway === "bank" &&
                                <>
                                    <div className='row g-0 m-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>Bank Account Name :</div>
                                            <div>{agentRequestModalData?.item?.bank_account_name}</div>
                                        </div>
                                    </div>

                                    <div className='row g-0 m-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>Bank Branch Code :</div>
                                            <div>{agentRequestModalData?.item?.bank_branch_code}</div>
                                        </div>
                                    </div>

                                    <div className='row g-0 m-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>Bank Country :</div>
                                            <div>{agentRequestModalData?.item?.bank_country}</div>
                                        </div>
                                    </div>

                                    <div className='row g-0 m-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>Bank Branch :</div>
                                            <div>{agentRequestModalData?.item?.bank_branch}</div>
                                        </div>
                                    </div>

                                    <div className='row g-0 m-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>Bank Name :</div>
                                            <div>{agentRequestModalData?.item?.bank_name}</div>
                                        </div>
                                    </div>

                                    <div className='row g-0 m-1'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>Bank Swift Code :</div>
                                            <div>{agentRequestModalData?.item?.bank_swift_code}</div>
                                        </div>
                                    </div>


                                </>
                            }

                            {
                                agentRequestModalData?.item?.exchange_amount &&
                                <div className={(agentRequestModalData?.item?.status === "busy") ? "d-none" : 'row g-0 m-1'}>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Exchange Amount:</div>
                                        <div>{agentRequestModalData?.item?.exchange_amount}</div>
                                    </div>
                                </div>
                            }

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>To Account:</div>
                                    <div>{agentRequestModalData?.item?.to_account}</div>
                                </div>
                            </div>

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Status:</div>
                                    {
                                        (agentRequestModalData?.item?.status === "in_review") ?

                                            <Form.Select onChange={e => {
                                                let data = { ...editableData };
                                                data.status = e.target.value;
                                                setEditableData(data);
                                            }}
                                                className="w-50 px-1 py-0 form-control" value={editableData?.status} aria-label="Default select example" >
                                                {
                                                    buttonList?.map(item => <option key={item?.id} value={item?.status}>{item?.name}</option>)
                                                }
                                            </Form.Select>
                                            :
                                            <div className="text-capitalize fw-bold" style={(agentRequestModalData?.item?.status === "successful") ? { color: "#7DAA2F", } : (agentRequestModalData?.item?.status === "busy") ? { color: "#c30010", } : { color: "#000", }}>{agentRequestModalData?.item?.status}</div>
                                    }
                                </div>
                            </div>

                            {
                                (editableData?.status === "busy" || editableData?.status === "successful") &&
                                <>
                                    <div className='row g-0 m-1 my-2'>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div>Reason:</div>
                                            {
                                                (agentRequestModalData?.item?.busy_reason) ?
                                                    <div>{agentRequestModalData?.item?.busy_reason}</div>
                                                    :
                                                    <div className="w-50">
                                                        <textarea className="form-control" rows="1" placeholder={editableData?.status === "busy" ? "I'm busy for..." : editableData?.status === "successful" && "Type your message..."} value={editableData?.busy_reason} onChange={e => {
                                                            let data = { ...editableData };
                                                            data.busy_reason = e.target.value;
                                                            setEditableData(data);
                                                        }}></textarea>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    {
                                        agentRequestModalData?.item?.busy_screenshot ?
                                            <div className='row g-0'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <div className='imgModalContainer'>
                                                        <div className="modalImage">
                                                            <img src={editableData?.busy_screenshot} alt="withdrawal-request-busy" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className='my-4 d-flex flex-column align-items-center justify-content-center'>
                                                    <input className='d-none' type="file" id="profilePic" onChange={inputPic} />
                                                    <label htmlFor='profilePic'>
                                                        <div className='btn btn-primary'>
                                                            <AiOutlineCloudUpload style={{ fontSize: "30px", marginRight: "10px" }} />
                                                            <span>{t('uploadScreenShootButton')}</span>
                                                        </div>
                                                    </label>
                                                </div>
                                                {
                                                    selectImage !== null &&
                                                    <div className='row g-0'>
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <div className='imgModalContainer'>
                                                                <div className="modalImage">
                                                                    <img src={`${URL.createObjectURL(selectImage)}`} alt="busy_screenshot" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                    }
                                </>
                            }

                            <div className={((editableData?.status === "in_review") || (((agentRequestModalData?.item?.status === "successful") || (agentRequestModalData?.item?.status === "busy")) && ((agentRequestModalData?.item?.busy_screenshot) && (agentRequestModalData?.item?.busy_reason)))) ? "d-none" : 'my-4 d-flex flex-column align-items-center justify-content-center'}>
                                <button className='btn btn-yellow w-25 py-1' style={{ coursor: 'pointer' }} onClick={agentRequestUpdateButton}>
                                    Continue
                                </button>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default WithdrawalRequest;