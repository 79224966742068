import React, { useCallback, useEffect, useState } from 'react';
import CustomSectionTitle from '../../../../Components/CustomSectionTitle/CustomSectionTitle';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogoutUser, userDetail } from '../../../../Redux/userSlice';
import { selectAgentBankingState } from '../../../../Redux/agentAdminSlice';
import { agentActivities } from '../../../../Data/AgentAdminData';
import { BsExclamationCircle } from 'react-icons/bs';
import { useTablePagination } from '../../../../Hooks/useTablePagination';
import { origin, rootUrl } from '../../../../App';
import { Modal } from 'react-bootstrap';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { ImCross } from 'react-icons/im';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

const GatewayStatus = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(userDetail);
    const { visibleRow, increaseVisibleDataIndex, decreaseVisibleDataIndex, } = useTablePagination();

    const [adminTransactions, setAdminTransactions] = useState([]);

    const selectAgentAdminBankingState = useSelector(selectAgentBankingState);
    const [toggleState, setToggleState] = useState(agentActivities);

    useEffect(() => {
        if (userInfo.user?.agent) {
            setToggleState(prev => [...prev,])
        }
    }, [userInfo?.user]);

    const getGatewayRequestStatusHistory = useCallback(() => {
        dispatch(setInnerLoaderTrue());
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            let value = JSON.parse(stringValue);

            fetch(`${rootUrl}/payment/agent-gateway-list/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    setAdminTransactions(data);
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
                .finally(
                    () => { dispatch(setInnerLoaderFalse()) }
                )
        }
    }, [dispatch]);

    useEffect(() => {
        getGatewayRequestStatusHistory();
    }, [getGatewayRequestStatusHistory]);

    // modal functionalities

    const [selectImage, setSelectImage] = useState(null);
    const [walletModalData, setWalletModalData] = useState({
        visible: false,
        item: null,
    });

    const closeWalletModal = (item) => {
        setWalletModalData({
            visible: false,
            item: null,
        });
        setSelectImage(null);
    }

    const agentUpdateBtn = () => {
        // let data = {
        //   id: walletModalData.item.id,
        //   Agent_status:'pending',
        //   gateway_id : walletModalData.item.gateway_id,
        //   gateway_image: selectImage
        // }
        // console.log(data)

        if (walletModalData.item) {
            const formData = new FormData();
            formData.append("id", +walletModalData?.item?.id);
            //   formData.append("agent_status",'pending'); // an optional field
            formData.append("gateway_id", walletModalData?.item?.gateway_id);
            if (selectImage) {
                // console.log(selectImage);
                formData.append("gateway_image", selectImage);
            } else {
                // console.log(selectImage);
                formData.append("gateway_image", null);
            }

            let stringValue = localStorage.getItem("bet7uUserKey")
            if (stringValue !== null) {
                let value = JSON.parse(stringValue);
                dispatch(setInnerLoaderTrue());
                fetch(`${rootUrl}/payment/agent-gateway-list/${origin}`, {
                    method: 'PATCH',
                    headers: {
                        'Authorization': `Token ${value.value}`,
                    },
                    body: formData
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        // dispatch(setCustomAlertTrue({ massage: data.report, type: 'success' }));
                        closeWalletModal();
                    })
                    .catch(err => {
                        err.json().then(text => {
                            // console.log(text);
                            if (text?.error) {
                                // dispatch(setCustomAlertTrue({ massage: `${text?.error}`, type: 'danger' }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                dispatch(fetchLogoutUser());
                                return;
                            }
                            if (text?.detail) {
                                // dispatch(setCustomAlertTrue({ massage: `${text?.detail}`, type: 'danger' }));
                                return;
                            }
                        });
                        // dispatch(setCustomAlertTrue({ massage: `${err.statusText}`, type: 'danger' }));
                    })
                    .finally(() => {
                        dispatch(setInnerLoaderFalse());
                    })
            }
        } else {
            //   dispatch(setCustomAlertTrue({ massage: `All Field Required`, type: 'danger' }));
        }
    }

    const inputPic = (e) => {
        const newFile = e.target.files[0];
        if (((e.target.files[0].type).split('/')[0]) === 'image') {
            setSelectImage(newFile);
        } else {
            alert("select an image file")
            // dispatch(setCustomAlertTrue({ massage: `select an image file`, type: 'danger' }));
        }
    }

    return (
        <>
            <div className="mb-4">
                <CustomSectionTitle title={toggleState[selectAgentAdminBankingState]?.cardTitle} page={"agentAdmin"} />
            </div>
            <div className='transactionHistoryTable'>
                {
                    (adminTransactions?.length === 0) ?

                        // dataless view
                        <div className="d-flex justify-content-center align-items-center mt-5 history-blurred-text text-center" >
                            <h3 className="mb-0">No Records</h3>
                        </div> :
                        <table className="table table-bordered border-golden">
                            <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                                <tr>
                                    {/* <th className='text-center' scope="col">SL.</th> */}
                                    <th className='text-center' scope="col">Gateway</th>
                                    <th className='text-center' scope="col">Status</th>
                                    <th className='text-center' scope="col">Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    adminTransactions?.length > 0 &&
                                    adminTransactions?.slice(visibleRow, visibleRow + 10).map((item, index) =>
                                        <tr key={item.id}>
                                            {/* <th className='text-center' scope="row">{index + 1}</th> */}
                                            <th className='text-center text-capitalize' scope="row">{item.gateway_name}</th>
                                            {
                                                (item.agent_status).toLowerCase() === "active" &&
                                                <th className='text-center text-capitalize' style={{ color: 'green' }} scope="row">{item.agent_status}</th>
                                            }
                                            {
                                                (item.agent_status).toLowerCase() === "pending" &&
                                                <th className='text-center text-capitalize' style={{ color: '#daaf37' }} scope="row">{item.agent_status}</th>
                                            }
                                            {
                                                (item.agent_status).toLowerCase() === "in_review" &&
                                                <th className='text-center text-capitalize' style={{ color: 'orange' }} scope="row">{"In Review"}</th>
                                            }
                                            {
                                                (item.agent_status).toLowerCase() === "cancel" &&
                                                <th className='text-center text-capitalize' style={{ color: 'red' }} scope="row">{item.agent_status}</th>
                                            }

                                            <td>
                                                <div
                                                    className='text-center'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setWalletModalData({
                                                            visible: true,
                                                            item
                                                        })
                                                    }}
                                                >
                                                    <BsExclamationCircle size={20} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                }

                {/* pagination buttons */}
                <div className={(adminTransactions?.length === 0) ? "d-none" : 'd-flex justify-content-end mb-3'}>
                    <div>
                        <button onClick={decreaseVisibleDataIndex} className={visibleRow === 0 ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow === 0 ? true : false}><Trans i18nKey="prev">PREV.</Trans></button>
                        <button onClick={increaseVisibleDataIndex} className={visibleRow + 10 >= adminTransactions?.length ? 'btn btn-sm disable-green-btn py-0 mx-1' : 'btn btn-sm btn-green py-0 mx-1'} disabled={visibleRow + 10 >= adminTransactions?.length ? true : false}><Trans i18nKey="next">NEXT</Trans></button>
                    </div>
                </div>
            </div>

            <Modal className='transactionModal' fullscreen={false} show={walletModalData.visible}>
                <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                    <div className='w-100'>
                        <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                            <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                <button onClick={closeWalletModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />

                                </button>

                                <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{toggleState[selectAgentAdminBankingState]?.cardTitle}</span>

                                <button onClick={closeWalletModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                    <ImCross />
                                </button>
                            </div>
                        </div>

                        <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                            <div className='row g-0 m-1'>
                                <div className="d-flex flex-column align-items-center justify-content-between">
                                    <img src={walletModalData?.item?.gateway_logo} className="" height="50px" alt="" />
                                    <p className='text-center m-0 p-0 mt-2' style={{ fontSize: "16px", color: "red", }}>
                                        <small>
                                            *** Deposit Amount limit : <span className='fw-bold'>{walletModalData?.item?.min_deposit_limit} to {walletModalData?.item?.max_deposit_limit}</span>
                                        </small>
                                        <br />
                                        <small>
                                            *** Exchange rate : <span className='fw-bold'>{walletModalData?.item?.currency_rate}</span>
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>
                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className='col'>Account Address:</div>
                                    <div className='col-7'>
                                        <textarea
                                            value={walletModalData?.item?.gateway_id}
                                            type="text"
                                            name="gateway_id"
                                            id="gateway_id"
                                            className="form-control rounded px-1 py-0"
                                            row="3"
                                            onChange={e => {
                                                let data = { ...walletModalData };
                                                data.item.gateway_id = e.target.value;
                                                setWalletModalData(data);
                                            }}
                                            placeholder="Account/Wallet Address"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Status:</div>
                                    <div style={
                                        walletModalData?.item?.agent_status === "pending"
                                            ? { color: "#D4AF37", textTransform: 'capitalize' }
                                            : walletModalData?.item?.agent_status === "active"
                                                ? { color: "#7DAA2F", textTransform: 'capitalize' }
                                                : walletModalData?.item?.agent_status === "in_review"
                                                    ? { color: "orange", textTransform: 'capitalize' }
                                                    : walletModalData?.item?.agent_status === "cancel"
                                                        ? { color: "#c30010", textTransform: 'capitalize' }
                                                        : { color: "#000", textTransform: 'capitalize' }
                                    }>
                                        {walletModalData?.item?.agent_status === "in_review" ? "In Review" : walletModalData?.item?.agent_status}
                                    </div>
                                </div>
                            </div>

                            <div className='row g-0'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className='imgModalContainer'>
                                        <div className="modalImage">
                                            {
                                                selectImage ? <img src={`${URL.createObjectURL(selectImage)}`} alt="gatewayImg" />
                                                    : <img src={walletModalData?.item?.gateway_image} alt="gatewayImgDB" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <p className='text-center'>✪ If you want to change your account, <br /> must be change the QR image</p>
                        <div className='my-4 d-flex flex-column align-items-center justify-content-center'>
                            <input className='d-none' type="file" id="profilePic" onChange={inputPic} />
                            <label htmlFor='profilePic'>
                                <div className='btn btn-primary'>
                                    <AiOutlineCloudUpload style={{ fontSize: "30px", marginRight: "10px" }} />
                                    <span>{t('uploadScreenShootButton')}</span>
                                </div>
                            </label>
                        </div>

                        <div className='my-4 d-flex flex-column align-items-center justify-content-center'>
                            <button className="btn btn-green w-50 mb-5" onClick={agentUpdateBtn}>Update</button>

                            {/* <button className='greenBtn' style={{ coursor: 'pointer' }} onClick={agentUpdateBtn}>
                                Update
                            </button> */}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default GatewayStatus;