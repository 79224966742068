import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
    name: 'customAlert',
    initialState: {
        show: false,
        message: null,
        type: null
    },
    reducers: {
        showCustomAlert: (state, action) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },

        closeCustomAlert: (state, action) => {
            state.show = false;
            state.message = null;
            state.type = null;
        }
    }
})

export const alertData = state => {
    let data = {
        status: state.customAlert.show,
        message: state.customAlert.message,
        type: state.customAlert.type
    }
    return data;
};
// export const alertMessage = state => state.customAlert.message;

export const { showCustomAlert, closeCustomAlert } = alertSlice.actions;

export default alertSlice.reducer;