import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { originPerfectMoney, rootUrl, } from '../../../../App';
// import { currentUser, fetchLogoutUser } from '../../../../Redux/userSlice';

import { userDetail } from '../../../../Redux/userSlice';
// import { showCustomAlert } from '../../../../Redux/alertSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';

const PerfectMoneyPayment = () => {
    let { am, id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);

    const [info, setInfo] = useState();

    const baseDomain = userInfo?.user?.user_base_origin;

    useEffect(() => {
        if (userInfo?.user?.id && am && id) {
            // console.log(userInfo, am, id);
            let stringValue = localStorage.getItem("bet7uUserKey")
            let value = JSON.parse(stringValue);
            dispatch(setInnerLoaderTrue());

            const controller = new AbortController();
            let signal = controller.signal;

            fetch(`${rootUrl}/payment/gateway/${id}/${originPerfectMoney}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                },
                signal,
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    setInfo(data[0]);
                    // console.log(data[0]);
                    // document.getElementById("Button1").click();
                })
                .catch(err => {
                    // navigate("/deposit");
                    console.log(err);
                    // dispatch(showCustomAlert({
                    //     type: "error",
                    //     message: `${err?.statusText}`,
                    // }));
                })
                .finally(() => {
                    dispatch(setInnerLoaderFalse());
                })
            return (() => {
                controller.abort();
            })
        } else {
            navigate(`/deposit${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`);
        }
    }, [])
    // }, [userInfo, dispatch, id, am, navigate])


    useEffect(() => {
        if (info?.id) {
            document.getElementById("Button1").click();
        }
    }, [info])

    return (
        <div>
            <form className='d-block' action={info?.gateway_url} method="POST">
                <p>
                    <input type="hidden" name="PAYEE_ACCOUNT" value={info?.gateway_id} />
                    <input type="hidden" name="PAYEE_NAME" value={info?.gateway_username} />
                    <input type="hidden" name="PAYMENT_AMOUNT" value={am} />
                    <input type="hidden" name="PAYMENT_UNITS" value="USD" />
                    <input type="hidden" name="STATUS_URL" value={`${baseDomain}perfectMoneyPaymentStatus`} />
                    <input type="hidden" name="STATUS_URL_METHOD" value="GET" />
                    <input type="hidden" name="PAYMENT_URL" value={`${baseDomain}perfectMoneyPaymentRedirect`} />
                    <input type="hidden" name="PAYMENT_URL_METHOD" value="GET" />
                    <input type="hidden" name="NOPAYMENT_URL" value={`${baseDomain}perfectMoneyNoPayment`} />
                    <input type="hidden" name="NOPAYMENT_URL_METHOD" value="GET" />
                    <input type="hidden" name="BAGGAGE_FIELDS" value="ORDER_NUM CUST_NUM" />
                    <input type="hidden" name="PAYMENT_ID" value={info?.transaction_id} />
                    <input type="hidden" name="ORDER_NUM" value="1" />
                    <input type="hidden" name="CUST_NUM" value={userInfo?.user?.id} />
                    <input type="submit" name="PAYMENT_METHOD" value="PerfectMoney account" id="Button1" />
                </p>
            </form>
        </div>
    );
};

export default PerfectMoneyPayment;