import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import loaderTop from '../../Assets/Images/statics/loder-top1.png';

const Progressbar = ({ percentage }) => {
    return (
        <div className="start-bg">
            <div className="started-progress-bar">
                <figure className="d-flex flex-column justify-content-center align-items-center">
                    <img
                        className="w-75"
                        src={loaderTop}
                        alt="sport" />
                </figure>
                <ProgressBar className="" animated now={percentage} label={`${percentage}%`} />
                <div className="text-center text-white mt-2">
                    Loading...
                </div>
            </div>
        </div>
    );
};

export default Progressbar;