import React, { useCallback, useState } from 'react';
import './PcsoHome.css';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FcPlus } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { origin, rootUrl } from '../../../App';
import { fetchLoginUserWallet, fetchLogoutUser, userDetail } from '../../../Redux/userSlice';
import YoutubeVideo from '../../../Components/CountryLotteryComponent/YoutubeVideo/YoutubeVideo';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../Redux/loaderSlice';
import { BsCheck } from 'react-icons/bs';
import { showCustomAlert } from '../../../Redux/alertSlice';
import { useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';

const PcsoHome = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);
    const [lotteryTime, setLotteryTime] = useState(null);
    const [selectedLotteryTime, setSelectedLotteryTime] = useState(null);
    const [dropDownData, setDropDownData] = useState(null);
    const [selectDropdownData, setSelectDropDownData] = useState(null);
    // console.log(selectDropdownData);
    const [listTotal, setListTotal] = useState(0.00);
    const [tableList, setTableList] = useState([
        {
            id: 1,
        },
    ]);
    const [currentLottery, setCurrentLottery] = useState([]);
    // const [lastBetResult, setLastBetResult] = useState([]);

    const takeDropDownData = (e) => {
        let currData = dropDownData.find(item => item.id === +e.target.value);
        setSelectDropDownData(currData)
    }

    const addRowInTable = () => {
        let data = [...tableList, {
            id: tableList.length + 1
        }]
        setTableList(data)
    }

    const fetchDropDownData = useCallback((id) => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            // dispatch(setInnerLoaderTrue())
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/lottery/settings/${id}/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data);
                    setDropDownData(data);
                    // setSelectDropDownData(data[0])
                    setSelectDropDownData(null)
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.detail}`,
                            }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
            // .finally(() => { dispatch(setInnerLoaderFalse()) })
        }
    }, [dispatch])

    // getting input data to post a bet
    const getInputDataFunction = (e, id) => {
        if (e.target.name === 'd_amount' || e.target.name === 'r_amount') {
            if (+e.target.value > +selectDropdownData.maximum_amount) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Maximum Limit : ${selectDropdownData.maximum_amount}`,
                }));
            } else if (+e.target.value < +selectDropdownData.minimum_amount) {
                dispatch(showCustomAlert({
                    type: "error",
                    message: `Minimum Amount : ${selectDropdownData.minimum_amount}`,
                }));
            } else {
                let selectItem = tableList.find(item => item.id === id);

                if (("" + selectItem.bet_number).length === selectDropdownData?.maximum_number) {
                    let newData = { ...selectItem };
                    newData[e.target.name] = +e.target.value;
                    // console.log(newData)
                    let list = [...tableList]
                    list[id - 1] = newData;
                    setTableList(list);
                } else {
                    dispatch(showCustomAlert({
                        type: "error",
                        message: `Please Input Bet Number`,
                    }));
                }

            }
        }
        if (e.target.name === 'bet_number') {
            // console.log(e.target.value);
            if ((e.target.value).length < selectDropdownData?.maximum_number) {
                let list = [...tableList]
                list[id - 1] = {
                    id: id,
                    bet_number: e.target.value,
                    d_amount: '',
                    r_amount: '',
                };
                setTableList(list);
            } else {
                let selectItem = tableList.find(item => item.id === id);
                let newData = { ...selectItem };
                newData['lottery_category'] = selectDropdownData?.id;
                newData['time_category'] = selectDropdownData?.time_category.id;
                newData['request_origin'] = "betkingly";
                newData[e.target.name] = e.target.value;
                // console.log(newData)
                let list = [...tableList]
                list[id - 1] = newData;
                setTableList(list);
            }
        }
    };

    const checkDigits = (a, id) => {
        let selectItem = tableList.find(item => item.id === id);
        // console.log(('' + selectItem.bet_number).length, selectDropdownData.maximum_number);

        if (('' + selectItem.bet_number).length !== selectDropdownData?.maximum_number) {
            dispatch(showCustomAlert({
                type: "error",
                message: `Enter Bet Number : ${selectDropdownData?.maximum_number} digit`,
            }));
        }
    }

    const betConfirmationFunction = (e) => {
        e.preventDefault();
        let payData = {
            total_amount: +listTotal.toFixed(2),
            discount_percentage: +selectDropdownData?.discount,
            discount_amount: +((listTotal.toFixed(2) * selectDropdownData?.discount) / 100).toFixed(2),
            bet_amount: +(listTotal.toFixed(2) - ((listTotal.toFixed(2) * selectDropdownData?.discount) / 100)).toFixed(2),
            lottery_id: currentLottery.id,
        };
        tableList.map(item => {
            item.total_amount = +listTotal.toFixed(2);
            item.discount_percentage = +selectDropdownData?.discount;
            item.discount_amount = +((listTotal.toFixed(2) * selectDropdownData?.discount) / 100).toFixed(2);
            item.bet_amount = +(listTotal.toFixed(2) - ((listTotal.toFixed(2) * selectDropdownData?.discount) / 100).toFixed(2)).toFixed(2);
            return item;
        });
        // console.log(payData);

        let finalData = [];
        tableList.map(item => {
            if (("" + item.bet_number).length === selectDropdownData?.maximum_number) {
                if (item.d_amount) {
                    if (!item.r_amount) {
                        item.r_amount = 0;
                    }
                } else {
                    item.d_amount = 0
                    if (!item.r_amount) {
                        item.r_amount = 0;
                        return null;
                    }
                }
                item.d_amount = +item.d_amount.toFixed(2);
                item.r_amount = +item.r_amount.toFixed(2);
                finalData.push(item);
            }
            return null;
        })

        if (finalData.length < 1) {
            dispatch(showCustomAlert({
                type: "error",
                message: `${'No data to submit'}`,
            }));
            return;
        };

        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue === null || undefined) {
            dispatch(showCustomAlert({
                type: "error",
                message: `${'User Not Available'}`,
            }));
            return;
        }

        dispatch(setInnerLoaderTrue());
        let value = JSON.parse(stringValue);
        fetch(`${rootUrl}/lottery/current-lottery/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value.value}`,
            }
        })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                if (data?.status === "active") {
                    return fetch(`${rootUrl}/lottery/pay/${origin}`, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            'Accept': 'application/json',
                            'Authorization': `Token ${value.value}`,
                        },
                        body: JSON.stringify(payData)
                    })
                }
            })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                dispatch(fetchLoginUserWallet());
                return fetch(`${rootUrl}/lottery/lotteries/${origin}`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        'Accept': 'application/json',
                        'Authorization': `Token ${value.value}`,
                    },
                    body: JSON.stringify(finalData)
                })
            })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                setSelectDropDownData(null);
                if (data?.length > 0) {
                    navigate(`/bet-success${userInfo?.user?.username ? ("?" + userInfo?.user?.username) : ''}`);
                }
            })
            .catch(err => {
                err.json().then(text => {
                    if (text?.error) {
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${text?.error}`,
                        }));
                        return;
                    }
                    if (text?.detail === "Invalid token.") {
                        // dispatch(fetchLogoutUser());
                        return;
                    }
                });
                dispatch(showCustomAlert({
                    type: "error",
                    message: `${err?.statusText}`,
                }));
            })
            .finally(() => { dispatch(setInnerLoaderFalse()) })
    };

    const selectedTime = () => {
        let selectedItem = lotteryTime.find(item => selectedLotteryTime?.id === item.id)
        return selectedItem?.lottery_time
    }

    useEffect(() => {
        setTableList([
            {
                id: 1,
            },
            {
                id: 2,
            },
            {
                id: 3,
            },
            {
                id: 4,
            },
            {
                id: 5,
            },
            {
                id: 6,
            },
            {
                id: 7,
            },
            {
                id: 8,
            },
            {
                id: 9,
            },
            {
                id: 10,
            },
        ])
    }, [lotteryTime, selectedLotteryTime, dropDownData, selectDropdownData])

    useEffect(() => {
        let total = 0
        tableList.map(item => total = total + (item.d_amount ? item.d_amount : 0) + (item.r_amount ? item.r_amount : 0));
        setListTotal(total)
    }, [tableList, listTotal])

    useEffect(() => {
        let stringValue = localStorage.getItem("bet7uUserKey");
        if (stringValue !== null) {
            dispatch(setInnerLoaderTrue())
            let value = JSON.parse(stringValue);
            fetch(`${rootUrl}/lottery/times/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    setLotteryTime(data);
                    setSelectedLotteryTime(data[0])
                    fetchDropDownData(data[0].id)
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.detail}`,
                            }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
            // .finally(() => { dispatch(setInnerLoaderFalse()) })

            // another function
            fetch(`${rootUrl}/lottery/current-lottery`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${value.value}`,
                }
            })
                .then(res => {
                    if (!res.ok) throw res;
                    else return res.json();
                })
                .then(data => {
                    // console.log(data)
                    setCurrentLottery(data);
                })
                .catch(err => {
                    err.json().then(text => {
                        if (text?.detail === "Invalid token.") {
                            dispatch(showCustomAlert({
                                type: "error",
                                message: `${text?.detail}`,
                            }));
                            dispatch(fetchLogoutUser());
                            return;
                        }
                    });
                })
            // .finally(() => { dispatch(setInnerLoaderFalse()) })


            // // another function 
            // fetch(`${rootUrl}/lottery/result-history/`, {
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Accept': 'application/json',
            //         'Authorization': `Token ${value?.value}`,
            //     }
            // })
            //     .then(res => {
            //         if (!res.ok) throw res;
            //         else return res.json();
            //     })
            //     .then(data => {
            //         // console.log(data)
            //         setLastBetResult(data?.filter((item) => item?.lottery?.id === data[0]?.lottery?.id));
            //     })
            //     .catch(err => {
            //         err.json().then(text => {
            //             if (text?.detail === "Invalid token.") {
            //                 dispatch(showCustomAlert({
            //                     type: "error",
            //                     message: `${text?.detail}`,
            //                 }));
            //                 dispatch(fetchLogoutUser());
            //                 return;
            //             }
            //         });
            //     })
            //     .finally(() => { dispatch(setInnerLoaderFalse()) })
        }

    }, [dispatch, fetchDropDownData])

    return (
        <div className='pcsoHome'>
            <div className='row g-0 flex-column justify-content-center align-items-center'>
                <div className='col-12 px-4'>
                    <div className='my-3'>
                        <YoutubeVideo title={<Trans i18nKey="liveStreamingTitle">LIVE STREAMING</Trans>} link={currentLottery?.youtube_link}></YoutubeVideo>
                    </div>

                    <div className="">
                        {
                            (currentLottery.status === "active" && lotteryTime) &&
                            lotteryTime.map(item =>
                                <div key={item.id} className='row g-0 mb-3 d-flex justify-content-evenly align-items-center' >
                                    {
                                        selectedLotteryTime?.id === item.id ?
                                            <div className='rounded row g-0 justify-content-between align-items-center p-1 w-100 ' style={{ backgroundImage: "linear-gradient(90deg, var(--yellow), var(--yellow))" }}>
                                                <div className='col-4 d-flex justify-content-start align-items-center'>
                                                    <div style={{ backgroundColor: "#FFF", marginRight: "5px", padding: "0 6px", borderRadius: "5px", fontWeight: "bold" }}>{item.lottery_time}</div>
                                                    <div className='vr me-2'></div>
                                                    <div style={{ backgroundColor: "var(--green)", marginRight: "5px", padding: "0 6px", borderRadius: "5px", fontWeight: "bold" }}><BsCheck color='var(--light)' size="20" /></div>
                                                </div>
                                                <div className='col-8'>
                                                    <Form.Select onChange={takeDropDownData} value={selectDropdownData?.id} className="pcso_select" aria-label="Default select example">
                                                        <option value={null}>{"SELECT GAME"}</option>
                                                        {
                                                            dropDownData &&
                                                            dropDownData.map(optionData => <option key={optionData.id} value={optionData.id}>{optionData?.lottery_category?.category_name}</option>)
                                                        }
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            :
                                            <div onClick={() => { setSelectedLotteryTime(item); fetchDropDownData(item.id) }} className='rounded row g-0 justify-content-between align-items-center p-1 w-100' style={{ backgroundColor: "var(--dark)", cursor: "pointer" }}>
                                                <div className='col-4 d-flex justify-content-start align-items-center'>
                                                    <div style={{ backgroundColor: "#FFF", marginRight: "5px", padding: "0 6px", borderRadius: "5px", fontWeight: "bold" }}>{item.lottery_time}</div>
                                                    <div className='vr'></div>
                                                </div>
                                                <div className='col-8'>
                                                    <Form.Select className="pcso_select" aria-label="Default select example" disabled>
                                                        <option value={null}>{"SELECT GAME"}</option>
                                                    </Form.Select>
                                                </div>
                                                {/* <div style={{ position: "absolute", right: "-7px", top: '-7px', height: "15px", width: "15px", borderRadius: "50%", backgroundColor: "var(--gray)" }}></div> */}
                                            </div>
                                    }

                                </div>)
                        }
                    </div>

                    {
                        selectDropdownData?.id &&
                        <div className='row g-0 justify-content-center align-items-center mb-3 mx-0'>
                            <div className='col-12 rounded text-center fw-bold' style={{ backgroundImage: "linear-gradient(90deg, #DD4BEC, #5A7CF7)", color: "#000000" }}>
                                <p className='p-1 py-2 m-0' style={{ fontSize: "12px" }}>{selectedTime()} : {selectDropdownData?.lottery_category?.category_name} (BET OF DIGITS {selectDropdownData?.maximum_number})</p>
                            </div>
                        </div>
                    }

                    <div className="row g-0 justify-content-center align-items-center" style={{ fontSize: "12px" }}>
                        <table className="table table-bordered border-golden">
                            <thead className="text-uppercase" style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                                <tr>
                                    <th className='text-center' scope="col">SL</th>
                                    <th className='text-center' scope="col">Bet Number</th>
                                    <th className='text-center' scope="col">D Amount</th>
                                    <th className='text-center' scope="col">R Amount</th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: "#fff", fontWeight: "bold", }}>
                                {
                                    tableList?.map((item, index) =>
                                        <tr key={item.id} className="text-dark">
                                            <td className='text-center'>{item.id}.</td>
                                            <td className='text-center p-0'>
                                                <input name="bet_number" onBlur={(e) => { checkDigits(e, item.id) }} onChange={(e) => { getInputDataFunction(e, item.id) }} value={item?.bet_number ? item?.bet_number : ""} type="text" pattern="[0-9]*" inputMode="numeric" maxLength={selectDropdownData?.maximum_number} size={selectDropdownData?.maximum_number} disabled={selectDropdownData?.id ? false : true} />
                                            </td>
                                            <td className='text-center p-0'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    {/* <button onClick={() => increaseDAmount(item.id)} className='tableGrayBtn px-1'>+</button> */}
                                                    <input name="d_amount" onChange={(e) => { getInputDataFunction(e, item.id) }} value={item?.d_amount ? item?.d_amount : ""} type="number" onWheel={event => event.target.blur()} placeholder={`$`} disabled={selectDropdownData?.id ? false : true} />
                                                    {/* <button className='tableGrayBtn px-1'>-</button> */}
                                                </div>
                                            </td>
                                            <td className='text-center p-0'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    {/* <button className='tableGrayBtn px-1'>+</button> */}
                                                    <input name="r_amount" onChange={(e) => { getInputDataFunction(e, item.id) }} value={item?.r_amount ? item?.r_amount : ""} type="number" onWheel={event => event.target.blur()} placeholder={`$`} disabled={(selectDropdownData?.id && selectDropdownData?.r_amount) ? false : true} />
                                                    {/* <button className='tableGrayBtn px-1'>-</button> */}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                        <div className="row gx-0" style={{ color: "#61FF39", fontSize: '13px' }}>
                            <div onClick={addRowInTable} className="col-1 d-flex justify-content-center align-items-center py-2" style={{ backgroundColor: "#575757", borderRadius: "5px" }}>
                                <FcPlus style={{ fontSize: "1.3rem", cursor: "pointer" }} />
                            </div>
                            <div className="col-3 d-flex justify-content-center text-center align-items-center mx-2" style={{ backgroundColor: "#575757", borderRadius: "5px" }}>
                                <small>TOTAL = ${listTotal ? listTotal.toFixed(2) : '00.00'}</small>
                            </div>
                            <div className="col d-flex justify-content-center text-center align-items-center me-2" style={{ backgroundColor: "#575757", borderRadius: "5px" }}>
                                <small>DISCOUNT ({selectDropdownData?.discount}%) = ${
                                    (+selectDropdownData?.discount > 0 && listTotal) ?
                                        ((listTotal.toFixed(2) * selectDropdownData?.discount) / 100).toFixed(2)
                                        : '00.00'
                                }
                                </small>
                            </div>
                            <div className="col d-flex justify-content-center text-center align-items-center" style={{ backgroundColor: "#575757", borderRadius: "5px" }}>
                                <small>BET AMOUNT = ${
                                    (+selectDropdownData?.discount > 0 && listTotal)
                                        ? (listTotal.toFixed(2) - ((listTotal.toFixed(2) * selectDropdownData?.discount) / 100).toFixed(2)).toFixed(2)
                                        : ((+selectDropdownData?.discount === 0) && listTotal) ? listTotal.toFixed(2) : '00.00'}</small>
                            </div>
                        </div>
                        <div className=" my-3">
                            <button onClick={betConfirmationFunction} className="w-100 btn btn-yellow"><Trans i18nKey="betNowButton">Bet Now</Trans></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PcsoHome;