import React, { useState } from "react";
import "./OfferCard.css";
import { useNavigate } from "react-router-dom";

import { rootUrl } from "../../App";
import { BsClock } from "react-icons/bs";

import useTime from "../../Hooks/useTime";

// import { useSelector } from "react-redux";
// import { userDetail } from "../../Redux/userSlice";
import OfferDetailsModal from "../OfferDetailsModal/OfferDetailsModal";

const OfferCard = ({ item }) => {
    const navigate = useNavigate();
    const { convertDate, convertTime } = useTime();
    // const user = useSelector(userDetail);

    const [offerCardDetailsModal, setOfferCardDetailsModal] = useState({
        visible: false,
        item: null
    });

    const openOfferCardDetailsModal = (item) => {
        // console.log(item);

        let tempData = {
            header: {},
            body: {},
        }

        tempData.header["image"] = `${item?.image}`;
        tempData.header["alt_text"] = `${item?.alt_text}`;

        if ((item?.video_url) && (!item?.image)) {
            tempData.header["video_url"] = `${item?.video_url}`;
        }

        tempData.header["title"] = `${item?.title}`;
        tempData.header["sub_title"] = `${item?.sub_title}`;

        tempData.body["description"] = item?.description;

        setOfferCardDetailsModal({
            visible: true,
            item: item,
        });
    }

    const closeOfferCardDetailsModal = () => {
        setOfferCardDetailsModal({
            visible: false,
            item: null,
        });
    }

    return (
        <div className="card mb-2">
            {
                item?.image &&
                <img className="rounded box" src={rootUrl + item?.image} alt={item?.alt_text} width={"100%"} height={"130"} />
            }
            {
                ((item?.video_url) && (!item?.image)) &&
                <iframe
                    className="rounded box"
                    width="100%"
                    height="130"
                    src={item?.video_url}
                    title={item?.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                >

                </iframe>
            }

            {/* {
                <div className="offer-card-video box">
                    <iframe
                        className="rounded"
                        width="100%"
                        // height="130px"
                        src={item?.video_url}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="offer card"
                    />
                </div>
            } */}

            <div className="p-2">

                <div className="d-flex flex-column">
                    <h5 className="mb-0">{item?.title}</h5>
                    <p className="mb-0">{item?.sub_title}</p>
                </div>

                <div className="d-flex justify-content-start align-items-center gap-2 py-0">
                    <BsClock className="" style={{ color: "var(--green)", fontSize: "0.9rem", fontWeight: "bold" }} />
                    <p className="mb-0">
                        <span><small>{convertDate(item?.started_at)} {convertTime(item?.started_at)}</small></span>
                        <small>{" ~ "}</small>
                        <span><small>{convertDate(item?.end_at)} {convertTime(item?.end_at)}</small></span>
                    </p>
                </div>
                <div className="d-flex justify-content-between align-items-center gap-3 py-1">
                    <button
                        onClick={() => navigate(
                            // (from === "affiliate") ?
                            "/verified_agent"
                            // :
                            // "/registration"
                        )}
                        className="btn btn-sm btn-yellow w-100"
                        // disabled={((user?.is_login) && (from === "promotion"))}
                        disabled={false}
                    >
                        {/* {(from === "affiliate") ? "Contact" : "Register Now"} */}
                        {"Contact"}
                    </button>

                    <button
                        onClick={() => openOfferCardDetailsModal(item)}
                        className="btn btn-sm btn-yellow w-100"
                    >
                        Details
                    </button>
                </div>
            </div>
            {
                (offerCardDetailsModal?.visible) &&
                <OfferDetailsModal item={offerCardDetailsModal} close={closeOfferCardDetailsModal} />
            }
        </div>
    );
};

export default OfferCard;