import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import mainLoaderImg from "../../../../Assets/LoaderImg/mainLoaderImg.svg";
import { fetchLoginUserWallet, fetchLogoutUser, userDetail } from '../../../../Redux/userSlice';
import { origin, originNameOnly, rootUrl } from '../../../../App';
import { showCustomAlert } from '../../../../Redux/alertSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../../../Redux/loaderSlice';

const PerfectMoneyPaymentRedirect = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);
    const [perfectmoneyPaymentGetWay, setPerfectmoneyPaymentGetWay] = useState(null);
    // console.log(perfectmoneyPaymentGetWay);

    const getQueryParams = (query = null) => (query || window.location.search.replace('?', '')).split('&').map(e => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});


    useEffect(() => {
        fetch(`${rootUrl}/payment/gateway/${origin}`)
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                // console.log(data);
                let currData = (data.find(item => item.gateway_name === "perfect_money"));
                // setAllPaymentGetWay(currData.gateway_title)
                setPerfectmoneyPaymentGetWay(currData);
            })
    }, [])


    useEffect(() => {
        let stringValue = localStorage.getItem("bet7uUserKey")
        if (stringValue !== null && perfectmoneyPaymentGetWay) {
            let value = JSON.parse(stringValue);
            if (userInfo?.user?.id) {
                dispatch(setInnerLoaderTrue());
                let tempData = getQueryParams();
                let confirmData = {
                    payment_gateway: "perfect_money",
                    deposit_type: "account", //kb changed this line
                    gateway_title: perfectmoneyPaymentGetWay.gateway_title, //kb changed this line
                    payment_batch: tempData.PAYMENT_BATCH_NUM,
                    receiver: userInfo?.user.id,
                    amount: tempData.PAYMENT_AMOUNT,
                    transaction_id: tempData.PAYMENT_ID,
                    referral: tempData.SUGGESTED_MEMO,
                    given_hash: tempData.V2_HASH,
                    payee_account: tempData.PAYEE_ACCOUNT,
                    payer_account: tempData.PAYER_ACCOUNT,
                    gateway_id: perfectmoneyPaymentGetWay.id,
                    request_origin: originNameOnly,
                };
                // console.log("payment Redirect", confirmData);

                // {'payment_gateway': ['perfect_money'], 'payment_batch': ['496774784'], 'receiver': ['1'], 'amount': ['11.00'], 
                // 'transaction_id': ['352006546'], 'referral': ['test referral'], 'payee_account': ['account number'],
                //  'deposit_type': ['account'], 'screenshot': [''], 'gateway_title': ['Perfect Money'], 'gateway_id': ['1']}

                fetch(`${rootUrl}/wallet/deposit/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Token ${value.value}`,
                    },
                    body: JSON.stringify(confirmData)
                })
                    .then(res => {
                        if (!res.ok) throw res;
                        else return res.json();
                    })
                    .then(data => {
                        navigate('/deposit');
                        dispatch(fetchLoginUserWallet());
                        dispatch(showCustomAlert({
                            type: "success",
                            message: `Deposit Successfully`,
                        }));
                    })
                    .catch(err => {
                        navigate('/deposit');
                        err.json().then(text => {
                            if (text?.error) {
                                dispatch(showCustomAlert({
                                    type: "error",
                                    message: `${text?.error}`,
                                }));
                                return;
                            }
                            if (text?.detail === "Invalid token.") {
                                // dispatch(setCustomAlertTrue({ massage: `${text?.detail}`, type: 'danger' }));
                                dispatch(fetchLogoutUser());
                                return;
                            }
                        });
                        dispatch(showCustomAlert({
                            type: "error",
                            message: `${err?.statusText}`,
                        }));
                    })
                    .finally(() => { dispatch(setInnerLoaderFalse()) })
            }
        }
    }, [userInfo?.user?.id, navigate, dispatch, perfectmoneyPaymentGetWay])

    return (
        <div>
            <div className='loaderStyle'>
                <div className='laderCircleArea mb-5'>
                    <div className='loaderInnerCircle'>
                        {/* <img src={mainLoaderImg} alt="" /> */}
                    </div>
                </div>
                {/* <ScaleLoader color="#d4af37" /> */}
            </div >
        </div>
    );
};

export default PerfectMoneyPaymentRedirect;