import React from 'react';
import './PcsoHowToPlay.css'
import YoutubeVideo from '../../../Components/CountryLotteryComponent/YoutubeVideo/YoutubeVideo';
import { Trans } from 'react-i18next';

const PcsoHowToPlay = () => {
    return (
        <div className="pcsoHowToPlay m-3">
            <YoutubeVideo title={<Trans i18nKey="howToPlayTitle">HOW TO PLAY</Trans>} link="https://www.youtube.com/embed/y54fIrUNtv0?autoplay=1&mute=1&enablejsapi=1" />
        </div>
    );
};

export default PcsoHowToPlay;