import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { origin, rootUrl } from "../App";

const initialState = {
    gameData: {
        gamesList: [],
        status: "ideal",
        error: null,
    },
    CurrRunGame: null
}

export const fetchGame = createAsyncThunk("game/fetchGame", async () => {
    const response = await fetch(`${rootUrl}/api/game_list/${origin}`);
    return response.json();
})

export const gameSlice = createSlice({
    name: 'fetchGameData',
    initialState: initialState,
    reducers: {
        setCurrRunGameFunc: (state, action) => {
            const { payload } = action;
            state.CurrRunGame = payload;
        },

    },
    extraReducers: {
        [fetchGame.pending]: (state, action) => {
            state.gameData.status = "loading"
        },
        [fetchGame.fulfilled]: (state, action) => {
            state.gameData.status = "success";
            const { payload } = action;
            state.gameData.gamesList = payload.response;
        },
        [fetchGame.rejected]: (state, action) => {
            state.gameData.status = "error"
        }
    }
})

export const selectGameData = state => state.game.gameData.gamesList;
export const gameLoadingStatus = state => state.game.gameData.status;
export const setCurrRunGame = state => state.game.CurrRunGame;

export const Atmosfera = state => (state.game.gameData.gamesList).filter(game => game.System === "906");  //liveCasino
export const AuthenticGaming = state => (state.game.gameData.gamesList).filter(game => game.System === "971"); //liveCasino
export const BetGames = state => (state.game.gameData.gamesList).filter(game => game.System === "990"); //liveCasino

export const Digitain = state => (state.game.gameData.gamesList).filter(game => game.System === "972");
export const GameArt = state => (state.game.gameData.gamesList).filter(game => game.System === "955"); //liveCasino
export const HoGaming = state => (state.game.gameData.gamesList).filter(game => game.System === "904"); //liveCasino
export const ISoftBetWL = state => (state.game.gameData.gamesList).filter(game => game.System === "887"); //liveCasino
export const LiveGames = state => (state.game.gameData.gamesList).filter(game => game.System === "934"); //liveCasino
export const LuckyStreak = state => (state.game.gameData.gamesList).filter(game => game.System === "980"); //liveCasino
export const OrientalGame = state => (state.game.gameData.gamesList).filter(game => game.System === "964"); //liveCasino
export const PragmaticPlay = state => (state.game.gameData.gamesList).filter(game => game.System === "960");
export const PragmaticPlayLive = state => (state.game.gameData.gamesList).filter(game => game.System === "913"); //liveCasino
export const ReevoGames = state => (state.game.gameData.gamesList).filter(game => game.System === "918"); //liveCasino
export const SAGaming = state => (state.game.gameData.gamesList).filter(game => game.System === "968"); //liveCasino
export const TVBet = state => (state.game.gameData.gamesList).filter(game => game.System === "900");

export const VivoGaming = state => (state.game.gameData.gamesList).filter(game => game.System === "945"); //liveCasino
export const XProGaming = state => (state.game.gameData.gamesList).filter(game => game.System === "942"); //liveCasino

export const Vir2alGames = state => (state.game.gameData.gamesList).filter(game => game.System === "886"); //virtual games page
export const LiveCasinoGame = state => (state.game.gameData.gamesList).filter(item => item.System !== "886" && item.System !== "972" && item.System !== "955" && item.System !== "960" && item.System !== "900" && item.System !== "918"); //live casino page
export const CasinoGame = state => (state.game.gameData.gamesList).filter(item => item.System === "918" || item.System === "955"); //casino page
export const LotteryGame = state => (state.game.gameData.gamesList).filter(item => item.System === "990" || item.System === "900"); //lottery page
export const SlotsGame = state => (state.game.gameData.gamesList).filter(item => item.System === "960"); // slots page

export const { setCurrRunGameFunc } = gameSlice.actions;
export default gameSlice.reducer;