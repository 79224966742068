import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { betfair_url } from "../App";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "./loaderSlice";

// Function to add cache data
export const addDataIntoCache = (cacheName = null, url = null, response = null) => {
    // Converting our response into Actual Response form
    const data = new Response(JSON.stringify(response));

    if ('caches' in window) {
        // Opening given cache and putting our data into it
        caches.open(cacheName).then((cache) => {
            cache.put(url, data);
            // alert('Data Added into cache!')
        });
    }
};

// Function to get cache data
export const getCacheData = async (cacheName, url) => {
    if (typeof caches === 'undefined') return null;

    const cacheStorage = await caches.open(cacheName);
    const cachedResponse = await cacheStorage.match(url);

    // If no cache exists
    if (!cachedResponse || !cachedResponse.ok) {
        return null
    } else {
        let data = await cachedResponse.json();
        // console.log(data);
        return data;
    }
};

export const deleteSpecificCacheData = (cacheName) => {
    if ("caches" in window) {
        caches.delete(cacheName).then(function (res) {
            // alert(cacheName);
            return res;
        });
    }
}

export const nextDate = () => {
    // let day = new Date().toDateString();

    let nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    let tomorrow = nextDay.toDateString();

    return tomorrow;
}

// all Game
export const getAllGameRequest = createAsyncThunk("user/getAllGameRequest", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        const cacheData = await getCacheData('allGameCache', `${betfair_url}/betfair/list-all/`);
        if (cacheData) {
            if (cacheData?.updateDate === new Date().toDateString()) {
                dispatch(setAllGame(cacheData));
                return null;
            } else {
                dispatch(setInnerLoaderTrue());
            }
        } else {
            dispatch(setInnerLoaderTrue());
        }

        const finalTodaySoccerList = [];
        const finalTodayTennisList = [];
        const finalTodayCricketList = [];

        const finalTomorrowSoccerList = [];
        const finalTomorrowTennisList = [];
        const finalTomorrowCricketList = [];

        const finalLeagueSoccerList = [];
        const finalLeagueTennisList = [];
        const finalLeagueCricketList = [];


        const allGameListReq = await fetch(`${betfair_url}/betfair/list-all/`);
        if (!allGameListReq.ok) throw allGameListReq;
        else {
            let allGameList = await allGameListReq.json();
            if (allGameList?.soccer) {
                const soccerComListReq = await fetch(`${betfair_url}/betfair/listcompetition/1/`);
                if (!soccerComListReq.ok) throw soccerComListReq;
                else {
                    let soccerComList = await soccerComListReq.json();
                    await soccerComList.map(item => {
                        const todayGameList = [];
                        const tomorrowGameList = [];
                        const leagueGameList = [];

                        (allGameList?.soccer).map(detailItem => {
                            if (detailItem?.competitionId === item?.Id && (new Date(detailItem?.openDate)?.toDateString() === new Date()?.toDateString())) {
                                todayGameList.push(detailItem);
                            }

                            if (detailItem?.competitionId === item?.Id && (new Date(detailItem?.openDate)?.toDateString() === nextDate())) {
                                tomorrowGameList.push(detailItem);
                            }

                            if (detailItem?.competitionId === item?.Id && ((new Date(detailItem?.openDate)?.toDateString() === nextDate()) || (new Date(detailItem?.openDate)?.toDateString() === new Date()?.toDateString()))) {
                                leagueGameList.push(detailItem);
                            }

                            return detailItem;
                        });


                        if (todayGameList.length > 0) {
                            // item["gameList"] = todayGameList;
                            finalTodaySoccerList.push({ ...item, gameList: todayGameList });
                        }

                        if (tomorrowGameList.length > 0) {
                            // item["gameList"] = tomorrowGameList;
                            // finalTomorrowSoccerList.push(item);
                            finalTomorrowSoccerList.push({ ...item, gameList: tomorrowGameList });
                        }

                        if (leagueGameList.length > 0) {
                            // item["gameList"] = leagueGameList;
                            // finalLeagueSoccerList.push(item);
                            finalLeagueSoccerList.push({ ...item, gameList: leagueGameList });
                        }
                        return item;
                    });
                }
            }

            if (allGameList?.tennis) {
                const tennisComListReq = await fetch(`${betfair_url}/betfair/listcompetition/2/`);
                if (!tennisComListReq.ok) throw tennisComListReq;
                else {
                    let tennisComList = await tennisComListReq.json();
                    await tennisComList.map(item => {
                        const todayGameList = [];
                        const tomorrowGameList = [];
                        const leagueGameList = [];

                        (allGameList?.tennis).map(detailItem => {
                            if (detailItem?.competitionId === item?.Id && (new Date(detailItem?.openDate)?.toDateString() === new Date()?.toDateString())) {
                                todayGameList.push(detailItem);
                            }

                            if (detailItem?.competitionId === item?.Id && (new Date(detailItem?.openDate)?.toDateString() === nextDate())) {
                                tomorrowGameList.push(detailItem);
                            }

                            if (detailItem?.competitionId === item?.Id && ((new Date(detailItem?.openDate)?.toDateString() === nextDate()) || (new Date(detailItem?.openDate)?.toDateString() === new Date()?.toDateString()))) {
                                leagueGameList.push(detailItem);
                            }

                            return detailItem;
                        });


                        if (todayGameList.length > 0) {
                            // item["gameList"] = todayGameList;
                            // finalTodayTennisList.push(item);
                            finalTodayTennisList.push({ ...item, gameList: todayGameList });
                        }

                        if (tomorrowGameList.length > 0) {
                            // item["gameList"] = tomorrowGameList;
                            // finalTomorrowTennisList.push(item);
                            finalTomorrowTennisList.push({ ...item, gameList: tomorrowGameList });
                        }

                        if (leagueGameList.length > 0) {
                            // item["gameList"] = leagueGameList;
                            // finalLeagueTennisList.push(item);
                            finalLeagueTennisList.push({ ...item, gameList: leagueGameList });
                        }

                        item["gameList"] = [];
                        return item;
                    });
                }
            }

            if (allGameList?.cricket) {
                const cricketComListReq = await fetch(`${betfair_url}/betfair/listcompetition/4/`);
                if (!cricketComListReq.ok) throw cricketComListReq;
                else {
                    let cricketComList = await cricketComListReq.json();
                    await cricketComList.map(item => {
                        const todayGameList = [];
                        const tomorrowGameList = [];
                        const leagueGameList = [];
                        (allGameList?.cricket).map(detailItem => {
                            if (detailItem?.competitionId === item?.Id && (new Date(detailItem?.openDate)?.toDateString() === new Date()?.toDateString())) {
                                todayGameList.push(detailItem);
                            }

                            if (detailItem?.competitionId === item?.Id && (new Date(detailItem?.openDate)?.toDateString() === nextDate())) {
                                // console.log(new Date(detailItem?.openDate)?.toDateString(), nextDate());
                                // console.log(detailItem);
                                tomorrowGameList.push(detailItem);
                            }

                            if (detailItem?.competitionId === item?.Id && ((new Date(detailItem?.openDate)?.toDateString() === nextDate()) || (new Date(detailItem?.openDate)?.toDateString() === new Date()?.toDateString()))) {
                                leagueGameList.push(detailItem);
                            }

                            return detailItem;
                        });


                        if (todayGameList.length > 0) {
                            // item["gameList"] = todayGameList;
                            // finalTodayCricketList.push(item);
                            finalTodayCricketList.push({ ...item, gameList: todayGameList });
                        }

                        if (tomorrowGameList.length > 0) {
                            // item["gameList"] = tomorrowGameList;
                            // finalTomorrowCricketList.push(item);
                            finalTomorrowCricketList.push({ ...item, gameList: tomorrowGameList });
                        }

                        if (leagueGameList.length > 0) {
                            // item["gameList"] = leagueGameList;
                            // finalLeagueCricketList.push(item);
                            finalLeagueCricketList.push({ ...item, gameList: leagueGameList });
                        }

                        item["gameList"] = [];
                        return item;
                    });
                }
            }
        };
        // console.log(finalTodayCricketList, finalTomorrowCricketList, finalLeagueCricketList);

        // console.log(finalTodaySoccerList, finalTomorrowSoccerList, finalTodayTennisList, finalTomorrowTennisList, finalTodayCricketList, finalTomorrowCricketList);

        addDataIntoCache('allGameCache', `${betfair_url}/betfair/list-all/`, {
            updateDate: new Date().toDateString(),
            today: [finalTodaySoccerList, finalTodayTennisList, finalTodayCricketList],
            tomorrow: [finalTomorrowSoccerList, finalTomorrowTennisList, finalTomorrowCricketList],
            league: [finalLeagueSoccerList, finalLeagueTennisList, finalLeagueCricketList]
        });
        return {
            updateDate: new Date().toDateString(),
            today: [finalTodaySoccerList, finalTodayTennisList, finalTodayCricketList],
            tomorrow: [finalTomorrowSoccerList, finalTomorrowTennisList, finalTomorrowCricketList],
            league: [finalLeagueSoccerList, finalLeagueTennisList, finalLeagueCricketList]
        };
    }
    catch (error) {
        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            // window.open(JSON.parse(stringValue).loginUrl, "_parent");
            localStorage.removeItem("bet7uUserKey");
            localStorage.removeItem("campaignVisible");
            throw rejectWithValue(errorData?.detail);
        } else {
            throw rejectWithValue(error.statusText);
        }
    }
    finally {
        dispatch(setInnerLoaderFalse());
    }
})


//inplay game
export const getInPlayGameRequest = createAsyncThunk("user/getInPlayGameRequest", async (payload, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        const cacheData = await getCacheData('inplayCache', `${betfair_url}/betfair/list-inplayevent/`);

        if (cacheData) {
            dispatch(setInPlayGame(cacheData));
        } else {
            dispatch(setInnerLoaderTrue());
        }

        const finalSoccerList = [];
        const finalCricketList = [];
        const finalTennisList = [];

        const soccerComListReq = await fetch(`${betfair_url}/betfair/listcompetition/1/`);
        if (!soccerComListReq.ok) throw soccerComListReq;
        else {
            let soccerComList = await soccerComListReq.json();
            const soccerGameListReq = await fetch(`${betfair_url}/betfair/list-inplayevent/1/`);
            if (!soccerGameListReq.ok) throw soccerGameListReq;
            else {
                let soccerGameList = await soccerGameListReq.json();

                soccerComList.map(item => {
                    let filterData = soccerGameList.filter(detailItem => detailItem?.competitionId === item?.Id);
                    if (filterData.length > 0) {
                        finalSoccerList.push({ ...item, gameList: filterData });
                    }
                    return item;
                });
            }
        }

        const tennisComListReq = await fetch(`${betfair_url}/betfair/listcompetition/2/`);
        if (!tennisComListReq.ok) throw tennisComListReq;
        else {
            let tennisComList = await tennisComListReq.json();
            // console.log(tennisComList);

            const tennisGameListReq = await fetch(`${betfair_url}/betfair/list-inplayevent/2/`);
            if (!tennisGameListReq.ok) throw tennisGameListReq;
            else {
                let tennisGameList = await tennisGameListReq.json();

                tennisComList.map(item => {
                    let filterData = tennisGameList.filter(detailItem => detailItem?.competitionId === item?.Id);
                    if (filterData.length > 0) {
                        finalTennisList.push({ ...item, gameList: filterData });
                    }
                    return item;
                });
            }
        }

        const cricketComListReq = await fetch(`${betfair_url}/betfair/listcompetition/4/`);
        if (!cricketComListReq.ok) throw cricketComListReq;
        else {
            let cricketComList = await cricketComListReq.json();

            const cricketGameListReq = await fetch(`${betfair_url}/betfair/list-inplayevent/4/`);
            if (!cricketGameListReq.ok) throw cricketGameListReq;
            else {
                let cricketGameList = await cricketGameListReq.json();
                // console.log(cricketComList, cricketGameList);

                cricketComList.map(item => {
                    let filterData = cricketGameList.filter(detailItem => detailItem?.competitionId === item?.Id);
                    if (filterData.length > 0) {
                        finalCricketList.push({ ...item, gameList: filterData });
                    }
                    return item;
                });
            }
        }

        if (cacheData) {
            if (JSON.stringify(cacheData) === JSON.stringify([finalSoccerList, finalTennisList, finalCricketList])) {
                return null;
            } else {
                addDataIntoCache('inplayCache', `${betfair_url}/betfair/list-inplayevent/`, [finalSoccerList, finalTennisList, finalCricketList]);
                return [finalSoccerList, finalTennisList, finalCricketList];
            }
        } else {
            addDataIntoCache('inplayCache', `${betfair_url}/betfair/list-inplayevent/`, [finalSoccerList, finalTennisList, finalCricketList]);
            return [finalSoccerList, finalTennisList, finalCricketList];
        }
    }
    catch (error) {
        let errorData = await error.json();
        if (errorData?.error) {
            throw rejectWithValue(errorData?.error);
        } else if (errorData?.detail === "Invalid token.") {
            // window.open(JSON.parse(stringValue).loginUrl, "_parent");
            localStorage.removeItem("bet7uUserKey");
            localStorage.removeItem("campaignVisible");
            throw rejectWithValue(errorData?.detail);
        } else {
            throw rejectWithValue(error.statusText);
        }
    }
    finally {
        dispatch(setInnerLoaderFalse());
    }
})



const sportsExchangeSlice = createSlice({
    name: "sportsExchange",
    initialState: {
        loaderVisible: false,
        status: 'idle', // 'idle' | 'loading' | 'success' | 'error'
        inplayGame: {
            soccer: [],
            tennis: [],
            cricket: []
        },
        todayGame: {
            soccer: [],
            tennis: [],
            cricket: []
        },
        tomorrowGame: {
            soccer: [],
            tennis: [],
            cricket: []
        },
        leagueGame: {
            soccer: [],
            tennis: [],
            cricket: []
        },

        selectedItem: { // in-play | today | tomorrow | league
            id: 1,
            navName: `In-Play`,
            name: ``,
        }
    },

    reducers: {
        setInPlayGame: (state, action) => {
            const { payload } = action;
            state.inplayGame.soccer = payload[0];
            state.inplayGame.tennis = payload[1];
            state.inplayGame.cricket = payload[2];
        },

        setAllGame: (state, action) => {
            const { payload } = action;
            state.todayGame.soccer = payload.today[0];
            state.todayGame.tennis = payload.today[1];
            state.todayGame.cricket = payload.today[2];

            state.tomorrowGame.soccer = payload.tomorrow[0];
            state.tomorrowGame.tennis = payload.tomorrow[1];
            state.tomorrowGame.cricket = payload.tomorrow[2];

            state.leagueGame.soccer = payload.league[0];
            state.leagueGame.tennis = payload.league[1];
            state.leagueGame.cricket = payload.league[2];
        },

        setSelectedItem: (state, action) => {
            const { payload } = action;

            if (payload.navName === "In-Play") {
                const tempData = { ...payload };
                tempData.gameList = state.inplayGame;
                state.selectedItem = tempData;
            }

            if (payload.navName === "Today") {
                const tempData = { ...payload };
                tempData.gameList = state.todayGame;
                state.selectedItem = tempData;
            }

            if (payload.navName === "Tomorrow") {
                const tempData = { ...payload };
                tempData.gameList = state.tomorrowGame;
                state.selectedItem = tempData;
            }

            if (payload.navName === "League") {
                const tempData = { ...payload };
                tempData.gameList = state.leagueGame;
                state.selectedItem = tempData;
            }
        }
    },

    extraReducers: {
        [getAllGameRequest.pending]: (state, action) => {
            state.status = "loading"
        },
        [getAllGameRequest.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;

            if (payload) {
                state.todayGame.soccer = payload.today[0];
                state.todayGame.tennis = payload.today[1];
                state.todayGame.cricket = payload.today[2];

                state.tomorrowGame.soccer = payload.tomorrow[0];
                state.tomorrowGame.tennis = payload.tomorrow[1];
                state.tomorrowGame.cricket = payload.tomorrow[2];

                state.leagueGame.soccer = payload.league[0];
                state.leagueGame.tennis = payload.league[1];
                state.leagueGame.cricket = payload.league[2];
            }
        },
        [getAllGameRequest.rejected]: (state, action) => {
            state.status = "failed";
        },


        [getInPlayGameRequest.pending]: (state, action) => {
            state.status = "loading"
        },
        [getInPlayGameRequest.fulfilled]: (state, action) => {
            state.status = "success";
            const { payload } = action;
            if (payload) {
                state.inplayGame.soccer = payload[0];
                state.inplayGame.tennis = payload[1];
                state.inplayGame.cricket = payload[2];
            }
        },
        [getInPlayGameRequest.rejected]: (state, action) => {
            state.status = "failed";
        },
    }
})
export const loaderState = state => state.loader.loaderVisible;
export const { setInPlayGame, setAllGame, setSelectedItem } = sportsExchangeSlice.actions;
export default sportsExchangeSlice.reducer;