import React, { useState } from 'react';
import useTime from '../../../Hooks/useTime';
import { Modal } from 'react-bootstrap';
import { BsExclamationCircle } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';
import { t } from 'i18next';

const MyBetsTable = ({ dataList, visibleRow, }) => {
    const { convertDate, convertTime } = useTime();
    const [betHistoryModal, setBetHistoryModal] = useState({
        visible: false,
        item: {},
    });

    const openBetHistoryModal = (item) => {
        setBetHistoryModal({
            visible: true,
            item,
        });
    }

    const closeBetHistoryModal = (item) => {
        setBetHistoryModal({
            visible: false,
            item: {},
        });
    }

    // console.log(dataList);

    return (
        <>{
            (dataList?.length > 0) &&
            // dataless view
            // <div className="d-flex justify-content-center align-items-center mt-5 history-blurred-text text-center" >
            //     <h3 className="mb-0">No Records</h3>
            // </div> 
            // :
            <table className="table table-bordered border-golden">
                <thead style={{ backgroundColor: "#3D3D3D", color: "#fff" }}>
                    <tr>
                        {/* <th className='text-center' scope="col">SL.</th> */}
                        <th className='text-center' scope="col">Date</th>
                        <th className='text-center' scope="col">Description</th>
                        <th className='text-center' scope="col">Amount</th>
                        <th className='text-center' scope="col">Details</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataList?.length > 0 &&
                        dataList?.slice(visibleRow, visibleRow + 10)?.map((item, index) =>
                            <tr key={item?.id || index}>
                                <td className='text-center'> {convertDate(item?.created_at)}</td>
                                <td style={{
                                    color:
                                        (item?.status === 0) ? "#D4AF37"
                                            : ((item?.status === 1) && (+(item?.payout - item?.bet) >= 0)) ? "#7DAA2F"
                                                : ((item?.status === 1) && (+(item?.payout - item?.bet) < 0)) ? "red"
                                                    : (item?.status === -1) ? "red"
                                                        : "blue",
                                    fontWeight: "normal", textAlign: "center", textTransform: 'capitalize'
                                }}
                                >
                                    {item?.product}</td>

                                <td style={{
                                    color:
                                        (item?.status === 0) ? "#D4AF37"
                                            : ((item?.status === 1) && (+(item?.payout - item?.bet) >= 0)) ? "#7DAA2F"
                                                : ((item?.status === 1) && (+(item?.payout - item?.bet) < 0)) ? "red"
                                                    : (item?.status === -1) ? "red"
                                                        : "blue",
                                    fontWeight: "normal", textAlign: "center", textTransform: 'capitalize'
                                }}>
                                    {
                                        (item?.status === 0) ? `${item?.bet}`
                                            : (item?.status === 1) && (+item?.payout > 0) ? `+ ${item?.payout}`
                                                : (item?.status === 1) && (+item?.payout === 0) ? `- ${item?.bet}`
                                                    : (item?.status === -1) && (+item?.payout > 0) ? `+ ${item?.payout}`
                                                        : (item?.status === -1) && (+item?.payout === 0) ? `- ${item?.bet}`
                                                            : "N/A"
                                    }
                                </td>
                                <td>
                                    <div
                                        className='text-center'
                                        style={{ cursor: "pointer" }}
                                        onClick={() => openBetHistoryModal(item)}
                                    >
                                        <BsExclamationCircle size={20} />
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        }

            {
                betHistoryModal?.visible &&
                <Modal className='transactionModal' fullscreen={false} show={betHistoryModal.visible}>
                    <Modal.Body className='d-flex justify-content-center'> {/* .modal-content */}
                        <div className='w-100'>
                            <div className='px-2 green-bg fw-bold' style={{ borderRadius: "10px 10px 0 0" }}>
                                <div className='d-flex align-items-center justify-content-center p-1' style={{ position: "relative" }}>
                                    <button onClick={closeBetHistoryModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", left: "0px" }} type="button" className="border-0 d-block d-md-none" data-bs-dismiss="modal" aria-label="Close">
                                        <ImCross />
                                    </button>

                                    <span style={{ color: '#fff', textAlign: "center", marginBottom: 0, fontSize: "18px" }} className='text-center'>{t('transactionDetailsModalTitle')}</span>

                                    <button onClick={closeBetHistoryModal} style={{ color: '#fff', backgroundColor: 'transparent', position: "absolute", top: "12%", right: "0px" }} type="button" className="border-0 d-none d-md-block" data-bs-dismiss="modal" aria-label="Close">
                                        <ImCross />
                                    </button>
                                </div>
                            </div>

                            <div className='mt-4 d-flex flex-column align-items-center justify-content-center'>
                                {
                                    // (betHistoryModal.item.type === "debit") ?
                                    //     <p style={{ color: "#c30010", fontWeight: "bold", textAlign: "center", fontSize: "30px", margin: 0 }}>- ${betHistoryModal?.payout}</p>
                                    //     : (betHistoryModal.item.type === "credit") &&
                                    <p style={{
                                        color:
                                            (betHistoryModal?.item?.status === 0) ? "#D4AF37"
                                                : ((betHistoryModal?.item?.status === 1) && (+(betHistoryModal?.item?.payout - betHistoryModal?.item?.bet) >= 0)) ? "#7DAA2F"
                                                    : ((betHistoryModal?.item?.status === 1) && (+(betHistoryModal?.item?.payout - betHistoryModal?.item?.bet) < 0)) ? "red"
                                                        : (betHistoryModal?.item?.status === -1) ? "red"
                                                            : "blue",
                                        fontWeight: "bold", textAlign: "center", fontSize: "30px", margin: 0
                                    }}>
                                        {
                                            (betHistoryModal?.item?.status === 0) ? `${betHistoryModal?.item?.bet}`
                                                : (betHistoryModal?.item?.status === 1) && (+betHistoryModal?.item?.payout > 0) ? `+ ${betHistoryModal?.item?.payout}`
                                                    : (betHistoryModal?.item?.status === 1) && (+betHistoryModal?.item?.payout === 0) ? `- ${betHistoryModal?.item?.bet}`
                                                        : (betHistoryModal?.item?.status === -1) && (+betHistoryModal?.item?.payout > 0) ? `+ ${betHistoryModal?.item?.payout}`
                                                            : (betHistoryModal?.item?.status === -1) && (+betHistoryModal?.item?.payout === 0) ? `- ${betHistoryModal?.item?.bet}`
                                                                : "N/A"
                                        }
                                        {/* + ${betHistoryModal?.item?.payout} */}
                                    </p>
                                }
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className='me-2'>{"Transaction Purpose"} :</div>
                                        <div>{betHistoryModal?.item?.bet_detail}</div>
                                    </div>
                                </div>
                            </div>

                            <div className='mx-2 my-4 pt-2' style={{ borderTop: "2px dashed #C2C2C2" }}>
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Transaction Date:</div>
                                        <div>{convertDate(betHistoryModal?.item?.created_at)} <span className="ms-1">{convertTime(betHistoryModal?.item?.created_at)}</span></div>
                                    </div>
                                </div>

                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Type:</div>
                                        <div>{
                                            // (betHistoryModal.item.type === "credit") ?
                                            //     "Credit"
                                            //     : (betHistoryModal.item.type === "debit") &&
                                            //     "Debit"
                                            betHistoryModal?.item?.product
                                        }</div>
                                    </div>
                                </div>
                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Bet Amount:</div>
                                        <div>{
                                            // (betHistoryModal.item.type === "credit") ?
                                            //     "Credit"
                                            //     : (betHistoryModal.item.type === "debit") &&
                                            //     "Debit"
                                            betHistoryModal?.item?.bet
                                        }</div>
                                    </div>
                                </div>

                                {/* <div className='row g-0 m-1'>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>Game ID:</div>
                                    <div>{betHistoryModal.item.i_gamedesc}</div>
                                </div>
                            </div> */}

                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Trx ID:</div>
                                        <div>{betHistoryModal?.item?.bet_id}</div>
                                    </div>
                                </div>

                                <div className='row g-0 m-1'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>Status:</div>
                                        <div className="fw-normal" style={{
                                            color:
                                                (betHistoryModal?.item?.status === 0) ? "#D4AF37"
                                                    : ((betHistoryModal?.item?.status === 1) && (+(betHistoryModal?.item?.payout - betHistoryModal?.item?.bet) >= 0)) ? "#7DAA2F"
                                                        : ((betHistoryModal?.item?.status === 1) && (+(betHistoryModal?.item?.payout - betHistoryModal?.item?.bet) < 0)) ? "red"
                                                            : (betHistoryModal?.item?.status === -1) ? "red"
                                                                : "blue",
                                            textTransform: "capitalize"
                                        }}>
                                            {
                                                (betHistoryModal?.item?.status === 0) ? `Pending`
                                                    : (betHistoryModal?.item?.status === 1) && (+(betHistoryModal?.item?.payout - betHistoryModal?.item?.bet) > 0) ? `Win`
                                                        : (betHistoryModal?.item?.status === 1) && (+(betHistoryModal?.item?.payout - betHistoryModal?.item?.bet) < 0) ? `Lose`
                                                            : (betHistoryModal?.item?.status === 1) && (+(betHistoryModal?.item?.payout - betHistoryModal?.item?.bet) === 0) ? `Draw`
                                                                : (betHistoryModal?.item?.status === -1) ? `Cancel`
                                                                    : "N/A"
                                            }
                                            {/* {betHistoryModal?.item?.status === 1 && "Success"} */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default MyBetsTable;