import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import loginIcon from "../../Assets/Icons/HeaderIcon/logInIcon.svg";
import error404Img from "../../Assets/Images/Error/Frame.svg"
import { useSelector } from 'react-redux';
import { userDetail } from '../../Redux/userSlice';


const PageNotFound = () => {
    const user = useSelector(userDetail);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.is_login) {
            navigate("/");
        }
    }, [user?.is_login, navigate]);

    return (
        <div className=''>
            <div >
                {/* <h1 className='text-center fs-1 mt-5 fw-bolder'>404</h1> */}
                <img className='w-100 mt-5' style={{ height: 'auto' }} src={error404Img} alt="" />
                <p className='text-center fw-bold'>Your connection error. Please try to login again.</p>
            </div>

            <div className='p-2 d-flex align-items-center justify-content-center' >
                <Link to="/login">
                    <button className='headerBtn signUpBtn'>
                        <img className='me-2' src={loginIcon} alt="" />
                        <Trans i18nKey="login">LOG IN</Trans>
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default PageNotFound;