const useTime = () => {
    const convertDate = (data) => {
        var date = new Date(`${data}`);
        return date.toLocaleDateString();
    }

    const convertTime = (data) => {
        var date = new Date(`${data}`);
        return date.toLocaleTimeString();
    }

    return {
        convertDate,
        convertTime
    }
}

export default useTime