import React from 'react';

const PerfectMoneyPaymentStatus = () => {
    const getQueryParams = (query = null) => (query || window.location.search.replace('?', '')).split('&').map(e => e.split('=').map(decodeURIComponent)).reduce((r, [k, v]) => (r[k] = v, r), {});
    // console.log("status", getQueryParams());

    return (
        <div>

        </div>
    );
};

export default PerfectMoneyPaymentStatus;