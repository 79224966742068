import React from 'react';
import './Header.css';
// import logo from "../../Assets/logo/logo.svg";
import loginIcon from "../../Assets/Icons/HeaderIcon/logInIcon.svg";
import signUpIcon from "../../Assets/Icons/HeaderIcon/signUpIcon.svg";
// import refreshIcon from "../../Assets/Icons/HeaderIcon/refreshIcon.svg";

// import accountsIcon from "../../Assets/Icons/HeaderIcon/accountsIcon.svg";
// import profileIcon from "../../Assets/Icons/HeaderIcon/profileIcon.svg";
// import logoutIcon from "../../Assets/Icons/HeaderIcon/logoutIcon.svg";

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userDetail } from '../../Redux/userSlice';
import { FaBell } from 'react-icons/fa';
import { useLiveNotifications } from '../../Hooks/useLiveNotifications';
import { AiFillLeftCircle } from 'react-icons/ai';
// import { NavDropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
// import { setInnerLoaderTrue } from '../../Redux/loaderSlice';
import { fetchLoginUserWallet } from '../../Redux/userSlice';
import useGetGSBalance from '../../Hooks/useGetGSBalance';

import userProfileIcon from "../../Assets/Icons/user-profile-icon.svg";
import { showCustomAlert } from '../../Redux/alertSlice';
import { setInnerLoaderFalse, setInnerLoaderTrue } from '../../Redux/loaderSlice';

const Header = ({ pageTitle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector(userDetail);
    // const userWallet = useSelector(currentUserWallet);
    // const [activeRefresh, setActiveRefresh] = useState(false);

    const { seenNotifications, } = useLiveNotifications();
    const { gsBalanceGetFetchAsync } = useGetGSBalance();

    let exchangeRedirected = localStorage.getItem("exchngd") || false;

    let stringValue = localStorage.getItem("bet7uUserKey");
    let value = JSON.parse(stringValue);

    const handleAccountAsMenuFunctionalities = async () => {
        try {
            dispatch(setInnerLoaderTrue());
            const successData = await gsBalanceGetFetchAsync();
            if (successData) {
                dispatch(fetchLoginUserWallet());
                navigate("/account");
                dispatch(setInnerLoaderFalse());
            }

        } catch (error) {
            handleFetchError(error);
        }
    };

    const handleFetchError = (error) => {
        dispatch(showCustomAlert({
            type: "error",
            message: `Try Again!`,
        }));
        dispatch(setInnerLoaderFalse());
    };

    // useEffect(() => {
    //     if (user?.is_login) {
    //         dispatch(fetchLoginUserWallet());
    //     }
    // }, [user?.is_login, dispatch,])

    // useEffect(() => {
    //     let refreshTimer;
    //     if (activeRefresh) {
    //         // Start refreshing

    //         // console.log('Refreshing...');
    //         dispatch(fetchLoginUserWallet());
    //         refreshTimer = setTimeout(() => {
    //             // Stop refreshing after 1 second
    //             // console.log('Stopped refreshing.');
    //             setActiveRefresh(false);
    //         }, 1000);
    //     }
    //     // else {
    //     //     // Start a timer to refresh after 30 seconds if not already refreshing

    //     //     // console.log('Waiting for next refresh...');
    //     //     refreshTimer = setTimeout(() => {
    //     //         setActiveRefresh(true);
    //     //     }, 30000);
    //     // }
    //     return () => clearInterval(refreshTimer);
    // }, [activeRefresh, dispatch]);

    // const refreshBtn = () => {
    //     setActiveRefresh(true);
    // };

    // const logoutFunction = async (item) => {
    //     // console.log('object');
    //     dispatch(setInnerLoaderTrue());
    //     gsBalanceGet();
    //     let currData = dispatch(fetchLogoutUser());
    //     if (currData?.error) return;
    //     navigate("/");
    // }

    return (
        <div className='row g-0 justify-content-between align-items-center py-1 px-2 header'>
            <div className='col-5 d-flex justify-content-start justify-content-md-start align-items-center position-relative'>
                <div className=''>
                    <AiFillLeftCircle size='30' color='var(--dark)' onClick={() => { navigate(-1) }} style={{ cursor: "pointer", marginRight: "5px" }} />
                </div>
                <h1 className="fw-bold text-uppercase mb-0 py-0" style={{ color: "#000", textDecoration: "none", fontSize: "0.8rem" }}>
                    {pageTitle}
                    {/* <img className='logo' src={logo} alt="" /> */}
                </h1>
            </div>
            {
                ((value?.is_login) || (user?.is_login)) &&
                <div className='col-7 d-flex justify-content-end'>
                    {/* 
                    <div className="customAmountBtn">
                        <div onClick={refreshBtn} className='d-flex align-items-center mx-2 ' style={{ cursor: "pointer" }}>
                            <div className='d-flex align-items-center'><img className={activeRefresh ? 'rotating' : ""} src={refreshIcon} alt="" style={{ height: "14px" }} /></div>
                        </div>
                        {
                            (+userWallet?.credit_balance >= 0) ?
                                <span className='ps-1 ps-md-2'>{userWallet ? (+userWallet?.total_balance || 0).toFixed(2) : "_.__"}</span>
                                :
                                <>
                                    <Spinner animation="grow" size="sm" />
                                </>
                        }
                        <div onClick={() => { (exchangeRedirected === "true") || navigate(`/deposit${user?.user?.username ? ("?" + user?.user?.username) : ''}`) }} style={{ width: '25px', height: '25px', marginLeft: "6px", cursor: (exchangeRedirected === "true") ? "default" : "pointer", }} className='amountDepositBtn'>+</div>
                    </div>
                     */}
                    {
                        (exchangeRedirected === "true") ||
                        <Link className={seenNotifications?.length > 0 ? "me-3 betNotificationNumber blink-image" : "me-3 betNotificationNumber"} to={`/notifications${user?.user?.username ? ("?" + user?.user?.username) : ''}`}>
                            <FaBell size={22} color="var(--dark)" />
                            {
                                seenNotifications?.length > 0 &&
                                <span>{(seenNotifications?.length > 9) ? "9+" : seenNotifications?.length ? seenNotifications?.length : ""}</span>
                            }
                        </Link>
                    }
                    {
                        (exchangeRedirected === "true") ||
                        // <Link
                        //     onClick={() => handleAccountAsMenuFunctionalities()}
                        //     className={""} to={`/account`}
                        // >
                        // </Link>
                        <img onClick={() => handleAccountAsMenuFunctionalities()} src={userProfileIcon} alt={"user"} width={"27"} style={{ cursor: "pointer" }} />
                        // <NavDropdown title={
                        //     // <FaUserCog size={24} color="var(--dark)" />
                        //     <img src={userProfileIcon} alt={"user"} width={"22"} />
                        // } id="navbarScrollingDropdown">
                        //     <Link className="dropdown-item" to={`/banking-profile${user?.user?.username ? ("?" + user?.user?.username) : ''}`}>
                        //         <img src={accountsIcon} alt="accounts" /> <span className="ms-2"><Trans i18nKey="accounts">Accounts</Trans></span>
                        //     </Link>
                        //     <Link className="dropdown-item" to={`/user-profile-edit${user?.user?.username ? ("?" + user?.user?.username) : ''}`}>
                        //         <img src={profileIcon} alt="profile" /> <span className="ms-2"><Trans i18nKey="profile">Profile</Trans></span>
                        //     </Link>
                        //     <Link className="dropdown-item" onClick={logoutFunction}>
                        //         <img src={logoutIcon} alt="logout" /> <span className="ms-2" style={{ color: "var(--tomato)" }}><Trans i18nKey="logout">Logout</Trans></span>
                        //     </Link>
                        // </NavDropdown>
                    }
                </div>
            }
            {
                ((value?.is_login) || (user?.is_login)) ||

                <div className='col-7 d-flex justify-content-end'>
                    <Link to={"/registration"}>
                        <button className='headerBtn loginUpBtn'>
                            <img className='me-2' src={signUpIcon} alt="" />
                            <Trans i18nKey="signup">SIGN UP</Trans>
                        </button>
                    </Link>

                    <Link to="/login">
                        <button className='headerBtn signUpBtn'>
                            <img className='me-2' src={loginIcon} alt="" />
                            <Trans i18nKey="login">LOG IN</Trans>
                        </button>
                    </Link>



                    {/* <button className='headerBtn signUpBtn' onClick={pushNotification}>
                            notice
                        </button> */}
                </div>
            }
        </div >
    )
};

export default Header;