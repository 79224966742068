import { React, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams, } from 'react-router-dom';
import { useDispatch, useSelector, } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

// import defaultLogo from "./Assets/logo/defaultLogo.png";

import './App.css';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Registration from './Pages/Registration/Registration';
import RegMailConfirm from './Pages/RegMailConfirm/RegMailConfirm';

import UserProfile from './Pages/UserProfile/UserProfile';
import ChangePassword from './Pages/BankingPages/ChangePassword/ChangePassword';
// import Menu from './Pages/Menu/Menu';
// import Lottery from './Pages/Lottery/Lottery';
// import DemoPage from './Pages/DemoPage/DemoPage';
import BankingProfile from './Pages/BankingPages/BankingProfile/BankingProfile';
import ReferralPage from './Pages/BankingPages/ReferralPage.js/ReferralPage';
import LayoutWithHeaderBNavigate from './Layout/LayoutWithHeaderBNavigate';
import Deposit from './Pages/BankingPages/DepositPages/Deposit/Deposit';
import Withdrawal from './Pages/BankingPages/WithdrawalPages/Withdrawal/Withdrawal';
import QRMethodDeposit from './Pages/BankingPages/DepositPages/QRMethodDeposit/QRMethodDeposit';
import QRBankingWithdrawal from './Pages/BankingPages/WithdrawalPages/QRBankingWithdrawal/QRBankingWithdrawal';
// import Sport from './Pages/Sport/Sport';
import Transfer from './Pages/BankingPages/TransferPages/Transfer/Transfer';
import Convert from './Pages/BankingPages/Convert/Convert';
import AgentAdmin from './Pages/BankingPages/AgentBanking/AgentAdmin/AgentAdmin';
import Payment from './Pages/BankingPages/Payment/Payment';
import CustomAlert from './Components/CustomAlert/CustomAlert';
import { fetchLoginUserData, fetchLoginUserWallet, userDetail, } from './Redux/userSlice';
import Contact from './Pages/ContactPage/Contact';
import LivePage from './Pages/LivePage/LivePage';
import PrivateRoute from './PrivateRoute/PrivateRoute';
// import { fetchGame } from './Redux/gameSlice';
// import Slots from './Pages/Slots/Slots';
// import Casino from './Pages/Casino/Casino';
// import VirtualGames from './Pages/VirtualGames/VirtualGames';
// import LiveCasino from './Pages/LiveCasino/LiveCasino';
import RegMailVerify from './Pages/RegMailVerify/RegMailVerify';
import RegSuccess from './Pages/RegSuccess/RegSuccess';
import LayoutForCountryLottery from './Layout/LayoutForCountryLottery';
import PerfectMoneyPayment from './Pages/BankingPages/DepositPages/PerfectMoneyPayment/PerfectMoneyPayment';
import PcsoHome from './Pages/PCSO/PcsoHome/PcsoHome';
import TermsConditionsPage from './Pages/TermsConditionsPage/TermsConditionsPage';
import NotificationsPage from './Pages/NotificationsPage/NotificationsPage';
// import SportsPage from './Pages/SportsPage/SportsPage';
import PcsoBetHistory from './Pages/PCSO/PcsoBetHistory/PcsoBetHistory';
import PcsoPrize from './Pages/PCSO/PcsoPrize/PcsoPrize';
import PcsoHowToPlay from './Pages/PCSO/PcsoHowToPlay/PcsoHowToPlay';
import PcsoAbout from './Pages/PCSO/PcsoAbout/PcsoAbout';
import PcsoResults from './Pages/PCSO/PcsoResults/PcsoResults';
import ThHome from './Pages/TH/ThHome/ThHome';
import ThBetHistory from './Pages/TH/ThBetHistory/ThBetHistory';
import THResults from './Pages/TH/THResults/THResults';
import ThPrize from './Pages/TH/ThPrize/ThPrize';
import ThHowToPlay from './Pages/TH/ThHowToPlay/ThHowToPlay';
import ForgotPasswordPage from './Pages/ForgotPasswordPage/ForgotPasswordPage';
import ForgotPasswordSuccess from './Pages/ForgotPasswordSuccess/ForgotPasswordSuccess';
import LoaderModal from './Components/LoaderModal/LoaderModal';
// import LayoutWithoutHeaderBackButton from './Layout/LayoutWithoutHeaderBackButton';
// import Demo2Page from './Pages/Demo2Page/Demo2Page';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
// import { setInnerLoaderFalse, setInnerLoaderTrue } from './Redux/loaderSlice';
// import GetStarted from './Pages/GetStarted/GetStarted';
// import SportsExchange from './Pages/SportsExchange/SportsExchange';
import Launcher from './Pages/Launcher/Launcher';
import Sportsbook from './Pages/Sportsbook/Sportsbook';
import BetSuccessComponent from './Components/SuccessComponent/BetSuccessComponent';
import { useBanner } from './Hooks/useBanner';
import VerifiedAgents from './Pages/VerifiedAgents/VerifiedAgents';
import OldUser from './Pages/UserProfile/OldUser';
import Languages from './Pages/Languages/Languages';
import { setInnerLoaderFalse, setInnerLoaderTrue } from './Redux/loaderSlice';
// import SportsExchangeGameDetail from './Pages/SportsExchange/SportsExchangeGameDetail';
import { addDataIntoCache, getCacheData, } from './Redux/sportsExchangeSlice';
// import { sportsExchangeSecondaryNavigationContent } from './Data/SportsExchangePageData';
import PerfectMoneyNoPayment from './Pages/BankingPages/DepositPages/PerfectMoneyPayment/PerfectMoneyNoPayment';
import PerfectMoneyPaymentStatus from './Pages/BankingPages/DepositPages/PerfectMoneyPayment/PerfectMoneyPaymentStatus';
import PerfectMoneyPaymentRedirect from './Pages/BankingPages/DepositPages/PerfectMoneyPayment/PerfectMoneyPaymentRedirect';
import Progressbar from './Components/Progressbar/Progressbar';
import Account from './Pages/BankingPages/AccountPage/Account';
import MovableHome from './Components/Movable/MovableHome';
import Affiliate from './Pages/Affiliate/Affiliate';
import Promotion from './Pages/Promotion/Promotion';
// import useGetGSBalance from './Hooks/useGetGSBalance';
// import GSBalanceMergerPopUp from './Components/GSBalanceMergerPopUp/GSBalanceMergerPopUp';
// import { addDataIntoCache, getCacheData } from './Redux/sportsExchangeSlice';

export const rootUrl = "https://www.k7k.asia";
// export const betfair_url = "http://173.212.254.73:81";
// export const betfair_url = "https://www.k7k.asia";
// export const rootUrl = "http://192.168.0.131:8001";
export const betfair_url = rootUrl;
export const mother_site = "https://9imax.com/";
// export const mother_site = "http://localhost:3001/";

// export const gameLaunch_site = "http://localhost:3001/";
// export const gameLaunch_site = "https://9imax.com/";
// export const gameLaunch_site = "https://1test.betkxk.com/";
// export const gameLaunch_site = "https://live.9imax.com/";
// export const gameLaunch_site = "https://3test.betkingly.com/";
export const gameLaunch_site = "https://tvlive.9imax.com";

// export const sportLaunch_site = "http://localhost:3001";
// export const sportLaunch_site = "https://betkingly.com/";
// export const sportLaunch_site = "https://2test.betkingly.com/";
export const sportLaunch_site = "https://tvlive.9imax.com";

// export const exchangeLaunch_site = "http://localhost:3001/";
export const exchangeLaunch_site = "https://9imax.com/";

export const originNameOnly = `betkingly`; // change here only to change origin
// export const originNameOnly = `mitalisports`; // change here only to change origin
// export const originNameOnly = `betbaj`; // change here only to change origin

export const extraOrigin = `request_origin=${originNameOnly}`;
export const origin = `?${extraOrigin}`;

export const originPerfectMoney = `?request_origin=betkingly`;

export const RE_DIGIT = new RegExp(/^\d+$/);

export const gameLaunchFunc = (gameCode) => {
  const mainDiv = document.getElementById("main");
  const getHeight = mainDiv.scrollHeight;
  const getWidth = mainDiv.clientWidth;
  // console.log(getHeight, getWidth, mainDiv);

  const myWindow = window.open(`${gameCode}`, `<div id="demo"></div>`, `width=${getWidth},height=${getHeight}`);
  myWindow.opener.document.getElementById("demo").innerHTML = `<div id=""></div>`;
}


function App() {
  const dispatch = useDispatch();
  // const location = useLocation();
  const user = useSelector(userDetail);
  // let stringValue = localStorage.getItem("bet7uUserKey");
  const [selectedFragmentId, setSelectedFragmentID] = useState(0);
  const [percentage, setPercentage] = useState(((new Date())?.getSeconds()) % 100);
  const [progressBar, setProgressBar] = useState(true);
  const progressTimer = 500;
  const { appBG, getStartedDataState, getStartedData } = useBanner();

  const [logo, setLogo] = useState(null);

  const [oldUser, setOldUser] = useState(false);
  const [getStartRef, setGetStartRef] = useState(localStorage.getItem("getStartAccess"));
  const [searchParams,] = useSearchParams();
  const navigate = useNavigate();

  // const { gsBalanceGet } = useGetGSBalance();
  // const userWallet = useSelector(currentUserWallet);
  // const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    dispatch(setInnerLoaderTrue());
    if (!user?.is_login) {
      const refFromParam = searchParams.get("ref");
      const exchangeRedirectedToken = searchParams.get("tfkhgjfecb646c6c2b7fe0fae3fb183d0036d25ba7f50eslkgdhjloken"); // token
      const exchanged = searchParams.get("excnbvcbnm3m6bm35b45n3m45bnhanged"); // exchange
      // console.log(parseInt(system));
      if (refFromParam) {
        localStorage.setItem("ref", refFromParam);
        // dispatch(setInnerLoaderTrue());
        navigate("/registration");
      }
      else if (exchangeRedirectedToken && exchanged) {
        // dispatch(setInnerLoaderTrue());
        setGetStartRef(true);

        localStorage.setItem("bet7uUserKey", JSON.stringify({
          is_login: true,
          value: exchangeRedirectedToken,
          old_user: false,
        }));

        localStorage.setItem("exchngd", true);
        localStorage.setItem("exchangeState", +exchanged);
        localStorage.setItem("getStartAccess", true);

        // dispatch(getAllGameRequest());
        dispatch(fetchLoginUserData());
        dispatch(fetchLoginUserWallet());
        navigate("/sports-exchange");

        setSelectedFragmentID(1);

        // dispatch(setSelectedItem(sportsExchangeSecondaryNavigationContent[+exchanged - 1]));
      } else {
        const fetchLogoFromCache = async () => {
          // console.log(appBG);
          // if (appBG) {
          const cacheDataOfLogo = await getCacheData('logoCache', `${rootUrl}/statics/logo/`);

          // Get the current time
          const currentTime = new Date();

          // Assuming you have a cached time stored as a Date object
          const cachedTimeString = cacheDataOfLogo?.updateDate; /* Get the cached time from wherever it's stored */

          // Convert the cached time string to a Date object
          const cachedTime = new Date(cachedTimeString);

          // Define a threshold (in milliseconds) for when to hit the API
          const cacheExpirationThreshold = (60 * 1000) * 60 * 24; // 24 hour (adjust as needed)

          // Calculate the time difference in milliseconds
          const timeDifference = currentTime - cachedTime;

          // console.log(timeDifference, currentTime, cachedTime, cacheExpirationThreshold);

          if (cacheDataOfLogo) {
            if (timeDifference < cacheExpirationThreshold) {
              setLogo(cacheDataOfLogo?.data);
              return null;
            }
          }

          fetch(`${rootUrl}/site-related/site-list/${origin}`)
            .then(res => {
              if (!res.ok) throw res;
              else return res.json();
            })
            .then(data => {
              // console.log(data);

              // setLogo(rootUrl + data?.link_logo);
              addDataIntoCache('logoCache', `${rootUrl}/statics/logo/`, {
                data: rootUrl + data?.link_logo,
                updateDate: new Date()
              });

              fetchLogoFromCache();
              // make sure to catch any error
              // .catch(console.error());
              window.document.title = document.title = data?.web_title;
            })
            .catch(err => { })
        };
        fetchLogoFromCache();

        // dispatch(fetchGame());
        // dispatch(getAllGameRequest());
        dispatch(fetchLoginUserData());
      }

      dispatch(setInnerLoaderFalse());
      return;
    }
    // localStorage.setItem("exchngd", false);
    let stringValue = localStorage.getItem("bet7uUserKey");
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);
      const old_user = value?.old_user;

      setOldUser(old_user);

      localStorage.setItem("bet7uUserKey", JSON.stringify({
        is_login: true,
        value: value.value,
        old_user: old_user,
      }));
      // dispatch(setLoaderTrue());
      // dispatch(fetchGame());
      // dispatch(getAllGameRequest());
      // dispatch(fetchLoginUserData());
      // dispatch(fetchLoginUserWallet());
      // navigate("/");
    }
    dispatch(setInnerLoaderFalse());
  }, [dispatch, user?.is_login, searchParams, navigate]);


  //========================| Notification |========================
  const reqNotificationPermission = async () => {
    const notificationPermission = await Notification.requestPermission();
    if (notificationPermission === 'granted') {
      // pushNotification();
    } else if (notificationPermission === 'denied') {
      // alert('You Denied for Notification');
    }
  }

  useEffect(() => {
    reqNotificationPermission();
  }, [])

  // useEffect(() => {
  //   dispatch(fetchGame());
  // }, [dispatch]);

  // useEffect(() => {
  //   let stringValue = localStorage.getItem("bet7uUserKey");

  //   if (stringValue !== null) {
  //     if (+userWallet?.gs_balance > 0) {
  //       setOpenPopUp(true);
  //     }
  //   }
  // }, [userWallet]);

  // const closePopUp = () => {
  //   setOpenPopUp(false);

  //   gsBalanceGet();
  //   dispatch(setInnerLoaderFalse());
  // }

  useEffect(() => {
    if (
      // getStartRef === null &&
      getStartedDataState === false &&
      getStartedData?.length > 0 &&
      appBG?.length > 0
    ) {
      if (percentage === 100) {
        setTimeout(() => {
          setProgressBar(false);
        }, progressTimer);
      } else {
        setPercentage(100);
      }
    }
    else {
      setTimeout(() => {
        setPercentage(80);
      }, progressTimer);
    }
  }, [progressTimer, getStartedDataState, getStartedData, appBG, percentage]);


  const setTrueStart = () => {
    let startData = localStorage.getItem("getStartAccess")
    setGetStartRef(startData);
  }

  if (oldUser === true) {
    return (
      <div className="App row g-0 justify-content-center" style={{ backgroundImage: `url(${appBG && appBG[0]?.banner_image})` }}>
        <div className='main' id="main">
          <OldUser setOldUser={setOldUser} />
        </div>
      </div>
    )
  }

  return (
    <div className="App row g-0 justify-content-center" style={{ backgroundImage: `url(${appBG && appBG[0]?.banner_image})` }}>
      {/* {
        openPopUp &&
        <GSBalanceMergerPopUp status={openPopUp} closePopUp={closePopUp} />
      } */}
      {
        (percentage <= 100 &&
          progressBar) &&
        <Progressbar percentage={percentage} />
      }
      <div className={(percentage <= 100 && progressBar) ? "d-none" : 'main'} id="main">
        <ScrollToTop selectedFragmentId={selectedFragmentId}></ScrollToTop>
        {
          getStartRef &&
          <MovableHome setSelectedFragmentID={setSelectedFragmentID} />
        }

        <AnimatePresence initial={false} >
          <Routes>
            <Route index element={<Home getStartRef={getStartRef} setTrueStart={setTrueStart} logo={logo} selectedFragmentId={selectedFragmentId} setSelectedFragmentID={setSelectedFragmentID} />} />

            <Route path={`pcso-lottery/*`} element={
              <LayoutWithHeaderBNavigate>
                <LayoutForCountryLottery logo={logo} lotteryType={"pcso"} setSelectedFragmentID={setSelectedFragmentID} />
              </LayoutWithHeaderBNavigate>
            } >
              <Route path='' element={<PrivateRoute><PcsoHome /></PrivateRoute>} />
              <Route path='bet_history' element={<PrivateRoute><PcsoBetHistory /></PrivateRoute>} />
              <Route path='result' element={<PcsoResults page={'pcso'}></PcsoResults>} />
              <Route path='prize' element={< PcsoPrize />} />
              <Route path='how_to_play' element={< PcsoHowToPlay />} />
              <Route path='about' element={< PcsoAbout />} />
            </Route>

            <Route path={`th-lottery/*`} element={
              <LayoutWithHeaderBNavigate>
                <LayoutForCountryLottery logo={logo} lotteryType={"th"} setSelectedFragmentID={setSelectedFragmentID} />
              </LayoutWithHeaderBNavigate>
            } >
              <Route path='' element={<PrivateRoute><ThHome /></PrivateRoute>} />
              <Route path='bet_history' element={<PrivateRoute><ThBetHistory /></PrivateRoute>} />
              <Route path='result' element={<THResults page={'pcso'}></THResults>} />
              <Route path='prize' element={< ThPrize />} />
              <Route path='how_to_play' element={< ThHowToPlay />} />
              <Route path='about' element={< PcsoAbout />} />
            </Route>

            <Route path="/launcher" element={<><Launcher /></>} />
            <Route path="/sportsbook" element={<LayoutWithHeaderBNavigate><Sportsbook /></LayoutWithHeaderBNavigate>} />
            {/* <Route path="/sports-exchange" element={<><SportsExchange /></>} />
            <Route path="/sports-exchange-game-detail/:gameId/:eventName/:hasFancy/:isPremium" element={<><SportsExchangeGameDetail /></>} /> */}
            <Route path="/notifications" element={<LayoutWithHeaderBNavigate><NotificationsPage /></LayoutWithHeaderBNavigate>} />
            <Route path="/webinar" element={<LayoutWithHeaderBNavigate><LivePage /></LayoutWithHeaderBNavigate>} />
            <Route path="/languages" element={<LayoutWithHeaderBNavigate><Languages /></LayoutWithHeaderBNavigate>} />
            <Route path="/contact" element={<LayoutWithHeaderBNavigate><Contact /></LayoutWithHeaderBNavigate>} />
            <Route path="/terms-conditions" element={<LayoutWithHeaderBNavigate><TermsConditionsPage /></LayoutWithHeaderBNavigate>} />
            <Route path="/verified_agent" element={<LayoutWithHeaderBNavigate><VerifiedAgents /></LayoutWithHeaderBNavigate>} />

            <Route path="/affiliate" element={<LayoutWithHeaderBNavigate><Affiliate /></LayoutWithHeaderBNavigate>} />
            <Route path="/promotion" element={<LayoutWithHeaderBNavigate><Promotion /></LayoutWithHeaderBNavigate>} />


            <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
            <Route path="/user-profile-edit" element={<PrivateRoute><LayoutWithHeaderBNavigate><UserProfile /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/change-password" element={<PrivateRoute><LayoutWithHeaderBNavigate><ChangePassword /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/banking-profile" element={<PrivateRoute><LayoutWithHeaderBNavigate><BankingProfile /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/referral" element={<PrivateRoute><LayoutWithHeaderBNavigate><ReferralPage /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/deposit" element={<PrivateRoute><LayoutWithHeaderBNavigate><Deposit /></LayoutWithHeaderBNavigate></PrivateRoute>} />

            <Route path="/perfectMoneyPaymentStatus" element={<PrivateRoute><LayoutWithHeaderBNavigate><PerfectMoneyPaymentStatus /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/perfectMoneyPaymentRedirect" element={<PrivateRoute><LayoutWithHeaderBNavigate><PerfectMoneyPaymentRedirect /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/perfectMoneyNoPayment" element={<PrivateRoute><LayoutWithHeaderBNavigate><PerfectMoneyNoPayment /></LayoutWithHeaderBNavigate></PrivateRoute>} />

            <Route path="/qr-deposit" element={<PrivateRoute><LayoutWithHeaderBNavigate><QRMethodDeposit /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/withdrawal" element={<PrivateRoute><Withdrawal /></PrivateRoute>} />
            <Route path="/qr-banking-withdrawal" element={<PrivateRoute><LayoutWithHeaderBNavigate><QRBankingWithdrawal /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path='/prefectMoney/:am/:id/:date' element={<PrivateRoute><LayoutWithHeaderBNavigate><PerfectMoneyPayment /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/transfer" element={<PrivateRoute><LayoutWithHeaderBNavigate><Transfer /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/convert" element={<PrivateRoute><LayoutWithHeaderBNavigate><Convert /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            <Route path="/payment" element={<PrivateRoute><LayoutWithHeaderBNavigate><Payment /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            {
              user?.user?.agent &&
              <Route path="/agent-admin" element={<PrivateRoute><LayoutWithHeaderBNavigate><AgentAdmin /></LayoutWithHeaderBNavigate></PrivateRoute>} />
            }


            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/forgot-password-success" element={<ForgotPasswordSuccess />} />

            <Route path="/bet-success" element={<LayoutWithHeaderBNavigate><BetSuccessComponent /></LayoutWithHeaderBNavigate>} />
            {
              (!user?.is_login) &&
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/regMailConfirm" element={<RegMailConfirm />} />
                <Route path="/regMailVerify" element={<RegMailVerify />} />
                <Route path="/regSuccess" element={<RegSuccess />} />
              </>
            }

            <Route path="*" element={<PageNotFound />} />
          </Routes>


          <CustomAlert />
          <LoaderModal />
        </AnimatePresence>
      </div>
    </div >
  );
}

export default App;