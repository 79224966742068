import React from 'react';
import './LoaderModal.css';
import { useSelector } from 'react-redux';
import { loaderState } from '../../Redux/loaderSlice';
import { RotatingLines } from 'react-loader-spinner';

const LoaderModal = () => {
    const loader = useSelector(loaderState);
    // console.log(loader);

    return (
        <section className={loader ? "loader-spinner" : "d-none"}>
            <div className="d-flex flex-column justify-content-center align-items-center spinner-square">
                <RotatingLines
                    strokeColor="white"
                    strokeWidth="3"
                    animationDuration="0.9"
                    width="40"
                    visible={true}
                />
                <p className="text-white fw-bold pt-md-1 mb-0">Loading</p>
            </div>
        </section >
    );
};

export default LoaderModal;