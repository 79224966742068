import React, { useState, useEffect } from 'react';
import './PcsoResultAccordion.css';
import { BsClock } from "react-icons/bs";
import useTime from '../../../Hooks/useTime';
import { FaMinus, FaPlus } from 'react-icons/fa';
import PcsoArrowBar from '../PcsoArrowBar/PcsoArrowBar';
import golden_plus from "../../../Assets/Icons/Lottery/plus.svg";
import golden_minus from "../../../Assets/Icons/Lottery/minus.svg";


const PcsoResultAccordion = ({ item, active, setActive }) => {
    const [open, setOpen] = useState(false);
    const { convertDate } = useTime();

    useEffect(() => {
        if (item.day === active.day) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [active, item])


    return (
        <div className='mb-1 mx-2'>
            <div className={`${open ? "openAccordionBar" : 'closeAccordionBar'}`}>
                <div style={{ backgroundColor: '#3d3d3d' }} className={`p-1 d-flex align-items-center`} >
                    <button onClick={() => setActive(item)} className="realBtn d-flex justify-content-center align-items-center ms-2">
                        {/* <FaPlus color='#000000' /> */}
                        <img src={golden_plus} alt="open result accordion" />
                    </button>
                    <span style={{ color: '#fff' }} className='ps-4'>{convertDate(item?.data[0]?.lottery?.result_announce_date)}</span>
                </div>
            </div>

            <div className={`${open ? "openAccordion row g-0" : "closeAccordion row g-0"}`}>
                <div className='col-12 mb-1'>
                    <div className='row g-0 rounded' style={{ height: "100%", backgroundColor: "#FFFFFF" }}>
                        <div className='col d-flex align-items-center justify-content-center rounded-start' style={{ backgroundColor: "var(--lightGray)", maxWidth: '50px' }}>
                            <button onClick={() => setActive(item)} className="realBtn d-flex justify-content-center align-items-center">
                                {/* <FaMinus color='#000000' /> */}
                                <img src={golden_minus} alt="open result accordion" />
                            </button>
                        </div>

                        <div className='col d-flex align-items-center justify-content-center' style={{ backgroundColor: "var(--dark)", maxWidth: '50px' }}>
                            <p className='verticalP' style={{ writingMode: 'vertical-rl', textOrientation: 'upright', color: "var(--light)", margin: 0, fontWeight: "bold" }}>LIVE RESULTS</p>
                        </div>

                        <div className="col d-flex justify-content-center rounded-0 rounded-end align-items-center video-frame m-0">
                            {
                                open &&
                                <iframe className="" width="" autoPlay='0' height="100%" src={item?.data[0]?.lottery?.youtube_link} title={'res'} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            }
                        </div>
                    </div>
                </div>

                <div className='col-12 mt-2'>
                    <div className="row g-0 d-flex flex-column justify-content-center align-items-start">
                        <div className="col">
                            <div className="row gx-0 d-flex justify-content-center align-items-center mb-1 fw-bold" style={{ color: "var(--light)", backgroundColor: "var(--green)", borderRadius: "5px" }}>
                                <div className="col-6 d-flex justify-content-center align-items-center py-1 my-1" style={{ borderRight: "2px solid var(--light)" }}>{convertDate(item?.data[0]?.lottery?.result_announce_date)}</div>
                                <div className="col-6 d-flex justify-content-center align-items-center py-1 my-1">
                                    <BsClock className="" style={{ color: "var(--light)", fontSize: "1.5rem", fontWeight: "bold" }} />
                                </div>
                            </div>

                            {
                                item?.data?.map((element, index) =>
                                    element?.publish &&
                                    <PcsoArrowBar key={index} category={element?.lottery_category} totalDigits={element?.total_digit} lotteryDigits={element?.lottery_digits}></PcsoArrowBar>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PcsoResultAccordion;
