import React from 'react';
import { BsPatchCheckFill } from 'react-icons/bs';

const TransactionReport = ({ report, amount, }) => {
    return (
        <div className="mx-4" style={{ color: "#000" }}>
            {/* <div className="row g-0 mx-4 text-center fw-bold">
                <p className="" style={{ color: "var(--green)" }}>{report}</p>
            </div> */}
            <div className="py-4 rounded-4" style={{ backgroundImage: "linear-gradient(to right,#D1C026, #EBFF00)", }}>
                <div className="row g-0 d-flex justify-content-center align-items-center text-center fw-bold mb-0">
                    <div className="col-9" style={{ borderBottom: "2px solid #000", }}>
                        <p className="mb-1" style={{ fontSize: "24px", color: "#000" }}>{report}</p>
                    </div>
                </div>
                <div className="row g-0 mx-4 d-flex align-items-center justify-content-center text-center fw-bold">
                    <p className="" style={{ fontSize: "40px", }}>${parseFloat(amount).toFixed(2)}
                        <BsPatchCheckFill className="mb-2 ms-5" size={30} color={"var(--green)"} />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TransactionReport;